import * as ecomexMiddleware from "middleware/ecomex";
import { types } from "reducers/ecomex";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { delay } from "redux-saga";
import { call, put, take, takeLatest } from "redux-saga/effects";
import { downloadPdf, downloadXls } from "util/download";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.ECOMEX_OPERATION_TYPE_CHANGE_REQUEST, ecomexOperationTypeChange),

    takeLatest(types.LIST_ECOMEX_OPERATIONS_REQUEST, listEcomexOperations),
    takeLatest(types.MORE_LIST_ECOMEX_OPERATIONS_REQUEST, moreListEcomexOperations),
    takeLatest(types.LIST_ECOMEX_OPERATIONS_PRE_REQUEST, listEcomexOperationsPre),
    takeLatest(types.DOWNLOAD_LIST_ECOMEX_OPERATIONS_REQUEST, downloadOperationsList),
    takeLatest(types.ECOMEX_OPERATION_DETAIL_REQUEST, getEcomexOperationDetail),
    takeLatest(types.DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_REQUEST, downloadOperationsDetail),

    takeLatest(types.LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST, listEcomexInformativeRegime),
    takeLatest(types.MORE_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST, moreListEcomexInformativeRegime),
    takeLatest(types.LIST_ECOMEX_INFORMATIVE_REGIME_PRE_REQUEST, listEcomexInformativeRegimePre),
    takeLatest(types.DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST, downloadInformativeRegimeList),
    takeLatest(types.ECOMEX_INFORMATIVE_REGIME_DETAIL_REQUEST, getEcomexInformativeRegimeDetail),
    takeLatest(types.DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_REQUEST, downloadInformativeRegimeDetail),

    takeLatest(types.LIST_ECOMEX_ALERTS_AND_DUES_REQUEST, listEcomexAlertsAndDues),
    takeLatest(types.MORE_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST, moreListEcomexAlertsAndDues),
    takeLatest(types.DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST, downloadAlertsAndDuesList),
    takeLatest(types.ECOMEX_SEND_ACCEPT_TRANSACTION, sendAcceptEcomexTransaction),
];

export default sagas;

function* ecomexOperationTypeChange({ optionSelected }) {
    const response = yield call(ecomexMiddleware.operationTypeChange, optionSelected);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", [
                "ecomex.consultOperations.list",
                "ecomex.informativeRegime.list",
            ]),
        );
        yield put({ type: types.ECOMEX_OPERATION_TYPE_CHANGE_FAILURE });
    } else {
        const { operationsTypes } = response.data.data;

        yield put({
            type: types.ECOMEX_OPERATION_TYPE_CHANGE_SUCCESS,
            operationsTypes,
        });
    }
}

function* listEcomexOperations({
    pageNumber,
    numberPerPage,
    optionSelected,
    ecomexState,
    operationType,
    beneficiaryName,
    operationNumber,
    openDateFrom,
    openDateTo,
    dueDateFrom,
    dueDateTo,
}) {
    const response = yield call(
        ecomexMiddleware.listEcomexOperations,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    );

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.consultOperations.list"]),
        );
        yield put({ type: types.LIST_ECOMEX_OPERATIONS_FAILURE });
    } else {
        const { ecomexOperations, totalPages } = response.data.data;

        yield put({
            type: types.LIST_ECOMEX_OPERATIONS_SUCCESS,
            ecomexOperations,
            pageNumber,
            numberPerPage,
            totalPages,
        });
    }
}

function* moreListEcomexOperations({
    pageNumber,
    numberPerPage,
    optionSelected,
    ecomexState,
    operationType,
    beneficiaryName,
    operationNumber,
    openDateFrom,
    openDateTo,
    dueDateFrom,
    dueDateTo,
}) {
    const response = yield call(
        ecomexMiddleware.listEcomexOperations,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    );

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.consultOperations.list"]),
        );
        yield put({ type: types.MORE_LIST_ECOMEX_OPERATIONS_FAILURE });
    } else {
        const { ecomexOperations, totalPages } = response.data.data;

        yield put({
            type: types.MORE_LIST_ECOMEX_OPERATIONS_SUCCESS,
            ecomexOperations,
            pageNumber,
            numberPerPage,
            totalPages,
        });
    }
}

function* listEcomexOperationsPre({ optionSelected }) {
    const response = yield call(ecomexMiddleware.listEcomexOperationsPre, optionSelected);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.consultOperations.list"]),
        );
        yield put({ type: types.LIST_ECOMEX_OPERATIONS_PRE_FAILURE });
    } else {
        const {
            ecomexOperations,
            operationsTypes,
            beneficiaryNames,
            numberPerPageOptions,
            totalPages,
        } = response.data.data;

        yield put({
            type: types.LIST_ECOMEX_OPERATIONS_PRE_SUCCESS,
            ecomexOperations,
            operationsTypes,
            beneficiaryNames,
            numberPerPageOptions,
            totalPages,
        });
    }
}

function* downloadOperationsList({ summary, format }) {
    const { type, data } = yield call(ecomexMiddleware.downloadOperationsList, summary, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LIST_ECOMEX_OPERATIONS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "ecomex.consultOperations.list",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", [
                "ecomex.consultOperations.list",
            ]),
        );
        yield call(delay, 3000);

        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LIST_ECOMEX_OPERATIONS_SUCCESS });
    }
}

function* getEcomexOperationDetail({ selectedOperation }) {
    const response = yield call(ecomexMiddleware.getEcomexOperationDetail, selectedOperation);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.consultOperations.list"]),
        );
        yield put({ type: types.ECOMEX_OPERATION_DETAIL_FAILURE });
    } else {
        const {
            settlementsDetail,
            notificationsDetail,
            swiftMessagesDetail,
            ticketsDetail,
            duesDetail,
            dueDatesDetail,
            usesDetail,
        } = response.data.data;

        yield put({
            type: types.ECOMEX_OPERATION_DETAIL_SUCCESS,
            settlementsDetail,
            notificationsDetail,
            swiftMessagesDetail,
            ticketsDetail,
            duesDetail,
            dueDatesDetail,
            usesDetail,
        });
    }
}

function* downloadOperationsDetail({ tabSelected, identification, format, fileName }) {
    const { type, data } = yield call(ecomexMiddleware.downloadOperationsDetail, tabSelected, identification);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "ecomex.consultOperations.list",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", [
                "ecomex.consult.operations.details",
            ]),
        );
        yield call(delay, 3000);

        const { content, fileNameForSwiftMessage } = data.data;
        const fileNameFormat = `${fileName}.${format.toLowerCase()}`;
        const fileNameToDownload = fileNameForSwiftMessage || fileNameFormat;

        if (format.toLowerCase() === "pdf") {
            downloadPdf(fileNameToDownload, content);
        } else {
            downloadXls(fileNameToDownload, content);
        }

        yield put({ type: types.DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_SUCCESS });
    }
}

function* listEcomexInformativeRegime({
    pageNumber,
    numberPerPage,
    optionSelected,
    ecomexState,
    operationType,
    destination,
    operationNumber,
    payDateFrom,
    payDateTo,
    dueDateFrom,
    dueDateTo,
    officialDateFrom,
    officialDateTo,
    settlementDateFrom,
    settlementDateTo,
    embDateFrom,
    embDateTo,
}) {
    const response = yield call(
        ecomexMiddleware.listEcomexInformativeRegime,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    );

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.informativeRegime.list"]),
        );
        yield put({ type: types.LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE });
    } else {
        const { ecomexInformativeRegime, totalPages } = response.data.data;

        yield put({
            type: types.LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS,
            ecomexInformativeRegime,
            pageNumber,
            numberPerPage,
            totalPages,
        });
    }
}

function* moreListEcomexInformativeRegime({
    pageNumber,
    numberPerPage,
    optionSelected,
    ecomexState,
    operationType,
    destination,
    operationNumber,
    payDateFrom,
    payDateTo,
    dueDateFrom,
    dueDateTo,
    officialDateFrom,
    officialDateTo,
    settlementDateFrom,
    settlementDateTo,
    embDateFrom,
    embDateTo,
}) {
    const response = yield call(
        ecomexMiddleware.listEcomexInformativeRegime,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    );

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.informativeRegime.list"]),
        );
        yield put({ type: types.MORE_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE });
    } else {
        const { ecomexInformativeRegime, totalPages } = response.data.data;

        yield put({
            type: types.MORE_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS,
            ecomexInformativeRegime,
            pageNumber,
            numberPerPage,
            totalPages,
        });
    }
}

function* listEcomexInformativeRegimePre({ optionSelected, ecomexState }) {
    const response = yield call(ecomexMiddleware.listEcomexInformativeRegimePre, optionSelected, ecomexState);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.informativeRegime.list"]),
        );
        yield put({ type: types.LIST_ECOMEX_OPERATIONS_PRE_FAILURE });
    } else {
        const {
            ecomexInformativeRegime,
            ecomexStates,
            operationsTypes,
            numberPerPageOptions,
            totalPages,
            optionSelectedFromPre,
        } = response.data.data;

        yield put({
            type: types.LIST_ECOMEX_INFORMATIVE_REGIME_PRE_SUCCESS,
            optionSelected,
            ecomexInformativeRegime,
            ecomexStates,
            operationsTypes,
            numberPerPageOptions,
            totalPages,
            ecomexState,
            optionSelectedFromPre,
        });
    }
}

function* downloadInformativeRegimeList({ summary, format }) {
    const { type, data } = yield call(ecomexMiddleware.downloadInformativeRegimeList, summary, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "ecomex.informativeRegime.list",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", [
                "ecomex.informativeRegime.list",
            ]),
        );

        yield call(delay, 3000);

        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS });
    }
}

function* getEcomexInformativeRegimeDetail({ selectedInformativeRegime, optionSelected }) {
    const response = yield call(
        ecomexMiddleware.getEcomexInformativeRegimeDetail,
        selectedInformativeRegime,
        optionSelected,
    );

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.message, "error", ["ecomex.informativeRegime.list"]),
        );
        yield put({ type: types.ECOMEX_INFORMATIVE_REGIME_DETAIL_FAILURE });
    } else {
        const {
            negociationsDetail,
            affectationsDetail,
            advanceExportPaymentsDetail,
            impurationsAndNegotiations,
            boardingPermitsNegotiations,
        } = response.data.data;

        yield put({
            type: types.ECOMEX_INFORMATIVE_REGIME_DETAIL_SUCCESS,
            negociationsDetail,
            affectationsDetail,
            advanceExportPaymentsDetail,
            impurationsAndNegotiations,
            boardingPermitsNegotiations,
        });
    }
}

function* downloadInformativeRegimeDetail({ tabSelected, identification, format, fileName }) {
    const { type, data } = yield call(ecomexMiddleware.downloadInformativeRegimeDetail, tabSelected, identification);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "ecomex.informativeRegime.list",
            ]),
        );
    } else {
        const { content } = data.data;

        const fileNameToDownload = fileName;

        if (format.toLowerCase() === "pdf") {
            downloadPdf(fileNameToDownload, content);
        } else {
            downloadXls(fileNameToDownload, content);
        }

        yield put({ type: types.DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_SUCCESS });
    }
}

function* listEcomexAlertsAndDues({ optionSelected, pageNumber, numberPerPage }) {
    const response = yield call(ecomexMiddleware.listEcomexAlertsAndDues, optionSelected, pageNumber, numberPerPage);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["ecomex.alertsAndDues.list"]));
        yield put({ type: types.LIST_ECOMEX_ALERTS_AND_DUES_FAILURE });
    } else {
        const {
            ecomexAlerts,
            ecomexDues,
            numberPerPageOptions,
            totalPages,
            numberPerPage: numberPerPageResponse,
        } = response.data.data;

        yield put({
            type: types.LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS,
            ecomexAlerts,
            ecomexDues,
            numberPerPageOptions,
            optionSelected,
            numberPerPage: numberPerPageResponse,
            pageNumber,
            totalPages,
        });
    }
}

function* moreListEcomexAlertsAndDues({ optionSelected, pageNumber, numberPerPage }) {
    const response = yield call(ecomexMiddleware.listEcomexAlertsAndDues, optionSelected, pageNumber, numberPerPage);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["ecomex.alertsAndDues.list"]));
        yield put({ type: types.MORE_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE });
    } else {
        const {
            ecomexAlerts,
            ecomexDues,
            numberPerPageOptions,
            totalPages,
            numberPerPage: numberPerPageResponse,
        } = response.data.data;

        yield put({
            type: types.MORE_LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS,
            ecomexAlerts,
            ecomexDues,
            numberPerPageOptions,
            optionSelected,
            numberPerPage: numberPerPageResponse,
            pageNumber,
            totalPages,
        });
    }
}

function* downloadAlertsAndDuesList({ summary, format }) {
    const { type, data } = yield call(ecomexMiddleware.downloadAlertsAndDuesList, summary, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "ecomex.alertsAndDues.list",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", [
                "ecomex.alertsAndDues.list",
            ]),
        );

        yield call(delay, 3000);

        const { fileName, content } = data.data;

        if (format.toLowerCase() === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS });
    }
}

function* sendAcceptEcomexTransaction({ ecomexTransaction }) {
    const response = yield call(ecomexMiddleware.ecomexSendAcceptTransaction, ecomexTransaction);
    try {
        if (response?.data?.code !== "COR110W") {
            if (response.data.code === "COR054W" || response.data.code === "COR065W") {
                const errorMessage = response.data.message;
                yield put(notificationActions.showNotification(errorMessage, "error", ["ecomexConfirm"]));
            } else if (response?.data?.code === "API524W") {
                yield put(
                    notificationActions.showNotification(i18n.get("token.otp.invalid"), "error", ["ecomexConfirm"]),
                );
            } else {
                yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
                yield take("READ_TRANSACTION_REQUEST");
            }
        }
    } finally {
        yield put({ type: types.SET_FETCHING, fetching: false });
    }
}
