import React, { Component } from "react";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import { func, string, shape, int, bool } from "prop-types";
import { goBack } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import { Field, withFormik, Form } from "formik";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import { actions, selectors } from "reducers/frequentDestination";
import * as config from "util/config";
import * as i18n from "util/i18n";
import Yup from "yup";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Heading from "pages/_components/Heading";
import PageLoading from "pages/_components/PageLoading";

const FORM_ID = "frequentDestination.modify";

class CreateFrequentDestinationTransfer extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        alias: string.isRequired,
        frequentDestination: shape({
            idFrequentDestinations: int,
            accountNumber: string,
            productType: string,
            isOwn: string,
            cbu: string,
            recipientDocumentType: string,
            recipientDocumentNumber: string,
            recipientName: string,
            localBank: string,
            SubsidiaryBank: string,
            recipientEmail: string,
            name: string,
        }),
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {
        frequentDestination: {},
    };

    state = {};

    componentDidMount() {
        const { match, dispatch, frequentDestination } = this.props;
        const cbuAlias = match.params.id;
        if (!frequentDestination.cbu) {
            dispatch(actions.createFrequentDestinationTransferPreRequest(cbuAlias));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue d-flex justify-content-center">
            <h1>{i18n.get("frequentDestination.create.newFrequentDestination")}</h1>
        </div>
    );

    renderHeader = () => {
        const { isDesktop } = this.props;
        return (
            <div className="admin-detail-head px-0">
                <Head
                    onBack={this.handleBack}
                    centerElement={isDesktop ? null : this.centerContentMobile}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                />
                {isDesktop && (
                    <div className="view-title">
                        <h1>{i18n.get("frequentDestination.create.newFrequentDestination")}</h1>
                    </div>
                )}
            </div>
        );
    };

    renderForm = () => {
        const { frequentDestination, alias, isDesktop, fetching } = this.props;

        const containerClassName = `transfer-data data-wrapper-flex ${!isDesktop ? "space-between mb-0" : ""}`;
        const labelClassName = isDesktop ? "mb-0" : "";
        return (
            <PageLoading loading={fetching}>
                <Container
                    className="container--layout flex-grow align-items-center flex-grow admin-action"
                    gridClassName="form-content">
                    <Col sm={12} md={3} lg={5} className="col col-12 py-3">
                        <div className="d-flex justify-content-center">
                            <Col sm={12} className="col col-12">
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.numberAccount"
                                    data={`${frequentDestination.accountNumber}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.accountType"
                                    data={`${frequentDestination.productType}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.own"
                                    data={`${frequentDestination.isOwn === "1" ? "SI" : "NO"}`}
                                />
                                {alias && (
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.alias"
                                        data={`${alias}`}
                                    />
                                )}
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.cbu"
                                    data={`${frequentDestination.cbu}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.document"
                                    data={`${frequentDestination.recipientDocumentType}
                                    ${frequentDestination.recipientDocumentNumber}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.recipientName"
                                    data={`${frequentDestination.recipientName}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.bank"
                                    data={`${frequentDestination.localBank}/${frequentDestination.subsidiaryBank}`}
                                />
                            </Col>
                        </div>
                    </Col>
                </Container>
                <Container
                    className="container--layout flex-grow align-items-center flex-grow admin-action"
                    gridClassName="form-content">
                    <Col sm={12} md={3} lg={5} className="col col-12">
                        <Col sm={12} lg={12} className="col col-12">
                            <Field idForm={FORM_ID} component={TextField} name="email" type="text" isFocused />
                        </Col>
                        <Col sm={12} lg={12} className="col col-12">
                            <Field idForm={FORM_ID} component={TextField} name="name" type="text" isFocused />
                        </Col>
                    </Col>
                </Container>
            </PageLoading>
        );
    };

    render() {
        const { fetching, frequentDestination } = this.props;
        return (
            <>
                <Notification scopeToShow="FrequentDestinationsCreate" />
                {this.renderHeader()}
                <Form>
                    <MainContainer>
                        {frequentDestination && this.renderForm()}
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={5} className="col col-12">
                                <Col sm={12} lg={12} className="col col-12">
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        type="submit"
                                        loading={fetching}
                                    />
                                </Col>
                            </Col>
                        </Container>
                    </MainContainer>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    frequentDestination: selectors.getFrequentDestination(state),
    alias: selectors.getAlias(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            email: "",
            name: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                if (values.email === undefined || values.name === undefined) {
                    return Yup.object().shape({
                        email: Yup.string().email(i18n.get(`${FORM_ID}.email.invalid`)),
                        name: Yup.string().required(i18n.get(`${FORM_ID}.name.required`)),
                    });
                }

                return Yup.object().shape({
                    email: Yup.string().email(i18n.get(`${FORM_ID}.email.invalid`)),
                    name: Yup.string().max(
                        config.getInteger("frequentDestination.reference.maxLength", 100),
                        i18n.get(`frequentDestination.reference.length.error`),
                    ),
                });
            }),
        handleSubmit: (data, formikBag) => {
            const { credentialGroups, dispatch, frequentDestination, alias } = formikBag.props;
            const params = {
                ...data,
                cbu: alias || frequentDestination.cbu,
                email: data.email,
                name: data.name,
            };
            dispatch(actions.frequentDestinationCreatePreview(params, formikBag, credentialGroups));
        },
    }),
)(CreateFrequentDestinationTransfer);
