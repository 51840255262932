import * as transactionMiddleware from "middleware/transactions";
import { downloadPdf } from "util/download";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import moment from "moment-timezone";
import { parseFloatToAmountFormat } from "util/number";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionActions, selectors as transactionSelectors } from "reducers/transactions";
import { selectors as holidaysSelector } from "reducers/holidays";
import { selectors as transferSelector } from "reducers/transfer";
import { goBack, push } from "react-router-redux";
import { history, store } from "store";
import { DAY_MONTH_YEAR } from "./date";
import { FINISHED, FAILED, CANCELLED } from "constants.js";

export const exportGenericTicketPdf = async (ticketData) => {
    const { data } = await transactionMiddleware.exportGeneralTicket(ticketData);

    const { content, fileName } = data.data;
    downloadPdf(fileName, content);
};

export const exportGenericTicketPdfWithSuccessMessage = async (ticketData, dispatch, message) => {
    const { data } = await transactionMiddleware.exportGeneralTicket(ticketData);

    dispatch(
        notificationActions.showNotification(message, "success", [
            "pendingTransfer",
            "multipleTransfer.detail",
            "transfersHistoric",
        ]),
    );

    setTimeout(() => {
        const { content, fileName } = data.data;
        downloadPdf(fileName, content);
    }, 3000);
};

function getKey(key) {
    const id = key ? String(i18n.get(key)) : "";
    return id.replaceAll("*", "");
}

export const getGeneralTicketItem = (key, value, type) => {
    let data = {
        key: getKey(key),
    };

    switch (type) {
        case "datetime": {
            const dateToParse = moment(value).format();
            const newDate = new Date(dateToParse).toLocaleString("en-GB", {
                timeZone: "America/Argentina/Buenos_Aires",
            });

            data = { ...data, value: String(newDate.slice(0, newDate.length - 3).replace(",", "")) };
            break;
        }
        case "date": {
            const dateToParse = moment(value).format();
            const newDate = new Date(dateToParse).toLocaleString("en-GB", {
                timeZone: "America/Argentina/Buenos_Aires",
            });

            data = { ...data, value: String(newDate.slice(0, newDate.length - 10)) };
            break;
        }
        case "datefulltime": {
            const dateToParse = moment(value).format();
            const newDate = new Date(dateToParse).toLocaleString("en-GB", {
                timeZone: "America/Argentina/Buenos_Aires",
            });

            data = { ...data, value: String(newDate.replace(",", "")) };
            break;
        }
        case "amountUSD": {
            data = { ...data, value: `USD ${parseFloatToAmountFormat(String(value))}` };
            break;
        }
        case "amountARS": {
            data = { ...data, value: `ARS ${parseFloatToAmountFormat(String(value))}` };
            break;
        }
        case "amountFrequency": {
            const { quantity, frequency, currency } = value;
            const endText = value.endText ? value.endText : "";
            const currencyLabel = i18n.get(`currency.label.${currency}`);
            data = {
                ...data,
                value: `${currencyLabel} ${parseFloatToAmountFormat(String(quantity))} ${frequency || ""} ${endText}`,
            };
            break;
        }
        default: {
            data = { ...data, value: String(value) };
            break;
        }
    }

    return data;
};

const pendingDispatchString = (transaction) => {
    let stringValue = 0;

    if (!transaction.pendingDispatch && transaction.idTransactionStatus === "PENDING") {
        stringValue = 1;
    } else if (transaction.pendingDispatch) {
        stringValue = 2;
    }

    return stringValue;
};

export const dynamicSort = (property, direction) => {
    let sortOrder = 1;
    let attribute = property;
    if (property[0] === "-") {
        sortOrder = -1;
        attribute = property.substr(1);
    }
    let result = 0;

    return (a, b) => {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        if (property === "amount") {
            const currencya = a.transactionAmounts ? Object.keys(a.transactionAmounts) : [];
            const currencyb = b.transactionAmounts ? Object.keys(b.transactionAmounts) : [];

            if (currencya.length > 0 && currencyb.length > 0) {
                const amounta = a.transactionAmounts[currencya];
                const amountb = b.transactionAmounts[currencyb];

                if (direction === "ascendent") {
                    result = amounta < amountb ? -1 : 1;
                } else {
                    result = amounta > amountb ? -1 : 1;
                }
            } else if (currencya.length === 0) {
                if (currencyb.length > 0) {
                    if (direction === "ascendent") {
                        result = -1;
                    }
                } else if (direction === "descendent") {
                    result = 1;
                }
            } else if (direction === "ascendent") {
                result = 1;
            } else {
                result = -1;
            }
        } else {
            const aux1 =
                property === "pendingDispatch"
                    ? pendingDispatchString(a.transaction)
                    : a.transaction[attribute].trim() || "";
            const aux2 =
                property === "pendingDispatch"
                    ? pendingDispatchString(b.transaction)
                    : b.transaction[attribute].trim() || "";

            if (direction === "ascendent") {
                if (aux1 === "") {
                    result = -1;
                } else if (aux2 === "") {
                    result = 1;
                } else {
                    result = aux1 < aux2 ? -1 : 1;
                }
            } else if (aux1 === "") {
                result = 1;
            } else if (aux2 === "") {
                result = -1;
            } else {
                result = aux1 > aux2 ? -1 : 1;
            }
        }

        return result * sortOrder;
    };
};

export default {};
export const chooseImage = (idTransactionStatus) => {
    let image = "";
    switch (idTransactionStatus?.toLowerCase()) {
        case "failed": {
            image = "images/transactionStatusIcons/cancelled.svg";
            break;
        }
        case "cancelled": {
            image = "images/transactionStatusIcons/cancelled.svg";
            break;
        }
        case "pending": {
            image = "images/transfer-PENDING.svg";
            break;
        }
        case "finished": {
            image = "images/successOnboard.svg";
            break;
        }
        case "processing": {
            image = "images/processing.svg";
            break;
        }
        case "scheduled": {
            image = "images/transactionStatusIcons/schedule.svg";
            break;
        }
        default: {
            image = "images/successOnboard.svg";
            break;
        }
    }

    return image;
};

export const normalizeTransactionPath = async ({ dispatch }) => {
    const transactionRoute = transactionSelectors.getTransactionRoute(store.getState());
    const unlisten = history.listen((location) => {
        const nextPath = location?.pathname;
        if (nextPath === "/communications") {
            unlisten();
            dispatch(push(nextPath));
        } else if (nextPath === transactionRoute) {
            unlisten();
        } else {
            dispatch(goBack());
        }
    });
};

export const saveLocation = ({ location, dispatch }) => {
    dispatch(transactionActions.saveTransactionRoute(location?.pathname));
};

const validateHolidays = (valueDate) => {
    let apply = false;
    const holidays = holidaysSelector.getHolidays(store.getState());
    let formattedDate = valueDate.format(DAY_MONTH_YEAR);
    while (holidays.includes(formattedDate)) {
        apply = true;
        valueDate.add(+1, "days");
        formattedDate = valueDate.format(DAY_MONTH_YEAR);
    }
    return apply;
};

const setSchedulerDay = (date, day) => {
    let applyValidation = true;
    const TDAnonWorkingDays = configUtils.getArrayInteger("frontend.TAD.nonWorkingDays", "5|6|7");
    const scheduleExecutionTime = configUtils.get("scheduler.transactions.execution.hour", "00:00");
    const scheduleExecutionHour = moment(scheduleExecutionTime, "hh:mm");
    let valueDate = date
        .add(day, "days")
        .set("hour", scheduleExecutionHour.hour())
        .set("minute", scheduleExecutionHour.minute())
        .set("second", 0);

    while (applyValidation) {
        applyValidation = false;
        if (TDAnonWorkingDays.indexOf(valueDate.isoWeekday()) > -1) {
            applyValidation = true;
            valueDate = validateNonWorkingDay(valueDate);
        }

        applyValidation = validateHolidays(valueDate);
    }

    return valueDate;
};

const validateNonWorkingDay = (dateNow) => {
    const format = "hh:mm:ss";
    const scheduleExecutionTime = configUtils.get("scheduler.transactions.execution.hour", "00:00");
    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
    const scheduleExecutionHour = moment(scheduleExecutionTime, "hh:mm");
    const beforeTime = moment(TDAhourFrom, format);
    let newValueDate = moment();
    if (dateNow.day() === 5) {
        if (dateNow.isBefore(beforeTime)) {
            newValueDate = dateNow.set("hour", scheduleExecutionHour.hour()).set("minute", 0);
        } else {
            newValueDate = setSchedulerDay(dateNow, 8 - dateNow.day());
        }
    } else if (dateNow.isoWeekday() === 6 || dateNow.isoWeekday() === 7) {
        newValueDate = setSchedulerDay(dateNow, 8 - dateNow.isoWeekday());
    } else {
        newValueDate = setSchedulerDay(dateNow, 1);
    }
    return newValueDate;
};

export const updateSchedulerToSend = () => {
    // Don't forget before load the holidays in the component
    const serverDate =
        transferSelector.getServerDate(store.getState()) || transactionSelectors.getServerDate(store.getState());
    const dateNow = moment.tz(serverDate, "America/Argentina/Buenos_Aires");
    let newValueDate = moment.tz(serverDate, "America/Argentina/Buenos_Aires");
    let schedulerToSend = null;
    const selectedOption = "FUTURE";

    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00").split(":");
    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00").split(":");
    const scheduleExecutionTime = configUtils.get("scheduler.transactions.execution.hour", "00:00").split(":");
    const TDAnonWorkingDays = configUtils.getArrayInteger("frontend.TAD.nonWorkingDays", "5|6|7");
    const beforeTime = moment.tz(serverDate, "America/Argentina/Buenos_Aires");
    beforeTime.set({ hour: TDAhourFrom[0], minute: TDAhourFrom[1], second: TDAhourFrom[2], millisecond: 0 });

    const afterTime = moment.tz(serverDate, "America/Argentina/Buenos_Aires");
    afterTime.set({ hour: TDAhourTo[0], minute: TDAhourTo[1], second: TDAhourTo[2], millisecond: 0 });

    const scheduleExecutionHour = moment.tz(serverDate, "America/Argentina/Buenos_Aires");
    scheduleExecutionHour.set({
        hour: scheduleExecutionTime[0],
        minute: scheduleExecutionTime[1],
        second: 0,
        millisecond: 0,
    });

    validateHolidays(dateNow);
    if (TDAnonWorkingDays.indexOf(dateNow.isoWeekday()) > -1) {
        newValueDate = validateNonWorkingDay(dateNow);
        schedulerToSend = { selectedOption, newValueDate };
    } else if (dateNow.isAfter(newValueDate)) {
        newValueDate = dateNow
            .set("hour", scheduleExecutionHour.hour())
            .set("minute", scheduleExecutionHour.minute())
            .set("second", 0);
        schedulerToSend = { selectedOption, newValueDate };
    } else if (!dateNow.isBetween(beforeTime, afterTime)) {
        if (
            dateNow.isBefore(scheduleExecutionHour) ||
            (scheduleExecutionHour.isAfter(beforeTime) && dateNow.isBefore(scheduleExecutionHour))
        ) {
            newValueDate = dateNow
                .set("hour", scheduleExecutionHour.hour())
                .set("minute", scheduleExecutionHour.minute())
                .set("second", 0);
        } else {
            newValueDate = setSchedulerDay(dateNow, 1);
        }
        schedulerToSend = { selectedOption, newValueDate, program: null };
    }
    return schedulerToSend;
};

export const checkSign = (summary, loggedUser, potentialSignaturesData) => {
    const { signatures: transactionSignatures } = summary;
    if (loggedUser?.signatureLevel) {
        const hasSigned = transactionSignatures?.find((u) => u.idUser === loggedUser?.userId);
        const schemesSignatures =
            !hasSigned &&
            potentialSignaturesData?.reduce((signatureSchemes, signatures) => {
                const { typesOfSignatures } = signatures;
                const compatibleSignatureEmpty = typesOfSignatures.find(
                    (signature) => signature.signature === loggedUser.signatureLevel && !signature.selected,
                );
                if (compatibleSignatureEmpty && !hasSigned) {
                    return signatureSchemes.concat(
                        typesOfSignatures
                            .filter((signatureScheme) => !signatureScheme.selected)
                            .map((signatureScheme) => signatureScheme.signature),
                    );
                }

                return signatureSchemes;
            }, []);

        const schemesHasLiberator = potentialSignaturesData?.some((scheme) => scheme.liberator);
        return (
            (schemesSignatures && schemesSignatures?.includes(loggedUser.signatureLevel)) ||
            (loggedUser.liberator && schemesHasLiberator)
        );
    }

    return false;
};

export const isFinalState = (state) => [FINISHED, FAILED, CANCELLED].includes(state);