
export const isValidCVU = (number) => number?.startsWith("000");

export const calculateTotalAmountCbuList = (cbuList) =>
    cbuList.reduce(
        (newElem, elem) => ({
            currency: elem.amount.currency,
            quantity: newElem.quantity + elem.amount.quantity,
        }),
        {
            currency: null,
            quantity: 0,
        },
    );

export const separarCbuList = (cbuList) => {
    const cbuListCVU = [];
    const cbuListCBU = [];

    cbuList.forEach((cbuObject) => {
        if (isValidCVU(cbuObject?.cbu)) {
            cbuListCVU.push(cbuObject);
        } else {
            cbuListCBU.push(cbuObject);
        }
    });

    return { cbuListCVU, cbuListCBU };
};

export const separarCredinAndCbuList = (credin, cbuList) => {
    const { cbuListCVU, cbuListCBU } = separarCbuList(cbuList);

    const credinCVU = {
        ...credin,
        amount: {
            currency: credin.amount.currency,
            quantity: cbuListCVU.reduce((sum, cbuObject) => sum + cbuObject?.amount?.quantity, 0),
        },
    };

    const credinCBU = {
        ...credin,
        amount: {
            currency: credin.amount.currency,
            quantity: cbuListCBU.reduce((sum, cbuObject) => sum + cbuObject?.amount?.quantity, 0),
        },
    };

    // Eliminar los amounts no necesarios
    Object.keys(credinCVU).forEach((key) => {
        if (key !== "amount" && key.startsWith("amount")) {
            delete credinCVU[key];
        }
    });
    Object.keys(credinCBU).forEach((key) => {
        if (key !== "amount" && key.startsWith("amount")) {
            delete credinCBU[key];
        }
    });

    return { credinCVU, credinCBU, cbuListCVU, cbuListCBU };
};

export const separarRecipients = (recipients) => {
    const recipientsCVU = [];
    const recipientsCBU = [];

    recipients.forEach((recipient) => {
        if (isValidCVU(recipient?.cbu)) {
            recipientsCVU.push(recipient);
        } else {
            recipientsCBU.push(recipient);
        }
    });

    return { recipientsCVU, recipientsCBU };
};
