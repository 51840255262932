import React, { Component } from "react";
import Iframe from "@nicholasadamou/react-iframe";
import { routerActions } from "react-router-redux";
import { connect } from "react-redux";
import { actions as ecomexIframeActions, selectors } from "reducers/ecomexIframe";
import Notification from "pages/_components/Notification";
import { func } from "prop-types";
import PageLoading from "pages/_components/PageLoading";
import * as config from "util/config";

class ecomexNewRequests extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(ecomexIframeActions.loadRequestHistory());
    };

    componentDidUpdate = () => {
        const { dispatch, error } = this.props;
        if (error) {
            dispatch(routerActions.push("/desktop"));
        }
    };

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(ecomexIframeActions.cleanUp());
    };

    render() {
        const { fetching, title, width = "100%", height = "100%", token } = this.props;
        const url = config.get("ecomex.service.url.requestHistory");
        const encodedToken = encodeURIComponent(token);
        const urlWithHash = `${url}?hashid=${encodedToken}`;

        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="pages.ecomexIframe" notificationClassname="navbar-fixed-top" />
                {token && (
                    <div className="pl-0 pr-0 w-100 h-100">
                        <Iframe
                            src={urlWithHash}
                            headers={{
                                Authorization: token,
                                "Access-Control-Allow-Origin": "*",
                            }}
                            frameBorder="0"
                            title={title}
                            width={width}
                            height={height}
                        />
                    </div>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    token: selectors.getToken(state),
    error: selectors.getError(state),
    fetching: selectors.getFetching(state),
});
export default connect(mapStateToProps)(ecomexNewRequests);
