/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors, actions } from "reducers/checks";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { func, bool, arrayOf, string, shape } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import FormattedDate from "pages/_components/FormattedDate";
import * as i18n from "util/i18n";
import InfoTag from "pages/_components/InfoTag";
import { statusTagColor, echeqShape, checkDisableMultiple, findEmisor } from "util/checks";
import Check from "pages/_components/fields/Checkbox";
import ReceivedContextMenu from "pages/checks/Echeqs/ReceivedContextMenu";
import { Link } from "react-router-dom";
import Image from "pages/_components/Image";
import classNames from "classnames";
import Spinner from "pages/_components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "pages/_components/Card/Card";
import CardContainer from "pages/_components/Card/CardContainer";

const FORM_ID = "echeq";

class ReceivedEcheqs extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        option: string.isRequired,
        echeqsList: arrayOf(echeqShape).isRequired,
        multipleSelection: bool.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        selectedRows: arrayOf(echeqShape).isRequired,
        addSelectedRow: func.isRequired,
        selectAll: func.isRequired,
        environmentCuit: string.isRequired,
        setSubmitting: func.isRequired,
        filters: shape({
            createdFrom: string.isRequired,
            createdTo: string.isRequired,
            payedFrom: string.isRequired,
            payedTo: string.isRequired,
            payedCheck: bool.isRequired,
            emitedCheck: bool.isRequired,
            receiver: string.isRequired,
            emitted: string.isRequired,
            status: shape({ value: string.isRequired, label: string.isRequired }).isRequired,
            number: string.isRequired,
            grouper: string.isRequired,
            id: string.isRequired,
            orderColumName: string.isRequired,
            orderDirection: string.isRequired,
        }).isRequired,
        hasMoreData: bool.isRequired,
        fetchNextTransactions: func.isRequired,
        orderByData: func.isRequired,
    };

    componentDidMount() {
        const { filters, orderByData } = this.props;
        orderByData(filters.orderColumName, filters.orderDirection, this.changeFilter);
    }

    changeFilter = (filterName, filterDirecion) => {
        const { filters, dispatch, option, setSubmitting, selectAll, isDesktop } = this.props;

        const filtersEcheq = {
            ...filters,
            option,
            status: filters.status,
            orderColumName: filterName,
            orderDirection: filterDirecion,
            pageNumber: 1,
        };
        dispatch(actions.setEcheqFilters(filtersEcheq));

        selectAll(false);
        setSubmitting(true);
        dispatch(actions.filterEcheqList(filtersEcheq, setSubmitting, isDesktop));
    };

    cardsReceivedEcheqs = () => {
        const {
            echeqsList,
            multipleSelection,
            addSelectedRow,
            selectedRows,
            environmentCuit,
            isDesktop,
            filters,
        } = this.props;

        const listSorted = echeqsList;
        return listSorted.map((object) => {
            const emisor = findEmisor(object, environmentCuit);
            const receivedDocument = emisor.emisorDocumento.toUpperCase();
            const receivedDocumentNumber = receivedDocument.replace(/\D/g, "");
            return (
                <CardContainer
                    key={object.cheque_numero}
                    renderAs={Link}
                    to={`/echeqs/received/detail/${object.cheque_id}`}>
                    {multipleSelection && (
                        <Card
                            checks={
                                multipleSelection && (
                                    <div
                                        className="echeqs__cards-checks"
                                        role="button"
                                        tabIndex={0}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            addSelectedRow(object);
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                            addSelectedRow(object);
                                        }}>
                                        <Check
                                            hideLabel
                                            key={object.cheque_numero}
                                            id={object.cheque_numero}
                                            name={object.cheque_numero}
                                            disabledCheck={checkDisableMultiple(object, environmentCuit, filters.status)}
                                            checked={selectedRows.find(
                                                (element) => element.cheque_id === object.cheque_id,
                                            )}
                                            formGroupClassName="mb-0"
                                        />
                                    </div>
                                )
                            }
                            title={
                                <div className="echeqs__cards-title">
                                    <I18n
                                        id={`${FORM_ID}.paymentDate`}
                                        componentProps={{ className: "echeqs__paymentDate" }}
                                    />
                                    <FormattedDate date={object.fecha_pago} />
                                </div>
                            }
                            content={
                                <div className="echeqs__cards-content">
                                    <span className="echeqs__echeqNumber">{object.cheque_numero}</span>
                                    {object.tenencia_beneficiario_documento === environmentCuit && (
                                        <Image src="images/ownershipEcheqs.svg" />
                                    )}
                                    <span className="echeqs__type">{object.cheque_caracter.toUpperCase()}</span>
                                </div>
                            }
                            bottom={
                                <div className="echeqs__cards-bottom">
                                    <div className="echeqs__received">
                                        <I18n
                                            id={`${FORM_ID}.received.from`}
                                            componentProps={{ className: "echeqs__received-title" }}
                                        />
                                        <span>{emisor.emisorRazonSocial.replaceAll("#", "Ñ")}</span>
                                    </div>
                                    <I18n
                                        id={`${FORM_ID}.received.from.document`}
                                        componentProps={{ className: "echeqs__received-document" }}
                                    />
                                    <span className="echeqs__received-document-number">{receivedDocumentNumber}</span>
                                </div>
                            }
                            rightContent={
                                <div className="echeqs__cards-rightContent">
                                    <div className="echeqs__cards-right-info">
                                        <div className="echeqs__cards-amount">
                                            <FormattedAmount
                                                currency={i18n.get("currency.label.".concat(object.emisor_moneda))}
                                                quantity={object.monto}
                                            />
                                        </div>
                                        <InfoTag
                                            type="info"
                                            message={object.estado}
                                            tagBackground={statusTagColor(object.estado)}
                                            moreStyles={{
                                                alignSelf: "flex-end",
                                                width: "fit-content",
                                                color: "black",
                                                margin: "auto",
                                                marginRight: 0,
                                            }}
                                        />
                                    </div>
                                    <div className="echeqs__context-menu-container">
                                        <button
                                            type="button"
                                            className="btn btn-link p-0 m-0"
                                            onClick={(e) => e.stopPropagation()}>
                                            <ReceivedContextMenu echeq={object} isDesktop={isDesktop} />
                                        </button>
                                    </div>
                                </div>
                            }
                        />
                    )}
                </CardContainer>
            );
        });
    };

    echeqsListMobile = (echeq) => {
        const { environmentCuit, multipleSelection, addSelectedRow, selectedRows, isDesktop, filters } = this.props;
        const {
            cheque_numero: cheqNro,
            estado,
            monto,
            cheque_caracter: chequeCaracter,
            emisor_moneda: emisorMoneda,
            fecha_pago: fechaPago,
            cheque_id: cheqId,
        } = echeq;
        const emisor = findEmisor(echeq, environmentCuit);
        const { emisorRazonSocial, emisorDocumento } = emisor;

        return (
            <CardContainer renderAs={Link} to={`/echeqs/received/detail/${cheqId}`} className="px-3">
                {multipleSelection && (
                    <Card
                        hrClass="echeqs__hr-received"
                        checks={
                            <div
                                className="echeqs__cards-checks"
                                role="button"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    addSelectedRow(echeq);
                                }}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                    addSelectedRow(echeq);
                                }}>
                                <Check
                                    hideLabel
                                    key={cheqNro}
                                    id={cheqNro}
                                    name={cheqNro}
                                    disabledCheck={checkDisableMultiple(echeq, environmentCuit, filters.status)}
                                    onChange={() => {
                                        addSelectedRow(echeq);
                                    }}
                                    checked={selectedRows.find((element) => element.cheque_id === cheqId)}
                                />
                            </div>
                        }
                        title={
                            <div className="d-flex w-100 space-between echeqs__cards-title">
                                <div className="d-flex w-100">
                                    <I18n id="echeq.number" componentProps={{ className: "ml-2 echeq__info" }} />
                                    <span className="echeq__data">{cheqNro}</span>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-link p-0 m-0"
                                    onClick={(e) => e.stopPropagation()}>
                                    <ReceivedContextMenu echeq={echeq} isDesktop={isDesktop} />
                                </button>
                            </div>
                        }
                        content={
                            <div>
                                <hr className="ml-2" />
                                <div className="echeqs__cards-content">
                                    <div className="d-flex space-between w-100 pb-1 align-items-center">
                                        <I18n
                                            id="echeqs.status.label"
                                            componentProps={{ className: "ml-2 echeq__info" }}
                                        />
                                        <InfoTag
                                            type="info"
                                            message={estado}
                                            tagBackground={statusTagColor(estado)}
                                            tagClass="d-flex ml-2 my-0 mr-0"
                                            moreStyles={{
                                                color: "black",
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex space-between w-100">
                                        <I18n
                                            id="echeqs.payedCheck.label"
                                            componentProps={{ className: "ml-2 echeq__info" }}
                                        />
                                        <FormattedDate showTime={false} date={fechaPago} />
                                    </div>
                                    <div className="d-flex space-between w-100 pt-1 align-items-center">
                                        <I18n id="echeq.character" componentProps={{ className: "ml-2 echeq__info" }} />
                                        <span className="echeqs__type">{chequeCaracter}</span>
                                    </div>
                                    <div className="d-flex space-between w-100 pt-1">
                                        <I18n
                                            id={`${FORM_ID}.received.from`}
                                            componentProps={{ className: "ml-2 echeq__info" }}
                                        />
                                        <span className="echeq__data">{emisorRazonSocial.replaceAll("#", "Ñ")}</span>
                                    </div>
                                    <div className="d-flex space-between w-100 pt-1">
                                        <I18n
                                            id="echeqs.document.label"
                                            componentProps={{ className: "ml-2 echeq__info" }}
                                        />
                                        <span className="echeq__data">{emisorDocumento}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="echeqs__cards-rightContent">
                                <FormattedAmount
                                    currency={i18n.get("currency.label.".concat(emisorMoneda))}
                                    quantity={monto}
                                    notBold={isDesktop}
                                    className="echeq__data"
                                />
                            </div>
                        }
                    />
                )}
            </CardContainer>
        );
    };

    render() {
        const {
            echeqsList,
            isDesktop,
            isSmallDesktop,
            hasMoreData,
            fetchNextTransactions,
        } = this.props;
        const endOfListItem = (
            <div className="table-row table-end mb-5" key="noMoreTransactions">
                <I18n id="echeqs.noMoreEcheqs" />
            </div>
        );

        if (!isDesktop) {
            return (
                <>
                    <InfiniteScroll
                        dataLength={echeqsList.length}
                        next={fetchNextTransactions}
                        hasMore={hasMoreData}
                        loader={<Spinner />}
                        endMessage={endOfListItem}>
                        {echeqsList.map((echeq) => this.echeqsListMobile(echeq))}
                    </InfiniteScroll>
                </>
            );
        }

        return (
            <Container
                className={classNames("flex-grow", {
                    "overflow-visible": !isSmallDesktop,
                    "scrollable pb-2": isSmallDesktop,
                })}
                rowClassName="mheight-table-echeq">
                <Col className="col col-12 px-0">{this.cardsReceivedEcheqs()}</Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqsList: selectors.getEcheqsList(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
    hasMoreData: selectors.getHasMoreData(state),
});

export default connect(mapStateToProps)(ReceivedEcheqs);
