/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { shape, bool, arrayOf, func } from "prop-types";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import RequestItem from "pages/charges/_components/RequestItem";
import { push } from "react-router-redux";
import Pagination from "pages/_components/pagination/Pagination";
import CardContainer from "pages/_components/Card/CardContainer";

const tableLabel = "charges.debin.table.header";

class DebinRequestsList extends Component {
    static propTypes = {
        requests: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        itemsAreClickeable: bool,
        debinObject: shape({}).isRequired,
        filtersObject: shape({}).isRequired,
    };

    static defaultProps = {
        itemsAreClickeable: false,
    };

    handleLinkTo = (id) => {
        const { dispatch } = this.props;
        dispatch(push(`/charges/debin/detail/${id}`));
    };

    fetchMoreTransactions = (pageNumber) => {
        const { dispatch, filtersObject } = this.props;
        const { status, dateFrom, dateTo, recipient } = filtersObject;

        dispatch(
            debinActions.loadSentRequests({
                status,
                dateFrom,
                dateTo,
                recipient,
                recipientType: recipient ? "comprador" : null,
                pageNumber,
            }),
        );
    };

    render() {
        const { requests, itemsAreClickeable = false, isDesktop, dispatch, debinObject, filtersObject } = this.props;

        const list = requests?.debinList?.map((request) => {
            if (isDesktop) {
                return (
                    <CardContainer
                        className="w-100 mb-3"
                        key={request.id}
                        renderAs={itemsAreClickeable ? Link : "div"}
                        to={`/charges/debin/detail/${request.id}`}>
                        <RequestItem key={`transaction-${request.id}`} request={request} isDesktop={isDesktop} />
                    </CardContainer>
                );
            }

            return (
                <Col className="px-3" onClick={() => dispatch(push(`/charges/debin/detail/${request.id}`))}>
                    <RequestItem
                        key={`transaction-${request.id}`}
                        request={request}
                        isDesktop={isDesktop}
                        tableLabel={tableLabel}
                    />
                </Col>
            );
        });

        const renderTable = isDesktop ? (
            <>
                {list}
                <div className="d-flex w-100 justify-content-end mr-3">
                    <Pagination
                        totalPages={debinObject.totalPages}
                        pageNumber={filtersObject.pageNumber}
                        action={this.fetchMoreTransactions}
                    />
                </div>
            </>
        ) : (
            list
        );

        return (
            <Container className="flex-grow">
                <Col className="col col-12 px-0 mt-4">
                    <div
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {requests?.debinList?.length ? (
                            renderTable
                        ) : (
                            <div className="text-center no-more-data" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image src="images/coloredIcons/find-in-page.svg" className="svg-big-icon" />
                                </div>
                                <p className="text-lead" style={{ opacity: 1 }}>
                                    <I18n id="charges.debin.sentRequests.empty.message" />
                                </p>
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    requests: debinSelectors.getSentRequests(state),
    debinObject: debinSelectors.getDebinObject(state),
    filtersObject: debinSelectors.getFiltersSentRequests(state),
});

export default connect(mapStateToProps)(DebinRequestsList);
