/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, func, shape, string, arrayOf } from "prop-types";
import { connect } from "react-redux";
import FormattedAmount from "pages/_components/FormattedAmount";
import Table from "pages/_components/Table";
import * as i18n from "util/i18n";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { selectors as debinSelectors } from "reducers/debin";
import Image from "pages/_components/Image";
import * as configUtils from "util/config";
import { withRouter } from "react-router-dom";

class SelectedAccountsAmount extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        list: shape([]).isRequired,
        summaryView: bool,
        summaryViewMoreStyles: shape({}),
        withoutTables: bool,
        leftOperations: bool,
        className: string,
        currency: string,
        withGrid: bool,
        selectedAccount: shape({}),
        setErrors: func,
        setTouched: func,
        spaceBetween: bool,
        limits: arrayOf(shape({})),
        setErrorLimits: func,
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        summaryView: false,
        summaryViewMoreStyles: {},
        withoutTables: false,
        leftOperations: false,
        className: "",
        currency: null,
        withGrid: false,
        selectedAccount: null,
        setErrors: null,
        setTouched: null,
        spaceBetween: false,
        limits: [],
        setErrorLimits: null,
    };

    state = {
        total: 0,
        account: null,
    };

    componentDidMount() {
        const { list } = this.props;
        let totalAmount = 0;

        list.forEach((object) => {
            const amount = object?.amount?.quantity || object.monto;
            totalAmount += parseFloat(amount, 10) > 0 && parseFloat(amount, 10);
        });
        this.setState({
            total: totalAmount,
        });
    }

    componentDidUpdate() {
        const { list, selectedAccount, setErrors, setTouched } = this.props;
        const { total, account } = this.state;
        let totalAmount = 0;

        list.forEach((object) => {
            const amount = object?.amount?.quantity || object.monto;
            totalAmount += parseFloat(amount, 10) > 0 && parseFloat(amount, 10);
        });

        if (total !== totalAmount || account !== selectedAccount) {
            this.setState({
                total: totalAmount,
                account: selectedAccount,
            });
            if (selectedAccount && totalAmount > selectedAccount.balance) {
                setTouched({ account: true });
                setErrors({ account: i18n.get("services.multiplePayment.field.account.amountExceeded") });
            } else if (setErrors) {
                setErrors({});
            }
        }
    }

    getConsolidatedDataCredin = (limits, list, currency, setErrorLimits) => {
        const limitAviableCredinCVU =
            limits.find((limit) => limit.type === "CVU" && limit.currency === currency?.toString())?.limitAviable || 0;
        const limitAviableCredinCBU =
            limits.find((limit) => limit.type === "CBU" && limit.currency === currency?.toString())?.limitAviable || 0;

        const consolidatedCredinInit = {
            totalAmount: 0,
            totalAmountCBU: 0,
            operationsCBU: 0,
            totalAmountCVU: 0,
            operationsCVU: 0,
        };
        const consolidatedCredin = list?.reduce((acumulador, objectList) => {
            const amount = objectList?.amount?.quantity || objectList.monto;
            const amountFloat = parseFloat(amount, 10) > 0 && parseFloat(amount, 10);
            const newAcomulador = acumulador;

            newAcomulador.totalAmount += amountFloat;
            if (objectList?.cbu?.startsWith("000")) {
                newAcomulador.totalAmountCVU += amountFloat;
                newAcomulador.operationsCVU += 1;
            } else {
                newAcomulador.totalAmountCBU += amountFloat;
                newAcomulador.operationsCBU += 1;
            }
            return newAcomulador;
        }, consolidatedCredinInit);

        const errorLimitCVU = consolidatedCredin?.totalAmountCVU > limitAviableCredinCVU;
        const errorLimitCBU = consolidatedCredin?.totalAmountCBU > limitAviableCredinCBU;

        const hasErrorLimits = errorLimitCVU || errorLimitCBU;

        if (typeof setErrorLimits === "function" && currency?.toString()) {
            setErrorLimits(hasErrorLimits);
        }

        const operationsTotal = `${i18n
            .get("transactions.selected.total.operations")
            .toUpperCase()}: ${consolidatedCredin?.operationsCVU + consolidatedCredin?.operationsCBU}`;
        const operationsCVU = `${i18n.get("transactions.selected.operations").toUpperCase()}: ${
            consolidatedCredin?.operationsCVU
        }`;
        const operationsCBU = `${i18n.get("transactions.selected.operations").toUpperCase()}: ${
            consolidatedCredin?.operationsCBU
        }`;

        return { operationsTotal, operationsCVU, operationsCBU, errorLimitCVU, errorLimitCBU, consolidatedCredin };
    };

    render() {
        const {
            list,
            isDesktop,
            summaryView,
            summaryViewMoreStyles,
            leftOperations,
            currency,
            className,
            withoutTables,
            withGrid,
            spaceBetween,
            limits,
            setErrorLimits,
            location,
        } = this.props;
        const { total } = this.state;

        const operations = i18n.get("transactions.selected.operations").concat(" ", list?.length);

        const isrequestCredin = location?.pathname?.includes("requestCredin");
        const isCredinCVUSignatureActive = configUtils.getBoolean("frontend.esquemaFirmas.validation.credinCVU.enable");
        const hasValidateCVUSignature = isCredinCVUSignatureActive && isrequestCredin;

        if (summaryView) {
            if (hasValidateCVUSignature) {
                const {
                    operationsTotal,
                    operationsCVU,
                    operationsCBU,
                    errorLimitCVU,
                    errorLimitCBU,
                    consolidatedCredin,
                } = this.getConsolidatedDataCredin(limits, list, currency, setErrorLimits);
                return (
                    <div className="w-100">
                        <div className="align-items-center">
                            <div className="d-flex w-100 align-items-center space-between border-bottom pb-2 mb-2 border-color-divider">
                                <div className="d-flex align-items-center">
                                    <span className="f-size-6 font-medium mr-1 no-wrap">
                                        {i18n.get("transactions.selected.total.amount").toUpperCase()}
                                    </span>
                                    <span className="f-size-6 nowrap">({operationsTotal})</span>
                                </div>

                                <FormattedAmount
                                    fontClassName="f-size-6 font-medium no-wrap"
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmount}
                                />
                            </div>
                        </div>

                        <div className="align-items-center">
                            <div className="d-flex w-100 align-items-center space-between mb-2">
                                <div className="d-flex align-items-center">
                                    <div className={`d-flex ${errorLimitCBU ? "color-error" : ""}`}>
                                        <span className="f-size-6 font-medium mr-1 no-wrap">
                                            {i18n.get("transactions.selected.totalCBU.amount").toUpperCase()}
                                        </span>
                                        <span className="text-12 no-wrap">({operationsCBU})</span>
                                    </div>
                                    {errorLimitCBU && (
                                        <div className="d-flex align-items-center">
                                            <Image
                                                src="images/warning-red.svg"
                                                wrapperClassName="svg-wrapper icon-16 mr-15"
                                            />
                                            <span className="f-size-6 color-error">
                                                {i18n.get("transfers.limit.exceeded")}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <FormattedAmount
                                    fontClassName={`f-size-6 font-medium text-nowrap ${
                                        errorLimitCBU ? "color-error" : ""
                                    }`}
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmountCBU}
                                />
                            </div>
                        </div>

                        <div className="align-items-center">
                            <div className="d-flex w-100 align-items-center space-between mb-2">
                                <div className="d-flex align-items-center">
                                    <div className={`d-flex ${errorLimitCVU ? "color-error" : ""}`}>
                                        <span className="f-size-6 font-medium mr-1 text-nowrap">
                                            {i18n.get("transactions.selected.totalCVU.amount").toUpperCase()}
                                        </span>
                                        <span className="text-12 nowrap">({operationsCVU})</span>
                                    </div>
                                    {errorLimitCVU && (
                                        <div className="d-flex align-items-center">
                                            <Image
                                                src="images/warning-red.svg"
                                                wrapperClassName="svg-wrapper icon-16 mr-15"
                                            />
                                            <span className="f-size-6 color-error">
                                                {i18n.get("transfers.limit.exceeded")}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <FormattedAmount
                                    fontClassName={`f-size-6 font-medium text-nowrap ${
                                        errorLimitCVU ? "color-error" : ""
                                    }`}
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmountCVU}
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <div style={{ ...summaryViewMoreStyles }} className={className}>
                    <div className="d-flex align-items-center">
                        <div>
                            <span className="acoount-header-detail-value-font p-0 text-14  bold-font mb-0">
                                {i18n.get("transactions.selected.total.amount").toUpperCase()}
                            </span>
                        </div>
                        <FormattedAmount
                            currency={currency || "0"}
                            quantity={total}
                            fontClassName="acoount-header-detail-value-font text-14 bold-font m-0 p-0 pl-1"
                        />
                    </div>

                    <div
                        className={classNames("d=grid", {
                            "text-left": leftOperations,
                            "text-right": !leftOperations,
                        })}>
                        <div className="data-label m-0 text-12 text-capitalize">{operations}</div>
                    </div>
                </div>
            );
        }

        if (withoutTables || spaceBetween) {
            if (hasValidateCVUSignature) {
                const {
                    operationsTotal,
                    operationsCVU,
                    operationsCBU,
                    errorLimitCVU,
                    errorLimitCBU,
                    consolidatedCredin,
                } = this.getConsolidatedDataCredin(limits, list, currency, setErrorLimits);
                return (
                    <>
                        <div className="d-flex align-items-center">
                            <span className="f-size-6 font-medium mr-1 text-nowrap">
                                {i18n.get("transactions.selected.total.amount").toUpperCase()}
                            </span>
                            <FormattedAmount
                                fontClassName="f-size-6 font-medium text-nowrap"
                                currency={currency || "0"}
                                quantity={consolidatedCredin?.totalAmount}
                            />
                        </div>

                        <div className="text-12 nowrap">{operationsTotal}</div>
                        <hr />

                        <>
                            <div className={`d-flex align-items-center ${errorLimitCBU ? "color-error" : ""}`}>
                                <span className="f-size-6 font-medium mr-1 text-nowrap">
                                    {i18n.get("transactions.selected.totalCBU.amount").toUpperCase()}
                                </span>
                                <FormattedAmount
                                    fontClassName={`f-size-6 font-medium text-nowrap ${
                                        errorLimitCBU ? "color-error" : ""
                                    }`}
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmountCBU}
                                />
                            </div>

                            <div className={`text-12 nowrap ${errorLimitCBU ? "color-error" : ""}`}>
                                {operationsCBU}
                            </div>
                            {errorLimitCBU && (
                                <div className="d-flex align-items-center">
                                    <Image src="images/warning-red.svg" wrapperClassName="svg-wrapper icon-16 mr-15" />
                                    <span className="f-size-6 color-error">{i18n.get("transfers.limit.exceeded")}</span>
                                </div>
                            )}
                        </>

                        <>
                            <div className={`d-flex align-items-center ${errorLimitCVU ? "color-error" : ""}`}>
                                <span className="f-size-6 font-medium mr-1 text-nowrap">
                                    {i18n.get("transactions.selected.totalCVU.amount").toUpperCase()}
                                </span>
                                <FormattedAmount
                                    fontClassName={`f-size-6 font-medium text-nowrap ${
                                        errorLimitCVU ? "color-error" : ""
                                    }`}
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmountCVU}
                                />
                            </div>

                            <div className={`text-12 nowrap ${errorLimitCVU ? "color-error" : ""}`}>
                                {operationsCVU}
                            </div>
                            {errorLimitCVU && (
                                <div className="d-flex align-items-center">
                                    <Image src="images/warning-red.svg" wrapperClassName="svg-wrapper icon-16 mr-15" />
                                    <span className="f-size-6 color-error">{i18n.get("transfers.limit.exceeded")}</span>
                                </div>
                            )}
                        </>
                    </>
                );
            }
            return (
                <>
                    <div className={classNames({ "space-between d-flex w-100": spaceBetween })}>
                        <span
                            className={classNames("acoount-header-detail-value-font no-padding small-font  bold-font", {
                                "small-margin-bottom": !isDesktop,
                            })}>
                            {i18n.get("transactions.selected.total.amount").toUpperCase()}
                        </span>
                        <FormattedAmount
                            className={classNames("acoount-header-detail-value-font small-font bold-font-important", {
                                "small-margin-bottom pl-0 white-space-normal word-break text-left": !isDesktop,
                            })}
                            currency={currency || "0"}
                            quantity={total}
                        />
                    </div>

                    <div className="data-label m-0 f-size-55 text-capitalize">
                        {i18n.get("transactions.selected.operations").concat(" ", list?.length)}
                    </div>
                </>
            );
        }

        if (withGrid) {
            if (hasValidateCVUSignature) {
                const {
                    operationsTotal,
                    operationsCVU,
                    operationsCBU,
                    errorLimitCVU,
                    errorLimitCBU,
                    consolidatedCredin,
                } = this.getConsolidatedDataCredin(limits, list, currency, setErrorLimits);
                return (
                    <div className="mt-3 ">
                        <Container rowClassName="align-items-center">
                            <Col xs={12} lg={6} className="text-align-left d-flex space-between">
                                <div className="d-flex ">
                                    <span className="f-size-6 font-medium mr-1 text-nowrap">
                                        {i18n.get("transactions.selected.total.amount").toUpperCase()}
                                    </span>
                                    <span className="text-12 nowrap">({operationsTotal})</span>
                                </div>

                                <FormattedAmount
                                    fontClassName="f-size-6 font-medium text-nowrap"
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmount}
                                />
                            </Col>
                        </Container>

                        <Container rowClassName="align-items-center">
                            <Col xs={12} lg={6} className="text-align-left d-flex space-between">
                                <div>
                                    <div className={`d-flex ${errorLimitCBU ? "color-error" : ""}`}>
                                        <span className="f-size-6 font-medium mr-1 text-nowrap">
                                            {i18n.get("transactions.selected.totalCBU.amount").toUpperCase()}
                                        </span>
                                        <span className="text-12 nowrap">({operationsCBU})</span>
                                    </div>
                                    {errorLimitCBU && (
                                        <div className="d-flex align-items-center">
                                            <Image
                                                src="images/warning-red.svg"
                                                wrapperClassName="svg-wrapper icon-16 mr-15"
                                            />
                                            <span className="f-size-6 color-error">
                                                {i18n.get("transfers.limit.exceeded")}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <FormattedAmount
                                    fontClassName={`f-size-6 font-medium text-nowrap ${
                                        errorLimitCBU ? "color-error" : ""
                                    }`}
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmountCBU}
                                />
                            </Col>
                        </Container>
                        <Container rowClassName="align-items-center">
                            <Col xs={12} lg={6} className="text-align-left d-flex space-between">
                                <div>
                                    <div className={`d-flex ${errorLimitCVU ? "color-error" : ""}`}>
                                        <span className="f-size-6 font-medium mr-1 text-nowrap">
                                            {i18n.get("transactions.selected.totalCVU.amount").toUpperCase()}
                                        </span>
                                        <span className="text-12 nowrap">({operationsCVU})</span>
                                    </div>
                                    {errorLimitCVU && (
                                        <div className="d-flex align-items-center">
                                            <Image
                                                src="images/warning-red.svg"
                                                wrapperClassName="svg-wrapper icon-16 mr-15"
                                            />
                                            <span className="f-size-6 color-error">
                                                {i18n.get("transfers.limit.exceeded")}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <FormattedAmount
                                    fontClassName={`f-size-6 font-medium text-nowrap ${
                                        errorLimitCVU ? "color-error" : ""
                                    }`}
                                    currency={currency || "0"}
                                    quantity={consolidatedCredin?.totalAmountCVU}
                                />
                            </Col>
                        </Container>
                    </div>
                );
            }
            return (
                <Container className="mt-2">
                    <Col xs={3} className="text-align-center">
                        <span
                            className={`acoount-header-detail-value-font no-padding small-font  bold-font ${
                                !isDesktop ? "small-margin-bottom" : ""
                            }`}>
                            {i18n.get("transactions.selected.total.amount").toUpperCase()}
                        </span>

                        <div className="data-label m-0 f-size-55">{operations}</div>
                    </Col>
                    <Col xs={5}>
                        <FormattedAmount
                            className={`acoount-header-detail-value-font small-font bold-font-important ${
                                !isDesktop ? "small-margin-bottom" : ""
                            }`}
                            currency={currency || "0"}
                            quantity={total}
                        />
                    </Col>
                </Container>
            );
        }

        return (
            <>
                <Table.Data />
                <Table.Data align="center">
                    <span
                        className={`acoount-header-detail-value-font no-padding small-font  bold-font ${
                            !isDesktop ? "small-margin-bottom" : ""
                        }`}>
                        {i18n.get("transactions.selected.total.amount").toUpperCase()}
                    </span>
                    <div className="data-label m-0 f-size-55">{operations}</div>
                </Table.Data>
                <Table.Data align="left" moreStyles={{ verticalAlign: "top" }}>
                    <FormattedAmount
                        className={`acoount-header-detail-value-font mb-0 small-font bold-font-important ${
                            !isDesktop ? "small-margin-bottom" : ""
                        }`}
                        currency={currency || "0"}
                        quantity={total}
                    />
                </Table.Data>
                <Table.Data />
                <Table.Data />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    limits: debinSelectors.getLimits(state),
});

export default connect(mapStateToProps)(withRouter(SelectedAccountsAmount));
