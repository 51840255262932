import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { replace } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { bool, func, string, number, shape } from "prop-types";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";
import Row from "react-bootstrap/lib/Row";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Movement from "pages/accounts/_components/Movement";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { DateField } from "pages/_components/fields/DateField";
import * as i18nUtils from "util/i18n";
import * as numUtils from "util/number";
import Dropdown from "pages/_components/Dropdown";
import Selector from "pages/_components/fields/formik/Selector";
import FormattedAmount from "pages/_components/FormattedAmount";
import Pagination from "pages/_components/pagination/Pagination";
import PageLoading from "pages/_components/PageLoading";
import * as configUtils from "util/config";
import AmountField from "pages/_components/fields/formik/AmountField";
import TextField from "pages/_components/fields/TextField";
import Image from "pages/_components/Image";
import moment from "moment";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import { productTypes, maskedAccountNumber } from "util/accounts";
import classNames from "classnames";
import MovementDetail from "./MovementDetail";

const FORM_ID = "accounts.movements";
class Movements extends Component {
    static propTypes = {
        accountId: string.isRequired,
        dispatch: func.isRequired,
        accountCurrency: number.isRequired,
        isDesktop: bool.isRequired,
        isFetchingMovements: bool.isRequired,
        values: shape({}),
        findBy: string,
        selectedAccount: shape({}).isRequired,
        latestMovements: shape([]).isRequired,
        latestMovementsPageNumber: number.isRequired,
        latestMovementsTotalPages: number.isRequired,
        pendingMovements: shape([]).isRequired,
        pendingMovementsPageNumber: number.isRequired,
        pendingMovementsTotalPages: number.isRequired,
        amountFrom: shape({}).isRequired,
        amountTo: shape({}).isRequired,
        voucher: shape({}).isRequired,
        detail: shape({}).isRequired,
        setFieldValue: func.isRequired,
        isLatestMovementsSelected: bool,
        resetForm: func.isRequired,
        fetchingDownload: bool.isRequired,
    };

    static defaultProps = {
        values: {},
        isLatestMovementsSelected: false,
        findBy: "period",
    };

    state = {
        isLatestMovementsSelected: true,
    };

    componentDidMount() {
        const { isLatestMovementsSelected } = this.state;
        this.handleMovementToShow(isLatestMovementsSelected); // Set to show LatestMovements data when it mounts.
    }

    handleFiletrClick = () => {
        const { dispatch, accountId } = this.props;
        dispatch(replace(`/accounts/${accountId}/filters`));
    };

    handleMovementClick = (movement) => {
        const { dispatch } = this.props;

        dispatch(accountsActions.setSelectedMovement(movement));
    };

    renderItem = (movement) => {
        const { accountCurrency, isDesktop } = this.props;
        return (
            <Table.Row
                renderAs={Link}
                to={`/accounts/${movement.idAccount}/movement`}
                onClick={() => this.handleMovementClick(movement)}
                key={movement.idStatement}>
                <Movement accountCurrency={accountCurrency} isDesktop={isDesktop} movement={movement} />
            </Table.Row>
        );
    };

    fetchInitialMovements = (isLatestMovementsSelected) => {
        const configuredDateFromValue = configUtils.getInteger("movements.dateTo.periodFront", 1);
        const { dispatch, selectedAccount, findBy, amountFrom, amountTo, voucher, detail, setFieldValue } = this.props;

        const lastDateFrom = moment()
            .startOf("day")
            .subtract(configuredDateFromValue, "months");
        const lastDateTo = moment().startOf("day");
        const pendingDateFrom = this.getPendingMinDate();
        const plusDays = configUtils.getInteger("movements.maxDays.default", 10);
        const pendingDateTo = this.getPendingMinDate().add(plusDays, "days");
        setFieldValue("lastDateFrom", lastDateFrom);
        setFieldValue("lastDateTo", lastDateTo);
        setFieldValue("pendingDateFrom", pendingDateFrom);
        setFieldValue("pendingDateTo", pendingDateTo);
        dispatch(
            accountsActions.fetchLatestMovements({
                selectedAccount,
                latestMovementsPageNumber: "1",
                pendingMovementsPageNumber: "1",
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                isLatestMovementsSelected,
            }),
        );
    };

    handleMovementToShow = (isLatestMovementsSelected) => {
        this.setState({
            isLatestMovementsSelected,
        });

        const { dispatch, resetForm } = this.props;
        resetForm();
        dispatch(accountsActions.setIsLatestMovements(isLatestMovementsSelected));
        this.fetchInitialMovements(isLatestMovementsSelected);
    };

    handleExport = (format) => {
        const {
            dispatch,
            selectedAccount,
            latestMovementsPageNumber,
            pendingMovementsPageNumber,
            values: {
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                movementType,
            },
        } = this.props;
        const pageNumber = 1;
        const isLatestMovementsSelected = document.getElementById("lastMovements").children.length > 1;
        const type = movementType > 1 ? null : movementType;

        dispatch(
            accountsActions.downloadLatestMovements({
                format,
                isLatestMovementsSelected,
                latestMovementsPageNumber,
                pendingMovementsPageNumber,
                findBy,
                dateFrom: isLatestMovementsSelected ? lastDateFrom : pendingDateFrom,
                dateTo: isLatestMovementsSelected ? lastDateTo : pendingDateTo,
                selectedAccount,
                pageNumber,
                amountFrom,
                amountTo,
                voucher,
                detail,
                type,
            }),
        );
    };

    getStrDate = (str) => {
        const date = str.substring(0, str.indexOf("T"));
        return moment(date).format("DD/MM/YYYY");
    };

    getLatestMovementsNextPage = (latestMovementsPageNumber) => {
        const {
            dispatch,
            selectedAccount,
            pendingMovementsPageNumber,
            values: {
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
            },
            isLatestMovementsSelected,
        } = this.props;
        dispatch(
            accountsActions.fetchLatestMovements({
                selectedAccount,
                latestMovementsPageNumber,
                pendingMovementsPageNumber,
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                isLatestMovementsSelected,
            }),
        );
    };

    getPendingMovementsNextPage = (pendingMovementsPageNumber) => {
        const {
            dispatch,
            selectedAccount,
            latestMovementsPageNumber,
            values: {
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
            },
            isLatestMovementsSelected,
        } = this.props;
        dispatch(
            accountsActions.fetchLatestMovements({
                selectedAccount,
                latestMovementsPageNumber,
                pendingMovementsPageNumber,
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                isLatestMovementsSelected,
            }),
        );
    };

    getMaxDateFrom = () => {
        const maxDateFrom = configUtils.getInteger("accounts.max.dateFrom", 3);
        return moment()
            .startOf("day")
            .add(maxDateFrom * -1, "months");
    };

    getPendingMinDate = () => {
        const isTest = configUtils.getBoolean("corePendingDate.isTest", false);
        const strDate = configUtils.get("coreDate.defaultPendingDate");
        return isTest && strDate ? moment.utc(strDate) : moment().startOf("day");
    };

    isValidAmount = (amount) => amount && amount.amount.toString().length > 0;

    renderTableLatestMovements = () => {
        const { latestMovements, selectedAccount, isDesktop } = this.props;
        const { currency } = selectedAccount;
        const list = latestMovements.map(
            ({ fechaValor, comprobante, concepto, importe, saldoParcial, signoImporte, signoSdoParcial }) => {
                if (isDesktop) {
                    return (
                        <Row className="account-table-wrapper mr-0">
                            <Col sm={12} md={1} lg={1} className="align-self-center text-align-left light-font">
                                <div className="data-text">{this.getStrDate(fechaValor)}</div>
                            </Col>
                            <Col
                                sm={12}
                                md={3}
                                lg={2}
                                className="align-self-center text-align-center justify-content-center light-font">
                                <div className="data-text">{comprobante}</div>
                            </Col>
                            <Col
                                sm={12}
                                md={4}
                                lg={4}
                                className="align-self-center text-align-center justify-content-center light-font">
                                <div className="data-text">{concepto}</div>
                            </Col>
                            <Col
                                sm={12}
                                md={2}
                                lg={2}
                                className="align-self-center text-align-right justify-content-right light-font">
                                <FormattedAmount
                                    currency={currency}
                                    className="data-amount"
                                    quantity={numUtils.parseDoubleWithSymbol(importe, signoImporte)}
                                    medium
                                    notBold
                                />
                            </Col>
                            <Col
                                sm={12}
                                md={2}
                                lg={3}
                                className="align-self-center text-align-right justify-content-right light-font">
                                <FormattedAmount
                                    currency={currency}
                                    className="data-amount"
                                    quantity={numUtils.parseDoubleWithSymbol(saldoParcial, signoSdoParcial)}
                                    medium
                                    notBold
                                />
                            </Col>
                        </Row>
                    );
                }
                return (
                    <ButtonDrawer
                        width="100%"
                        backButton
                        headerContent={
                            <div className="title-account-header-multiline-background-blue">
                                <h1 className="w-100 m-0 px-2">{i18nUtils.get("accounts.movements.row.detail")}</h1>
                                <p>
                                    {`${i18nUtils
                                        .get(`accounts.productType.${productTypes[selectedAccount.productType]}`)
                                        .toUpperCase()}  Nº ${maskedAccountNumber(selectedAccount.number)}`}
                                </p>
                            </div>
                        }
                        content={
                            <MovementDetail
                                arrayInfo={[
                                    {
                                        label: "accounts.lastestMovements.table.header.date",
                                        data: <div className="data-text">{this.getStrDate(fechaValor)}</div>,
                                    },
                                    {
                                        label: "accounts.lastestMovements.table.header.voucher",
                                        data: <div className="data-text">{comprobante}</div>,
                                    },
                                    {
                                        label: "accounts.lastestMovements.table.header.detail",
                                        data: <div className="data-text">{concepto}</div>,
                                    },
                                    {
                                        label: "accounts.lastestMovements.table.header.amount",
                                        data: (
                                            <FormattedAmount
                                                currency={currency}
                                                className="data-amount"
                                                quantity={numUtils.parseDoubleWithSymbol(importe, signoImporte)}
                                                small
                                                notBold
                                            />
                                        ),
                                    },
                                    {
                                        label: "accounts.lastestMovements.table.header.partialBalance",
                                        data: (
                                            <FormattedAmount
                                                currency={currency}
                                                className="data-amount"
                                                quantity={numUtils.parseDoubleWithSymbol(saldoParcial, signoSdoParcial)}
                                                small
                                                notBold
                                            />
                                        ),
                                    },
                                ]}
                            />
                        }
                        buttonElement={
                            <Row className="account-table-wrapper font-size-13-px pb-3 pt-2">
                                <Col sm={6} className="col col-6 align-self-center text-align-left f-dir-col">
                                    <div className="data-text">{this.getStrDate(fechaValor)}</div>
                                    <div className="data-text">{concepto}</div>
                                </Col>
                                <Col
                                    sm={6}
                                    className="col col-6 align-self-center text-align-center justify-content-right">
                                    <div className="align-self-center text-align-right justify-content-right">
                                        <FormattedAmount
                                            currency={currency}
                                            className="data-amount font-size-13-px"
                                            quantity={numUtils.parseDoubleWithSymbol(importe, signoImporte)}
                                            small
                                            notBold
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                    />
                );
            },
        );

        return (
            <Fragment>
                {isDesktop && (
                    <Row className="account-table-wrapper account-table-header account-movements-desktop mr-0">
                        <Col sm={12} md={1} lg={1} className="align-self-center text-align-left">
                            <I18n id="accounts.lastestMovements.table.header.date" />
                        </Col>
                        <Col
                            sm={12}
                            md={3}
                            lg={2}
                            className="align-self-center text-align-center justify-content-center">
                            <I18n id="accounts.lastestMovements.table.header.voucher" />
                        </Col>

                        <Col
                            sm={12}
                            md={4}
                            lg={4}
                            className="align-self-center text-align-center justify-content-center">
                            <I18n id="accounts.lastestMovements.table.header.detail" />
                        </Col>

                        <Col sm={12} md={2} lg={2} className="align-self-center text-align-right justify-content-right">
                            <I18n id="accounts.lastestMovements.table.header.amount" />
                        </Col>
                        <Col sm={12} md={2} lg={3} className="align-self-center text-align-right justify-content-right">
                            <I18n id="accounts.lastestMovements.table.header.partialBalance" />
                        </Col>
                    </Row>
                )}

                {list.length > 0 ? (
                    list
                ) : (
                    <div className="text-center no-more-data" key="noMoreMovements">
                        <div className="illustration-wrapper">
                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id={`accounts.movements.notFound${!isDesktop ? ".mobile" : ""}`} />
                        </p>
                    </div>
                )}
            </Fragment>
        );
    };

    renderTablePendingMovements = () => {
        const { pendingMovements, selectedAccount, isDesktop } = this.props;
        const { currency } = selectedAccount;
        const list = pendingMovements.map((movement) => {
            const { fechaIngreso, fechaImputacion, numeroComprobante, descripcion, importe, tipoMovimiento } = movement;
            if (isDesktop) {
                return (
                    <Row className="account-table-wrapper">
                        <Col sm={12} md={2} lg={2} className="align-self-center text-align-left">
                            <div className="data-text">{this.getStrDate(fechaIngreso)}</div>
                        </Col>
                        <Col sm={12} md={2} lg={2} className="align-self-center text-align-center">
                            <div className="data-text">{this.getStrDate(fechaImputacion)}</div>
                        </Col>
                        <Col sm={12} md={3} lg={2} className="align-self-center text-align-center">
                            <div className="data-text">{numeroComprobante}</div>
                        </Col>
                        <Col sm={12} md={3} lg={4} className="align-self-center text-align-center">
                            <div className="data-text">{descripcion}</div>
                        </Col>
                        <Col sm={12} md={1} lg={2} className="align-self-center text-align-right">
                            <FormattedAmount
                                currency={currency}
                                className="data-amount"
                                quantity={numUtils.parseDoubleWithSymbol(
                                    importe,
                                    tipoMovimiento ? "+" : "-" /* signoImporte */,
                                )}
                                medium
                                notBold
                            />
                        </Col>
                    </Row>
                );
            }
            return (
                <ButtonDrawer
                    width="100%"
                    backButton
                    headerContent={
                        <div>
                            <h1 className="w-100 m-0 px-2">{i18nUtils.get("accounts.movements.row.detail")}</h1>
                            <p>
                                {`${i18nUtils
                                    .get(`accounts.productType.${productTypes[selectedAccount.productType]}`)
                                    .toUpperCase()}  Nº ${maskedAccountNumber(selectedAccount.number)}`}
                            </p>
                        </div>
                    }
                    content={
                        <MovementDetail
                            arrayInfo={[
                                {
                                    label: "accounts.pendingMovements.table.header.entranceDate",
                                    data: <div className="data-text">{this.getStrDate(fechaIngreso)}</div>,
                                },
                                {
                                    label: "accounts.pendingMovements.table.header.imputationDate",
                                    data: <div className="data-text">{this.getStrDate(fechaImputacion)}</div>,
                                },
                                {
                                    label: "accounts.pendingMovements.table.header.voucher",
                                    data: <div className="data-text">{numeroComprobante}</div>,
                                },
                                {
                                    label: "accounts.pendingMovements.table.header.detail",
                                    data: <div className="data-text">{descripcion}</div>,
                                },
                                {
                                    label: "accounts.pendingMovements.table.header.amount",
                                    data: (
                                        <FormattedAmount
                                            currency={currency}
                                            className="data-amount"
                                            quantity={numUtils.parseDoubleWithSymbol(importe, "+")}
                                            small
                                            notBold
                                        />
                                    ),
                                },
                            ]}
                        />
                    }
                    buttonElement={
                        <Row
                            key="buttonElement"
                            className="account-table-wrapper font-size-13-px"
                            style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                            <Col sm={6} className="col col-6 text-align-left">
                                <div className="data-text">{this.getStrDate(fechaIngreso)}</div>
                                <div className="data-text">{descripcion}</div>
                            </Col>
                            <Col sm={6} className="col col-6 text-align-center ">
                                <Row style={{ justifyContent: "flex-end", marginRight: 0 }}>
                                    <Col className="col col-6 text-align-right" sm={2}>
                                        <I18n
                                            id="accounts.pendingMovements.table.header.imputationDate.mobile"
                                            componentProps={{ className: "data-field to-uppercase" }}
                                        />
                                    </Col>
                                    <Col className="col col-6 text-align-left" sm={3}>
                                        <div className="data-text">{this.getStrDate(fechaImputacion)}</div>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: "flex-end", marginRight: 0 }}>
                                    <Col className="col col-6 text-align-center" sm={6}>
                                        <FormattedAmount
                                            currency={currency}
                                            className="data-amount"
                                            quantity={importe}
                                            small
                                            notBold
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                />
            );
        });

        return (
            <Fragment>
                {isDesktop && (
                    <Row className="account-table-wrapper account-table-header account-movements-desktop">
                        <Col sm={12} md={2} lg={2} className="align-self-center text-align-left">
                            <I18n id="accounts.pendingMovements.table.header.entranceDate" />
                        </Col>
                        <Col sm={12} md={2} lg={2} className="align-self-center text-align-center">
                            <I18n id="accounts.pendingMovements.table.header.imputationDate" />
                        </Col>
                        <Col sm={12} md={3} lg={2} className="align-self-center text-align-center">
                            <I18n id="accounts.pendingMovements.table.header.voucher" />
                        </Col>

                        <Col sm={12} md={3} lg={4} className="align-self-center text-align-center">
                            <I18n id="accounts.pendingMovements.table.header.detail" />
                        </Col>

                        <Col sm={12} md={1} lg={2} className="align-self-center text-align-right">
                            <I18n id="accounts.pendingMovements.table.header.amount" />
                        </Col>
                    </Row>
                )}
                <div>
                    {list.length > 0 ? (
                        list
                    ) : (
                        <div className="text-center no-more-data" key="noMoreMovements">
                            <div className="illustration-wrapper">
                                <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                            </div>
                            <p className="text-lead">
                                <I18n id={`accounts.movements.notFound${!isDesktop ? ".mobile" : ""}`} />
                            </p>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    };

    handleChangeDate = (fieldName) => (selectedDate, form) => {
        const {
            values: { pendingDateTo, pendingDateFrom },
        } = this.props;

        const maxDays = configUtils.getInteger("movements.maxDays.default", 10);

        let dateTo;
        let dateFrom;
        let diff;

        if (fieldName === "pendingDateFrom") {
            diff = pendingDateTo ? Math.abs(moment(pendingDateTo)?.diff(selectedDate, "days")) : null;
            dateFrom = selectedDate;
            dateTo = diff && diff > maxDays ? moment(selectedDate)?.add(maxDays, "days") : pendingDateTo;
        } else {
            diff = pendingDateFrom ? moment(selectedDate)?.diff(pendingDateFrom, "days") : null;
            dateTo = selectedDate;
            dateFrom = diff && diff > maxDays ? moment(selectedDate)?.subtract(maxDays, "days") : pendingDateFrom;
        }

        form.setFieldValue("pendingDateTo", dateTo);
        form.setFieldValue("pendingDateFrom", dateFrom);
    };

    renderPeriod = () => {
        const {
            values: { lastDateFrom, pendingDateFrom },
        } = this.props;
        const { isLatestMovementsSelected } = this.state;
        return (
            <>
                {isLatestMovementsSelected ? (
                    <>
                        <div className="pr-3 w-224px">
                            <Field
                                component={DateField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="lastDateFrom"
                                selectsStart
                                minDate={this.getMaxDateFrom()}
                                maxDate={moment().startOf("day")}
                                autocomplete="off"
                            />
                        </div>
                        <div className="pr-3 w-224px">
                            <Field
                                component={DateField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="lastDateTo"
                                selectsEnd
                                minDate={lastDateFrom || this.getMaxDateFrom()}
                                maxDate={moment().startOf("day")}
                                autocomplete="off"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="pr-3 w-224px">
                            <Field
                                component={DateField}
                                hidePlaceholder
                                handleCustomChange={this.handleChangeDate("pendingDateFrom")}
                                idForm={FORM_ID}
                                name="pendingDateFrom"
                                selectsStart
                                minDate={this.getPendingMinDate()}
                                maxDate={this.getPendingMinDate().add(+3, "months")}
                                autocomplete="off"
                            />
                        </div>
                        <div className="pr-3 w-224px">
                            <Field
                                component={DateField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                handleCustomChange={this.handleChangeDate("pendingDateTo")}
                                name="pendingDateTo"
                                selectsEnd
                                minDate={pendingDateFrom || this.getPendingMinDate()}
                                maxDate={this.getPendingMinDate().add(+3, "months")}
                                autocomplete="off"
                            />
                        </div>
                    </>
                )}
            </>
        );
    };

    renderAmount = (currency) => {
        const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.${currency}`) }];

        return (
            <>
                <div className="pr-3 w-224px">
                    <Field
                        autocomplete="off"
                        component={AmountField}
                        data={{ options: currencies }}
                        idForm={FORM_ID}
                        name="amountFrom"
                        clearable={false}
                        label="transfers.amount.label"
                        maxLength={15}
                        disableSelect
                        fixedDecimalScale
                        useCustomHandleKeyDown
                    />
                </div>
                <div className="pr-3 w-224px">
                    <Field
                        autocomplete="off"
                        component={AmountField}
                        data={{ options: currencies }}
                        idForm={FORM_ID}
                        name="amountTo"
                        clearable={false}
                        label="transfers.amount.label"
                        maxLength={15}
                        disableSelect
                        fixedDecimalScale
                        useCustomHandleKeyDown
                    />
                </div>
            </>
        );
    };

    renderTypeFilter = (name) => {
        const options = [
            {
                value: 1,
                label: i18nUtils.get("accounts.movements.findBy.type.label.credit").toUpperCase(),
            },
            {
                value: 0,
                label: i18nUtils.get("accounts.movements.findBy.type.label.debit").toUpperCase(),
            },
            {
                value: 2,
                label: i18nUtils.get("accounts.movements.findBy.type.label.all").toUpperCase(),
            },
        ];

        return (
            <div className="w-224px pr-3">
                <Field
                    component={Selector}
                    options={options}
                    idForm={FORM_ID}
                    name={name}
                    inLineControl
                    isRequired
                    placeholder={i18nUtils.get("accounts.movements.findBy.placeholder")}
                    style={{ padding: "12px 1rem" }}
                />
            </div>
        );
    };

    renderTextFilter = (name) => (
        <div className="w-224px pr-3">
            <Field
                component={TextField}
                hidePlaceholder
                idForm={FORM_ID}
                name={name}
                type="text"
                maxLength={name === "voucher" ? "9" : "200"}
                errorClassName="p-absolute"
            />
        </div>
    );

    getCommonOptions = () => [
        {
            value: "period",
            label: i18nUtils.get("accounts.movements.findBy.period.label").toUpperCase(),
        },
        {
            value: "amount",
            label: i18nUtils.get("accounts.movements.findBy.amount.label").toUpperCase(),
        },
        {
            value: "detail",
            label: i18nUtils.get("accounts.movements.findBy.detail.label").toUpperCase(),
        },
    ];

    getLatestMovementsOptions = () => {
        const options = this.getCommonOptions();

        options.push({
            value: "voucher",
            label: i18nUtils.get("accounts.movements.findBy.voucher.label").toUpperCase(),
        });

        return options;
    };

    getPendingMovementsOptions = () => {
        const options = this.getCommonOptions();

        options.push({
            value: "movementType",
            label: i18nUtils.get("accounts.movements.findBy.operations.label").toUpperCase(),
        });

        return options;
    };

    buttonsFilters = () => {
        const { isDesktop } = this.props;
        const { isLatestMovementsSelected } = this.state;

        return (
            <div
                className={classNames("data-wrapper-flex align-items-end", {
                    "justify-content-center": !isDesktop,
                    "gap-1": isDesktop,
                })}>
                <div
                    className={classNames({
                        "tablet-selector-button": !isDesktop,
                    })}>
                    <Button
                        label={`${FORM_ID}.lastMovements.label`}
                        id="lastMovements"
                        className={classNames("btn-outline btn-regular chip-selector-btn", {
                            selected: isLatestMovementsSelected,
                            "py-2 px-0 m-0 account-movements-selector": !isDesktop,
                            "py-25 mx-0": isDesktop,
                        })}
                        replace={{
                            componentProps: { className: "p-0" },
                        }}
                        block={false}
                        image={isLatestMovementsSelected && `images/check.svg`}
                        onClick={() => this.handleMovementToShow(true)}
                    />
                </div>
                <div
                    className={classNames({
                        "tablet-selector-button": !isDesktop,
                    })}>
                    <Button
                        label={`${FORM_ID}.pendingMovements.label`}
                        id="pendingMovements"
                        className={classNames("btn-outline btn-regular chip-selector-btn", {
                            selected: !isLatestMovementsSelected,
                            "px-0 m-0 account-movements-selector": !isDesktop,
                            "py-25 mx-0 min-width-maxcontent": isDesktop,
                        })}
                        block={false}
                        image={!isLatestMovementsSelected && `images/check.svg`}
                        onClick={() => this.handleMovementToShow(false)}
                    />
                </div>
            </div>
        );
    };

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(accountsActions.removeMovements());
    }

    render() {
        const {
            isFetchingMovements,
            isDesktop,
            values: {
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                movementType,
            },
            latestMovements,
            latestMovementsPageNumber,
            latestMovementsTotalPages,
            pendingMovements,
            pendingMovementsTotalPages,
            pendingMovementsPageNumber,
            selectedAccount,
            fetchingDownload,
        } = this.props;

        const { isLatestMovementsSelected } = this.state;

        const isButtonDisabled =
            (findBy === "period" &&
                isLatestMovementsSelected &&
                ((!lastDateFrom && !lastDateTo) || (lastDateFrom && lastDateTo && lastDateFrom > lastDateTo))) ||
            (findBy === "period" &&
                !isLatestMovementsSelected &&
                ((!pendingDateFrom && !pendingDateTo) ||
                    (pendingDateFrom && pendingDateTo && pendingDateFrom > pendingDateTo))) ||
            (findBy === "amount" &&
                ((!this.isValidAmount(amountFrom) && !this.isValidAmount(amountTo)) ||
                    (this.isValidAmount(amountFrom) &&
                        this.isValidAmount(amountTo) &&
                        amountFrom.amount > amountTo.amount))) ||
            (findBy === "voucher" && !voucher) ||
            (findBy === "detail" && !detail) ||
            (findBy === "movementType" && typeof movementType !== "number" && !movementType);

        return (
            <PageLoading className="line-loader" loading={isFetchingMovements}>
                {selectedAccount && (
                    <>
                        {isDesktop ? (
                            <>
                                <Form autoComplete="off">
                                    <Container
                                        className="align-items-center
                                            account-header-detail mb-2"
                                        gridClassName="form-content px-0"
                                        rowClassName="d-flex flex-wrap w-100 p-3 mx-0">
                                        <div className="d-flex align-self-center account-button-filters mt-35">
                                            {this.buttonsFilters()}
                                        </div>
                                        <div className="pr-3 w-224px">
                                            <Field
                                                component={Selector}
                                                options={
                                                    isLatestMovementsSelected
                                                        ? this.getLatestMovementsOptions()
                                                        : this.getPendingMovementsOptions()
                                                }
                                                idForm={FORM_ID}
                                                name="findBy"
                                                inLineControl
                                                isRequired
                                                placeholder={i18nUtils.get("accounts.movements.findBy.placeholder")}
                                                style={{ padding: "12px 1rem" }}
                                            />
                                        </div>
                                        {findBy === "period" && this.renderPeriod()}
                                        {findBy === "amount" && this.renderAmount(selectedAccount.currency)}
                                        {findBy === "voucher" &&
                                            isLatestMovementsSelected &&
                                            this.renderTextFilter(findBy)}
                                        {findBy === "detail" && this.renderTextFilter(findBy)}
                                        {findBy === "movementType" &&
                                            !isLatestMovementsSelected &&
                                            this.renderTypeFilter(findBy)}
                                        <div className="d-flex align-items-end pr-3 pl-0">
                                            <Button
                                                bsStyle="primary"
                                                label={`${FORM_ID}.btn.filter.label`}
                                                loading={isFetchingMovements}
                                                type="submit"
                                                className="filter-button mb-3"
                                                disabled={isButtonDisabled}
                                            />
                                        </div>
                                        <div className="d-flex align-items-end ml-auto">
                                            <Dropdown
                                                image="images/download_bold.svg"
                                                listClassName="min-width-maxcontent"
                                                buttonClass="btn btn-outline mb-3"
                                                fetching={fetchingDownload}
                                                bsStyle={!isDesktop ? "link" : undefined}
                                                imageStyle={!isDesktop ? "toolbar-btn-image-mobile" : "px-4 m-0"}
                                                pullDown>
                                                <Button
                                                    onClick={() => this.handleExport("pdf")}
                                                    label="global.file.pdf"
                                                    className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                                                />
                                                <Button
                                                    onClick={() => this.handleExport("xls")}
                                                    label="global.file.xls"
                                                    className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                                                />
                                                {isDesktop && (
                                                    <Button
                                                        onClick={() => this.handleExport("csv")}
                                                        label="global.file.csv"
                                                        className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                                                    />
                                                )}
                                            </Dropdown>
                                        </div>
                                    </Container>
                                </Form>
                            </>
                        ) : (
                            <Row className="conteiner-white font-size-13-px d-flex justify-content-center mx-0">
                                <div className="my-3 w-100 justify-content-center">{this.buttonsFilters()}</div>
                            </Row>
                        )}
                        {isLatestMovementsSelected ? (
                            <div>
                                {latestMovements && this.renderTableLatestMovements()}
                                {latestMovements && latestMovements.length > 0 && (
                                    <div className="d-flex justify-content-end mt-3 mr-3">
                                        <Pagination
                                            totalPages={latestMovementsTotalPages}
                                            pageNumber={latestMovementsPageNumber}
                                            action={this.getLatestMovementsNextPage}
                                        />
                                    </div>
                                )}
                                {!latestMovements && (
                                    <div className="text-center no-more-data" key="noMoreMovements">
                                        <div className="illustration-wrapper">
                                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                                        </div>
                                        <p className="text-lead">
                                            <I18n id="accounts.movements.notFound" />
                                        </p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {pendingMovements && this.renderTablePendingMovements()}
                                {pendingMovements && pendingMovements.length > 0 && (
                                    <div className="d-flex justify-content-end mt-3 mr-3">
                                        <Pagination
                                            totalPages={pendingMovementsTotalPages}
                                            pageNumber={pendingMovementsPageNumber}
                                            action={this.getPendingMovementsNextPage}
                                        />
                                    </div>
                                )}
                                {!pendingMovements && (
                                    <div className="text-center no-more-data" key="noMoreMovements">
                                        <div className="illustration-wrapper">
                                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                                        </div>
                                        <p className="text-lead">
                                            <I18n id="accounts.movements.notFound" />
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: accountsSelectors.getFetching(state),
    filters: accountsSelectors.getFilters(state),
    firstFetched: accountsSelectors.getFirstFetched(state),
    movements: accountsSelectors.getMovements(state),
    moreMovements: accountsSelectors.getMoreMovements(state),
    pageNumber: accountsSelectors.getPageNumber(state),
    isFetchingMovements: accountsSelectors.getFetchingMovements(state),
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    latestMovements: accountsSelectors.getLatestMovements(state),
    latestMovementsPageNumber: accountsSelectors.getLatestMovementsPageNumber(state),
    latestMovementsTotalPages: accountsSelectors.getLatestMovementsTotalPages(state),
    pendingMovements: accountsSelectors.getPendingMovements(state),
    pendingMovementsPageNumber: accountsSelectors.getPendingMovementsPageNumber(state),
    pendingMovementsTotalPages: accountsSelectors.getPendingMovementsTotalPages(state),
    isLatestMovementsSelected: accountsSelectors.isLatestMovementsSelected(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            dateFrom: null,
            dateTo: null,
            lastDateFrom: null,
            lastDateTo: null,
            pendingDateFrom: null,
            pendingDateTo: null,
            findBy: "period",
            amountFrom: null,
            amountTo: null,
            voucher: null,
            detail: null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18nUtils.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().nullable(),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().nullable(),
                    voucher: values.voucher
                        ? Yup.number()
                              .typeError(i18nUtils.get("accounts.movements.integer.positive"))
                              .nullable()
                              .positive(i18nUtils.get("accounts.movements.integer.positive"))
                              .integer(i18nUtils.get("accounts.movements.integer.invalidValue"))
                        : Yup.number().nullable(),
                }),
            ),
        handleSubmit: (
            {
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                movementType,
            },
            formikBag,
        ) => {
            const { dispatch, selectedAccount, isLatestMovementsSelected } = formikBag.props;

            const type = movementType > 1 ? null : movementType;

            dispatch(
                accountsActions.fetchLatestMovements({
                    selectedAccount,
                    latestMovementsPageNumber: "1",
                    pendingMovementsPageNumber: "1",
                    findBy,
                    lastDateFrom,
                    lastDateTo,
                    pendingDateFrom,
                    pendingDateTo,
                    amountFrom,
                    amountTo,
                    voucher,
                    detail,
                    type,
                    isLatestMovementsSelected,
                }),
            );
        },
    }),
)(Movements);
