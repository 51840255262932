/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik } from "formik";
import Yup from "yup";
import { func, bool, shape } from "prop-types";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import moment from "moment";
import {
    Box,
    Icon,
    Modal,
    Text,
    Form,
    Button,
    Separator,
    useMediaQuery,
    BottomSheet,
} from "@ui-kit/components/index.es";
import LapseSubOption from "./LapseSubOption";

const STARTS_ON_DATE = moment(new Date()).add(1, "days");

function SchedulerModal(props) {
    SchedulerModal.propTypes = {
        onScheduleClick: func,
        onClose: func,
        isVisible: bool,
        scheduler: shape({}),
    };

    SchedulerModal.defaultProps = {
        onScheduleClick: () => {},
        onClose: () => {},
        isVisible: false,
        scheduler: {},
    };

    const isMobile = useMediaQuery("min", "md");

    const [values, setValues] = useState({
        startsOn: STARTS_ON_DATE.toDate(),
        lapse: "",
        frequency: "TODAY",
        customFreq: "",
    });

    const [lapseValues, setLapseValues] = useState({
        startsOn: STARTS_ON_DATE.toDate(),
        date: STARTS_ON_DATE.toDate(),
        lapse: schedulerUtils.NEVER,
        number: 1,
    });

    const [formikProps, setFormikProps] = useState({});

    const { onClose, isVisible, scheduler } = props;

    useEffect(() => {
        if (scheduler) {
            const program = scheduler.program || {
                frequency: scheduler.selectedOption,
                startsOn: scheduler.newValueDate?.toDate(),
            };

            setValues({
                startsOn: program.startsOn || STARTS_ON_DATE.toDate(),
                lapse: program.lapse || "",
                frequency: program.frequency || "TODAY",
                customFreq: "",
            });
            setLapseValues({
                startsOn: program.startsOn || STARTS_ON_DATE.toDate(),
                date: program.date || STARTS_ON_DATE.toDate(),
                lapse: program.lapse || schedulerUtils.NEVER,
                number: program.number || 1,
            });
            if (formikProps?.setValues) {
                formikProps.setValues({
                    frequency: {
                        label: program.frequency
                            ? i18nUtils.get(`scheduler.${program.frequency.toLowerCase()}`)
                            : "Ahora",
                        value: program.frequency || "TODAY",
                    },
                    dateFrom: program.startsOn,
                });
            }
        }
    }, []);

    const handleOnScheduleClick = (schedulerValues) => {
        const { onScheduleClick } = props;

        onScheduleClick({ ...schedulerValues, ...values, lapse: { ...lapseValues } });
    };

    const { frequency, startsOn } = values;

    const initialValues = {
        frequency: {
            label: "Ahora",
            value: "TODAY",
        },
        dateFrom: startsOn,
    };

    if (formikProps?.setValues) {
        formikProps.setValues({
            frequency: {
                label: frequency ? i18nUtils.get(`scheduler.${frequency.toLowerCase()}`) : "Ahora",
                value: frequency || "TODAY",
            },
            dateFrom: startsOn,
        });
    }

    return (
        <>
            {!isMobile ? (
                <Modal closeOnBgTap isVisible={isVisible} onClose={onClose} className="scheduler-modal__box">
                    <Box m={24}>
                        <Box justify="end">
                            <Button
                                onClick={() => onClose(true)}
                                variant="icon"
                                icon="Cancel"
                                size="large"
                                iconVariant="fill"
                                className="scheduler-modal__close-btn"
                            />
                        </Box>
                        <Box align="center" flex column my={24}>
                            <Box className="scheduler-modal__icon">
                                <Icon
                                    color="text-inverse-color"
                                    id="UserDefault"
                                    name="CalendarAdd"
                                    variant="fill"
                                    size={32}
                                />
                            </Box>
                            <Box flex mt={16}>
                                <Text align="center" variant="bodyStrong">
                                    {i18nUtils.get("scheduler.title")}
                                </Text>
                            </Box>
                        </Box>
                        <Form
                            disabledSubmitByDirty
                            rowSpacingForm={24}
                            withSeparator={false}
                            buttonSubmit={{
                                colProps: {
                                    size: 8,
                                    offset: 3,
                                },
                                text: "Agendar",
                                variant: "solid",
                            }}
                            initialValues={initialValues}
                            inputsList={[
                                {
                                    colProps: {
                                        size: 12,
                                    },
                                    component: "selectField",
                                    defaultOption: "TODAY",
                                    id: "frequency",
                                    options: schedulerUtils.listRecurrencies(),
                                    selectLabel: "FRECUENCIA",
                                    value: frequency,
                                    onSelectOption: (freq) => setValues({ ...values, frequency: freq.value }),
                                },
                                ...(frequency !== schedulerUtils.TODAY
                                    ? [
                                          {
                                              colProps: {
                                                  size: 12,
                                              },
                                              component: "dateField",
                                              id: "dateFrom",
                                              label: i18nUtils.get("scheduler.startsOn.label"),
                                              minDate: STARTS_ON_DATE.toDate(),
                                              startDate: startsOn,
                                              value: startsOn,
                                              formattedDisplay: true,
                                              selectsStart: true,
                                              onDateChange: (date) => setValues({ ...values, startsOn: date }),
                                          },
                                      ]
                                    : []),
                                ...(frequency !== schedulerUtils.TODAY && frequency !== schedulerUtils.ONCE
                                    ? [
                                          {
                                              colProps: {
                                                  size: 12,
                                              },
                                              id: "lapse",
                                              customElement: () => (
                                                  <Box w="100%">
                                                      <Separator type="horizontal" color="neutral-light" m={0} />
                                                      <LapseSubOption
                                                          values={lapseValues}
                                                          onChange={setLapseValues}
                                                          frequency={frequency}
                                                      />
                                                  </Box>
                                              ),
                                          },
                                      ]
                                    : []),
                            ]}
                            onSubmit={(e) => {
                                handleOnScheduleClick(e);
                            }}
                            setFormikProps={setFormikProps}
                            validationSchema={Yup.object().shape({
                                dateFrom: Yup.string()
                                    .nullable()
                                    .test(
                                        "test",
                                        i18nUtils.get("scheduler.startsOn.greaterThanEndDate"),
                                        (date) =>
                                            new Date(date) <=
                                                (lapseValues.date !== undefined ? lapseValues.date : new Date(date)) ||
                                            frequency === schedulerUtils.TODAY ||
                                            frequency === schedulerUtils.ONCE ||
                                            lapseValues.lapse === schedulerUtils.NEVER,
                                    ),
                            })}
                        />
                    </Box>
                </Modal>
            ) : (
                <BottomSheet isVisible={isVisible} onClose={onClose}>
                    <Box mb={24} px={24} className="min-height-75">
                        <Box flex mb={24} justify="center">
                            <Text align="center" variant="bodyStrong">
                                {i18nUtils.get("scheduler.title")}
                            </Text>
                        </Box>
                        <Form
                            wrapButtons
                            disabledSubmitByDirty
                            rowSpacingForm={24}
                            withSeparator={false}
                            buttonSubmit={{
                                colProps: {
                                    size: 12,
                                },
                                text: "Agendar",
                                variant: "solid",
                            }}
                            initialValues={initialValues}
                            inputsList={[
                                {
                                    colProps: {
                                        size: 12,
                                    },
                                    component: "selectField",
                                    defaultOption: "TODAY",
                                    id: "frequency",
                                    options: schedulerUtils.listRecurrencies(),
                                    selectLabel: "FRECUENCIA",
                                    iconMobile: "",
                                    value: frequency,
                                    onSelectOption: (freq) => setValues({ ...values, frequency: freq.value }),
                                },
                                ...(frequency !== schedulerUtils.TODAY
                                    ? [
                                          {
                                              colProps: {
                                                  size: 12,
                                              },
                                              component: "dateField",
                                              id: "dateFrom",
                                              label: i18nUtils.get("scheduler.startsOn.label"),
                                              minDate: STARTS_ON_DATE.toDate(),
                                              startDate: startsOn,
                                              value: startsOn,
                                              selectsStart: true,
                                              formattedDisplay: true,
                                              onDateChange: (date) => setValues({ ...values, startsOn: date }),
                                          },
                                      ]
                                    : []),
                                ...(frequency !== schedulerUtils.TODAY && frequency !== schedulerUtils.ONCE
                                    ? [
                                          {
                                              colProps: {
                                                  size: 12,
                                              },
                                              id: "lapse",
                                              customElement: () => (
                                                  <Box w="100%">
                                                      <Separator type="horizontal" color="neutral-light" m={0} />
                                                      <LapseSubOption
                                                          values={lapseValues}
                                                          onChange={setLapseValues}
                                                          frequency={frequency}
                                                      />
                                                  </Box>
                                              ),
                                          },
                                      ]
                                    : []),
                            ]}
                            onSubmit={(e) => {
                                handleOnScheduleClick(e);
                            }}
                            setFormikProps={setFormikProps}
                            validationSchema={Yup.object().shape({
                                dateFrom: Yup.string()
                                    .nullable()
                                    .test(
                                        "test",
                                        i18nUtils.get("scheduler.startsOn.greaterThanEndDate"),
                                        (date) =>
                                            new Date(date) <=
                                                (lapseValues.date !== undefined ? lapseValues.date : new Date(date)) ||
                                            frequency === schedulerUtils.TODAY ||
                                            frequency === schedulerUtils.ONCE ||
                                            lapseValues.lapse === schedulerUtils.NEVER,
                                    ),
                            })}
                        />
                    </Box>
                </BottomSheet>
            )}
        </>
    );
}

const mapStateToProps = () => ({});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { value } = props;
            const hasDate = value?.valueDate;
            const newProgram = {
                frequency: value?.selectedOption ? value.selectedOption : schedulerUtils.TODAY,
                startsOn:
                    value && value.selectedOption === schedulerUtils.ONCE && hasDate
                        ? moment(value.valueDate).toDate()
                        : STARTS_ON_DATE.startOf("day").toDate(),
                lapse: {
                    date: STARTS_ON_DATE.startOf("day").toDate(),
                    lapse: schedulerUtils.NEVER,
                },
                customFreq: schedulerUtils.DAY,
                customFreqValue: "1",
                days: dateUtils.getDayFromDate(STARTS_ON_DATE.startOf("day").toDate()),
                occurrenceType: "day", // day(every month on x day ) or occurrence(every month on the "tirth" "tuesday")
            };

            if (!value || !value.program) {
                return newProgram;
            }

            const startsOn =
                typeof value.program.startsOn === "string"
                    ? moment(value.program.startsOn).toDate()
                    : value.program.startsOn;
            return {
                ...value.program,
                startsOn,
                frequency: value.program.isCustom ? schedulerUtils.CUSTOM : value.program.frequency,
                lapse: {
                    lapse: value.program.lapse,
                    date: value.program.date,
                    number: value.program.number,
                },
                customFreq: value.program.frequency,
                customFreqValue: value.program.frequencyValue,
                occurrenceType: value.program.occurrence ? "occurrence" : "day",
            };
        },
    }),
)(SchedulerModal);
