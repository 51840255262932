/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import { push, goBack } from "react-router-redux";
import SelectedTransactionsAmount from "pages/transactions/SelectedTransactionsAmount";
import * as i18n from "util/i18n";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { compose } from "redux";
import Container from "pages/_components/Container";
import Credential from "pages/_components/fields/credentials/Credential";
import MultipleSignaturesDataTables from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesDataTables";
import { selectors as sessionSelectors } from "reducers/session";
import MainContainer from "pages/_components/MainContainer";
import isTokenActive from "util/token";

class MultipleSignaturesSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        multipleSignaturesData: shape({}).isRequired,
        isSubmitting: bool.isRequired,
    };

    componentDidMount() {
        const { multipleSignaturesData, dispatch } = this.props;

        if (!multipleSignaturesData) {
            dispatch(push("/pendingTransaction/list"));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
        dispatch(transactionsActions.clearSelectedTransactionRows());
    };

    montoOperaciones = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return (
            <SelectedTransactionsAmount
                selectedTransactionRows={selectedTransactionRows}
                isDesktop={isDesktop}
                showTotalOperations={false}
            />
        );
    };

    centerContentMobile = () => (
        <div
            className="title-account-header-multiline-background-blue"
            style={{ display: "flex", justifyContent: "center" }}>
            <h1 style={{ margin: "-0.3rem", width: "70%" }}>{i18n.get("pendingDispatch.list.title")}</h1>
        </div>
    );

    render() {
        const { isDesktop, multipleSignaturesData, isSubmitting } = this.props;

        return (
            <>
                {multipleSignaturesData && (
                    <>
                        <Notification scopeToShow="multipleSignatures" />
                        <div className="admin-detail-head px-0">
                            <Head
                                onBack={this.handleBack}
                                headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                                centerElement={isDesktop ? undefined : this.centerContentMobile}
                                emptyRightOption
                            />
                            {isDesktop && (
                                <div className="view-title" style={{ display: "flex" }}>
                                    <h1>{i18n.get("pendingDispatch.list.title")}</h1>
                                </div>
                            )}
                            <div
                                className={
                                    isDesktop
                                        ? "d-flex align-center mb-4 w-100 space-between mt-075"
                                        : "admin-content-center"
                                }>
                                <div
                                    className={`${
                                        isDesktop ? "d-flex flex-column align-items-start" : "d-flex flex-column"
                                    }`}>
                                    <span className={`${isDesktop ? "text-16" : "text-14"}`}>
                                        {i18n.get("sign.transaction")}
                                    </span>
                                    <span className={`${isDesktop ? "text-16 mt-075" : "text-14"}`}>
                                        {multipleSignaturesData?.activitySelected?.activityName?.toUpperCase()}
                                    </span>
                                </div>
                                <div className={!isDesktop && "mr-0 mt-3"}>{this.montoOperaciones()}</div>
                            </div>
                        </div>
                        <MainContainer>
                            <Form className="above-the-fold">
                                <Container className="flex-grow align-items-center" gridClassName="form-content">
                                    <MultipleSignaturesDataTables isDesktop={isDesktop} />
                                </Container>
                                <Container
                                    className={`flex-grow align-items-center ${isDesktop &&
                                        "container-white with-margin with-padding mt-0"}`}
                                    gridClassName="form-content">
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        {!isTokenActive(!isDesktop) ? (
                                            <div className="admin-content-center">
                                                <I18n
                                                    id="transfer.summary.confirm.label"
                                                    componentProps={
                                                        !isDesktop && {
                                                            className: "confirmation__text-without-otp text-12",
                                                        }
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={
                                                        isDesktop
                                                            ? { className: "confirmation__text-without-otp" }
                                                            : { className: "confirmation__text-without-otp text-12" }
                                                    }
                                                />
                                            </div>
                                        )}

                                        <div className="admin-content-center">
                                            <Field
                                                name="otp"
                                                type="otp"
                                                idForm="administration"
                                                component={Credential}
                                                loading={isSubmitting}
                                            />
                                        </div>
                                    </Col>
                                </Container>
                                <Container className="flex-grow align-items-center" gridClassName="form-content">
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            className="text-capitalize"
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </MainContainer>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, multipleSignaturesData, userId } = formikBag.props;
            const { selectedTransactionRows } = multipleSignaturesData;
            const selectedRows = selectedTransactionRows.map((transaction) => transaction.transaction.idTransaction);
            const { idForm } = selectedTransactionRows[0].transaction;

            dispatch(
                transactionsActions.signMultipleTransactions({
                    otp,
                    idActivity: multipleSignaturesData.activitySelected.idActivity,
                    transactions: selectedRows,
                    userId,
                    formikBag,
                    idForm: idForm === null ? "" : idForm,
                }),
            );
        },
    }),
)(MultipleSignaturesSummary);
