import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { string, shape, func, bool } from "prop-types";
import { connect } from "react-redux";

import { selectors as sessionSelectors } from "reducers/session";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Hint from "pages/_components/hints/Hint";
import Dropdown from "pages/_components/Dropdown";
import Notification from "pages/_components/Notification";
import { productTypes, maskedAccountNumber } from "util/accounts";
import * as i18nUtils from "util/i18n";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import SetAlias from "pages/accounts/_components/SetAlias";
import { selectors as accountsSelectors } from "reducers/accounts";

class Information extends Component {
    static propTypes = {
        account: shape({
            ownerName: string.isRequired,
            nationalIdentifier: string,
        }).isRequired,
        onClickDownloadInfo: func,
        isDesktop: bool.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                accountModifyAlias: bool,
            }),
        }).isRequired,
        fetchingDownload: bool.isRequired,
    };

    static defaultProps = {
        onClickDownloadInfo: null,
    };

    formatDocument = (document, documentType) => {
        let documentFormated = "";
        const documentWithoutBlank = document.replace(" ", "").trim();
        if (documentType !== "DNI" && documentWithoutBlank.length > 2) {
            if (documentWithoutBlank.length > 2) {
                documentFormated = documentWithoutBlank.substring(0, 2).concat("-");
            }
            if (documentWithoutBlank.length <= 10) {
                documentFormated += documentWithoutBlank.substring(2);
            }
            if (documentWithoutBlank.length > 10) {
                documentFormated += documentWithoutBlank
                    .substring(2, 10)
                    .concat("-")
                    .concat(documentWithoutBlank.substring(10));
            }
        } else {
            documentFormated = documentWithoutBlank;
        }

        return documentFormated;
    };

    render() {
        const { account, onClickDownloadInfo, isDesktop, activeEnvironment, fetchingDownload } = this.props;
        const {
            productType,
            number,
            cbu,
            cbuAlias,
            ownerName,
            documentNumber,
            documentType,
            currency,
            productAlias,
        } = account;
        const accountType = i18nUtils.get(`accounts.productType.${productTypes[productType]}`).toUpperCase();
        const currencyType = i18nUtils.get(`currency.label.${currency}`);
        const accountLabel = `${accountType} en ${currencyType}`;

        const downloadDropDownButton = (
            <>
                <Dropdown
                    image="images/download.svg"
                    imageStyle="mx-2"
                    label="global.download"
                    listClassName="min-w-full"
                    buttonClass={`${
                        isDesktop
                            ? "toolbar-btn view-close cmf-button-width account-download-info"
                            : "cmf-button-width btn-link"
                    }`}
                    pullDown
                    fetching={fetchingDownload}>
                    <Button
                        onClick={() => onClickDownloadInfo("pdf")}
                        label="global.download.pdf"
                        className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                    />
                    <Button
                        onClick={() => onClickDownloadInfo("xls")}
                        label="global.download.xls"
                        className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                    />
                    <Button
                        onClick={() => onClickDownloadInfo("csv")}
                        label="global.download.csv"
                        className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                    />
                </Dropdown>
            </>
        );

        return (
            <div className="account-information-data">
                <Row className={`px-1 ${!isDesktop && "mx-0"}`}>
                    <Col sm={12} md={6} lg={6} className="p-2">
                        <div className="data-wrapper-flex cmf-container-white account-info-row">
                            <div className="data-label data-label-inline">
                                <I18n id="accounts.information.accountType" />
                            </div>
                            <div className="data-desc data-desc-inline m-0">{accountLabel || " - "}</div>
                        </div>
                    </Col>

                    <Col sm={12} md={6} lg={6} className="p-2">
                        <div className="data-wrapper-flex cmf-container-white account-info-row">
                            <div className="data-label data-label-inline">
                                <I18n id="accounts.information.accountNumber" />
                            </div>
                            <div className="data-desc data-desc-inline m-0">{number || " - "}</div>
                        </div>
                    </Col>
                </Row>
                <Row className={`px-1 ${!isDesktop && "mx-0"}`}>
                    <Col sm={12} md={6} lg={6} className="p-2">
                        <div className="data-wrapper-flex cmf-container-white account-info-row">
                            <div className="data-label data-label-inline">
                                <I18n id="accounts.information.cbu" />
                            </div>
                            <div className="data-desc data-desc-inline m-0">{cbu || " - "}</div>
                        </div>
                    </Col>

                    <Col sm={12} md={6} lg={6} className="p-2">
                        <div className="data-wrapper-flex cmf-container-white account-info-row">
                            <div className="d-flex">
                                <div className="data-label data-label-inline">
                                    <I18n id="accounts.information.alias" />
                                </div>
                                <div className="data-desc data-desc-inline m-0 no-word-break">{cbuAlias || " - "}</div>
                            </div>
                            {activeEnvironment.permissions.accountModifyAlias ? (
                                <div className="data-desc data-desc-inline m-0">
                                    <ButtonDrawer
                                        label={`accounts.cbuAlias.edit${!isDesktop ? ".mobile" : ""}`}
                                        buttonClassName="btn-link p-0 mx-2 medium-font f-size-6"
                                        width={isDesktop ? "600px" : "100%"}
                                        closeButtonWhite={!isDesktop}
                                        notificationContent={<Notification scopeToShow="account/alias" />}
                                        headerContent={
                                            !isDesktop && (
                                                <div className="title-account-header-multiline-background-blue">
                                                    <div className="title-content mb-0">
                                                        <span>{productAlias || maskedAccountNumber(number)}</span>
                                                        <p className="my-0">
                                                            {`${i18nUtils
                                                                .get(
                                                                    `accounts.productType.${productTypes[productType]}`,
                                                                )
                                                                .toUpperCase()}  Nº ${maskedAccountNumber(number)}`}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        content={<SetAlias {...this.props} />}
                                    />
                                </div>
                            ) : (
                                undefined
                            )}
                            {isDesktop && (
                                <Hint
                                    classNameGroup="max-width-50 mb-0"
                                    idMessage="accounts.cbuAlias.edit.info"
                                    classNameImage="align-self-center"
                                    classNameHintP="hint-font-size-small"
                                />
                            )}
                        </div>
                    </Col>
                </Row>
                <Row className={`px-1 ${!isDesktop && "mx-0"}`}>
                    <Col sm={12} md={6} lg={6} className="p-2">
                        <div className="data-wrapper-flex cmf-container-white account-info-row">
                            <div className="data-label data-label-inline">
                                <I18n id="accounts.information.headlines" />
                            </div>
                            <div className="data-desc data-desc-inline m-0">{ownerName || " - "}</div>
                        </div>
                    </Col>

                    <Col sm={12} md={6} lg={6} className="p-2">
                        <div className="data-wrapper-flex cmf-container-white account-info-row">
                            <div className="data-label data-label-inline">
                                <I18n id={`documentType.label.${documentType}`} />
                            </div>
                            <div className="data-desc data-desc-inline  m-0">
                                {documentNumber ? this.formatDocument(documentNumber.toString(), documentType) : " - "}
                            </div>
                        </div>
                    </Col>
                </Row>
                {isDesktop ? (
                    <div className="d-flex justify-content-right">{downloadDropDownButton}</div>
                ) : (
                    <Row className={`justify-content-center ${!isDesktop && "mx-0"}`}>{downloadDropDownButton}</Row>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
});

export default connect(mapStateToProps)(Information);
