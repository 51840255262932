/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from "react";
import { bool, func } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { goBack } from "react-router-redux";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";

import * as i18n from "util/i18n";
import * as config from "util/config";

import {
    Box,
    Button,
    Container,
    Hint,
    RangeDatefield,
    Separator,
    Text,
    Dropdown,
    DropdownItem,
    ThemeProvider,
    Grid,
    Col,
} from "@ui-kit/components/index.es";

function V2Transfer(props) {
    V2Transfer.propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
    };

    const limitMonths = config.getInteger("frontend.Transfers.maxMonths.ToSearch", 3);
    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - limitMonths);
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const endDate = new Date();
    const [rangeDates, setRangeDates] = useState([startDate, endDate]);

    const [optionWidth, setOptionWidth] = useState(null);
    const downloadRef = useRef(null);

    useEffect(() => {
        const btnWidth = downloadRef.current?.offsetWidth;
        setOptionWidth(btnWidth);
    }, []);

    const handleBack = () => {
        const { dispatch } = props;
        dispatch(goBack());
    };
    const handleDownload = (format) => {
        const { dispatch } = props;
        const dateFrom = rangeDates[0];
        const dateTo = rangeDates[1];
        dispatch(transferActions.downloadTransfersHistoric(dateFrom, dateTo, format));
    };
    const downloadDisabled = !rangeDates[0] || !rangeDates[1];

    const { isDesktop, fetching } = props;
    return (
        <ThemeProvider>
            <Notification scopeToShow="form" />
            {!isDesktop && (
                <Box className="admin-detail-head z-index-1 px-0">
                    <Head
                        onBack={handleBack}
                        headerClassName="blue-main-header-mobile"
                        centerElement={() => (
                            <Text as="subtitle" className="m-0 no-wrap text-white font-medium">
                                {i18n.get("transfers.historic.btn.download")}
                            </Text>
                        )}
                        accessibilityTextId="transfers.historic.download.title"
                    />
                    <Box flex column justify="between" mb={24} px={16}>
                        <Hint size="big" direction="left" text={config.get("frontend.Transfers.Download.Message")} />
                        <Box flex w="100%" mt={20}>
                            <RangeDatefield
                                field={{
                                    id: "dates",
                                    name: "dates",
                                }}
                                onDateChange={(e) => setRangeDates(e)}
                                id="rangedatefield"
                                label={i18n.get("transfers.historic.download.executionDate")}
                                minDate={minDate}
                                startDate={startDate}
                                endDate={endDate}
                                my={10}
                            />
                        </Box>
                        <Box flex w="100%" my={20}>
                            <Separator color="neutral-light" type="horizontal" />
                        </Box>
                        <Box>
                            <Button
                                className="w-100"
                                text={i18n.get("transfers.historic.btn.download.pdf")}
                                variant="solid"
                                icon="SendDown"
                                iconVariant="outline"
                                disabled={downloadDisabled}
                                onClick={() => handleDownload("pdf")}
                                loading={fetching}
                                isInactive={fetching}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
            <Container fixed className="h-100">
                {isDesktop && (
                    <Box className="admin-detail-head px-0">
                        <Head onBack={handleBack} accessibilityTextId="transfers.historic.download.title" />
                        <Box my={12}>
                            <Text variant="h2">{i18n.get("transfers.historic.download.title")}</Text>
                        </Box>
                        <Container fixed={false} maxWidth={932}>
                            <Box shadow="default" py={16} px={24} my={32}>
                                <Grid rowSpacing={8}>
                                    <Col size={5}>
                                        <RangeDatefield
                                            field={{
                                                id: "dates",
                                                name: "dates",
                                            }}
                                            onDateChange={(e) => setRangeDates(e)}
                                            id="rangedatefield"
                                            label={i18n.get("transfers.historic.download.executionDate")}
                                            minDate={minDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            required
                                        />
                                    </Col>
                                    <Col size={7} align="end">
                                        <Box pb={4}>
                                            <Hint
                                                size="big"
                                                direction="left"
                                                text={config.get("frontend.Transfers.Download.Message")}
                                            />
                                        </Box>
                                    </Col>
                                    <Col size={12}>
                                        <Separator my={24} color="neutral-light" type="horizontal" />
                                    </Col>
                                    <Col size={4} offset={9}>
                                        <Dropdown
                                            w="100%"
                                            direction="right"
                                            optionWidth={optionWidth}
                                            label={
                                                <Button
                                                    ref={downloadRef}
                                                    disabled={downloadDisabled}
                                                    w="100%"
                                                    isInactive={fetching}
                                                    loading={fetching}
                                                    icon="SendDown"
                                                    iconVariant="outline"
                                                    text={i18n.get("global.download")}
                                                />
                                            }>
                                            <DropdownItem>
                                                <Box
                                                    onClick={() => handleDownload("pdf")}
                                                    rol="button"
                                                    p={8}
                                                    justify="center">
                                                    <Text variant="label">PDF</Text>
                                                </Box>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Box
                                                    onClick={() => handleDownload("xls")}
                                                    rol="button"
                                                    p={8}
                                                    justify="center">
                                                    <Text variant="label">XLS</Text>
                                                </Box>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Box
                                                    onClick={() => handleDownload("csv")}
                                                    rol="button"
                                                    p={8}
                                                    justify="center">
                                                    <Text variant="label">CSV</Text>
                                                </Box>
                                            </DropdownItem>
                                        </Dropdown>
                                    </Col>
                                </Grid>
                            </Box>
                        </Container>
                    </Box>
                )}
            </Container>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    fetching: transferSelectors.getFetchingDownload(state),
});

export default compose(connect(mapStateToProps))(V2Transfer);
