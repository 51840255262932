import React from "react";
import { getAccountDescription } from "util/accounts";

const TransactionTo = {
    "credin.sentRequest.send": ({ recipients }) =>
        (Array.isArray(recipients) && recipients?.map(({ id }) => <div key={id}> {id} </div>)),  // eslint-disable-line
    "credinCVU.sentRequest.send": ({ recipients }) =>
        (Array.isArray(recipients) && recipients?.map(({ id }) => <div key={id}> {id} </div>)), 
    "pay.paymentAFIP": ({ paymentAFIP }) => paymentAFIP?.contributorsCUIT,
    "payments.makePayment": ({ summaryInfo }) => summaryInfo?.selectedEntity?.description,
    "delete.paymentAFIP": ({ taxpayerCUIT }) => taxpayerCUIT,
    "scheduled.transactions.cancel": ({ clientUser }) => clientUser?.firstName,
    "cesion.echeq.activity": ({ beneficiaryName }) => beneficiaryName,
    "emit.echeq.activity": ({ beneficiaryName }) => beneficiaryName,
    "endorse.echeq.activity": ({ beneficiaryName }) => beneficiaryName,
    "transfers.internal.send": ({ formikBag }) => {
        if (formikBag?.props?.summary?.creditAccount?.number) {
            return getAccountDescription(formikBag?.props?.summary?.creditAccount);
        }
        return "";
    },
    "transfers.thirdParties.send": ({ clientUser }) => clientUser?.firstName,
    "transfers.toContact.send": ({ clientUser }) => clientUser?.firstName,
};

export default TransactionTo;
