/* eslint-disable import/no-unresolved */
import React, { Component, Fragment } from "react";
import { bool, shape, string, func, arrayOf, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { actions as debinActions, selectors as debinSelectors } from "reducers/debin";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import * as accountUtils from "util/accounts";
import { statusTagColor } from "util/debin";
import * as i18nUtils from "util/i18n";

import { resizableRoute } from "pages/_components/Resizable";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import InfoTag from "pages/_components/InfoTag";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Hint from "pages/_components/hints/Hint";
import * as configUtils from "util/config";
import { isValidCVU } from "util/credin";
import IncomingDebinModal from "./IncomingDebinModal";

class IncomingDebinDetail extends Component {
    static propTypes = {
        fetching: bool,
        fetchingAccounts: bool,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        dispatch: func.isRequired,
        debinDetail: shape({
            id: string,
        }).isRequired,
        buyerDetail: shape({}).isRequired,
        accounts: shape({}),
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        fetching: false,
        fetchingAccounts: false,
        match: null,
        accounts: null,
        loggedUser: null,
    };

    state = {
        modalDisplayed: false,
    };

    componentDidMount() {
        const {
            dispatch,
            match: {
                params: { idDebin },
            },
            accounts,
            loggedUser,
        } = this.props;

        dispatch(debinActions.acceptIncomingRequestPre(idDebin));

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    componentDidUpdate(prevProps) {
        const { debinDetail, accounts } = this.props;
        if (debinDetail && prevProps.debinDetail !== debinDetail) {
            const { dispatch } = this.props;
            const { buyerDetail } = debinDetail;
            const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
            const accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
            dispatch(debinActions.loadAccountName(accountName));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    handleAccept = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push("/payments/payDebin/accept/confirm"));
    };

    handleDecline = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push("/payments/payDebin/decline/confirm"));
    };

    handleModal = () => {
        this.setState({ modalDisplayed: true });
    };

    handleCancel = (e) => {
        e.preventDefault();
        this.setState({ modalDisplayed: false });
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <I18n id="payments.debin.detail.header.label.mobile" component="h1" />
        </div>
    );

    getDebitAccount = () => {
        const {
            debinDetail: { buyerDetail },
            accounts,
        } = this.props;
        return accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
    };

    getDebinTitle = () => {
        const {
            debinDetail: {
                id,
                status: { code },
                buyerDetail,
                preAutorizado,
            },
            accounts,
            isDesktop,
        } = this.props;
        const { cbu } = buyerDetail;
        const name = `${i18nUtils.get("payments.debin.detail.header.label ")} ${id}`.toUpperCase();
        const loggedUserAccount = accountUtils.getUserAccount(accounts, cbu);
        const accountName =
            accountUtils.getIncomingDebinAccountName(loggedUserAccount) ||
            accountUtils.maskedAccountNumber(cbu.substring(cbu.length - 8));

        if (!code) {
            return <></>;
        }

        return (
            <>
                {isDesktop ? (
                    <>
                        <div className="view-title data-wrapper-flex">
                            <h1 className="my-0 mr-3">{name}</h1>
                            {preAutorizado && (
                                <InfoTag
                                    type="info"
                                    message={i18nUtils.get("debin.recurrency.tag.recurrent")}
                                    tagBackground="#000"
                                    tagClass="align-self-end my-2 mr-3 ml-0"
                                    moreStyles={{ color: "white" }}
                                />
                            )}
                            <InfoTag
                                type="info"
                                message={code}
                                tagBackground={statusTagColor(code, preAutorizado)}
                                tagClass="mx-0"
                                moreStyles={{ color: "black" }}
                            />
                        </div>
                        <div className="d-flex my-1">
                            <p className="font-bold my-0 mr-2">
                                {i18nUtils.get(`debin.create.summary.debitAccount.label`)}
                            </p>
                            <p className="my-0">{accountName}</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="view-title data-wrapper my-0 mx-3">
                            <h3 className="mt-3 mb-0">{name}</h3>
                            <p className="font-bold m-0">{i18nUtils.get(`debin.create.summary.debitAccount.label`)}</p>
                            <p className="mt-0">{accountName}</p>
                            <div className="d-flex">
                                {preAutorizado && (
                                    <InfoTag
                                        type="info"
                                        message={i18nUtils.get("debin.recurrency.tag.recurrent")}
                                        tagBackground="#000"
                                        tagClass="align-self-end my-2 mr-3 ml-0"
                                        moreStyles={{ color: "white" }}
                                    />
                                )}
                                <InfoTag
                                    type="info"
                                    message={code}
                                    tagBackground={statusTagColor(code)}
                                    moreStyles={{
                                        alignSelf: "flex-start",
                                        width: "min-content",
                                        color: "black",
                                        margin: "0.5rem 0",
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    renderPageHeader = () => {
        const { isDesktop, debinDetail } = this.props;
        const { modalDisplayed } = this.state;
        const { status, sellerDetail } = debinDetail;

        const isValidCVUSeller = isValidCVU(sellerDetail.cbu);
        const isDebinCVUSignatureActive = configUtils.getBoolean("frontend.esquemaFirmas.validation.debinCVU.enable");

        if (isDesktop) {
            return (
                <>
                    <Notification scopeToShow="debin" />
                    <IncomingDebinModal
                        isDisplayed={modalDisplayed}
                        isDesktop={isDesktop}
                        acceptClick={this.handleAccept}
                        cancelClick={this.handleCancel}
                    />
                    <div className="admin-detail-head px-0">
                        <Head onBack={this.handleBack} />
                        {!debinDetail.preAutorizado &&
                        this.getDebitAccount() &&
                        status.code.toUpperCase() === "INICIADO" ? (
                            <>
                                <Head
                                    handleAcceptDebinClick={this.handleModal}
                                    handleDeclineDebinClick={() => this.handleDecline()}
                                    hasInlineButtons
                                    centerElement={this.getDebinTitle}
                                    centerContentClassName="d-flex flex-column align-items-start"
                                    acceptDebinCVUButton={isValidCVUSeller && isDebinCVUSignatureActive}
                                />
                                <Hint
                                    alwaysShowMessage
                                    classNameHintP="mt-0"
                                    nodeMessage={
                                        <I18n
                                            id=""
                                            defaultValue={configUtils.get("DEBIN.requestreceived.text")}
                                            component="p"
                                            componentProps={{ className: "hint-text hint-text-header" }}
                                        />
                                    }
                                />
                            </>
                        ) : (
                            <Head
                                centerElement={this.getDebinTitle}
                                centerContentClassName="d-flex flex-column align-items-start"
                            />
                        )}
                    </div>
                </>
            );
        }
        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="px-0 mb-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName="blue-main-header-mobile"
                        centerElement={this.centerContentMobile}
                        emptyRightOption
                    />
                    {this.getDebinTitle()}
                </div>
            </>
        );
    };

    renderContent = () => {
        const {
            debinDetail: {
                amount: { currency, quantity },
                concept,
                description,
                expirationDate,
                idVoucher,
                sellerDetail,
                creationDate,
                reference,
            },
            debinDetail: { amount, preAutorizado, status },
            isDesktop,
        } = this.props;

        const isValidCVUSeller = isValidCVU(sellerDetail.cbu);
        const isDebinCVUSignatureActive = configUtils.getBoolean("frontend.esquemaFirmas.validation.debinCVU.enable");

        const { modalDisplayed } = this.state;

        const containerClassName = `transfer-data ${
            !isDesktop ? "data-wrapper-flex space-between mb-0 debin-detail" : "data-wrapper"
        }`;

        return (
            <Fragment>
                {this.renderPageHeader()}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className="flex-grow container-white my-2 with-padding"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.amount"
                                    data={
                                        amount ? (
                                            <FormattedAmount currency={currency} quantity={quantity} notBold="true" />
                                        ) : (
                                            "-"
                                        )
                                    }
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.concept"
                                    data={concept ? i18nUtils.get(`transfers.concept.${concept}`) : "-"}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.description"
                                    data={description || "-"}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.expirationDate"
                                    data={
                                        expirationDate ? (
                                            <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                        ) : (
                                            "-"
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                        <Container
                            className="flex-grow container-white my-2 with-padding"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.voucher"
                                    data={idVoucher || "-"}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.sender"
                                    data={sellerDetail.titular || "-"}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.documentType"
                                    data={
                                        (
                                            <div>
                                                <span style={{ marginRight: "0.5rem" }}>
                                                    {sellerDetail.documentType}
                                                </span>
                                                <span>{sellerDetail.documentNumber}</span>
                                            </div>
                                        ) || "-"
                                    }
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.creationDate"
                                    data={
                                        creationDate ? (
                                            <FormattedDate date={creationDate} dateFormat="dd/MM/yyyy" showTime />
                                        ) : (
                                            "-"
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                        <Container
                            className="flex-grow container-white my-2 with-padding"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.cbu"
                                    data={sellerDetail.cbu || "-"}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.alias"
                                    data={sellerDetail.cbuAlias || "-"}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.branch"
                                    data={`${sellerDetail.bank} / ${sellerDetail.branch}`}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="payments.debin.detail.table.header.reference"
                                    data={reference || "-"}
                                />
                            </Col>
                        </Container>
                        {preAutorizado && (
                            <Container
                                className="container--layout flex-grow align-items-center"
                                gridClassName="form-content">
                                <Col md={6} sm={12}>
                                    <Hint
                                        idMessage="debin.recurrency.reverse.charge.hint"
                                        styleText={{ maxWidth: "100%" }}
                                        styleImage={{ alignSelf: "center", marginTop: "0.7rem" }}
                                        classNameHintP="hint-font-size-small"
                                        alwaysShowMessage
                                    />
                                </Col>
                            </Container>
                        )}
                    </div>
                    {!isDesktop &&
                        !preAutorizado &&
                        this.getDebitAccount() &&
                        status.code.toUpperCase() === "INICIADO" && (
                            <>
                                <IncomingDebinModal
                                    isDisplayed={modalDisplayed}
                                    isDesktop={isDesktop}
                                    acceptClick={this.handleAccept}
                                    cancelClick={this.handleCancel}
                                />
                                <Container
                                    className="container--layout flex-grow align-items-center"
                                    gridClassName="form-content">
                                    <Col sm={12} md={9} lg={7} xl={7} className="col col-12 align-items-center">
                                        <Col
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "column-reverse",
                                            }}
                                            sm={12}
                                            className="col col-12 col-no-pad-mobile">
                                            <Button
                                                bsStyle="outline"
                                                onClick={() => this.handleDecline()}
                                                label="payments.debin.table.menu.decline"
                                                style={{ marginTop: "0.25rem", marginBottom: "0.25rem" }}
                                            />
                                            <Button
                                                bsStyle="primary"
                                                style={{
                                                    marginTop: "0.25rem",
                                                    marginBottom: "0.25rem",
                                                }}
                                                onClick={this.handleModal}
                                                label={
                                                    isValidCVUSeller && isDebinCVUSignatureActive
                                                        ? "payments.debinCVU.table.menu.accept"
                                                        : "payments.debin.table.menu.accept"
                                                }
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                            </>
                        )}
                </MainContainer>
            </Fragment>
        );
    };

    render() {
        const { fetching, debinDetail, fetchingAccounts } = this.props;

        return (
            <Fragment>
                <PageLoading loading={fetching || fetchingAccounts}>
                    {!fetching && debinDetail && this.renderContent()}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    debinDetail: debinSelectors.getDebinDetail(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    fetching: debinSelectors.getFetching(state),
});

export default connect(mapStateToProps)(resizableRoute(IncomingDebinDetail));
