export const types = {
    TRANSFER_PRE_ACTIVITY_REQUEST: "transfer/TRANSFER_PRE_ACTIVITY_REQUEST",
    TRANSFER_PRE_ACTIVITY_SUCCESS: "transfer/TRANSFER_PRE_ACTIVITY_SUCCESS",
    TRANSFER_PRE_ACTIVITY_FAILURE: "transfer/TRANSFER_PRE_ACTIVITY_FAILURE",
    CREATE_INTERNAL_TRANSFER_REQUEST: "transfer/CREATE_INTERNAL_TRANSFER_REQUEST",
    CREATE_INTERNAL_TRANSFER_SUCCESS: "transfer/CREATE_INTERNAL_TRANSFER_SUCCESS",
    CREATE_INTERNAL_TRANSFER_FAILURE: "transfer/CREATE_INTERNAL_TRANSFER_FAILURE",
    CREATE_EXTERNAL_TRANSFER_REQUEST: "transfer/CREATE_EXTERNAL_TRANSFER_REQUEST",
    CREATE_EXTERNAL_TRANSFER_SUCCESS: "transfer/CREATE_EXTERNAL_TRANSFER_SUCCESS",
    CREATE_EXTERNAL_TRANSFER_FAILURE: "transfer/CREATE_EXTERNAL_TRANSFER_FAILURE",
    CREATE_TRANSFER_TO_CONTACT_REQUEST: "transfer/CREATE_TRANSFER_TO_CONTACT_REQUEST",
    CREATE_TRANSFER_TO_CONTACT_SUCCESS: "transfer/CREATE_TO_CONTACT_SUCCESS",
    CREATE_TRANSFER_TO_CONTACT_FAILURE: "transfer/CREATE_TO_CONTACT_FAILURE",
    CLEAN_TRANSFER_STATE: "transfer/CLEAN_TRANSFER_STATE",
    GET_ACCOUNT_INFO: "transfer/GET_ACCOUNT_INFO",
    LOAD_INTERNAL_TRANSFER: "transfer/LOAD_INTERNAL_TRANSFER",
    LOAD_EXTERNAL_TRANSFER: "transfer/LOAD_EXTERNAL_TRANSFER",
    LOAD_SCHEDULED_TRANSACTION_CANCEL: "transfer/LOAD_SCHEDULED_TRANSACTION_CANCEL",
    LOAD_THIRD_PARTIES_TRANSFER: "transfer/LOAD_THIRD_PARTIES_TRANSFER",
    SEND_INTERNAL_TRANSFER_REQUEST: "transfer/SEND_INTERNAL_TRANSFER_REQUEST",
    SEND_INTERNAL_TRANSFER_SUCCESS: "transfer/SEND_INTERNAL_TRANSFER_SUCCESS",
    SEND_INTERNAL_TRANSFER_PENDING: "transfer/SEND_INTERNAL_TRANSFER_PENDING",
    SEND_INTERNAL_TRANSFER_FAILURE: "transfer/SEND_INTERNAL_TRANSFER_FAILURE",
    SEND_EXTERNAL_TRANSFER_REQUEST: "transfer/SEND_EXTERNAL_TRANSFER_REQUEST",
    SEND_EXTERNAL_TRANSFER_SUCCESS: "transfer/SEND_EXTERNAL_TRANSFER_SUCCESS",
    SEND_EXTERNAL_TRANSFER_PENDING: "transfer/SEND_EXTERNAL_TRANSFER_PENDING",
    SEND_EXTERNAL_TRANSFER_FAILURE: "transfer/SEND_EXTERNAL_TRANSFER_FAILURE",
    SEND_TRANSFER_TO_CONTACT_REQUEST: "transfer/SEND_TRANSFER_TO_CONTACT_REQUEST",
    SEND_TRANSFER_TO_CONTACT_SUCCESS: "transfer/SEND_TRANSFER_TO_CONTACT_SUCCESS",
    SEND_TRANSFER_TO_CONTACT_FAILURE: "transfer/SEND_TRANSFER_TO_CONTACT_FAILURE",
    SEND_TRANSFER_TO_CONTACT_PENDING: "transfer/SEND_TRANSFER_TO_CONTACT_PENDING",
    CHANGE_TRANSFER_GROUP: "transfer/CHANGE_TRANSFER_GROUP",
    SET_TRANSFER_GROUP: "transfer/SET_TRANSFER_GROUP",
    SET_DEBIT_ACCOUNT_INFO: "transfer/SET_DEBIT_ACCOUNT_INFO",
    SET_CREDIT_ACCOUNT_INFO: "transfer/SET_CREDIT_ACCOUNT_INFO",
    CLEAR_TRANSFER_DATA: "transfer/CLEAR_TRANSFER_DATA",
    CANCEL_SCHEDULED_TRANSACTION_REQUEST: "transfer/CANCEL_SCHEDULED_TRANSACTION_REQUEST",
    CANCEL_SCHEDULED_TRANSACTION_FAILURE: "transfer/CANCEL_SCHEDULED_TRANSACTION_FAILURE",
    CANCEL_SCHEDULED_TRANSACTION_SUCCESS: "transfer/CANCEL_SCHEDULED_TRANSACTION_SUCCESS",
    ACCOUNT_LIMITS_REQUEST: "transfer/ACCOUNT_LIMITS_REQUEST",
    ACCOUNT_LIMITS_FAILURE: "transfer/ACCOUNT_LIMITS_FAILURE",
    ACCOUNT_LIMITS_SUCCESS: "transfer/ACCOUNT_LIMITS_SUCCESS",
    SEND_SCHEDULER_EMAIL_REQUEST: "transfer/SEND_SCHEDULER_EMAIL_REQUEST",
    SEND_SCHEDULER_EMAIL_FAILURE: "transfer/SEND_SCHEDULER_EMAIL_FAILURE",
    SEND_SCHEDULER_EMAIL_SUCCESS: "transfer/SEND_SCHEDULER_EMAIL_SUCCESS",
    GET_CBU_CVU_INFO: "transfer/GET_CBU_CVU_INFO",
    SET_IS_CVU: "transfer/SET_IS_CVU",
    SET_SEND_EMAIL: "transfer/SET_SEND_EMAIL",
    LOAD_HISTORIC_LIST_REQUEST: "transfer/LOAD_HISTORIC_LIST_REQUEST",
    LOAD_HISTORIC_LIST_FAILURE: "transfer/LOAD_HISTORIC_LIST_FAILURE",
    LOAD_HISTORIC_LIST_SUCCESS: "transfer/LOAD_HISTORIC_LIST_SUCCESS",
    LOAD_MORE_TRANSFERS_REQUEST: "transfer/LOAD_MORE_TRANSFERS_REQUEST",
    LOAD_MORE_TRANSFERS_SUCCESS: "transfer/LOAD_MORE_TRANSFERS_SUCCESS",
    LOAD_PENDING_QUANTITY_REQUEST: "transfer/LOAD_PENDING_QUANTITY_REQUEST",
    LOAD_PENDING_QUANTITY_SUCCESS: "transfer/LOAD_PENDING_QUANTITY_SUCCESS",
    SET_FILTERS: "transfer/SET_FILTERS",
    RESET_ALL_FILTERS: "transfer/RESET_ALL_FILTERS",
    RESET_HISTORIC_TRANSFERS: "transfer/RESET_HISTORIC_TRANSFERS",
    RESET_LIST_TRANSFERS: "transfer/RESET_LIST_TRANSFERS",
    SET_HAS_FILTER_CHANGED: "transfer/SET_HAS_FILTER_CHANGED",
    SET_SELECTED_CHIP: "transfer/SET_SELECTED_CHIP",
    SET_SHOW_FILTERS: "transfer/SET_SHOW_FILTERS",
    SET_FILTERS_HISTORIC: "transfer/SET_FILTERS_HISTORIC",
    SET_FILTERS_PENDING: "transfer/SET_FILTERS_PENDING",
    SET_FILTERS_SCHEDULED: "transfer/SET_FILTERS_SCHEDULED",
    SET_FILTERS_DRAFT: "transfer/SET_FILTERS_DRAFT",
    RESET_PENDING_FILTERS: "transfer/RESET_PENDING_FILTERS",
    RESET_DRAFT_FILTERS: "transfer/RESET_DRAFT_FILTERS",
    RESET_SCHEDULED_FILTERS: "transfer/RESET_SCHEDULED_FILTERS",
    SET_AGENDA_DRAWER_VISIBLE: "transfer/SET_AGENDA_DRAWER_VALUES",
    SET_AGENDA_DRAWER_VALUES: "transfer/SET_AGENDA_DRAWER_VISIBLE",
    RESET_AGENDA_DRAWER_VALUES: "transfer/RESET_AGENDA_DRAWER_VISIBLE",
    FREQUENT_DESTINATION_TRANSFER_CREATE_REQUEST: "transfer/FREQUENT_DESTINATION_TRANSFER_CREATE_REQUEST",
    FREQUENT_DESTINATION_TRANSFER_CREATE_SUCCESS: "transfer/FREQUENT_DESTINATION_TRANSFER_CREATE_SUCCESS",
    SET_FREQUENT_DESTINATION_ON_LIST: "transfer/SET_FREQUENT_DESTINATION_ON_LIST",
    SET_SELECTED_FROM_AGENDA: "transfer/SET_SELECTED_FROM_AGENDA",
    RESET_STATE_MODAL_AGENDA: "transfer/RESET_STATE_MODAL_AGENDA",
    FREQUENT_DESTINATION_TRANSFER_CREATE_FAILED: "transfer/FREQUENT_DESTINATION_TRANSFER_CREATE_FAILED",
    READ_CANCEL_SCHEDULED: "transfer/READ_CANCEL_SCHEDULED",
    READ_DOWNLOAD_TRANSACTION: "transfer/READ_DOWNLOAD_TRANSACTION",
    DOWNLOAD_TRANSFERS_HISTORIC: "transfer/DOWNLOAD_TRANSFERS_HISTORIC",
    DOWNLOAD_TRANSFERS_HISTORIC_SUCCESS: "transfer/DOWNLOAD_TRANSFERS_HISTORIC_SUCCESS",
    DOWNLOAD_TRANSFERS_HISTORIC_FAILURE: "transfer/DOWNLOAD_TRANSFERS_HISTORIC_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    fetchingHistoric: false,
    fetchingSubmit: false,
    summary: {
        type: null,
        debitAccount: "",
        creditAccount: "",
        cbuOrAlias: null,
        concept: null,
        transferKind: null,
        currency: 0,
        amount: null,
        email: null,
        text: null,
        sendEmail: false,
        idTransaction: null,
        scheduler: null,
        reference: "",
        tadTrx: false,
        control: false,
    },
    creditAccountDetail: null,
    clientUser: null,
    otp: null,
    internalTransfer: true,
    transferGroup: "transferInternal",
    accounts: [],
    concepts: [],
    limit: 0.0,
    limitUsed: 0.0,
    scheduledCancel: false,
    serverDate: null,
    isCVU: false,
    pspEntityName: null,
    sendEmail: false,
    isFirstFetching: true,
    isFetchingMore: false,
    filters: {
        transferType: {
            label: "Todos",
            value: "all",
        },
        beneficiary: "",
        reference: "",
        orderBy: "submit_date_time DESC",
        dateType: "submit_date_time",
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
        onlyPendings: false,
    },
    pageNumber: 1,
    hasMoreData: false,
    activities: null,
    transfersList: [],
    totalPages: 1,
    hasFilterChanged: false,
    selectedChip: "historic",
    showFilters: false,
    filtersHistoric: {
        transferType: {
            label: "Todos",
            value: "all",
        },
        beneficiary: "",
        reference: "",
        orderBy: "submit_date_time DESC",
        dateType: "submit_date_time",
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
        onlyPendings: false,
        minAmount: 0,
        maxAmount: 0,
        // status: "TODOS",
    },
    filtersPending: {
        transferType: {
            label: "Todos",
            value: "all",
        },
        beneficiary: "",
        reference: "",
        orderBy: "creation_date_time DESC",
        dateType: "creation_date_time",
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
        onlyPendings: false,
        minAmount: 0,
        maxAmount: 0,
        status: "PENDING",
    },
    filtersScheduled: {
        transferType: {
            label: "Todos",
            value: "all",
        },
        beneficiary: "",
        reference: "",
        orderBy: "value_date_time DESC",
        dateType: "value_date_time",
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
        onlyPendings: false,
        minAmount: 0,
        maxAmount: 0,
        status: "SCHEDULED",
    },
    filtersDraft: {
        transferType: {
            label: "Todos",
            value: "all",
        },
        beneficiary: "",
        reference: "",
        orderBy: "creation_date_time DESC",
        dateType: "creation_date_time",
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
        onlyPendings: false,
        minAmount: 0,
        maxAmount: 0,
        status: "DRAFT",
    },
    agendaDrawerVisibleRed: false,
    frequentDestination: {},
    selectedFromAgenda: false,
    frequentDestinationExistOnList: false,
    frequentDestinationLoading: false,
    agendaDrawerValues: {
        referenceAgenda: "",
        emailAgenda: "",
        tokenAgenda: "",
    },
    cvuCbuFromAlias: null,
    fetchingAlias: false,
    controlActivity: false,
    controlARS: null,
    controlUSD: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        creditAccountDetail,
        clientUser,
        otp,
        idTransaction,
        internalTransfer,
        transferGroup,
        summary,
        accountInfo,
        concepts,
        accounts,
        limit,
        limitUsed,
        tadTrx,
        serverDate,
        isCVU,
        pspEntityName,
        sendEmail,
        hasFilterChanged,
        pendingQuantity,
        selectedChip,
        control,
        showFilters,
        frequentDestination,
    } = action;

    switch (action.type) {
        case types.GET_ACCOUNT_INFO:
            return {
                ...state,
                fetching: true,
            };
        case types.SET_DEBIT_ACCOUNT_INFO:
            return {
                ...state,
                summary: { ...state.summary, debitAccount: accountInfo },
                fetching: false,
            };
        case types.SET_CREDIT_ACCOUNT_INFO:
            return {
                ...state,
                summary: { ...state.summary, creditAccount: accountInfo },
                fetching: false,
            };
        case types.CLEAR_TRANSFER_DATA:
            return {
                ...state,
                summary: {},
            };
        case types.CHANGE_TRANSFER_GROUP:
            return {
                ...INITIAL_STATE,
                internalTransfer,
                concepts: state.concepts,
                accounts: state.accounts,
                fetching: state.fetching,
                serverDate: state.serverDate,
            };
        case types.SET_TRANSFER_GROUP:
            return {
                ...state,
                transferGroup,
            };
        case types.CREATE_INTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                fetching: true,
                internalTransfer: true,
                summary,
            };

        case types.CREATE_INTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: { ...state.summary, creditAccountDetail, clientUser, tadTrx },
            };

        case types.CREATE_INTERNAL_TRANSFER_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.CREATE_TRANSFER_TO_CONTACT_REQUEST:
        case types.CREATE_EXTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                internalTransfer: false,
                fetching: true,
                fetchingSubmit: true,
                summary,
            };
        case types.CREATE_TRANSFER_TO_CONTACT_SUCCESS:
        case types.CREATE_EXTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetchingSubmit: false,
                summary: {
                    ...state.summary,
                    creditAccountDetail,
                    clientUser,
                    tadTrx,
                    pspEntityName,
                    cbu: creditAccountDetail?.cbu,
                    control,
                },
            };
        case types.CREATE_TRANSFER_TO_CONTACT_FAILURE:
        case types.CREATE_EXTERNAL_TRANSFER_FAILURE:
            return {
                ...state,
                fetching: false,
                fetchingSubmit: false,
            };
        case types.LOAD_INTERNAL_TRANSFER:
            return {
                ...state,
                internalTransfer: true,
                scheduledCancel: false,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };
        case types.LOAD_EXTERNAL_TRANSFER:
            return {
                ...state,
                internalTransfer: false,
                scheduledCancel: false,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };

        case types.LOAD_SCHEDULED_TRANSACTION_CANCEL:
            return {
                ...state,
                scheduledCancel: true,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };

        case types.LOAD_THIRD_PARTIES_TRANSFER:
            return {
                ...state,
                internalTransfer: false,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };

        case types.CLEAN_TRANSFER_STATE:
            return {
                ...INITIAL_STATE,
            };
        case types.SEND_INTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                otp,
            };
        case types.SEND_INTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                idTransaction,
            };
        case types.SEND_INTERNAL_TRANSFER_PENDING:
            return {
                ...state,
                idTransaction,
                summary: { ...state.summary, idTransaction },
            };
        case types.SEND_TRANSFER_TO_CONTACT_REQUEST:
        case types.SEND_EXTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                internalTransfer: false,
                idTransaction,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
                otp,
            };
        case types.SEND_TRANSFER_TO_CONTACT_SUCCESS:
        case types.SEND_EXTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                idTransaction,
                summary: { ...state.summary, idTransaction },
            };
        case types.SEND_TRANSFER_TO_CONTACT_PENDING:
        case types.SEND_EXTERNAL_TRANSFER_PENDING:
            return {
                ...state,
                idTransaction,
            };
        case types.TRANSFER_PRE_ACTIVITY_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TRANSFER_PRE_ACTIVITY_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.TRANSFER_PRE_ACTIVITY_SUCCESS:
            return {
                ...state,
                fetching: false,
                concepts,
                accounts,
                serverDate,
            };
        case types.CANCEL_SCHEDULED_TRANSACTION_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.CANCEL_SCHEDULED_TRANSACTION_FAILURE:
        case types.CANCEL_SCHEDULED_TRANSACTION_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.ACCOUNT_LIMITS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ACCOUNT_LIMITS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ACCOUNT_LIMITS_SUCCESS:
            return {
                ...state,
                fetching: false,
                limit,
                limitUsed,
            };
        case types.SEND_SCHEDULER_EMAIL_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.SEND_SCHEDULER_EMAIL_FAILURE:
        case types.SEND_SCHEDULER_EMAIL_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_IS_CVU:
            return {
                ...state,
                isCVU,
                cvuCbuFromAlias: action.cvuCbuFromAlias,
                fetchingAlias: false,
            };
        case types.SET_SEND_EMAIL:
            return {
                ...state,
                sendEmail,
            };
        case types.LOAD_HISTORIC_LIST_REQUEST:
            return {
                ...state,
                fetchingHistoric: true,
                filters: action.filters,
                historyFilters: action?.filters,
                isFirstFetching: action.isFirstFetching === true,
                transfersList: [],
            };
        case types.LOAD_HISTORIC_LIST_FAILURE:
            return {
                ...state,
                fetchingHistoric: false,
                isFirstFetching: false,
            };
        case types.LOAD_HISTORIC_LIST_SUCCESS:
            return {
                ...state,
                transfersList: action.transfers,
                hasMoreData: action.pageNumber < action.totalPages,
                fetchingHistoric: false,
                isFirstFetching: false,
                pageNumber: action.pageNumber,
                totalPages: action.totalPages,
                totalRows: action.totalRows,
                showFilters: state.isFirstFetching ? action.totalPages > 1 : state.showFilters,
                selectedTransactionRows: [],
                controlActivity: action.controlActivity,
                controlARS: action.controlARS,
                controlUSD: action.controlUSD,
            };
        case types.LOAD_PENDING_QUANTITY_REQUEST:
            return {
                ...state,
            };
        case types.LOAD_PENDING_QUANTITY_SUCCESS:
            return {
                ...state,
                pendingQuantity: pendingQuantity || undefined,
            };
        case types.LOAD_MORE_TRANSFERS_REQUEST:
            return {
                ...state,
                isFetchingMore: true,
            };
        case types.LOAD_MORE_TRANSFERS_SUCCESS:
            return {
                ...state,
                isFetchingMore: false,
                transfersList: [...state.transfersList, ...action.transfers],
                hasMoreData: action.pageNumber < action.totalPages,
                pageNumber: action.pageNumber,
                totalPages: action.totalPages,
                totalRows: action.totalRows,
            };
        case types.SET_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        case types.RESET_ALL_FILTERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
                filtersHistoric: INITIAL_STATE.filtersHistoric,
                filtersPending: INITIAL_STATE.filtersPending,
                filtersScheduled: INITIAL_STATE.filtersScheduled,
                filtersDraft: INITIAL_STATE.filtersDraft,
                selectedChip: INITIAL_STATE.selectedChip,
                selectedTransactionRows: INITIAL_STATE.selectedTransactionRows,
                transfersList: INITIAL_STATE.transfersList,
                pageNumber: INITIAL_STATE.pageNumber,
                hasFilterChanged: INITIAL_STATE.hasFilterChanged,
                showFilters: INITIAL_STATE.showFilters,
            };
        case types.RESET_LIST_TRANSFERS:
            return {
                ...state,
                transfersList: INITIAL_STATE.transfersList,
                pageNumber: INITIAL_STATE.pageNumber,
            };
        case types.SET_HAS_FILTER_CHANGED:
            return {
                ...state,
                hasFilterChanged,
            };
        case types.SET_SELECTED_CHIP:
            return {
                ...state,
                selectedChip,
            };
        case types.SET_SHOW_FILTERS:
            return {
                ...state,
                showFilters,
            };
        case types.SET_FILTERS_HISTORIC:
            return {
                ...state,
                filtersHistoric: action.filtersHistoric,
            };
        case types.SET_FILTERS_PENDING:
            return {
                ...state,
                filtersPending: action.filtersPending,
            };
        case types.SET_FILTERS_SCHEDULED:
            return {
                ...state,
                filtersScheduled: action.filtersScheduled,
            };
        case types.SET_FILTERS_DRAFT:
            return {
                ...state,
                filtersDraft: action.filtersDraft,
            };
        case types.RESET_HISTORIC_TRANSFERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
                filtersHistoric: INITIAL_STATE.filtersHistoric,
                selectedTransactionRows: INITIAL_STATE.selectedTransactionRows,
                transfersList: INITIAL_STATE.transfersList,
                pageNumber: INITIAL_STATE.pageNumber,
                hasFilterChanged: INITIAL_STATE.hasFilterChanged,
            };
        case types.RESET_PENDING_FILTERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
                filtersPending: INITIAL_STATE.filtersPending,
                selectedTransactionRows: INITIAL_STATE.selectedTransactionRows,
                transfersList: INITIAL_STATE.transfersList,
                pageNumber: INITIAL_STATE.pageNumber,
                hasFilterChanged: INITIAL_STATE.hasFilterChanged,
            };
        case types.RESET_DRAFT_FILTERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
                filtersDraft: INITIAL_STATE.filtersDraft,
                selectedTransactionRows: INITIAL_STATE.selectedTransactionRows,
                transfersList: INITIAL_STATE.transfersList,
                pageNumber: INITIAL_STATE.pageNumber,
                hasFilterChanged: INITIAL_STATE.hasFilterChanged,
            };
        case types.RESET_SCHEDULED_FILTERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
                filtersScheduled: INITIAL_STATE.filtersScheduled,
                selectedTransactionRows: INITIAL_STATE.selectedTransactionRows,
                transfersList: INITIAL_STATE.transfersList,
                pageNumber: INITIAL_STATE.pageNumber,
                hasFilterChanged: INITIAL_STATE.hasFilterChanged,
            };
        case types.SET_AGENDA_DRAWER_VISIBLE:
            return {
                ...state,
                agendaDrawerVisibleRed: action.agendaDrawerVisibleRed,
            };
        case types.SET_AGENDA_DRAWER_VALUES:
            return {
                ...state,
                agendaDrawerValues: action.agendaDrawerValues,
            };
        case types.RESET_AGENDA_DRAWER_VALUES:
            return {
                ...state,
                agendaDrawerValues: INITIAL_STATE.agendaDrawerValues,
            };
        case types.FREQUENT_DESTINATION_TRANSFER_CREATE_REQUEST:
            return {
                ...state,
                frequentDestinationLoading: true,
            };
        case types.FREQUENT_DESTINATION_TRANSFER_CREATE_SUCCESS:
            return {
                ...state,
                frequentDestination,
                agendaDrawerVisibleRed: false,
                selectedFromAgenda: true,
                frequentDestinationExistOnList: true,
                frequentDestinationLoading: false,
            };
        case types.FREQUENT_DESTINATION_TRANSFER_CREATE_FAILED:
            return {
                ...state,
                frequentDestinationLoading: false,
            };
        case types.SET_FREQUENT_DESTINATION_ON_LIST:
            return {
                ...state,
                frequentDestinationExistOnList: action.frequentDestinationExistOnList,
            };
        case types.SET_SELECTED_FROM_AGENDA:
            return {
                ...state,
                selectedFromAgenda: action.selectedFromAgenda,
            };
        case types.RESET_STATE_MODAL_AGENDA:
            return {
                ...state,
                agendaDrawerVisibleRed: INITIAL_STATE.agendaDrawerVisibleRed,
                frequentDestination: INITIAL_STATE.frequentDestination,
                selectedFromAgenda: INITIAL_STATE.selectedFromAgenda,
                frequentDestinationExistOnList: INITIAL_STATE.frequentDestinationExistOnList,
                frequentDestinationLoading: INITIAL_STATE.frequentDestinationLoading,
            };
        case types.GET_CBU_CVU_INFO:
            return {
                ...state,
                fetchingAlias: true,
            };
        case types.DOWNLOAD_TRANSFERS_HISTORIC:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_TRANSFERS_HISTORIC_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_TRANSFERS_HISTORIC_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        default:
            return state;
    }
};

export const actions = {
    preActivity: () => ({
        type: types.TRANSFER_PRE_ACTIVITY_REQUEST,
    }),
    createInternalTransfer: (summary, formikBag) => ({
        type: types.CREATE_INTERNAL_TRANSFER_REQUEST,
        summary,
        formikBag,
    }),
    clearTransferData: () => ({
        type: types.CLEAR_TRANSFER_DATA,
    }),
    createExternalTransfer: (summary, formikBag) => ({
        type: types.CREATE_EXTERNAL_TRANSFER_REQUEST,
        summary,
        formikBag,
    }),
    createTransferToContact: (summary, formikBag) => ({
        type: types.CREATE_TRANSFER_TO_CONTACT_REQUEST,
        summary,
        formikBag,
    }),
    cleanData: () => ({
        type: types.CLEAN_TRANSFER_STATE,
    }),
    sendInternalTransfer: (summary, otp) => ({
        type: types.SEND_INTERNAL_TRANSFER_REQUEST,
        otp,
        summary,
    }),
    sendExternalTransfer: (summary, otp) => ({
        type: types.SEND_EXTERNAL_TRANSFER_REQUEST,
        otp,
        summary,
    }),
    sendTransferToContact: (summary, otp) => ({
        type: types.SEND_TRANSFER_TO_CONTACT_REQUEST,
        otp,
        summary,
    }),
    changeTransferGroup: (internalTransfer) => ({
        type: types.CHANGE_TRANSFER_GROUP,
        internalTransfer,
    }),
    setTransferGroup: (transferGroup) => ({
        type: types.SET_TRANSFER_GROUP,
        transferGroup,
    }),
    loadInternalTransfer: (summary) => ({
        type: types.LOAD_INTERNAL_TRANSFER,
        summary,
    }),
    loadThirdPartiesTransfer: (summary) => ({
        type: types.LOAD_THIRD_PARTIES_TRANSFER,
        summary,
    }),
    loadTransfer: (summary) => ({
        type: types.LOAD_INTERNAL_TRANSFER,
        summary,
    }),
    loadExternalTransfer: (summary) => ({
        type: types.LOAD_EXTERNAL_TRANSFER,
        summary,
    }),
    loadScheduledTransactionCancel: (summary) => ({
        type: types.LOAD_SCHEDULED_TRANSACTION_CANCEL,
        summary,
    }),
    getAccountInfo: (account, accountType, isTransfer) => ({
        type: types.GET_ACCOUNT_INFO,
        account,
        accountType,
        isTransfer,
    }),
    cancelScheduled: (
        idTransaction,
        otp,
        debitAccount,
        creditAccount,
        cbu,
        amount,
        currency,
        formikBag,
        clientUser,
    ) => ({
        type: types.CANCEL_SCHEDULED_TRANSACTION_REQUEST,
        idTransaction,
        otp,
        debitAccount,
        creditAccount,
        cbu,
        amount,
        currency,
        formikBag,
        clientUser,
    }),
    accountLimitsActivities: (idAccount, idActivity) => ({
        type: types.ACCOUNT_LIMITS_REQUEST,
        idAccount,
        idActivity,
    }),
    sendSchedulerEmail: (summary) => ({
        type: types.SEND_SCHEDULER_EMAIL_REQUEST,
        summary,
    }),
    getCbuCvuFromAlias: (alias) => ({
        type: types.GET_CBU_CVU_INFO,
        alias,
    }),
    setIsCVU: (isCVU) => ({
        type: types.SET_IS_CVU,
        isCVU,
    }),
    setSendEmail: (sendEmail) => ({
        type: types.SET_SEND_EMAIL,
        sendEmail,
    }),
    loadListRequest: (filters, onlyPendings, pendingDispatch) => ({
        type: types.LOAD_HISTORIC_LIST_REQUEST,
        filters,
        onlyPendings,
        pendingDispatch,
    }),
    loadPendingQuantityRequest: (filters, onlyPendings, pendingDispatch) => ({
        type: types.LOAD_PENDING_QUANTITY_REQUEST,
        filters,
        onlyPendings,
        pendingDispatch,
    }),
    loadPendingQuantitySuccess: (pendingQuantity) => ({
        type: types.LOAD_PENDING_QUANTITY_SUCCESS,
        pendingQuantity,
    }),
    loadListFailure: () => ({
        type: types.LOAD_HISTORIC_LIST_FAILURE,
    }),
    loadListSuccess: (transfers, pageNumber, totalPages, totalRows, controlActivity, controlARS, controlUSD) => ({
        type: types.LOAD_HISTORIC_LIST_SUCCESS,
        transfers,
        pageNumber,
        totalPages,
        totalRows,
        controlActivity,
        controlARS,
        controlUSD,
    }),
    loadMoreTransfersRequest: (filters, onlyPendings, pendingDispatch) => ({
        type: types.LOAD_MORE_TRANSFERS_REQUEST,
        filters,
        onlyPendings,
        pendingDispatch,
    }),
    loadMoreTransfersSuccess: (transfers, pageNumber, totalPages, totalRows) => ({
        type: types.LOAD_MORE_TRANSFERS_SUCCESS,
        transfers,
        pageNumber,
        totalPages,
        totalRows,
    }),
    setFilters: (filters) => ({
        type: types.SET_FILTERS,
        filters,
    }),
    resetAllFilters: () => ({
        type: types.RESET_ALL_FILTERS,
    }),
    resetHistoric: () => ({
        type: types.RESET_HISTORIC_TRANSFERS,
    }),
    resetListTransfers: () => ({
        type: types.RESET_LIST_TRANSFERS,
    }),
    setHasFilterChanged: (hasFilterChanged) => ({
        type: types.SET_HAS_FILTER_CHANGED,
        hasFilterChanged,
    }),
    setSelectedChip: (selectedChip) => ({
        type: types.SET_SELECTED_CHIP,
        selectedChip,
    }),
    setShowFilters: (showFilters) => ({
        type: types.SET_SHOW_FILTERS,
        showFilters,
    }),
    setFiltersHistoric: (filtersHistoric) => ({
        type: types.SET_FILTERS_HISTORIC,
        filtersHistoric,
    }),
    setFiltersPending: (filtersPending) => ({
        type: types.SET_FILTERS_PENDING,
        filtersPending,
    }),
    setFiltersScheduled: (filtersScheduled) => ({
        type: types.SET_FILTERS_SCHEDULED,
        filtersScheduled,
    }),
    setFiltersDraft: (filtersDraft) => ({
        type: types.SET_FILTERS_DRAFT,
        filtersDraft,
    }),
    resetPendingFilters: () => ({
        type: types.RESET_PENDING_FILTERS,
    }),
    resetScheduledFilters: () => ({
        type: types.RESET_SCHEDULED_FILTERS,
    }),
    resetDraftFilters: () => ({
        type: types.RESET_DRAFT_FILTERS,
    }),
    setAgendaDrawerVisible: (agendaDrawerVisibleRed) => ({
        type: types.SET_AGENDA_DRAWER_VISIBLE,
        agendaDrawerVisibleRed,
    }),
    setAgendaDrawerValues: (agendaDrawerValues) => ({
        type: types.SET_AGENDA_DRAWER_VALUES,
        agendaDrawerValues,
    }),
    resetAgendaDrawerValues: () => ({
        type: types.RESET_AGENDA_DRAWER_VALUES,
    }),
    frequentDestinationCreateRequest: (params) => ({
        type: types.FREQUENT_DESTINATION_TRANSFER_CREATE_REQUEST,
        params,
    }),
    frequentDestinationCreateSuccess: (frequentDestination) => ({
        type: types.FREQUENT_DESTINATION_TRANSFER_CREATE_SUCCESS,
        frequentDestination,
        agendaDrawerVisibleRed: false,
    }),
    setFrequentDestinationExistOnList: (frequentDestinationExistOnList) => ({
        type: types.SET_FREQUENT_DESTINATION_ON_LIST,
        frequentDestinationExistOnList,
    }),
    setSelectedFromAgenda: (selectedFromAgenda) => ({
        type: types.SET_SELECTED_FROM_AGENDA,
        selectedFromAgenda,
    }),
    resetModalAgendaValues: () => ({
        type: types.RESET_STATE_MODAL_AGENDA,
    }),
    readCancelScheduled: (idTransaction) => ({
        type: types.READ_CANCEL_SCHEDULED,
        idTransaction,
    }),
    readDownloadTransaction: (idTransaction, dispatch, activeEnvironment) => ({
        type: types.READ_DOWNLOAD_TRANSACTION,
        idTransaction,
        dispatch,
        activeEnvironment,
    }),
    downloadTransfersHistoric: (dateFrom, dateTo, format) => ({
        type: types.DOWNLOAD_TRANSFERS_HISTORIC,
        dateFrom,
        dateTo,
        format,
    }),
};

export const selectors = {
    getTadTrx: ({ transfer }) => transfer.summary.tadTrx,
    getFetching: ({ transfer }) => transfer.fetching,
    getFetchingHistoric: ({ transfer }) => transfer.fetchingHistoric,
    getFetchingSubmit: ({ transfer }) => transfer.fetchingSubmit,
    getDebitAccount: ({ transfer }) => transfer.summary.debitAccount,
    getCreditAccount: ({ transfer }) => transfer.summary.creditAccount,
    getCurrency: ({ transfer }) => transfer.summary.currency,
    getAmount: ({ transfer }) => transfer.summary.amount,
    getEmail: ({ transfer }) => transfer.summary.email,
    getEmailText: ({ transfer }) => transfer.summary.emailText,
    getReferente: ({ transfer }) => transfer.summary.reference,
    getSummary: ({ transfer }) => transfer.summary,
    getIdTransaction: ({ transfer }) => transfer.idTransaction,
    getInternalTransfer: ({ transfer }) => transfer.internalTransfer,
    getTransferGroup: ({ transfer }) => transfer.transferGroup,
    getCreditAccountDetails: ({ transfer }) => transfer.creditAccountDetail,
    getClientUser: ({ transfer }) => transfer.clientUser,
    getConcepts: ({ transfer }) => transfer.concepts,
    getAccounts: ({ transfer }) => transfer.accounts,
    getFetchingDownload: ({ transfer }) => transfer.fetchingDownload,
    getLimit: ({ transfer }) => transfer.limit,
    getLimitUsed: ({ transfer }) => transfer.limitUsed,
    getScheduledCancel: ({ transfer }) => transfer.scheduledCancel,
    getServerDate: ({ transfer }) => transfer.serverDate,
    getIsCVU: ({ transfer }) => transfer.isCVU,
    getSendEmail: ({ transfer }) => transfer.sendEmail,
    getFirstFetch: ({ transfer }) => transfer.isFirstFetching,
    getFilters: ({ transfer }) => transfer.filters,
    getPageNumber: ({ transfer }) => transfer.pageNumber,
    getHasMoreData: ({ transfer }) => transfer.hasMoreData,
    isFetchingMore: ({ transfer }) => transfer.isFetchingMore,
    getTransfers: ({ transfer }) => transfer.transfersList,
    getTotalPages: ({ transfer }) => transfer.totalPages,
    getHasFilterChanged: ({ transfer }) => transfer.hasFilterChanged,
    getSelectedChip: ({ transfer }) => transfer.selectedChip,
    getPendingQuantity: ({ transfer }) => transfer.pendingQuantity,
    getShowFilters: ({ transfer }) => transfer.showFilters,
    getFiltersHistoric: ({ transfer }) => transfer.filtersHistoric,
    getFiltersPending: ({ transfer }) => transfer.filtersPending,
    getFiltersScheduled: ({ transfer }) => transfer.filtersScheduled,
    getFiltersDraft: ({ transfer }) => transfer.filtersDraft,
    getAgendaDrawerVisibleRed: ({ transfer }) => transfer.agendaDrawerVisibleRed,
    getFrequentDestination: ({ transfer }) => transfer.frequentDestination,
    getFrequentDestinationExistOnList: ({ transfer }) => transfer.frequentDestinationExistOnList,
    getSelectedFromAgenda: ({ transfer }) => transfer.selectedFromAgenda,
    getFrequentDestinationLoading: ({ transfer }) => transfer.frequentDestinationLoading,
    getAgendaDrawerValues: ({ transfer }) => transfer.agendaDrawerValues,
    getCvuCbuFromAlias: ({ transfer }) => transfer.cvuCbuFromAlias,
    getFetchingAlias: ({ transfer }) => transfer.fetchingAlias,
    getControlActivity: ({ transfer }) => transfer.controlActivity,
    getControlARS: ({ transfer }) => transfer.controlARS,
    getControlUSD: ({ transfer }) => transfer.controlUSD,
};
