import { delay } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import * as transactionsMiddleware from "middleware/transactions";
import { push } from "react-router-redux";
import { types as loginTypes } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { actions, types } from "reducers/transactions";
import { globalTypes } from "reducers/types/global";
import * as configUtil from "util/config";
import { downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors } from "util/form";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest([globalTypes.INIT, loginTypes.LOGIN_SUCCESS], refreshPendingTransactionsQuantity),
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.DOWNLOAD_PENDING_TRANSACTIONS_REQUEST, downloadPendingTransactionsRequest),
    takeLatest(types.LOAD_TRANSACTION_ACTIVITIES_REQUEST, loadTransactionActivities),
    takeLatest(types.LOAD_MORE_TRANSACTIONS_REQUEST, fetchMoreTransactions),
    takeLatest(types.LOAD_MORE_TRANSACTIONS_REQUEST_MOBILE, fetchMoreTransactions),
    takeLatest(types.DELETE_DRAFT_REQUEST, deleteDraftRequest),
    takeLatest(types.SIGN_MULTIPLE_TRANSACTIONS, signMultipleTransaction),
    takeLatest(types.DELETE_MULTIPLE_TRANSACTIONS, deleteMultipleTransaction),
    takeLatest(types.POTENTIAL_SIGNATURES_REQUEST, potentialSignaturesRequest),
    takeLatest(types.IS_FIRST_TRANSACTION_CBU_REQUEST, isFirstTransactionCbuRequest),
    takeLatest(types.INIT_PENDINGS, initPendings),
    takeLatest(types.MULTIPLE_SIGNATURES_DATA_REQUEST, multipleSignatureData),
];

export default sagas;

function* refreshPendingTransactionsQuantity() {
    const activeEnvironment = yield select(sessionSelectors.getActiveEnvironment);
    if (activeEnvironment.type !== CORPORATE_GROUP_ENVIRONMENT_TYPE) {
        if (configUtil.get("feature.transactions.refreshPendingQuantity")) {
            while (true) {
                const hasActiveSession = yield select(sessionSelectors.isLoggedIn);
                if (!hasActiveSession) {
                    break;
                }

                try {
                    const response = yield call(transactionsMiddleware.getPendingTransactionsQuantity);
                    if (response.status !== 304 && response.type === "I") {
                        yield put({
                            type: types.REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS,
                            pendingTransactionsQuantity: response.data.data.pendingTransactionsQuantity,
                        });
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
                yield call(delay, configUtil.get("transactions.pending.refreshRate", 60) * 1000);
            }
        }
    }
}

function* loadListRequest({ filters, onlyPendings, pendingDispatch }) {
    const response = yield call(transactionsMiddleware.loadListRequest, filters, onlyPendings, pendingDispatch);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const {
            transactions,
            pageNumber,
            totalPages,
            totalRows,
            controlActivity,
            controlARS,
            controlUSD,
        } = response.data.data;
        yield put(
            actions.loadListSuccess(
                transactions,
                pageNumber,
                totalPages,
                totalRows,
                controlActivity,
                controlARS,
                controlUSD,
            ),
        );
    }
}

function* downloadPendingTransactionsRequest({ filters, onlyPendings, pendingDispatch, format }) {
    try {
        const { type, data } = yield call(
            transactionsMiddleware.downloadPendingTransactionsRequest,
            filters,
            onlyPendings,
            pendingDispatch,
            format,
        );
        if (type === "W") {
            yield put({ type: types.DOWNLOAD_PENDING_TRANSACTIONS_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]),
            );
        } else {
            const { content, fileName } = data.data;

            if (format === "pdf") {
                downloadPdf(fileName, content);
            } else {
                downloadXls(fileName, content);
            }

            yield put({ type: types.DOWNLOAD_PENDING_TRANSACTIONS_SUCCESS });
            yield put(
                notificationActions.showNotification(
                    i18n.get("global.successDownload"),
                    "success",
                    ["transactions"],
                    null,
                    false,
                ),
            );
        }
    } catch (error) {
        yield put({ type: types.DOWNLOAD_PENDING_TRANSACTIONS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    }
}

function* loadTransactionActivities() {
    const response = yield call(transactionsMiddleware.loadTransactionActivities);

    if (response.type === "W") {
        yield put(actions.loadTransactionActivitiesFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { activities } = response.data.data;
        yield put(actions.loadTransactionActivitiesSuccess(activities));
    }
}

function* fetchMoreTransactions({ filters, onlyPendings, pendingDispatch, filter, isDesktop }) {
    const page = filters.pageNumber;
    const response = yield call(
        transactionsMiddleware.loadListRequest,
        { ...filters, pageNumber: page },
        onlyPendings,
        pendingDispatch,
        filter,
    );

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { transactions, pageNumber, totalPages, totalRows } = response.data.data;
        if (isDesktop) {
            yield put(
                actions.loadMoreTransactionsSuccess(
                    transactions,
                    pageNumber,
                    totalPages === 0 ? filters.totalPages : totalPages,
                    totalRows,
                ),
            );
        } else {
            yield put(
                actions.loadMoreTransactionsSuccessMobile(
                    transactions,
                    pageNumber,
                    totalPages === 0 ? filters.totalPages : totalPages,
                    totalRows,
                ),
            );
        }
    }
}

function* deleteDraftRequest({ idTransaction }) {
    const response = yield call(transactionsMiddleware.deleteDraftRequest, idTransaction);

    if (response.type === "W") {
        yield put(actions.deleteDraftFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { deleted } = response.data.data;

        if (deleted) {
            yield put(actions.deleteDraftSuccess(idTransaction));
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted"), "success", [
                    "transactions",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted.fail"), "success", [
                    "transactions",
                ]),
            );
        }
    }
}

function* multipleSignatureData({ multipleSignaturesData }) {
    const {
        activitySelected: { idActivity },
        selectedTransactionRows,
    } = multipleSignaturesData;
    const transactions = selectedTransactionRows.map((transaction) => transaction.transaction?.data);
    const { data, type } = yield call(transactionsMiddleware.multipleSignatureDataPreview, idActivity, transactions);
    if (type === "W") {
        if (data.code === "COR020W") {
            yield put(notificationActions.showNotification(data?.data?.message, "warning", ["transactions"]));
        }
        yield put({ type: types.MULTIPLE_SIGNATURES_DATA_FAILURE });
    } else if (data.code === "COR005E") {
        yield put(notificationActions.showNotification(i18n.get("returnCode.COR005E"), "error", ["transactions"]));
        yield put({ type: types.MULTIPLE_SIGNATURES_DATA_FAILURE });
    } else {
        yield put(push("/pendingTransaction/signMultiple"));
        yield put({ type: types.MULTIPLE_SIGNATURES_DATA_SUCCESS });
    }
}

function* signMultipleTransaction({ payload }) {
    const { transactions, idActivity, otp, formikBag, userId, idForm } = payload;
    const { data, type } = yield call(
        transactionsMiddleware.signMultipleTransactions,
        idActivity,
        transactions,
        otp,
        userId,
        idForm,
    );
    formikBag.setSubmitting(false);
    if (type === "W") {
        if (data.code === "COR020W") {
            if (data?.data?.NO_FIELD) {
                yield put(
                    notificationActions.showNotification(data?.data?.NO_FIELD, "warning", ["multipleSignatures"]),
                );
            } else {
                formikBag.setErrors(adjustIdFieldErrors(data.data));
            }
        } else {
            yield put(notificationActions.showNotification(data.message, "error", ["multipleSignatures"]));
        }
    } else if (data.code === "COR005E") {
        yield put(
            notificationActions.showNotification(i18n.get("returnCode.COR005E"), "error", ["multipleSignatures"]),
        );
    } else {
        yield put(push(`/pendingTransaction/multipleSignatures/processing`));
        yield put(
            notificationActions.showNotification(i18n.get("multiple.signatures.notification.processing"), "warning", [
                "multipleSignaturesProcessing",
            ]),
        );
    }
}
function* deleteMultipleTransaction({ payload }) {
    const { transactions, idActivity, otp, formikBag, userId, idForm } = payload;
    const { data, type } = yield call(
        transactionsMiddleware.deleteMultipleTransactions,
        idActivity,
        transactions,
        otp,
        userId,
        idForm,
    );
    formikBag.setSubmitting(false);
    if (type === "W") {
        if (data.code === "COR020W") {
            if (data?.data?.NO_FIELD) {
                yield put(notificationActions.showNotification(data?.data?.NO_FIELD, "warning", ["multipleDelete"]));
            } else {
                formikBag.setErrors(adjustIdFieldErrors(data.data));
            }
        } else {
            yield put(notificationActions.showNotification(data.message, "error", ["multipleDelete"]));
        }
    } else if (data.code === "COR005E") {
        yield put(
            notificationActions.showNotification(i18n.get("returnCode.COR005E"), "error", ["multipleSignatures"]),
        );
    } else {
        yield put(push(`/pendingTransaction/multipleDelete/processing`));
        yield put(
            notificationActions.showNotification(i18n.get("multiple.delete.notification.processing"), "warning", [
                "multipleDeleteProcessing",
            ]),
        );
    }
}

function* potentialSignaturesRequest({ idTransaction }) {
    const response = yield call(transactionsMiddleware.potentialSignaturesRequest, idTransaction);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        yield put({
            type: types.POTENTIAL_SIGNATURES_SUCCESS,
            potentialSignaturesData: response.data.data.potentialSignatures,
            serverDate: response.data.data.serverDate,
        });
    }
}

function* isFirstTransactionCbuRequest({ cbu }) {
    const dateFrom = new Date();
    dateFrom.setYear(dateFrom.getFullYear() - 10);
    const dateTo = new Date();
    const filters = {
        dateFrom,
        dateTo,
        pageNumber: 1,
        filter: "last",
        status: null,
        idActivity: "transfers.thirdParties.send",
        filterPendingDispatch: false,
        functionRequest: "pendingTransactions",
    };

    const responseFrequentDestination = yield call(transactionsMiddleware.isCbuFrequentDestination, cbu);
    const frequentDestination = responseFrequentDestination.data.data?.CBUInFrequentDestination;
    let showSheduleNotification = !frequentDestination;

    if (showSheduleNotification) {
        const responseSummary = yield call(transactionsMiddleware.loadListRequest, filters, false, false);
        const summary = responseSummary.data.data?.transactions;
        let index = 0;
        let cont = 0;
        while (index < summary.length && showSheduleNotification && summary.length > 1) {
            if (summary[index].transaction.data.cbu === cbu) {
                cont += 1;
                if (cont > 1) {
                    showSheduleNotification = false;
                }
            }
            index += 1;
        }
    }

    if (showSheduleNotification) {
        yield put(
            notificationActions.showNotification(
                i18n.get("transfer.frequentDestinacions.notification.firstTransfer"),
                "warning",
                ["pendingTransfer"],
                i18n.get("transfer.frequentDestinacions.notification.firstTransfer.tittle"),
            ),
        );
    }
}

function* initPendings() {
    const status = "PENDING";
    const filters = {
        pageNumber: 1,
        filter: undefined,
        status,
        filterPendingDispatch: false,
        functionRequest: "pendingTransactions",
    };
    yield put({
        type: types.CHANGE_STATUS_TYPE,
        status,
    });
    yield put({
        type: types.LOAD_LIST_REQUEST,
        filters,
        onlyPendings: true,
        pendingDispatch: false,
    });
}
