/* eslint-disable import/no-unresolved */
import React, { Component, Fragment } from "react";
import { bool, node, string, shape, oneOf, oneOfType } from "prop-types";
import { connect } from "react-redux";
import PageTransition from "react-router-page-transition";
import Redirect from "react-router-dom/Redirect";
import withRouter from "react-router-dom/withRouter";

import { getTransition } from "util/transition";

import { resizableRoute } from "pages/_components/Resizable";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import NavigationBarDesktop from "pages/_components/NavigationBarDesktop";
import FooterDesktop from "pages/_components/FooterDesktop";
import Menu from "pages/_components/menu/Menu";
import classNames from "classnames";
import { selectors as sessionSelectors } from "reducers/session";

import PrivateRoute from "pages/_components/route/PrivateRoute";

class DefaultLayout extends Component {
    static propTypes = {
        isDesktop: bool,
        isMobile: bool,
        isMobileNative: bool,
        isTablet: bool,
        isSmallDesktop: bool,
        component: node.isRequired,
        authorizedRoute: bool,
        componentProps: shape({}).isRequired,
        path: oneOfType([string, shape({ pathname: string })]).isRequired,
        transition: oneOf([
            "transition-drill-out",
            "transition-drill-in",
            "transition-flow-close",
            "transition-flow-open",
            "transition-change-feature",
        ]),
        location: shape({
            pathname: string.isRequired,
            state: shape({
                transition: oneOf([
                    "transition-drill-out",
                    "transition-drill-in",
                    "transition-flow-close",
                    "transition-flow-open",
                    "transition-change-feature",
                ]),
            }),
        }).isRequired,
        withoutLayout: bool,
        isRetailEnvironment: bool,
    };

    static defaultProps = {
        authorizedRoute: true,
        isDesktop: false,
        isMobile: false,
        isTablet: false,
        isSmallDesktop: false,
        isMobileNative: false,
        transition: "",
        withoutLayout: false,
        isRetailEnvironment: false,
    };

    state = {
        transition: "",
        isSidebarCollapsed: false,
        styles: {},
    };

    componentDidMount() {
        const { isMobileNative, isSmallDesktop } = this.props;

        if (isMobileNative) {
            this.setState({
                styles: {
                    position: "absolute",
                    top: 0,
                },
            });
        }

        if (isSmallDesktop) {
            this.setState({ isSidebarCollapsed: isSmallDesktop });
        }
    }

    handleToggleSidebar = () => {
        const { isSidebarCollapsed } = this.state;

        this.setState((prevState) => ({ isSidebarCollapsed: !prevState.isSidebarCollapsed }));

        if (!isSidebarCollapsed) {
            window.dispatchEvent(new Event("resize"));
        }
    };

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location, isSmallDesktop } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition:
                    (nextProps.location.state && nextProps.location.state.transition) ||
                    nextProps.transition ||
                    getTransition(this.props, nextProps),
            });
        }

        if (isSmallDesktop !== nextProps.isSmallDesktop) {
            this.setState({
                isSidebarCollapsed: nextProps.isSmallDesktop,
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isTablet,
            isSmallDesktop,
            isMobileNative,
            path
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, isTablet, isSmallDesktop };
        const { transition, styles } = this.state;

        return (
            <div className={transition} style={styles}>
                <PageTransition timeout={600}>
                    <div className={classNames("view-wrapper theme-auth transition-item", {"container-white": path.startsWith("/v2")})}>
                        <ErrorBoundary>
                            <ReceivedComponent {...matchProps} {...extras} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isSmallDesktop,
            isTablet,
            isMobileNative,
            componentProps,
            path,
            withoutLayout,
            isRetailEnvironment,
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, isSmallDesktop, isTablet, ...componentProps };
        const { isSidebarCollapsed } = this.state;

        const menuClasses = classNames("app-sidebar-container", {
            "sidebar-small-desktop": isSmallDesktop,
            "sidebar-collapsed": isSidebarCollapsed,
        });

        const headerClasses = classNames("app-header", {
            "header-small-desktop": isSmallDesktop,
            "sidebar-collapsed": isSidebarCollapsed,
        });

        const contentClasses = classNames("app-page", {
            "page-small-desktop": isSmallDesktop,
            "sidebar-collapsed": isSidebarCollapsed,
        });

        return (
            <ErrorBoundary>
                {!withoutLayout && (
                    <div className="app theme-auth default-layout">
                        <div className={menuClasses}>
                            <aside className="app-sidebar">
                                <div
                                    className={classNames("scrollable-sidebar", {
                                        "menu-retail": isRetailEnvironment,
                                    })}>
                                    <Menu
                                        isMobile={isMobile}
                                        onToggleSidebar={this.handleToggleSidebar}
                                        isCollapsed={isSidebarCollapsed}
                                        path={path}
                                        isSmallDesktop={isSmallDesktop}
                                    />
                                </div>
                            </aside>
                        </div>
                        <header className={headerClasses}>
                            <NavigationBarDesktop isDesktop={isDesktop} />
                        </header>
                        <div className={contentClasses}>
                            <div className="app-content">
                                <div id="app" className={classNames("view-wrapper theme-auth", {"container-white pl-2 pr-0": path.startsWith("/v2")})}>
                                    <ReceivedComponent {...matchProps} {...extras} />
                                </div>

                                <div className="app-footer">
                                    <FooterDesktop isDesktop={isDesktop} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {withoutLayout && (
                    <div className="app-page sidebar-collapsed">
                        <div className="app-content">
                            <div className="view-wrapper theme-auth">
                                <ReceivedComponent {...matchProps} {...extras} />
                            </div>
                        </div>
                    </div>
                )}
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, authorizedRoute, ...rest } = this.props;

        if (!authorizedRoute) {
            return <Redirect to="/desktop" />;
        }

        return (
            <PrivateRoute
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(DefaultLayout)));
