import React, { Component } from "react";
import { string, func, shape, bool, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { push, goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Container from "pages/_components/Container";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";
import Yup from "yup";
import { actions as formActions } from "reducers/form";
import Heading from "pages/_components/Heading";
import { actions as checkActions, selectors as checkSelector } from "reducers/checks";
import { selectors as sessionSelectors } from "reducers/session";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import Selector from "pages/_components/fields/formik/Selector";
import * as configUtils from "util/config";
import Hint from "pages/_components/hints/Hint";
import TextField from "pages/_components/fields/TextField";
import SelectorInput from "pages/_components/fields/formik/SelectorInput";
import { selectors as templateSelectors, actions as templateActions } from "reducers/template";
import CreateTemplateModal from "pages/forms/_components/CreateTemplateModal";
import TemplateList from "pages/forms/_components/TemplateList";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import { saveDraft } from "middleware/form";
import { saveLocation } from "util/transaction";
import classNames from "classnames";

const FORM_ID = "new.check";

class Checks extends Component {
    static propTypes = {
        forEach: func.isRequired,
        fetching: bool.isRequired,
        dispatch: func.isRequired,
        documentType: string.isRequired,
        values: shape({}).isRequired,
        checksRequestData: shape({}).isRequired,
        location: shape({}).isRequired,
        checksAmount: shape({}).isRequired,
        checksTypesList: shape({}).isRequired,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        templates: {},
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        forceBack: bool,
    };

    static defaultProps = {
        templates: null,
        forceBack: false,
    };

    state = {
        alreadyFetch: false,
        alreadyFetchRest: false,
        buttonDisabled: false,
    };

    componentWillMount = () => {
        const { dispatch, location } = this.props;
        saveLocation(this.props);

        if (location.pathname === "/checks") {
            dispatch(checkActions.clearRequestData());
        }
    };

    componentDidMount() {
        const { dispatch, loggedUser } = this.props;

        dispatch(checkActions.fetchAccounts(loggedUser.userId));
        dispatch(templateActions.loadTemplateList("requestCheckbook"));
    }

    handleForceBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleBack = () => {
        const { dispatch, checksRequestData } = this.props;

        if (checksRequestData && checksRequestData.forceBack) {
            this.handleForceBack();
        } else if (checksRequestData && checksRequestData.returnToPending) {
            dispatch(push("/pendingTransaction/list"));
        } else {
            dispatch(goBack());
        }
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("new.check.request")}</h1>
        </div>
    );

    createCheckBooksAmount = () => {
        const cantidadChequeras = configUtils.getInteger("client.checkbook.amount", 5);
        let options = [];

        for (let i = 1; i <= cantidadChequeras; i++) {
            options = [...options, { value: i, label: i }];
        }
        return options;
    };

    renderInputs = (values, setValues) => (
        <Col sm={12} className="col-no-pad-mobile" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <h4 style={{ marginLeft: "1rem" }}>{i18n.get(`${FORM_ID}.authorized.data`)}</h4>
            <Col sm={12} md={4} lg={4} className="col-no-pad-mobile">
                <Field
                    component={SelectorInput}
                    name="document"
                    idForm={FORM_ID}
                    selectorSize="small"
                    selectProps={{
                        name: "documentType",
                        value: {
                            value: values.documentType,
                            label: values.documentType,
                        } /* values.documentType */,
                        options: [
                            { value: "DNI", label: "DNI" },
                            { value: "CUIT", label: "CUIT" },
                            { value: "CUIL", label: "CUIL" },
                            { value: "CDI", label: "CDI" },
                        ],
                    }}
                    label={i18n.get(`${FORM_ID}.documentType.label`)}
                    inputProps={{
                        name: "document",
                        value: values.document,
                        inputmode: "numeric",
                        type: "number",
                        className: "input-without-arrows pl-3 w-100 form-control",
                    }}
                    onChange={(type, stringDocument) => {
                        setValues({
                            ...values,
                            documentType: type?.value ? type.value : type,
                            document: stringDocument,
                        });
                    }}
                    inputMaxLength={values.documentType === "DNI" ? 8 : 11}
                />
            </Col>
            <Col sm={12} md={4} lg={4} className="col-no-pad-mobile">
                <Field name="name" idForm={FORM_ID} component={TextField} mode="edit" hidePlaceholder />
            </Col>
            <Col sm={12} md={4} lg={4} className="col-no-pad-mobile">
                <Field name="surname" idForm={FORM_ID} component={TextField} mode="edit" hidePlaceholder />
            </Col>
        </Col>
    );

    getCheckBookChecksAmount = (checkType, amount) => {
        const { dispatch, setValues, values } = this.props;

        setValues({ ...values, checkBookChecksAmount: amount || "" });
        dispatch(checkActions.fetchChecksAmount(checkType));
    };

    checksAmountOptions = () => {
        const { checksAmount } = this.props;

        let options = [];

        checksAmount.forEach((amount) => {
            options = [...options, { value: amount, label: amount }];
        });

        return options;
    };

    saveDraft = () => {
        const { dispatch, values, checksRequestData, checksTypesList } = this.props;
        const { checksTypes } = values;
        const completeCheckType = checksTypesList.find(({ value }) => value === checksTypes);

        this.setState({ buttonDisabled: true });

        dispatch(
            formActions.saveDraft({
                idForm: "requestCheckbook",
                idActivityDraft: "account.checks",
                data: {
                    ...values,
                    checksTypes: completeCheckType,
                    account: values.account ? { value: values.account } : null,
                },
                idTransaction: checksRequestData?.returnToPending ? checksRequestData.idTransaction : null,
            }),
        );
    };

    handleCreateTemplateClick = () => {
        const { dispatch } = this.props;
        dispatch(templateActions.createTemplate());
    };

    handleTemplateSelect = (template) => {
        const { setValues, setErrors } = this.props;
        this.getCheckBookChecksAmount(template.checksTypes);
        setValues(template);
        setErrors({});
    };

    rightContentMobile = () => (
        <ButtonDrawer
            buttonClassName=""
            width="100%"
            bsStyle="link"
            image="images/template-icon.svg"
            styleImage={{ width: "24px", height: "24px", color: "white" }}
            styleCloseButton={{ color: "white" }}
            backButton
            closeButton={false}
            headerContent={
                <div className="title-account-header-multiline-background-blue">
                    <h1 className="m-0">{i18n.get("transfers.templates.label")}</h1>
                </div>
            }
            content={<TemplateList onSelect={this.handleTemplateSelect} className="navigational-list" />}
            contentClassname="mx-3"
        />
    );

    render() {
        const {
            values,
            accounts,
            isDesktop,
            setValues,
            fetching,
            checksTypesList,
            location,
            templates,
            checksRequestData,
            documentType,
            isSmallDesktop,
            loggedUser,
            dispatch,
        } = this.props;
        const { account, checksTypes } = values;
        const { alreadyFetch, alreadyFetchRest, buttonDisabled } = this.state;
        const accountsFiltered = accounts?.filter((a) => a.permiteChequera === "SI");

        if (location.pathname === "/checks" && !alreadyFetchRest) {
            this.setState({ alreadyFetchRest: true });
            dispatch(checkActions.fetchAccounts(loggedUser.userId));
            dispatch(templateActions.loadTemplateList("requestCheckbook"));
        }

        if (account === "" && accountsFiltered && accountsFiltered.length > 0) {
            setValues({
                ...values,
                account:
                    location.pathname !== "/checks"
                        ? checksRequestData?.account.idProduct
                        : accountsFiltered[0].idProduct,
            });
        }

        if (values.documentType === "") {
            const valueDocumentType = location.pathname !== "/checks" ? checksRequestData?.documentType : "DNI";

            setValues({
                ...values,
                documentType: documentType || valueDocumentType,
            });
        }
        if (location.pathname !== "/checks" && !alreadyFetch) {
            this.getCheckBookChecksAmount(
                checksRequestData?.checksTypes?.value || checksRequestData?.checksTypes,
                checksRequestData?.checkBookChecksAmount,
            );
            this.setState({ alreadyFetch: true });
        }

        return (
            <>
                <Notification scopeToShow="requestChecks" />
                <Notification scopeToShow="form" />
                <CreateTemplateModal values={values} idForm="requestCheckbook" idActivityTemplate="account.checks" />
                {!isDesktop && (
                    <div className="admin-detail-head px-0">
                        <Head
                            onBack={this.handleBack}
                            headerClassName="blue-main-header-mobile"
                            centerElement={this.centerContentMobile}
                            centerContentClassName="mx-5"
                            rightContent={templates.length > 0 && this.rightContentMobile}
                            accessibilityTextId={
                                checksRequestData?.returnToPending ? "modify.check.request" : "new.check.request"
                            }
                        />
                    </div>
                )}
                <PageLoading loading={fetching} className="screen-loader">
                    {isDesktop && (
                        <div className="admin-detail-head px-0">
                            <Head
                                onBack={this.handleBack}
                                accessibilityTextId={
                                    checksRequestData?.returnToPending ? "modify.check.request" : "new.check.request"
                                }
                            />
                            <div className="view-title">
                                <h1>
                                    {i18n.get(
                                        checksRequestData?.returnToPending
                                            ? "modify.check.request"
                                            : "new.check.request",
                                    )}
                                </h1>
                            </div>
                        </div>
                    )}
                    <div>
                        {accountsFiltered && (
                            <Form>
                                <Container
                                    className={classNames(
                                        "container--layout flex-grow align-items-center cmf-container-white py-3 mt-4",
                                        { "mb-4": isDesktop },
                                    )}
                                    gridClassName="form-content">
                                    <Col sm={12} lg={isSmallDesktop ? 12 : 8} className="align-items-center">
                                        <Col sm={12} className="col-no-pad-mobile">
                                            <Field
                                                name="account"
                                                component={Productselector}
                                                idField="account"
                                                customPlaceholder="Seleccioná"
                                                data={{
                                                    emptyOptionLabel: "",
                                                    options: accountsFiltered,
                                                }}
                                                value={account}
                                                isRequired
                                                mode="edit"
                                                labelText="new.check.account"
                                                idForm={FORM_ID}
                                                isDesktop={isDesktop}
                                            />
                                        </Col>
                                        <Col sm={12} className="col-no-pad-mobile">
                                            <Field
                                                idForm={FORM_ID}
                                                name="checksTypes"
                                                component={Selector}
                                                customOnChange
                                                onCustomChange={(checkType) => this.getCheckBookChecksAmount(checkType)}
                                                options={checksTypesList}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} className="col-no-pad-mobile">
                                            <Field
                                                idForm={FORM_ID}
                                                name="checkBookChecksAmount"
                                                hidePlaceholder
                                                component={Selector}
                                                options={this.checksAmountOptions()}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} className="col-no-pad-mobile">
                                            <Field
                                                idForm={FORM_ID}
                                                name="checkBooksAmount"
                                                hidePlaceholder
                                                component={Selector}
                                                options={this.createCheckBooksAmount()}
                                            />
                                        </Col>
                                        {checksTypesList.find((type) => type.value === checksTypes)
                                            ?.showsDeliveryPoint && (
                                            <>
                                                {this.renderInputs(values, setValues)}
                                                <Col sm={12} className="col-no-pad-mobile">
                                                    <Heading.DataGroup
                                                        containerClassName="transfer-data data-wrapper-flex"
                                                        label={`${FORM_ID}.delivery.point`}
                                                    />
                                                </Col>
                                                <Col sm={12} className="col-no-pad-mobile">
                                                    <div>
                                                        <I18n id={`${FORM_ID}.delivery.point.description`} />
                                                    </div>
                                                </Col>
                                                <Col sm={12} className="col-no-pad-mobile">
                                                    <Hint
                                                        idMessage={`${FORM_ID}.hint`}
                                                        styleText={{ maxWidth: "100%" }}
                                                        styleImage={{
                                                            alignSelf: "center",
                                                            marginTop: "0.7rem",
                                                        }}
                                                        classNameHintP="hint-font-size-small"
                                                        alwaysShowMessage
                                                    />
                                                </Col>
                                            </>
                                        )}
                                        {!checksTypesList.find((type) => type.value === checksTypes)
                                            ?.showsDeliveryPoint && (
                                            <Col sm={12} className="col-no-pad-mobile">
                                                <Hint
                                                    idMessage={`${FORM_ID}.hint.echeq`}
                                                    styleText={{ maxWidth: "100%" }}
                                                    styleImage={{ alignSelf: "center", marginTop: "0.7rem" }}
                                                    classNameHintP="hint-font-size-small"
                                                    alwaysShowMessage
                                                />
                                            </Col>
                                        )}
                                    </Col>
                                </Container>

                                {isDesktop && templates.length > 0 && (
                                    <Container
                                        className="container--layout flex-grow align-items-center"
                                        gridClassName="form-content">
                                        <Col sm={12} md={3} lg={3} xl={3} className="align-items-center">
                                            <h2>{i18n.get("transfers.templates.label")}</h2>
                                            <TemplateList
                                                onSelect={this.handleTemplateSelect}
                                                className="navigational-list"
                                            />
                                        </Col>
                                    </Container>
                                )}

                                <Container>
                                    <Col sm={12} lg={isSmallDesktop ? 12 : 8} className="align-items-center">
                                        <Col sm={12} className="col-no-pad-mobile">
                                            <div
                                                className={classNames("mb-4 d-flex gap-3", {
                                                    "justify-content-center pl-3 pr-3": isDesktop,
                                                    "f-dir-col-reverse pt-45": !isDesktop,
                                                })}>
                                                <Button
                                                    bsStyle="outline"
                                                    onClick={this.saveDraft}
                                                    label="transfers.actions.saveDraft"
                                                    className={classNames("m-0")}
                                                    disabled={buttonDisabled}
                                                />
                                                <Button
                                                    bsStyle="outline"
                                                    onClick={this.handleCreateTemplateClick}
                                                    label="transfers.actions.saveTemplate"
                                                    className={classNames("m-0")}
                                                />
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    label="global.continue"
                                                    className={classNames("m-0")}
                                                />
                                            </div>
                                        </Col>
                                    </Col>
                                </Container>
                            </Form>
                        )}
                    </div>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    accounts: checkSelector.getAccounts(state),
    fetching: checkSelector.getFetching(state),
    checksTypesList: checkSelector.getChecksTypes(state),
    checksAmount: checkSelector.getChecksAmount(state),
    checksRequestData: checkSelector.getChecksRequestData(state),
    templates: templateSelectors.getTemplateList(state),
});

const documentTypeFormats = (documentType, document) => {
    const isnum = /^\d+$/.test(document);
    if (documentType === "DNI") {
        if (document.length < 6 || document.length > 8 || !isnum) {
            return false;
        }
    }

    if (documentType === "CUIT" || documentType === "CUIL" || documentType === "CDI") {
        if (document.length !== 11 || !isnum) {
            return false;
        }
    }
    return true;
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            account:
                props.location.pathname !== "/checks"
                    ? props.checksRequestData?.account.idProduct || props.checksRequestData?.account
                    : "",
            checksTypes:
                props.location.pathname !== "/checks"
                    ? props.checksRequestData?.checksTypes?.value || props.checksRequestData?.checksTypes
                    : "",
            checkBookChecksAmount:
                props.location.pathname !== "/checks" ? props.checksRequestData?.checkBookChecksAmount : "",
            checkBooksAmount: props.location.pathname !== "/checks" ? props.checksRequestData?.checkBooksAmount : 1,
            documentType: "",
            document: props.location.pathname !== "/checks" ? props.checksRequestData?.document : "",
            name: props.location.pathname !== "/checks" ? props.checksRequestData?.name : "",
            surname: props.location.pathname !== "/checks" ? props.checksRequestData?.surname : "",
        }),
        validationSchema: (props) => {
            const { checksTypesList } = props;
            let validateObject = {
                account: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                checksTypes: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                checkBooksAmount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                checkBookChecksAmount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
            };
            return Yup.lazy((values) => {
                if (checksTypesList.find((type) => type.value === values.checksTypes)?.showsDeliveryPoint) {
                    validateObject = {
                        account: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        checksTypes: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        checkBooksAmount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        name: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        surname: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        document: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        checkBookChecksAmount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    };
                }
                return Yup.object().shape(validateObject);
            });
        },

        handleSubmit: (
            { account, checksTypes, checkBookChecksAmount, checkBooksAmount, documentType, document, name, surname },
            formikBag,
        ) => {
            const { dispatch, accounts, checksTypesList, checksRequestData } = formikBag.props;
            const formatValidation = documentTypeFormats(documentType, document, formikBag);
            if (formatValidation || !checksTypesList.find((type) => type.value === checksTypes)?.showsDeliveryPoint) {
                const completeAccount = accounts.find(({ idProduct }) => idProduct === (account.value || account));
                const completeCheckType = checksTypesList.find(({ value }) => value === checksTypes);
                dispatch(
                    checkActions.checksRequestData({
                        account: completeAccount,
                        checksTypes: completeCheckType,
                        checkBookChecksAmount,
                        checkBooksAmount,
                        documentType,
                        document,
                        name,
                        surname,
                        returnToPending: checksRequestData?.returnToPending,
                        idTransaction: checksRequestData?.idTransaction,
                    }),
                );
                if (checksRequestData?.returnToPending) {
                    saveDraft();
                }
                dispatch(push("/checks/summary"));
            } else {
                formikBag.setErrors({ document: i18n.get("login.step1.wrong.format") });
                formikBag.setSubmitting(false);
            }
        },
    }),
)(Checks);
