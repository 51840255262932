import { call, put, select, takeLatest, take } from "redux-saga/effects";
import { actions as formActions } from "reducers/form";
import * as accountsMiddleware from "middleware/accounts";
import { selectors, types, actions as accountsActions } from "reducers/accounts";
import { actions as notificationActions } from "reducers/notification";
import { downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors, credentialsToUnderscoreFormat } from "util/form.js";
import * as i18n from "util/i18n";
import * as form from "middleware/form";
import * as configUtils from "util/config";
import { delay } from "redux-saga";

const sagas = [
    takeLatest(types.ACCOUNT_READ_REQUEST, accountRead),
    takeLatest(types.ACCOUNT_DETAILS_REQUEST, accountDetails),
    takeLatest(types.ACCOUNT_PROYECTED_BALANCE, accountProyectedBalance),
    takeLatest(types.ACCOUNT_DIFERED_MOVEMENTS, accountsDiferedMovements),
    takeLatest(types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST, downloadStatement),
    takeLatest(types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovements),
    takeLatest(types.ACCOUNT_GET_HISTORIC_BALANCE_REQUEST, getHistoricBalance),
    takeLatest(types.ACCOUNT_MOVEMENT_DETAILS_REQUEST, movementDetails),
    takeLatest(types.SET_FAVORITE_ACCOUNT, setFavoriteAccount),
    takeLatest(types.EDIT_MOVEMENT_NOTE_REQUEST, editMovementNote),
    takeLatest(types.LIST_ACCOUNT_STATEMENTS_REQUEST, listStatements),
    takeLatest(types.LIST_ACCOUNTS_REQUEST, listAccountsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeLatest(types.DOWNLOAD_ACCOUNTS_LIST_REQUEST, downloadAccountsList),
    takeLatest(types.DOWNLOAD_HISTORIC_BALANCE_REQUEST, downloadHistoricBalance),
    takeLatest(types.ACCOUNT_FETCH_MOVEMENTS_REQUEST, fetchMovements),
    takeLatest(types.ACCOUNT_DOWNLOAD_INFORMATION_REQUEST, downloadInformation),
    takeLatest(types.MODIFY_CBU_ALIAS_REQUEST, modifyCbuAlias),
    takeLatest(types.OPENING_ACCOUNT, openingAccount),
    takeLatest(types.ACCOUNT_LATEST_MOVEMENTS_REQUEST, getLatestMovements),
    takeLatest(types.DOWNLOAD_LATEST_MOVEMENTS_REQUEST, downloadLatestMovements),
    takeLatest(types.ACCOUNT_SUMMARIES_QUERY_REQUEST, summariesQuery),
    takeLatest(types.ACCOUNT_SUMMARIES_EXTRACT_REQUEST, summariesExtract),
];

export default sagas;

function* openingAccount({ openingAccountData, otp, userId, formikBag }) {
    try {
        const creationDate = new Date();
        const response = yield call(accountsMiddleware.openingAccount, openingAccountData, otp, creationDate, userId);

        if (response.type === "W") {
            if (response.data.code !== "COR020W") {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["accountOpeningSummary"]),
                );
            } else {
                formikBag.setErrors(response.data.data);
            }
        } else {
            if (openingAccountData.idTransaction !== undefined) {
                yield put(
                    notificationActions.showNotification(i18n.get("accounts.modify.success.message"), "success", [
                        "openingAccount",
                    ]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("accounts.page.success.notificacion"), "success", [
                        "accountOpeningConfirmation",
                    ]),
                );
            }
            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* accountsDiferedMovements({ accountId, pageNumber }) {
    if (accountId !== undefined) {
        const response = yield call(accountsMiddleware.listDiferedMovements, accountId, pageNumber);

        if (response) {
            if (response.status === 200) {
                yield put({
                    type: types.ACCOUNT_DIFERED_MOVEMENTS_SUCCESS,
                    diferedMovements: response.data.data,
                });
            }
        }
    }
}

function* accountProyectedBalance({ accountId }) {
    if (accountId !== undefined) {
        const response = yield call(accountsMiddleware.listProyectedBalances, accountId);

        if (response) {
            if (response.status === 200) {
                yield put({
                    type: types.ACCOUNT_PROYECTED_BALANCE_SUCCESS,
                    ...response.data.data,
                });
            }
        }
    }
}

function* accountDetails({ idSelectedAccount, userId, formikBag }) {
    try {
        const filters = yield select(selectors.getFilters);
        const response = yield call(accountsMiddleware.listMovements, idSelectedAccount, userId, filters);

        if (response) {
            if (response.type === "W" && formikBag) {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            } else if (response.status === 200) {
                const responseCredentials = yield call(
                    form.listCredentialsGroups,
                    null,
                    "administration.users.blockunblock.send",
                );
                const credentialGroups = responseCredentials.data.data.groups;

                yield put({
                    type: types.ACCOUNT_DETAILS_SUCCESS,
                    ...response.data.data,
                    credentialGroups,
                });
            }
        }
    } finally {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    }
}

function* accountRead({ idAccount }) {
    const response = yield call(accountsMiddleware.readAccount, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_READ_SUCCESS,
            account: response.data.data ? response.data.data.account : response.data,
        });
    }
}

function* setFavoriteAccount({ idProduct, userId, favorite }) {
    const response = yield call(accountsMiddleware.setFavoriteStatus, idProduct, userId, favorite);

    if (response && response.data.code === "COR000I") {
        yield put(accountsActions.listAccounts(userId));
    }
}

function* downloadStatement({ idAccount, idStatement }) {
    const response = yield call(accountsMiddleware.downloadStatement, idAccount, idStatement);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        downloadPdf(fileName, content);

        yield put({
            type: types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS,
        });
    }
}

function* editMovementNote({ accountId, movementId, note }) {
    const response = yield call(accountsMiddleware.editMovementNote, accountId, movementId, note);

    if (response && response.status === 200) {
        const confirmationMessage = i18n.get("accounts.movement.detail.noteSaved");

        yield put(notificationActions.showNotification(confirmationMessage, "success", ["movementDetail"]));
    } else {
        const errorMessage = i18n.get("accounts.movement.detail.noteUnsaved");

        yield put(notificationActions.showNotification(errorMessage, "error", ["movementDetail"]));
    }
}

function* fetchMovements({ accountId, filters }) {
    const response = yield call(accountsMiddleware.listMovements, accountId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* fetchMoreMovements({ accountId, filters }) {
    const response = yield call(accountsMiddleware.listMovements, accountId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* getHistoricBalance({ dateFrom, dateTo, selectedAccount, numberPage }) {
    const response = yield call(accountsMiddleware.historicBalance, selectedAccount, dateFrom, dateTo, numberPage);

    if (response && response.status === 200) {
        const { historicBalances, pageNumber, totalPages } = response.data.data;

        yield put({
            type: types.ACCOUNT_GET_HISTORIC_BALANCE_SUCCESS,
            historicBalances,
            historicBalancesTotalPages: totalPages,
            historicBalancesPageNumber: pageNumber,
        });
    }
}

function* listAccountsRequest({ userId }) {
    const response = yield call(accountsMiddleware.listAccounts, userId);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_ACCOUNTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* listStatements({ idAccount }) {
    const response = yield call(accountsMiddleware.listStatements, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_ACCOUNT_STATEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* movementDetails({ idAccount, idStatement }) {
    const response = yield call(accountsMiddleware.movementDetails, idAccount, idStatement);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* downloadMovements({ idAccount, format }) {
    const filters = yield select(selectors.getFilters);
    const { type, data } = yield call(accountsMiddleware.downloadMovements, idAccount, filters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("global.download.success"), "success", ["account/details"]),
        );
    }
}

function* downloadAccountsList({ format }) {
    const { type, data } = yield call(accountsMiddleware.downloadAccountsList, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_ACCOUNTS_LIST_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_ACCOUNTS_LIST_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("global.download.success"), "success", [
                "accounts",
                "account/details",
            ]),
        );
    }
}

function* downloadHistoricBalance({ accountId, format, dateFrom, dateTo }) {
    const { type, data } = yield call(accountsMiddleware.downloadHistoricBalance, accountId, format, dateFrom, dateTo);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_HISTORIC_BALANCE_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_HISTORIC_BALANCE_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("global.download.success"), "success", [
                "historicbalance",
                "account/details",
            ]),
        );
    }
}

function* downloadInformation({ idAccount, data }) {
    const response = yield call(accountsMiddleware.downloadInformation, idAccount, data);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        if (data.format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({
            type: types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS,
        });
        yield put(
            notificationActions.showNotification(i18n.get("global.download.success"), "success", ["account/details"]),
        );
    }
}

function* modifyCbuAlias({ idAccount, cuit, cbu, cbuAlias, newAlias, credentials, formikBag }) {
    try {
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const response = yield call(accountsMiddleware.modifyCbuAlias, idAccount, {
            cuit,
            cbu,
            cbuAlias,
            newAlias,
            ...credentialsWithUnderscore,
        });

        const warnCodes = configUtils.get("backend.coelsa.response.Consultar.warn", "").split("|");

        if (response) {
            if (response.type === "W" && formikBag) {
                const { errorDescription } = response.data.data;
                if (errorDescription) {
                    yield put(notificationActions.showNotification(errorDescription, "error", ["account/alias"]));
                } else if (response.data.code === "API524W") {
                    yield put(
                        notificationActions.showNotification(i18n.get("token.otp.invalid"), "error", ["account/alias"]),
                    );
                } else {
                    formikBag.setErrors(response.data.data);
                }
            } else if (response.type === "I") {
                const { account, errorDescription, errorCode } = response.data.data;
                const isWarn = warnCodes?.includes(errorCode);
                yield put({
                    type: types.MODIFY_CBU_ALIAS_RETURN,
                    account,
                });
                if (errorDescription && !isWarn) {
                    yield put({
                        type: types.MODIFY_CBU_ALIAS_RETURN_ERROR,
                    });
                    yield put(
                        notificationActions.showNotification(i18n.get(`coelsa.error.${errorCode}`), "error", [
                            "account/details",
                        ]),
                    );
                } else {
                    yield put({
                        type: types.MODIFY_CBU_ALIAS_SUCCESS,
                        selectedAccount: account,
                    });
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(`coelsa.error.${errorCode}`),
                            isWarn ? "error" : "success",
                            ["account/details"],
                        ),
                    );
                }
            }
        }
    } finally {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    }
}

function* getLatestMovements({
    selectedAccount,
    latestMovementsPageNumber,
    pendingMovementsPageNumber,
    findBy,
    lastDateFrom,
    lastDateTo,
    pendingDateFrom,
    pendingDateTo,
    amountFrom,
    amountTo,
    voucher,
    detail,
    movementType,
    isLatestMovementsSelected,
    longDetail,
}) {
    const response = yield call(
        accountsMiddleware.latestMovements,
        selectedAccount,
        latestMovementsPageNumber,
        pendingMovementsPageNumber,
        findBy,
        isLatestMovementsSelected ? lastDateFrom : pendingDateFrom,
        isLatestMovementsSelected ? lastDateTo : pendingDateTo,
        amountFrom,
        amountTo,
        voucher,
        detail,
        movementType,
        isLatestMovementsSelected,
        longDetail,
    );

    if (response && response.status === 200) {
        const {
            latestMovements,
            latestMovementsTotalPages,
            latestMovementsPageNumber: newLatestMovementsPageNumber,
            pendingMovements,
            pendingMovementsTotalPages,
            pendingMovementsPageNumber: newPendingMovementsPageNumber,
        } = response.data.data;
        yield put({
            type: types.ACCOUNT_LATEST_MOVEMENTS_SUCCESS,
            latestMovements,
            latestMovementsTotalPages,
            latestMovementsPageNumber: newLatestMovementsPageNumber,
            pendingMovements,
            pendingMovementsTotalPages,
            pendingMovementsPageNumber: newPendingMovementsPageNumber,
        });
    } else {
        yield put({
            type: types.ACCOUNT_LATEST_MOVEMENTS_FAILURE,
        });
    }
}

function* downloadLatestMovements({
    format,
    isLatestMovementsSelected,
    latestMovementsPageNumber,
    pendingMovementsPageNumber,
    findBy,
    dateFrom,
    dateTo,
    selectedAccount,
    pageNumber,
    amountFrom,
    amountTo,
    voucher,
    detail,
    movementType,
}) {
    try {
        const { type, data } = yield call(
            accountsMiddleware.downloadLatestMovements,
            format,
            isLatestMovementsSelected,
            latestMovementsPageNumber,
            pendingMovementsPageNumber,
            findBy,
            dateFrom,
            dateTo,
            selectedAccount,
            pageNumber,
            amountFrom,
            amountTo,
            voucher,
            detail,
            movementType,
        );

        if (type === "W") {
            yield put({ type: types.DOWNLOAD_LATEST_MOVEMENTS_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
            );
        } else {
            const { content, fileName } = data.data;

            if (format === "pdf") {
                downloadPdf(fileName, content);
            } else {
                downloadXls(fileName, content);
            }

            yield put({ type: types.DOWNLOAD_LATEST_MOVEMENTS_SUCCESS });
            yield put(
                notificationActions.showNotification(i18n.get("global.download.success"), "success", [
                    "account/details",
                ]),
            );
        }
    } catch (error) {
        yield put({ type: types.DOWNLOAD_LATEST_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    }
}

function* summariesQuery({ accountId, year, month }) {
    const response = yield call(accountsMiddleware.summariesQuery, accountId, year, month);
    if (response && response.status === 200) {
        const { listDateToYear } = response.data.data;
        yield put({
            type: types.ACCOUNT_SUMMARIES_QUERY_SUCCESS,
            listDateToYear,
        });
    } else {
        yield put({
            type: types.ACCOUNT_LATEST_MOVEMENTS_FAILURE,
        });
    }
}

function* summariesExtract({ extractId, productType }) {
    try {
        const response = yield call(accountsMiddleware.summariesExtract, extractId, productType);
        if (response && response.status === 200) {
            yield put(
                notificationActions.showNotification(i18n.get("global.successDownload"), "success", [
                    "account/details",
                ]),
            );
            yield call(delay, 3000);

            const { content, fileName } = response.data.data;
            downloadPdf(fileName, content);
        }
    } catch (error) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } finally {
        yield put({
            type: types.ACCOUNT_SUMMARIES_EXTRACT_FINISH,
            extractId,
        });
    }
}
