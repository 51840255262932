import React from "react";
import { shape, string } from "prop-types";
import { compose } from "redux";
import classNames from "classnames";

import { RangeDatefield } from "@ui-kit/components/index.es";

import FieldError from "pages/_components/fields/FieldError";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";

import * as i18n from "util/i18n";
function RangeDatePicker({
    idForm,
    form,
    field,
    ...props
}) {

    const { touched, errors } = form;

    const hasError = touched[field.name] && errors[field.name];

    const fieldLabel = i18n.get(`${idForm}.${field.name}.label`);

    return (
        <div className={classNames("rangedate-picker__old", { "has-error": hasError })}>
            <RangeDatefield 
                field={field}
                form={form}
                id={field.id}
                label={fieldLabel}
                error={hasError}
                {...props}
            />
            {hasError && (
                <FieldError
                    error={errors[field.name]}
                    errorClassName="p-absolute"
                />
            )}
        </div>
    )
}

RangeDatePicker.propTypes = {
    idForm: string.isRequired,
    form: shape({}).isRequired,  
    field: shape({}).isRequired,  
}

export default compose(resizableRoute, withFocus)(RangeDatePicker);
