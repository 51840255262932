import { Box, Text, FormattedAmount } from "@ui-kit/components/index.es";
import React from "react";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { bool, number, shape } from "prop-types";

function Limits(props) {
    Limits.propTypes = {
        isDesktop: bool.isRequired,
        values: shape({}).isRequired,
        currency: number.isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
    };

    const { isDesktop, values, limit, limitUsed, currency } = props;

    const currencyLabel = () => i18n.get(`currency.label.${currency}`);

    const getTruncNumber = (value) => {
        const numberText = value?.toString();
        const index = numberText?.indexOf(".");

        if (!numberText || index === -1) {
            return value;
        }
        const end = numberText.length >= index + 3 ? index + 3 : index + 2;
        return parseFloat(numberText.slice(0, end));
    };

    if (!values.debitAccount) {
        return null;
    }

    let limitVar = limit;
    let limitUsedVar = limitUsed;

    if (currency === "2") {
        const dolarQuot = parseFloat(configUtils.get("CMF_Dolar_Valor", "98.90"));
        limitVar = getTruncNumber(limitVar / dolarQuot);
        limitUsedVar = getTruncNumber(limitUsedVar / dolarQuot);
    }

    const res = window.innerWidth > 1120;

    if (isDesktop && res) {
        return (
            <Box flex align="baseline">
                <Text color="primary" variant="bodyBold" className="no-wrap">
                    {i18n.get("transfers.form.limit")}
                </Text>
                <FormattedAmount color="primary" variant="body2" amount={limitVar} currency={currencyLabel()} />
                <Box mx={8}>
                    <Text color="primary" variant="body2">
                        |
                    </Text>
                </Box>
                <Text color="primary" variant="bodyBold" className="no-wrap">
                    {i18n.get("transfers.form.limit.available")}
                </Text>
                <FormattedAmount color="primary" variant="body2" amount={limitUsedVar} currency={currencyLabel()} />
            </Box>
        );
    }
    return (
        <Box>
            <Box flex align="baseline">
                <Text color="primary" variant="bodyBold">
                    {i18n.get("transfers.form.limit")}
                </Text>
                <FormattedAmount color="primary" variant="body2" amount={limitVar} currency={currencyLabel()} />
            </Box>
            <Box flex align="baseline">
                <Text color="primary" variant="bodyBold">
                    {i18n.get("transfers.form.limit.available")}
                </Text>
                <FormattedAmount color="primary" variant="body2" amount={limitUsedVar} currency={currencyLabel()} />
            </Box>
        </Box>
    );
}

export default Limits;
