import React from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";
import { Provider } from "react-redux";
import { ThemeProvider2 } from "@ui-kit/components/index.es";

import { store } from "store";

import "styles/main.scss";

import App from "App";

/* eslint-disable react/jsx-filename-extension */
window.startApp = () => {
    ReactDOM.render(
        <Provider store={store}>
            <ThemeProvider2>
                <App />
            </ThemeProvider2>
        </Provider>,
        document.getElementById("root"),
    );
};
/* eslint-enable react/jsx-filename-extension */

// Phonegap app start in ondeviceready inside index.js
if (!window.cordova) {
    window.startApp();

    WebFont.load({
        google: {
            families: ["Montserrat:400,500,700", "sans-serif"],
        },
        custom: {
            families: [
                "Graphik-Thin",
                "Graphik-Light",
                "Graphik-Regular",
                "Graphik-Medium",
                "Graphik-Semibold",
                "Graphik-Bold",
            ],
            urls: ["../public/font/fonts.css"],
        },
    });
}
