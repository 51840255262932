import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Yup from "yup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Button, Box, Form, Spinner, Text, useMediaQuery, Agenda } from "@ui-kit/components/index.es";

import CreateTemplateModal from "pages/v2/transfer/CreateTemplateModal";

import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import {
    actions as frequentDestinationActions,
    selectors as frequentDestinationSelectors,
} from "reducers/frequentDestination";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as holidaysActions } from "reducers/holidays";
import { actions as formActions } from "reducers/form";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import * as dateUtils from "util/date";
import * as schedulerUtils from "util/scheduler";
import { saveLocation, updateSchedulerToSend } from "util/transaction";

import * as transferMiddleware from "middleware/transfer";
import { saveDraft } from "middleware/form";
import SchedulerModal from "./scheduler/SchedulerModal";
import TADWarningModal from "./TADWarningModal";
import TemplateDrawer from "./TemplateDrawer";
import Message from "./scheduler/Message";
import Limits from "./Limits";
import { numberFormat } from "util/number";
import { selectors as i18nSelectors } from "reducers/i18n";
import { replace } from "react-router-redux";

const MAX_REQUIRED = "22";
const ID_FORM = "transferToContact";

function TransferToContactForm(props) {
    const {
        dispatch,
        isDesktop,
        fetching,
        location,
        accounts,
        summary,
        templates,
        isTemplatesVisible,
        setIsTemplatesVisible,
        setSchedulerButtonVisible,
        isCVU,
        limit,
        limitUsed,
        onCreateTemplate,
        schedulerButtonVisible,
        listFrequentDestinations,
        lang,
    } = props;

    const [values, setValues] = useState({
        debitAccount: "",
        transferKind: "",
        concept: "",
        creditAccount: "",
        amount: "",
        reference: "",
        sendEmail: false,
        email: "",
        emailText: "",
        scheduler: {},
    });
    const [draftButtonDisabled, setDraftButtonDisabled] = useState(false);
    const [formikProps, setFormikProps] = useState({});
    const [creditAccountConfirmed, setCreditAccountConfirmed] = useState(false);
    const [disabledMail, setDisabledMail] = useState(false);
    const [showFullList, setShowFullList] = useState(false);
    const [tadWarningPopupVisible, setTadWarningPopupVisible] = useState(false);
    const [tadWarningShowed, setTadWarningShowed] = useState(false);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
    const isMobile = useMediaQuery("min", "md");

    const {
        debitAccount,
        creditAccount,
        amount,
        reference,
        email,
        emailText,
        sendEmail,
        transferKind,
        concept,
    } = values;

    useEffect(() => {
        const { idEnvironment } = props;
        dispatch(templateActions.loadTemplateList(ID_FORM));
        dispatch(frequentDestinationActions.loadListRequest(idEnvironment, null));
    }, []);

    useEffect(() => {
        const {
            amount: { amount },
            debitAccount: debitAccountDraft,
        } = values;
        dispatch(transferActions.preActivity());
        dispatch(holidaysActions.listHolidays());

        const { cbu, email, transferKind, isFrequentDestinations } = summary;

        if (location?.pathname === "/v2/transfer/transfer") {
            saveLocation(props);
            if (location?.state) {
                const { isInternal, debitAccount } = location?.state;
                dispatch(transferActions.changeTransferGroup(isInternal));
                setValues({
                    ...values,
                    debitAccount,
                    creditAccount: "",
                    amount: shape({
                        amount: 0,
                        currency: "",
                    }),
                    cbuOrAlias: "",
                    reference: "",
                    email: "",
                    emailText: "",
                    concept: "VAR",
                    transferKind: "",
                });
                dispatch(transferActions.accountLimitsActivities(debitAccount, "transfers.toContact.send"));
            } else {
                const { activeEnvironment } = props;
                const isInternalTrx = transferKind === "other" ? false : activeEnvironment.permissions.transferInternal;

                if (!isInternalTrx && isFrequentDestinations) {
                    if (cbu) {
                        setValues({
                            ...values,
                            cbuOrAlias: cbu,
                            email,
                            transferKind,
                        });
                    }
                } else if (email && isFrequentDestinations) {
                    setValues({
                        ...values,
                        email,
                    });
                }
                dispatch(transferActions.changeTransferGroup(isInternalTrx));
            }
        } else if (location?.pathname === "/transfer/modify" && debitAccountDraft) {
            dispatch(transferActions.accountLimitsActivities(debitAccountDraft, "transfers.toContact.send"));
        }
        if (amount > 0) {
            setHint(amount);
        }
    }, []);

    useEffect(() => {
        const { amount } = values;
        const { amount: amountValue } = amount;
        if (amountValue > 0) {
            setHint(amountValue);
        }
    }, [isCVU]);

    useEffect(() => {
        const { sendEmail } = values;
        dispatch(transferActions.setSendEmail(sendEmail));
    }, [sendEmail]);

    useEffect(() => {
        const { transferKind, creditAccount, reference, amount, concept, email, emailText, sendEmail, cbu } = summary;
        const creditAccountToUse = creditAccount?.value || creditAccount?.idProduct || cbu;
        if (creditAccountToUse) {
            setCreditAccountConfirmed(true);
        }
        setValues({
            ...values,
            creditAccount: creditAccountToUse,
            amount: amount || "",
            transferKind: transferKindOptions.find(({ value }) => value === transferKind),
            concept: conceptsFiltered[0] || conceptsFiltered.find(({ value }) => value === concept),
            reference: reference || "",
            email: email || "",
            emailText: emailText || "",
            sendEmail,
        });
    }, [summary]);

    const selectedCurrency = () => {
        const selectedAccount = accounts.find((account) => account.idProduct === values.debitAccount);
        return i18n.get(`currency.label.${selectedAccount?.currency}`);
    };

    const selectedAccount = () => accounts.find((account) => account.idProduct === values.debitAccount);
    const getDefaultAccount = () => accounts?.find((account) => account.favorite)?.idProduct || accounts[0]?.idProduct;

    useEffect(() => {
        if (!selectedAccount()) {
            const defaultAccount = getDefaultAccount();
            if (defaultAccount) {
                setValues({ ...values, debitAccount: defaultAccount });
                dispatch(transferActions.accountLimitsActivities(defaultAccount, "transfers.toContact.send"));
                if (formikProps?.setValues) {
                    formikProps.setValues({ debitAccount: defaultAccount });
                }
            }
        }
    }, [values.debitAccount]);

    const handleDebitAccountSelect = (accountId) => {
        const { dispatch } = props;
        setValues({ ...values, debitAccount: accountId });
        dispatch(transferActions.accountLimitsActivities(accountId, "transfers.toContact.send"));
    };

    const transferKindOptions = [
        {
            value: "same",
            label: i18n.get("transfers.form.transfer.kind.same"),
        },
        {
            value: "other",
            label: i18n.get("transfers.form.transfer.kind.other"),
        },
    ];

    const conceptsFilter = () => {
        const { concepts } = props;
        return isCVU
            ? concepts
                  .filter(({ id }) => !["BRH", "SON", "ROP", "OIH", "BNR", "APC", "SIS"].includes(id))
                  .map(({ id, name }) => ({
                      value: id,
                      label: `${id} - ${name}`,
                  }))
            : concepts.map(({ id, name }) => ({
                  value: id,
                  label: `${id} - ${name}`,
              }));
    };

    const conceptsFiltered = conceptsFilter();

    const mapAccounts = accounts.map((account) => ({
        accountName: account.productAlias || account.shortLabel,
        amount: account.balance,
        currency: i18n.get(`currency.label.${account.currency}`),
        description: i18n.get(`productType.${account.productType}`),
        id: account.idProduct,
    }));

    if (!values.concept && conceptsFiltered[0]) {
        setValues({ ...values, concept: conceptsFiltered[0] });
    }

    if (!values.debitAccount) {
        const { dispatch } = props;
        const { debitAccount } = summary;
        let defaultAccount;
        if (debitAccount) {
            defaultAccount = debitAccount.value || debitAccount.idProduct || debitAccount;
        } else {
            defaultAccount = getDefaultAccount();
        }
        if (defaultAccount) {
            setValues({ ...values, debitAccount: defaultAccount });
            dispatch(transferActions.accountLimitsActivities(defaultAccount, "transfers.toContact.send"));
            if (formikProps?.setValues) {
                formikProps.setValues({ debitAccount: defaultAccount });
            }
        }
    }

    const getActivityId = () => "transfers.toContact.send";
    const getActivityIdTad = () => "transfers.toContact.tad.send";

    const setHint = (value) => {
        const isTadTrx = isTad(value);
        if (isTadTrx) {
            setSchedulerButtonVisible(false);
            if (!tadWarningShowed) {
                setTadWarningPopupVisible(true);
                setTadWarningShowed(true);
            }
        } else {
            setTadWarningPopupVisible(false);
            setTadWarningShowed(false);
            setSchedulerButtonVisible(true);
        }
    };

    const changeCbu = (record) => {
        const transferKindDestination = record.isOwn === "1" ? "same" : "other";
        setValues({
            ...values,
            cbuOrAlias: record.account,
            transferKind: transferKindDestination,
            email: record.email,
        });
        verifyCVU(record.account);
    };

    const isValidAlias = (value) => {
        /* Regex that accept letter from a to z including capital letters, (.), (-), numbers
        and excluding (Ñ) and (ñ) */
        const reg = /^(?:(?![ñÑ])[a-zA-Z.\-\d])+$/;
        return reg.test(value);
    };

    const isValidCbu = (value) => {
        const reg = /^\d+$/;
        return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
    };

    // VERIFICAR EN ONBLUR / OnChange de cbu/alias
    const verifyCVU = (value) => {
        if (isValidCbu(value)) {
            const isCVUvalue = value.startsWith("000");
            dispatch(transferActions.setIsCVU(isCVUvalue));
        } else if (isValidAlias(value)) {
            dispatch(transferActions.getCbuCvuFromAlias(value));
        }
    };

    const closeTADModal = () => {
        setTadWarningPopupVisible(false);
    };

    const handleCreateTemplate = (propsFormik) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        onCreateTemplate({ ...propsFormik.values, currency });
    };

    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", "."));
        }
        return value;
    };

    const handleChangeAmount = (amount) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        setHint({ amount: parseAmount(amount), currency });

        setValues({ ...values, amount });
    };

    const handleAccountSearch = async (cbuCvuOrAlias) => {
        const { isValidCbu } = props;
        let cbuCvu;
        let alias;
        if (isValidCbu(cbuCvuOrAlias)) {
            cbuCvu = cbuCvuOrAlias;
        } else {
            alias = cbuCvuOrAlias;
        }
        return new Promise((resolve, reject) => {
            transferMiddleware
                .getAccountByCbu(cbuCvu, alias)
                .then((response) => {
                    const { clientUser, accountDetail } = response.data.data;
                    if (!clientUser && !accountDetail) {
                        reject(response.data.message);
                    }
                    resolve({
                        dataAccount: {
                            owner: clientUser.firstName,
                            document: clientUser.cuil,
                            bankName: accountDetail.bankName,
                        },
                    });
                })
                .catch(() => {
                    reject(i18n.get("problems.finding.cbu"));
                });
        });
    };

    const referenceMaxLength = configUtils.getBoolean("frontend.transfers.reference.maxLength", 12);
    const idActivity = "transfers.toContact.send";

    const isTad = (amountWithCurrency) => {
        const { isRetailEnvironment, activeEnvironment } = props;
        const value = amountWithCurrency.amount;
        const environmentTypeTag = isRetailEnvironment ? "Retail" : "Corporate";
        const currencyTag = amountWithCurrency.currency.toString() === "0" ? "Pesos" : "Dolares";
        const minAmount = configUtils.getInteger(`frontend.TAD.minAmount${currencyTag}.${environmentTypeTag}`, 100000);
        const maxAmount = configUtils.getInteger(`frontend.TAD.maxAmount${currencyTag}.${environmentTypeTag}`, 1000000);
        const enabledCorporate = configUtils.getBoolean("frontend.show.TAD.functionalities.Corporate", false);
        const enabledRetail = configUtils.getBoolean("frontend.show.TAD.functionalities.Retail", false);

        let tadEnabled = false;

        if (isCVU) {
            return false;
        }

        if (!isRetailEnvironment) {
            if (enabledCorporate) {
                tadEnabled = true;
            }
        } else if (enabledRetail) {
            tadEnabled = true;
        }

        if (tadEnabled && activeEnvironment?.permissions?.transferDiferredAccreditation) {
            if (value >= minAmount && value <= maxAmount) {
                return true;
            }
        }
        return false;
    };

    const handleTemplateSelect = (template) => {
        const amount = template.amount?.amount || 0;
        const amountWithCurrency = template.amount;
        verifyCVU(template.cbuOrAlias);
        const isTadTrx = isTad(amountWithCurrency);

        if (amount > 0) {
            setHint(amountWithCurrency);
        }
        setIsTemplatesVisible(false);
        setValues({
            ...values,
            debitAccount: template.debitAccount,
            creditAccount: template.creditAccount || template.cbuOrAlias,
            transferKind: transferKindOptions.find(({ value }) => value === template.transferKind),
            concept: conceptsFiltered.find(({ value }) => value === template.concept),
            amount,
            reference: template.reference,
            sendEmail: template.sendEmail,
            emailText: template.emailText,
            email: template.email,
            flagHint: isTadTrx,
            tadTrx: isTadTrx,
        });

        dispatch(transactionLinesActions.loadTransactionLinesTemplateData(template));
        const { debitAccount } = template;
        if (debitAccount) {
            dispatch(transferActions.accountLimitsActivities(debitAccount, "transfers.toContact.send"));
        }
        setCreditAccountConfirmed(true);
    };

    const getDraftSummary = (draftValues) => {
        const { idTransaction, accounts } = props;
        const {
            debitAccount,
            creditAccount,
            amount,
            currency,
            reference,
            sendEmail,
            email,
            emailText,
            tadTrx,
            concept,
            transferKind,
            scheduler,
            flagHint,
        } = draftValues;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
        const isCbu = () => /^\d+$/.test(creditAccount);
        return {
            accounts,
            debitAccount: completeDebitAccount,
            cbu: isCbu() ? creditAccount : null,
            alias: !isCbu() ? creditAccount : null,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            transferKind: transferKind?.value || "",
            concept: concept?.value,
            scheduler: schedulerToSend,
            idTransaction,
            tadTrx,
            flagHint,
        };
    };

    const onSaveDraft = (draftValues) => {
        const { idTransaction } = props;
        const { transferKind, concept, debitAccount, creditAccount } = draftValues;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);

        const parsedAmount = parseAmount(draftValues.amount) || 0;
        const tadTrx = isTad({
            currency: completeDebitAccount.currency,
            amount: parsedAmount,
        });
        dispatch(
            formActions.saveDraft({
                idForm: ID_FORM,
                idActivityDraft: tadTrx ? getActivityIdTad() : getActivityId(),
                data: {
                    ...draftValues,
                    cbuOrAlias: creditAccount,
                    amount: {
                        quantity: parsedAmount,
                        currency: completeDebitAccount.currency,
                    },
                    currency: completeDebitAccount.currency,
                    debitAccount: { value: debitAccount },
                    creditAccount: { value: creditAccount },
                    transferKind: transferKind?.value || "",
                    concept: concept?.value,
                },
                idTransaction: idTransaction || null,
                scheduler: draftValues?.scheduler,
                summary: getDraftSummary({
                    ...draftValues,
                    amount: parsedAmount,
                    currency: completeDebitAccount.currency,
                }),
            }),
        );
    };

    const onSubmit = ({
        debitAccount,
        creditAccount,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        transferKind,
        flagHint,
    }) => {
        const { dispatch, accounts, location, summary } = props;
        const { scheduler } = values;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const currency = parseInt(completeDebitAccount.currency, 10);
        const isCbu = () => {
            const reg = /^\d+$/;
            return reg.test(creditAccount);
        };

        let schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
        const newValueDate = schedulerToSend?.valueDate;
        schedulerToSend = { ...schedulerToSend, newValueDate };
        delete schedulerToSend.valueDate;

        const tadTrx = isTad({
            currency,
            amount: parseAmount(amount),
        });
        if (!isCVU && tadTrx) {
            const schedulerTad = updateSchedulerToSend();
            if (schedulerTad) {
                schedulerToSend = schedulerTad;
            }
        }
        dispatch(replace("/v2/transfer/toContact/back"));
        dispatch(
            transferActions.createTransferToContact(
                {
                    accounts,
                    debitAccount: completeDebitAccount,
                    cbu: isCbu() ? creditAccount : null,
                    alias: !isCbu() ? creditAccount : null,
                    currency,
                    amount: parseAmount(amount),
                    reference,
                    sendEmail,
                    email,
                    emailText,
                    concept: concept.value,
                    transferKind: transferKind.value,
                    scheduler: schedulerToSend,
                    idTransaction: summary.idTransaction,
                    tadTrx,
                    flagHint,
                },
                null, // formikBag,
            ),
        );
        if (location?.pathname === "/transfer/modify") {
            saveDraft();
        }
    };

    const handleSchedulerChange = (data) => {
        if (data.frequency === schedulerUtils.ONCE) {
            setValues({
                ...values,
                scheduler: {
                    selectedOption: data.frequency,
                    valueDate: moment(data.startsOn),
                    program: null,
                },
            });
        } else {
            const newProgramData = {
                frequency: data.frequency === schedulerUtils.CUSTOM ? data.customFreq : data.frequency, // one of DAY, WEEK, MONTH, YEAR
                frequencyValue: data.frequency === schedulerUtils.CUSTOM ? data.customFreqValue : "1", // int that represents the frequency, frequency = DAY +  frequencyValue = 2 => every 2 days
                days: dateUtils.getDayFromDate(data.startsOn), // int that represents the days of the week used by the java class Days at the backend. Same class used by the calendar restriction
                day: data.startsOn.getDate(), // day of the month.
                occurrence: null, // occurrence=3 + days=4 every 3rd WEDNESDAY of the month
                startsOn: data.startsOn,
                lapse: data.lapse.lapse, // no change at all
                number: data.lapse.number,
                date: data.lapse.date,
                isCustom: data.frequency === schedulerUtils.CUSTOM,
                selectedOption: data.frequency,
            };
            if (newProgramData.frequency === schedulerUtils.WEEK && newProgramData.isCustom) {
                // if it's custom, I take the days from the bitwisedays control
                newProgramData.days = data.days;
            }
            if (newProgramData.frequency === schedulerUtils.MONTH && data.occurrenceType === "occurrence") {
                newProgramData.occurrence = dateUtils.weekOfMonth(moment(data.startsOn));
                newProgramData.day = null;
            }
            setValues({
                ...values,
                scheduler: {
                    selectedOption: newProgramData.frequency,
                    program: newProgramData,
                },
            });
        }
        setIsScheduleModalVisible(false);
    };

    const customHandleKeyDown = (e) => {
        const { decimalSeparator } = numberFormat(lang);
        const { value, selectionStart, selectionEnd } = e.target;
        const integerPart = value.split(decimalSeparator)[0].replace(/\D/g, "");
        const extendedAmountEnabled = configUtils.getBoolean("increase.size.amount.link.iso8583", false);
        const maxSize = extendedAmountEnabled ? 15 : 10;

        // Array de teclas permitidas para movimiento, navegación y selección
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Home",
            "End",
            "Control",
            "Meta",
            "Shift",
        ];

        // Permitir combinaciones con teclas de control (Ctrl, Cmd, etc.)
        if (e.ctrlKey || e.metaKey || e.shiftKey) {
            return; // Permitir combinaciones de teclas como Ctrl+A, Ctrl+C, etc.
        }

        const isAllSelected = selectionStart === 0 && selectionEnd === value.length;

        // Detectar si el cursor está en la parte decimal
        const decimalIndex = value.indexOf(decimalSeparator);
        // Permitir el ingreso del separador decimal cuando se alcanza el número máximo de dígitos
        if (
            (e.key === decimalSeparator || e.key === ".") &&
            (selectionStart <= decimalIndex || decimalIndex === -1) &&
            integerPart.length >= maxSize
        ) {
            return; // Permitir el ingreso del separador decimal
        }

        if (isAllSelected) {
            return; // Permitir el reemplazo al seleccionar todo el texto
        }

        // Si el cursor está en la parte entera y el número ya tiene 10 dígitos
        if (
            integerPart.length >= maxSize &&
            (selectionStart <= decimalIndex || decimalIndex === -1) &&
            !allowedKeys.includes(e.key)
        ) {
            e.preventDefault(); // Bloquea la entrada de más dígitos en la parte entera si se excede el límite
        }
    };

    useEffect(() => {
        if (sendEmail && values.email === "") {
            setDisabledMail(true);
        } else if (values.email !== "" && sendEmail) {
            setDisabledMail(false);
        } else if (values.email === "" && !sendEmail) {
            setDisabledMail(false);
        }
    }, [sendEmail, values.email]);

    const extendedAmountVariableEnabled = configUtils.getBoolean("increase.size.amount.link.iso8583", false);

    const inputList = [
        {
            colProps: {
                size: 12,
            },
            component: "accountSearcher",
            id: "creditAccount",
            required: true,
            value: values.creditAccount,
            isValidAccount: true,
            disabled: true,
            searchCallback: (cbuCvuOrAlias) => handleAccountSearch(cbuCvuOrAlias),
            onChange: (e) => {
                setValues({ ...values, creditAccount: e.target.value });
            },
            placeholder: i18n.get("transfers.form.cbu.placeholder"),
        },
        {
            colProps: {
                size: 12,
            },
            component: "accountSelect",
            id: "debitAccount",
            label: i18n.get("transfers.form.debit.account"),
            required: true,
            options: mapAccounts,
            defaultValue: debitAccount,
            value: debitAccount,
            onChange: (id) => handleDebitAccountSelect(id),
        },
        {
            colProps: {
                size: 12,
            },
            id: "limits",
            customElement: () => (
                <Limits
                    values={values}
                    limit={limit}
                    limitUsed={limitUsed}
                    isDesktop={isDesktop}
                    currency={selectedAccount()?.currency}
                />
            ),
        },
        {
            colProps: {
                size: 12,
            },
            component: "selectField",
            id: "transferKind",
            required: true,
            iconMobile: "TransferAlt",
            options: transferKindOptions,
            value: values.transferKind,
            onSelectOption: (option) => setValues({ ...values, transferKind: option }),
            selectLabel: i18n.get("transfers.form.transfer.kind"),
            placeholder: i18n.get("transfers.form.select.transfer.placeholder"),
        },
        {
            colProps: {
                size: 12,
            },
            component: "amountField",
            allowNegative: false,
            currency: selectedCurrency(),
            id: "amount",
            label: i18n.get("transfers.form.amount"),
            required: true,
            onBlur: (e, propsFormik) => handleChangeAmount(propsFormik.values.amount),
            maxLength: extendedAmountVariableEnabled ? undefined : 15,
            extendedAmountEnabled: extendedAmountVariableEnabled,
            onKeyDown: (e) => customHandleKeyDown(e),
            afterElement: tadWarningShowed ? (
                <Box mx={12}>
                    <Button
                        icon="Info"
                        variant="text"
                        onClick={(e) => {
                            e.preventDefault();
                            setTadWarningPopupVisible(true);
                        }}
                    />
                </Box>
            ) : null,
            helper: tadWarningShowed ? (
                <Text color="primary" variant="caption">
                    {configUtils.get("frontend.TAD.warning.text")}
                </Text>
            ) : null,
        },
        {
            colProps: {
                size: 12,
            },
            component: "selectField",
            id: "concept",
            required: true,
            options: conceptsFiltered,
            // defaultValue: conceptsFiltered[0],
            value: values.concept,
            onSelectOption: (option) => setValues({ ...values, concept: option }),
            selectLabel: i18n.get("transfers.form.transfer.concept"),
        },
        {
            colProps: {
                size: 12,
            },
            component: "textField",
            id: "reference",
            value: reference,
            onChange: (e) => {
                setValues({ ...values, reference: e.target.value });
            },
            label: i18n.get("transfers.form.reference"),
            placeholder: i18n.get("transfers.form.reference.placeholder"),
            maxLength: referenceMaxLength,
        },
        {
            colProps: {
                size: 12,
            },
            id: "schedule",
            customElement: () =>
                (schedulerButtonVisible ? (
                    <Box>
                        <Button
                            text={i18n.get("transfers.form.schedule")}
                            icon="CalendarAdd"
                            variant="solid"
                            id="modal-button"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsScheduleModalVisible(true);
                            }}
                        />
                        {values?.scheduler?.selectedOption !== undefined && (
                            <Box mt={8}>
                                <Message value={values.scheduler || { valueDate: "" }} />
                            </Box>
                        )}
                    </Box>
                ) : (
                    <></>
                )),
        },
        {
            colProps: {
                size: 12,
            },
            component: "checkbox",
            id: "sendEmail",
            label: i18n.get("transfers.form.send.mail"),
            isChecked: values.sendEmail,
            onChange: () => setValues({ ...values, sendEmail: !values.sendEmail }),
        },
    ];

    if (values.debitAccount && values.transferKind && creditAccountConfirmed && !showFullList) {
        setShowFullList(true);
    }

    if (values.sendEmail && showFullList) {
        inputList.push(
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "email",
                label: "Email",
                value: email,
                onChange: (e) => {
                    setValues({ ...values, email: e.target.value });
                },
                maxLength: 50,
                required: true,
            },
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "emailText",
                value: emailText,
                onChange: (e) => {
                    setValues({ ...values, emailText: e.target.value });
                },
                label: "Mensaje",
                maxLength: 50,
            },
        );
    }

    const { fetchingSubmit } = props;
    if ((fetching && !accounts.length) || !values.debitAccount || fetchingSubmit) {
        return (
            <Box flex justify="center" align="center" column py={64}>
                <Spinner size="large" />
            </Box>
        );
    }

    const initialValues = {
        debitAccount: "",
        transferKind: "",
        concept: "",
        creditAccount: "",
        amount: "",
        reference: "",
        sendEmail: false,
        email: "",
        emailText: "",
    };

    if (formikProps?.setValues) {
        formikProps.setValues({
            debitAccount,
            transferKind,
            concept,
            creditAccount,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
        });
    }

    const handleContactSelect = (cbucvu) => {
        setValues({ ...values, creditAccount: cbucvu, cbuOrAlias: cbucvu });
        verifyCVU(cbucvu);
        setCreditAccountConfirmed(true);
    };

    const agendaItems = listFrequentDestinations.map((contact) => ({
        cbucvu: contact.cbu,
        name: contact.recipientName,
        email: contact.recipientEmail,
        reference: contact.name,
        document: contact.recipientDocumentNumber,
        onClick: () => handleContactSelect(contact.cbu),
    }));

    return (
        <>
            <SchedulerModal
                isVisible={isScheduleModalVisible}
                onClose={() => setIsScheduleModalVisible(false)}
                onScheduleClick={(data) => handleSchedulerChange(data)}
            />
            <TADWarningModal
                isVisible={tadWarningPopupVisible}
                onClick={changeCbu}
                closeModal={closeTADModal}
                isDesktop={isDesktop}
            />
            <Box py={24} px={isMobile ? 0 : 24} gap={16} column>
                <Text variant={isMobile ? "subtitle" : "h4"} mb={24}>
                    {i18n.get("transfers.form.contact.select")}
                </Text>
                <Agenda
                    contactsArray={agendaItems}
                    activeContact={creditAccount}
                    id="AgendaElement"
                    idContainer="app"
                    showAddContact={false}
                    showSearcher
                    oneContactCallback={(value) => handleContactSelect(value)}
                />
                <Form
                    hiddeForm={!creditAccountConfirmed}
                    wrapButtons
                    wrapButtonsFlex
                    buttonSubmit={{
                        colProps: {
                            size: {
                                md: 3,
                                xs: 12,
                            },
                        },
                        text: i18n.get("transfers.form.continue"),
                        variant: "solid",
                    }}
                    initialValues={initialValues}
                    inputsList={inputList}
                    buttonsList={[
                        {
                            colProps: {
                                size: {
                                    xs: 12,
                                    lg: 3,
                                },
                            },
                            w: isMobile ? "100%" : 200,
                            component: "button",
                            key: "draft",
                            text: i18n.get("transfers.form.save.draft"),
                            icon: "Save",
                            isInactive: values.creditAccount === undefined || draftButtonDisabled,
                            variant: "outline",
                            type: "button",
                            onClick: (e, propsFormik) => {
                                setDraftButtonDisabled(true);
                                onSaveDraft(propsFormik.values);
                            },
                        },
                        {
                            colProps: {
                                size: {
                                    xs: 12,
                                    lg: 3,
                                },
                            },
                            w: isMobile ? "100%" : 200,
                            component: "button",
                            key: "template",
                            text: i18n.get("transfers.form.save.template"),
                            icon: "Save",
                            isInactive: values.creditAccount === undefined,
                            variant: "outline",
                            type: "button",
                            id: "modal-button",
                            onClick: (e, propsFormik) => handleCreateTemplate(propsFormik),
                        },
                        {
                            colProps: {
                                size: {
                                    xs: 12,
                                    lg: 3,
                                },
                            },
                            w: isMobile ? "100%" : 200,
                            component: "button",
                            key: "submit",
                            text: i18n.get("transfers.form.continue"),
                            icon: "ArrowRight",
                            iconVariant: "outline",
                            variant: "solid",
                            type: "submit",
                            extraInactiveCondition: !creditAccountConfirmed || disabledMail,
                            onClick: () => {},
                        },
                    ]}
                    onSubmit={(v) => onSubmit(v)}
                    setFormikProps={setFormikProps}
                    rowSpacingForm={32}
                    validationSchema={Yup.object().shape({
                        debitAccount: Yup.string().required(i18n.get("transfers.validation.required")),
                        creditAccount: Yup.string().required(i18n.get("transfers.validation.required")),
                        transferKind: Yup.string().required(i18n.get("transfers.validation.required")),
                        concept: Yup.string().required(i18n.get("transfers.validation.required")),
                        amount: Yup.string()
                            .required(i18n.get("transfers.validation.required"))
                            .test(
                                "test",
                                i18n.get("transfers.validation.min.amount"),
                                (amount) => parseAmount(amount) > 0,
                            ),
                        email: sendEmail
                            ? Yup.string()
                                  .required(i18n.get("transfers.validation.required"))
                                  .email(i18n.get("transfers.validation.invalid"))
                            : Yup.string(),
                    })}
                />
                <CreateTemplateModal values={values} idActivityTemplate={idActivity} idForm={ID_FORM} />
            </Box>
            <TemplateDrawer
                isVisible={isTemplatesVisible}
                setVisible={setIsTemplatesVisible}
                templates={templates}
                onSelect={handleTemplateSelect}
            />
        </>
    );
}

TransferToContactForm.propTypes = {
    fetching: bool.isRequired,
    fetchingSubmit: bool.isRequired,
    dispatch: func.isRequired,
    onCreateTemplate: func.isRequired,
    isDesktop: bool.isRequired,
    accounts: arrayOf(
        shape({
            idProduct: string.isRequired,
        }),
    ).isRequired,
    limit: number.isRequired,
    limitUsed: number.isRequired,
    summary: shape({}).isRequired,
    concepts: arrayOf(shape({})).isRequired,
    isCVU: bool.isRequired,
    isValidCbu: func.isRequired,
    schedulerButtonVisible: bool.isRequired,
    setSchedulerButtonVisible: func.isRequired,
    isRetailEnvironment: bool.isRequired,
    isTemplatesVisible: bool.isRequired,
    setIsTemplatesVisible: func.isRequired,
    templates: shape({}).isRequired,
    activeEnvironment: shape({
        permissions: shape({
            transferToContact: bool,
        }),
    }).isRequired,
    listFrequentDestinations: shape({}).isRequired,
    idEnvironment: number.isRequired,
};

const mapStateToProps = (state) => ({
    fetching: transferSelectors.getFetching(state),
    fetchingSubmit: transferSelectors.getFetchingSubmit(state),
    accounts: transferSelectors.getAccounts(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    concepts: transferSelectors.getConcepts(state),
    isCVU: transferSelectors.getIsCVU(state),
    summary: transferSelectors.getSummary(state),
    templates: templateSelectors.getTemplateList(state),
    listFrequentDestinations: frequentDestinationSelectors.getListFrequentDestinations(state),
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lang: i18nSelectors.getLang(state),
});

export default withRouter(connect(mapStateToProps)(TransferToContactForm));
