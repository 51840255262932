export const types = {
    SHOW_SNACKBAR: "ecomexIframe/SHOW_SNACKBAR",
    OK_NOT: "ecomexIframe/OK_NOT",
    ENTER_REQUESTS_PRE: "ecomexIframe/ENTER_REQUEST_PRE",
    ENTER_REQUESTS_FAILURE: "ecomexIframe/ENTER_REQUEST_FAILURE",
    ENTER_REQUESTS_SUCCESS: "ecomexIframe/ENTER_REQUEST_SUCCESS",
    REQUEST_HISTORY_PRE: "ecomexIframe/REQUEST_HISTORY_PRE",
    REQUEST_HISTORY_FAILURE: "ecomexIframe/REQUEST_HISTORY_FAILURE",
    REQUEST_HISTORY_SUCCESS: "ecomexIframe/REQUEST_HISTORY_SUCCESS",
    CLEAN_UP: "ecomexIfram/CLEAN_UP",
    FETCH_ID_TRANSACTION_REQUEST: "ecomexIframe/FETCH_ID_TRANSACTION_REQUEST",
    FETCH_ID_TRANSACTION_EMPTY: "ecomexIframe/FETCH_ID_TRANSACTION_EMPTY",
    FETCH_ID_TRANSACTION_SUCCESS: "ecomexIframe/FETCH_ID_TRANSACTION_SUCCESS",
    DOWNLOAD_ECOMEX_TRANSACTION_PDF_REQUEST: "ecomexIframe/DOWNLOAD_ECOMEX_TRANSACTION_PDF_REQUEST",
    DOWNLOAD_ECOMEX_TRANSACTION_PDF_FAILURE: "ecomexIframe/DOWNLOAD_ECOMEX_TRANSACTION_PDF_FAILURE",
    DOWNLOAD_ECOMEX_TRANSACTION_PDF_SUCCESS: "ecomexIframe/DOWNLOAD_ECOMEX_TRANSACTION_PDF_SUCCESS",
};
export const INITIAL_STATE = {};
export default (state = INITIAL_STATE, action = {}) => {
    const { idTransaction, token, payload, error } = action;
    switch (action.type) {
        case types.REQUEST_HISTORY_SUCCESS:
        case types.ENTER_REQUESTS_SUCCESS:
            return {
                ...state,
                token,
                payload,
                fetching: false,
            };
        case types.REQUEST_HISTORY_FAILURE:
        case types.ENTER_REQUESTS_FAILURE:
            return {
                ...state,
                error,
                fetching: false,
            };
        case types.REQUEST_HISTORY_PRE:
        case types.ENTER_REQUESTS_PRE:
            return {
                ...state,
                fetching: true,
            };
        case types.CLEAN_UP:
            return {
                state: INITIAL_STATE,
            };
        case types.FETCH_ID_TRANSACTION_SUCCESS:
            return {
                ...state,
                idTransaction,
            };
        case types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_FAILURE:
            return {
                ...state,
                error,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.FETCH_ID_TRANSACTION_EMPTY:
        case types.FETCH_ID_TRANSACTION_REQUEST:
        case types.SHOW_SNACKBAR:
        case types.OK_NOT:
        default:
            return state;
    }
};

export const actions = {
    show_snackbar: () => ({
        type: types.SHOW_SNACKBAR,
    }),
    loadEnterRequestCredentials: () => ({ type: types.ENTER_REQUESTS_PRE }),
    loadRequestHistory: () => ({ type: types.ENTER_REQUESTS_PRE }),
    cleanUp: () => ({ type: types.CLEAN_UP }),
    fetchTransaction: (token) => ({ type: types.FETCH_ID_TRANSACTION_REQUEST, token }),
    downloadEcomexTransaction: (sequencePDF, requestIdEcomex) => ({
        type: types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_REQUEST,
        sequencePDF,
        requestIdEcomex,
    }),
};

export const selectors = {
    getToken: ({ ecomexIframe }) => ecomexIframe.token,
    getServerDate: ({ ecomexIframe }) => ecomexIframe.serverDate,
    getError: ({ ecomexIframe }) => ecomexIframe.error,
    getFetching: ({ ecomexIframe }) => ecomexIframe.fetching,
    getIdTransaction: ({ ecomexIframe }) => ecomexIframe.idTransaction,
};
