import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import Select from "react-select";
import { compose } from "redux";
import classNames from "classnames";
import { bool, string, number, func, arrayOf, shape, oneOfType, objectOf } from "prop-types";
import * as i18n from "util/i18n";
import { selectors as i18nSelectors } from "reducers/i18n";
import { getInteger, getBoolean } from "util/config";
import { countDecimalPlaces, numberFormat } from "util/number";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import WarningAmount from "pages/_components/fields/WarningAmount";
import withFocus from "pages/_components/withFocus";

export class AmountField extends Component {
    static propTypes = {
        clearable: bool,
        disableSelect: bool,
        hideLabel: bool,
        hideCurrency: bool,
        labelText: string,
        idForm: string,
        name: string.isRequired,
        optional: string,
        isFocused: bool.isRequired,
        lang: string.isRequired,
        maximumDecimals: number.isRequired,
        minimumDecimals: number.isRequired,
        toggleIsFocused: func.isRequired,
        onCurrencyChange: func.isRequired,
        onInputChange: func.isRequired,
        onBlur: func.isRequired,
        size: string,
        currency: string,
        amount: oneOfType([string, number]),
        maxLength: string,
        data: shape({
            options: arrayOf(
                shape({
                    id: number.isRequired,
                    label: string.isRequired,
                }),
            ),
        }),
        field: shape({}),
        amountClassName: string,
        currencyClassName: string,
        noStyleToInput: bool,
        isDisabled: bool,
        form: shape({
            errors: objectOf(oneOfType([string, objectOf(string)])),
            touched: objectOf(oneOfType([arrayOf(bool), bool, objectOf(bool)])),
        }).isRequired,
        inputGroupClassName: string,
        idWarningText: string,
        formGroupClassName: string,
        maximumDecimalsCustom: number,
        minimumDecimalsCustom: number,
        fixedDecimalScale: bool,
        showWarn: bool,
        isDesktop: bool,
        onPressWarn: func,
        setHasError: func,
        hasError: bool,
        autocomplete: string,
        errorClassName: string,
        controlLabelClassName: string,
        isConfigText: bool,
        handleKeyDown: func,
        useCustomHandleKeyDown: func,
    };

    static defaultProps = {
        clearable: true,
        disableSelect: false,
        hideLabel: false,
        hideCurrency: false,
        labelText: null,
        idForm: "",
        size: "",
        optional: "",
        amount: "",
        currency: "",
        maxLength: "20",
        data: {},
        field: {},
        amountClassName: "",
        currencyClassName: "",
        noStyleToInput: false,
        isDisabled: false,
        inputGroupClassName: "",
        formGroupClassName: "",
        idWarningText: "",
        errorClassName: "",
        controlLabelClassName: "",
        maximumDecimalsCustom: null,
        minimumDecimalsCustom: null,
        fixedDecimalScale: false,
        showWarn: false,
        isDesktop: false,
        hasError: false,
        autocomplete: "off",
        isConfigText: false,
        onPressWarn: () => {},
        setHasError: () => {},
        handleKeyDown: () => {},
        useCustomHandleKeyDown: false,
    };

    componentDidUpdate() {
        const { setHasError, hasError } = this.props;
        setHasError(!!hasError);
    }

    renderLabel = () => {
        const { idForm, name, labelText, optional, isDisabled, controlLabelClassName } = this.props;
        const id = `${idForm}.${name}`;
        if (labelText !== null) {
            return <FieldLabel labelText={labelText} optional={optional} idField={id} isDisabled={isDisabled} controlLabelClassName={controlLabelClassName} />;
        }
        return (
            <FieldLabel labelKey={`${idForm}.${name}.label`} optional={optional} idField={id} isDisabled={isDisabled} controlLabelClassName={controlLabelClassName} />
        );
    };

    customHandleKeyDown = (e) => {
        const { lang } = this.props;
        const { decimalSeparator } = numberFormat(lang);
        const { value, selectionStart, selectionEnd } = e.target;
        const integerPart = value.split(decimalSeparator)[0].replace(/\D/g, "");
        const extendedAmountEnabled = getBoolean("increase.size.amount.link.iso8583", false);
        const maxSize = extendedAmountEnabled ? 15 : 10;

        // Array de teclas permitidas para movimiento, navegación y selección
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Home",
            "End",
            "Control",
            "Meta",
            "Shift",
        ];

        // Permitir combinaciones con teclas de control (Ctrl, Cmd, etc.)
        if (e.ctrlKey || e.metaKey || e.shiftKey) {
            return; // Permitir combinaciones de teclas como Ctrl+A, Ctrl+C, etc.
        }

        const isAllSelected = selectionStart === 0 && selectionEnd === value.length;

        // Detectar si el cursor está en la parte decimal
        const decimalIndex = value.indexOf(decimalSeparator);
        // Permitir el ingreso del separador decimal cuando se alcanza el número máximo de dígitos
        if (
            (e.key === decimalSeparator || e.key === ".") &&
            (selectionStart <= decimalIndex || decimalIndex === -1) &&
            integerPart.length >= maxSize
        ) {
            return; // Permitir el ingreso del separador decimal
        }

        if (isAllSelected) {
            return; // Permitir el reemplazo al seleccionar todo el texto
        }

        // Si el cursor está en la parte entera y el número ya tiene 10 dígitos
        if (
            integerPart.length >= maxSize &&
            (selectionStart <= decimalIndex || decimalIndex === -1) &&
            !allowedKeys.includes(e.key)
        ) {
            e.preventDefault(); // Bloquea la entrada de más dígitos en la parte entera si se excede el límite
        }
    };

    handlerOnPaste = (e) => {
        const { lang, useCustomHandleKeyDown, maxLength } = this.props;
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const pastedValue = e.clipboardData.getData("Text");
        const integerPart = pastedValue
            .split(decimalSeparator)[0]
            .replace(/\D/g, "")
            .replaceAll(thousandSeparator, "");

        const extendedAmountEnabled = getBoolean("increase.size.amount.link.iso8583", false);
        const maxSize = extendedAmountEnabled ? 15 : 10;

        if (
            (useCustomHandleKeyDown && integerPart.length > maxSize) ||
            (!useCustomHandleKeyDown && pastedValue.length > maxLength)
        ) {
            e.preventDefault(); // Bloquea el pegado si excede maxLength
        }
    };

    render() {
        /* eslint-disable react/prop-types */
        const {
            field,
            idForm,
            clearable,
            data,
            disableSelect,
            hideLabel,
            isFocused,
            lang,
            maximumDecimals,
            minimumDecimals,
            toggleIsFocused,
            hideCurrency,
            size,
            onCurrencyChange,
            onInputChange,
            currency,
            amount,
            maxLength,
            onBlur,
            amountClassName,
            currencyClassName,
            style,
            fixedDecimalScale,
            noStyleToInput,
            form,
            disabled,
            isDisabled,
            plainValue,
            inputGroupClassName,
            formGroupClassName,
            maximumDecimalsCustom,
            minimumDecimalsCustom,
            idWarningText,
            showWarn,
            isDesktop,
            onPressWarn,
            autocomplete,
            isConfigText,
            errorClassName,
            handleKeyDown,
            useCustomHandleKeyDown,
        } = this.props;
        /* eslint-enable react/prop-types */

        let options;
        if (!hideCurrency) {
            ({ options } = data);
        }
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const decimalPlaces = this.amountRef ? countDecimalPlaces(this.amountRef.value, decimalSeparator) : 0;
        const maximumDecimalsToUse = maximumDecimalsCustom || maximumDecimals;
        const minimumDecimalsToUse = minimumDecimalsCustom || minimumDecimals;
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimalsToUse), minimumDecimalsToUse);
        const idField = `${idForm}.${field.name}`;
        const errorAmount =
            errors[field.name] && Object.prototype.hasOwnProperty.call(errors[field.name], "amount")
                ? errors[field.name].amount
                : errors[field.name];

        const inputClassName = noStyleToInput ? "" : amountClassName;

        const amountValue = plainValue ? field.value : amount;

        return (
            <div
                className={classNames("form-group", "form-group--composite", formGroupClassName, {
                    "has-error": hasError,
                    "has-focus": isFocused,
                    "form-group--small": size === "small",
                })}
                style={style}
                onFocus={toggleIsFocused}>
                {!hideLabel && this.renderLabel()}
                <div
                    className={classNames("input-group ", inputGroupClassName, isDisabled && "input-group-disable", {
                        "w-90": showWarn && !isDesktop,
                    })}>
                    {!hideCurrency && options.length === 1 ? (
                        // is-disabled
                        <span
                            className={classNames(
                                "currency ",
                                inputGroupClassName,
                                currencyClassName,
                                isDisabled && "is-disabled",
                            )}>
                            {options[0].label}
                        </span>
                    ) : (
                        !hideCurrency && (
                            <Select
                                clearValueText={i18n.get(`currency.selector.value.text`)}
                                placeholder={i18n.get(`currency.selector.placeholder`)}
                                clearable={clearable}
                                className={classNames(
                                    "currency-selector slideFromBottom flex-container ",
                                    currencyClassName,
                                )}
                                disabled={disableSelect}
                                onChange={onCurrencyChange}
                                options={options.map(({ id, label }) => ({ value: id, label }))}
                                searchable={false}
                                value={currency}
                                onBlur={() => {
                                    toggleIsFocused();
                                    onBlur();
                                }}
                                optionClassName="needsclick"
                            />
                        )
                    )}
                    <NumberFormat
                        name={field.name}
                        id={idField}
                        disabled={disabled}
                        allowNegative={false}
                        className={`form-control ${inputClassName}`}
                        decimalScale={decimalScale}
                        decimalSeparator={decimalSeparator}
                        maxLength={useCustomHandleKeyDown ? undefined : maxLength}
                        onChange={onInputChange}
                        thousandSeparator={thousandSeparator}
                        type="text"
                        inputmode="numeric"
                        onBlur={(e) => {
                            toggleIsFocused();
                            onBlur(e);
                        }}
                        value={amountValue}
                        fixedDecimalScale={fixedDecimalScale}
                        autocomplete={autocomplete}
                        onKeyDown={useCustomHandleKeyDown ? this.customHandleKeyDown : handleKeyDown}
                        onPaste={this.handlerOnPaste}
                    />
                </div>
                {hasError && <FieldError error={errorAmount} errorClassName={errorClassName} />}
                {showWarn && (
                    <WarningAmount isConfigText={isConfigText} idText={idWarningText} onPressWarn={onPressWarn} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
    maximumDecimals: getInteger("defaultDecimal.maximum"),
    minimumDecimals: getInteger("defaultDecimal.minimum"),
});

export default compose(connect(mapStateToProps), withFocus)(AmountField);
