import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, arrayOf, bool, func, number } from "prop-types";
import I18n from "pages/_components/I18n";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Image from "pages/_components/Image";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import { Field } from "formik";
import AmountField from "pages/_components/fields/formik/AmountField";
import * as i18n from "util/i18n";
import Heading from "pages/_components/Heading";
import { Col } from "react-bootstrap";
import { selectors as accountsSelectors } from "reducers/accounts";
import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";

const ARS_CURRENCY = {
    code: "0",
    name: "ARS",
};

const USD_CURRENCY = {
    code: "2",
    name: "USD",
};
class SelectedReceivers extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        cbuList: arrayOf(
            shape({
                email: string.isRequired,
                cbu: string.isRequired,
                document: string.isRequired,
                headline: string.isRequired,
                reference: string.isRequired,
                idFrequentDestination: bool.isRequired,
                key: number.isRequired,
                isOwn: string.isRequired,
                comprobante: number.isRequired,
                schedule: bool.isRequired,
            }),
        ).isRequired,
        accountSelected: shape({}).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        accountsDebin: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        values: shape({}).isRequired,
        setValues: func.isRequired,
        hasToAddBeneficiary: func,
        updateCbuList: func.isRequired,
        setErrorLimits: func.isRequired,
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        hasToAddBeneficiary: null,
    };

    state = {
        focusInput: false,
    };

    deleteCbu = (cbu) => {
        const { cbuList, dispatch, values, setValues } = this.props;

        // eslint-disable-next-line no-use-before-define
        delete values[`amount${cbu}`];
        setValues({ ...values });

        const list = cbuList.filter((object) => object.cbu !== cbu);
        dispatch(debinActions.setCbuSelected(list));
    };

    setAmount = (value, cbu) => {
        const { cbuList, accounts, accountSelected, updateCbuList } = this.props;
        const accountCurrency = accounts.find((account) => account.idProduct === accountSelected)?.currency;

        let accountInfo = cbuList.find((object) => object.cbu === cbu);
        accountInfo = { ...accountInfo, amount: { quantity: value, amount: value, currency: accountCurrency } };
        updateCbuList(accountInfo);
    };

    setComprobante = (value, cbu) => {
        const { cbuList, updateCbuList } = this.props;
        let accountInfo = cbuList.find((object) => object.cbu === cbu);
        accountInfo = { ...accountInfo, comprobante: value };
        updateCbuList(accountInfo);
    };

    checkForCurrency = (accountCurrency) => {
        const { cbuList, dispatch } = this.props;
        let update = false;
        const receivers = [...cbuList];
        cbuList.forEach((object, index) => {
            if (object.amount && object.amount?.currency !== accountCurrency) {
                receivers[index] = { ...object, amount: { ...object.amount, currency: accountCurrency } };
                update = true;
            }
        });
        if (update) {
            dispatch(debinActions.setCbuSelected(receivers));
        }
    };

    tableBody = (accountCurrency) => {
        const { cbuList, isDesktop, hasToAddBeneficiary } = this.props;
        const { focusInput } = this.state;

        if (!cbuList) {
            return "";
        }

        this.checkForCurrency(accountCurrency);

        return cbuList.map((account) => {
            const currencyName = accountCurrency === ARS_CURRENCY.code ? ARS_CURRENCY.name : USD_CURRENCY.name;

            if (isDesktop) {
                return (
                    <Container rowClassName="align-items-center" key={account.cbu}>
                        <Col xs={2}>
                            <span className="break-word">{account.headline || account.reference}</span>
                        </Col>
                        <Col xs={3} className="text-align-center">
                            <span>{account.document.toUpperCase()}</span>
                        </Col>
                        <Col xs={4}>
                            <Field
                                autocomplete="off"
                                hideLabel
                                component={AmountField}
                                name={`amount${account.cbu}`}
                                data={{
                                    options: [{ id: 0, label: i18n.get(`currency.label.${currencyName}`) }],
                                }}
                                key={account.cbu}
                                clearable={false}
                                label="transfers.amount.label"
                                maxLength={15}
                                amountClassName="font-size-15-px text-align-right"
                                onCustomOnChange={(e) => this.setAmount(e, account.cbu)}
                                amount={account?.amount?.amount}
                                currency={accountCurrency}
                                style={{ marginTop: 0, marginBottom: 0 }}
                                fixedDecimalScale
                                useCustomHandleKeyDown
                                {...(hasToAddBeneficiary && {
                                    onBlur: ({ target }) =>
                                        hasToAddBeneficiary(
                                            Number(target.value.replaceAll(".", "").replace(",", ".")),
                                            accountCurrency,
                                            account.cbu,
                                        ),
                                })}
                            />
                        </Col>
                        <Col xs={3}>
                            <Field
                                hideLabel
                                hidePlaceholder
                                component={TextField}
                                maxLength={20}
                                pattern="\d*"
                                formGroupClassName={`mt-0 mb-0 form-group--composite ${focusInput ? "has-focus" : ""}`}
                                className="font-size-15-px"
                                value={account.comprobante >= 0 ? account.comprobante : ""}
                                onCustomChange={(value) => this.setComprobante(value, account.cbu)}
                            />
                        </Col>
                        <button
                            className="btn-link receiver-button"
                            type="button"
                            onClick={() => this.deleteCbu(account.cbu)}>
                            <Image src="images/cross-mobile-blue.svg" styles={{ width: "25px", height: "25px" }} />
                        </button>
                    </Container>
                );
            }

            return (
                <div className="container-white my-2 py-2" key={account.cbu}>
                    <div className="d-flex space-between pl-3 pr-2 p-relative">
                        <div>
                            <Heading.DataGroup
                                label="transferSummary.owner.label"
                                data={account.headline || account.reference}
                                containerClassName="transfer-data flex-start data-wrapper-flex"
                                labelClassName="mb-0 text-uppercase mr-25"
                            />
                            <Heading.DataGroup
                                label="transferSummary.cuit.label"
                                data={account.document.toUpperCase()}
                                containerClassName="transfer-data flex-start data-wrapper-flex mb-0"
                                labelClassName="mb-0 mr-25"
                            />
                        </div>
                        <button
                            className="btn-link delete-addressee"
                            type="button"
                            onClick={() => this.deleteCbu(account.cbu)}>
                            <Image src="images/cross-mobile-blue.svg" styles={{ width: "25px", height: "25px" }} />
                        </button>
                    </div>
                    <div className="d-flex px-0 space-between">
                        <Col xs={6} className="pr-2">
                            <Field
                                autocomplete="off"
                                component={AmountField}
                                data={{
                                    options: [{ id: 0, label: i18n.get(`currency.label.${currencyName}`) }],
                                }}
                                name={`amount${account.cbu}`}
                                key={account.cbu}
                                clearable={false}
                                labelText={i18n.get("transaction.amount.label")}
                                label="transaction.amount.label"
                                maxLength={15}
                                amountClassName="font-size-15-px text-align-right"
                                onCustomOnChange={(e) => this.setAmount(e, account.cbu)}
                                amount={account?.amount?.amount}
                                currency={accountCurrency}
                                fixedDecimalScale
                                useCustomHandleKeyDown
                                {...(hasToAddBeneficiary && {
                                    onBlur: ({ target }) =>
                                        hasToAddBeneficiary(
                                            Number(target.value.replaceAll(".", "").replace(",", ".")),
                                            accountCurrency,
                                            account.cbu,
                                        ),
                                })}
                            />
                        </Col>
                        <Col xs={6} className="pl-2">
                            <Field
                                hidePlaceholder
                                component={TextField}
                                maxLength={20}
                                pattern="\d*"
                                inputmode="numeric"
                                labelText="accounts.movement.detail.voucher"
                                formGroupClassName={`mt-0 mb-0 form-group--composite ${focusInput ? "has-focus" : ""}`}
                                className="font-size-15-px"
                                value={account.comprobante >= 0 ? account.comprobante : ""}
                                onCustomChange={(value) => this.setComprobante(value, account.cbu)}
                            />
                        </Col>
                    </div>
                </div>
            );
        });
    };

    render() {
        const { cbuList, isDesktop, accountSelected, accounts, accountsDebin, setErrorLimits } = this.props;
        let accountCurrency = accounts.find((account) => account.idProduct === accountSelected)?.currency;

        if (!accountCurrency) {
            accountCurrency = accountsDebin.find((account) => account.idProduct === accountSelected)?.currency;
        }

        if (isDesktop && cbuList) {
            return (
                <div className="receiver-table">
                    <Container className="receiver-header" rowClassName="justify-content-start align-items-center">
                        <Col xs={2}>
                            <I18n id="transferSummary.owner.label" />
                        </Col>
                        <Col xs={3} className="text-align-center">
                            <I18n id="transferSummary.cuit.label" />
                        </Col>
                        <Col xs={4}>
                            <I18n id="transaction.amount.label" />
                        </Col>
                        <Col xs={3}>
                            <I18n id="accounts.movement.detail.voucher" />
                        </Col>
                    </Container>
                    {this.tableBody(accountCurrency)}
                    <SelectedAccountsAmount
                        currency={accountCurrency}
                        list={cbuList}
                        setErrorLimits={setErrorLimits}
                        withGrid
                    />
                </div>
            );
        }

        return (
            <>
                {this.tableBody(accountCurrency)}
                <Col xs={12}>
                    <SelectedAccountsAmount
                        currency={accountCurrency}
                        list={cbuList}
                        setErrorLimits={setErrorLimits}
                        withoutTables
                    />
                </Col>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    cbuList: debinSelectors.getListCbu(state),
    accountsDebin: debinSelectors.getAccounts(state),
});

export default connect(mapStateToProps)(SelectedReceivers);
