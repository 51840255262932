/* eslint-disable import/no-unresolved */
import React from "react";
import TransferExternalDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/TransferExternalDataTable";
import TransferInternalDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/TransferInternalDataTable";
import AccountOpeningDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AccountOpeningDataTable";
import RequestCheckbookDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/RequestCheckbookDataTable";
import EcheqCesionDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/EcheqCesionDataTable";
import EcheqEndorseDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/EcheqEndorseDataTable";
import EcheqEmisionDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/EcheqEmisionDataTable";
import EcheqMassiveEmisionDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/EcheqMassiveEmisionDataTable";
import EcheqNegotiateDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/EcheqNegotiateDataTable";
import EcheqAcceptReturnDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/EcheqAcceptReturnDataTable";
import AdminGroupsDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminGroupsDataTable";
import AdminUsersDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminUsersDataTable";
import AdminSignaturesDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminSignaturesDataTable";
import AdminUserInviteDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminUserInviteDataTable";
import AdminRestrictionManageDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminRestrictionManageDataTable";
import AdminRestrictionDeleteDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminRestrictionDeleteDataTable";
import AdminUserSignatureDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminUserSignatureDataTable";
import AdminUserChannelsDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminUserChannelsDataTable";
import AdminUserGroupsDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/AdminUserGroupsDataTable";
import CancelTransactionDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/CancelTransactionDataTable";
import PaymentAFIPDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/PaymentAFIPDataTable";
import DeletePaymentAFIPDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/DeletePaymentAFIPDataTable";
import PaymentServiceDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/PaymentServiceDataTable";
import CredinDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/CredinDataTable";
import IncomingDebinDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/IncomingDebinDataTable";
import ThirdPaymentDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/ThirdPaymentDataTable";
import RecurrencyDebinDataTable from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBodies/RecurrencyDebinDataTable";
import TransferExternalInternalDataTable from "./MultipleSignaturesBodies/TransferExternalInternalDataTable";
import TransferMultipleDataTable from "./MultipleSignaturesBodies/TransferMultipleDataTable";

const MultipleSignaturesBody = {
    "transfers.external.internal": (isDesktop) => <TransferExternalInternalDataTable isDesktop={isDesktop} />,
    "transfers.internal.send": (isDesktop) => <TransferInternalDataTable isDesktop={isDesktop} />,
    "transfers.internal.tad.send": (isDesktop) => <TransferInternalDataTable isDesktop={isDesktop} />,
    "transfers.thirdParties.send": (isDesktop) => <TransferExternalDataTable isDesktop={isDesktop} />,
    "transfers.thirdParties.tad.send": (isDesktop) => <TransferExternalDataTable isDesktop={isDesktop} />,
    "transfer.multiple.send": (isDesktop) => <TransferMultipleDataTable isDesktop={isDesktop} />,
    "accounts.opening": (isDesktop) => <AccountOpeningDataTable isDesktop={isDesktop} />,
    "account.checks": (isDesktop) => <RequestCheckbookDataTable isDesktop={isDesktop} />,
    "cesion.echeq.activity": (isDesktop) => <EcheqCesionDataTable isDesktop={isDesktop} />,
    "endorse.echeq.activity": (isDesktop) => <EcheqEndorseDataTable isDesktop={isDesktop} />,
    "emit.echeq.activity": (isDesktop) => <EcheqEmisionDataTable isDesktop={isDesktop} />,
    "emit.massive.echeqs.send": (isDesktop) => <EcheqMassiveEmisionDataTable isDesktop={isDesktop} />,
    "negotiate.echeq.activity": (isDesktop) => <EcheqNegotiateDataTable isDesktop={isDesktop} />,
    "accept.return.echeqs": (isDesktop) => <EcheqAcceptReturnDataTable isDesktop={isDesktop} />,
    "scheduled.transactions.cancel": (isDesktop) => <CancelTransactionDataTable isDesktop={isDesktop} />,
    "delete.paymentAFIP": (isDesktop) => <DeletePaymentAFIPDataTable isDesktop={isDesktop} />,
    "pay.paymentAFIP": (isDesktop) => <PaymentAFIPDataTable isDesktop={isDesktop} />,
    "payments.makePayment": (isDesktop) => <PaymentServiceDataTable isDesktop={isDesktop} />,
    "credin.sentRequest.send": (isDesktop) => <CredinDataTable isDesktop={isDesktop} />,
    "credinCVU.sentRequest.send": (isDesktop) => <CredinDataTable isDesktop={isDesktop} />,
    "debin.incoming.accept.send": (isDesktop) => <IncomingDebinDataTable isDesktop={isDesktop} />,
    "debinCVU.incoming.accept.send": (isDesktop) => <IncomingDebinDataTable isDesktop={isDesktop} />,
    "load.debin.recurrency": (isDesktop) => <RecurrencyDebinDataTable isDesktop={isDesktop} />,
    "change.status.debin.recurrency": (isDesktop) => <RecurrencyDebinDataTable isDesktop={isDesktop} showAction />,
    "pay.multiline.send": (isDesktop) => <ThirdPaymentDataTable isDesktop={isDesktop} />,
    /** ADMIN ACTIVITIES* */
    "administration.advanced.group.create.send": (isDesktop) => <AdminGroupsDataTable isDesktop={isDesktop} />,
    "administration.advanced.group.modify.send": (isDesktop) => <AdminGroupsDataTable isDesktop={isDesktop} />,
    "administration.groups.blockunblock.send": (isDesktop) => <AdminGroupsDataTable isDesktop={isDesktop} />,
    "administration.groups.delete.send": (isDesktop) => <AdminGroupsDataTable isDesktop={isDesktop} />,
    "administration.users.blockunblock.send": (isDesktop) => <AdminUsersDataTable isDesktop={isDesktop} />,
    "administration.users.delete.send": (isDesktop) => <AdminUsersDataTable isDesktop={isDesktop} />,
    "administration.users.update": (isDesktop) => <AdminUsersDataTable isDesktop={isDesktop} />,
    "administration.signatures.create.send": (isDesktop) => <AdminSignaturesDataTable isDesktop={isDesktop} />,
    "administration.signatures.delete.send": (isDesktop) => <AdminSignaturesDataTable isDesktop={isDesktop} />,
    "administration.signatures.modify.send": (isDesktop) => <AdminSignaturesDataTable isDesktop={isDesktop} />,
    "administration.users.invite.send": (isDesktop) => <AdminUserInviteDataTable isDesktop={isDesktop} />,
    "administration.restrictions.manage.send": (isDesktop) => <AdminRestrictionManageDataTable isDesktop={isDesktop} />,
    "administration.restrictions.user.delete.send": (isDesktop) => (
        <AdminRestrictionDeleteDataTable isDesktop={isDesktop} />
    ),
    "administration.medium.modify.signature.send": (isDesktop) => <AdminUserSignatureDataTable isDesktop={isDesktop} />,
    "administration.modify.permissions.send": (isDesktop) => <AdminUsersDataTable isDesktop={isDesktop} />,
    "administration.medium.modify.channels.send": (isDesktop) => <AdminUserChannelsDataTable isDesktop={isDesktop} />,
    "administration.user.detail.groups.modify.send": (isDesktop) => <AdminUserGroupsDataTable isDesktop={isDesktop} />,
};

export default MultipleSignaturesBody;
