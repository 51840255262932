import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import MainContainer from "pages/_components/MainContainer";
import Step0Content from "pages/recoveryUserpass/_components/Step0Content";
import Head from "pages/_components/Head";
import { bool, func, shape } from "prop-types";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import I18n from "pages/_components/I18n";
import * as configUtils from "util/config";

class RecoveryUserpassStep0 extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => (
        <div className="w-min-content">
            <Logo className="svg-image logo logo-mobile-size" logoColor />
        </div>
    );

    render() {
        const { isDesktop, isRecoveryPassAndUserpass, propsGrid } = this.props;

        return (
            <GridLayoutExternal scopeToShow="recoveryUserpass" isDesktop={isDesktop} {...propsGrid}>
                <div className="admin-detail-head px-0 z-index-auto">
                    <Container>
                        <Col xs="12" className={isDesktop ? "px-0" : ""}>
                            <Head
                                title={isDesktop && "recoveryUserpass.step1.header"}
                                onClose={this.onHeaderClose}
                                headerClassName={
                                    !isDesktop
                                        ? "login-background-color-white"
                                        : "recover-pass-desktop login-header-desktop"
                                }
                                centerElement={!isDesktop && this.renderTitleMobile}
                                hideMobileMenu={!isDesktop && true}
                                fixed
                                tag="h4"
                                navbarClassName={!isDesktop && "px-0"}
                            />
                        </Col>
                    </Container>
                </div>
                <MainContainer
                    className={classNames(
                        "login__layout main-container",
                        { "container-fluid": isDesktop },
                        { "px-3": !isDesktop },
                    )}
                    viewPageClassName="has-blackout-selector">
                    {!isDesktop && configUtils.getBoolean("mobile.recovercredentials.enabled", false) && (
                        <Container className="container--layout flex-grow">
                            <Col className="col col-12 view-title px-0">
                                <div className="justify-content-center">
                                    <I18n
                                        id="recoveryUserpass.step1.header"
                                        component="h2"
                                        componentProps={{ className: "f-size-5 font-regular mt-0" }}
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                    <Step0Content {...this.props} isRecoveryPassAndUserpass={isRecoveryPassAndUserpass} />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

RecoveryUserpassStep0.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool,
    isRecoveryPassAndUserpass: bool,
    propsGrid: shape({}).isRequired,
};

RecoveryUserpassStep0.defaultProps = {
    isDesktop: true,
    isRecoveryPassAndUserpass: false,
};

const mapStateToProps = (state) => ({
    userName: loginSelectors.getUsername(state),
    isRecoveryPassAndUserpass: recoveryPasswordSelectors.getRecoveryPassAndUserpass(state),
    document: recoveryPasswordSelectors.getDocument(state),
    documentType: recoveryPasswordSelectors.getDocumentType(state),
    showCaptcha: recoveryPasswordSelectors.getShowCaptcha(state),
    resetCaptcha: recoveryPasswordSelectors.getResetCaptcha(state),
});

export default connect(mapStateToProps)(RecoveryUserpassStep0);
