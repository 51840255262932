import { globalTypes } from "reducers/types/global";

export const types = {
    CREATE_THIRDPAYMENT_REQUEST: "thirdPayment/CREATE_THIRDPAYMENT_REQUEST",
    CREATE_THIRDPAYMENT_SUCCESS: "thirdPayment/CREATE_THIRDPAYMENT_SUCCESS",
    CREATE_THIRDPAYMENT_FAILURE: "thirdPayment/CREATE_THIRDPAYMENT_FAILURE",
    SUMMARY_THIRDPAYMENT_REQUEST: "thirdPayment/SUMMARY_THIRDPAYMENT_REQUEST",
    SUMMARY_THIRDPAYMENT_SUCCESS: "thirdPayment/SUMMARY_THIRDPAYMENT_SUCCESS",
    LOAD_LIST_REQUEST: "thirdPayment/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "thirdPayment/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "thirdPayment/LOAD_LIST_SUCCESS",
    LOAD_MORE_LIST_REQUEST: "thirdPayment/LOAD_MORE_LIST_REQUEST",
    LOAD_MORE_LIST_FAILURE: "thirdPayment/LOAD_MORE_LIST_FAILURE",
    LOAD_MORE_LIST_SUCCESS: "thirdPayment/LOAD_MORE_LIST_SUCCESS",
    LOAD_THIRDPAYMENT: "thirdPayment/LOAD_THIRDPAYMENT",
    LOAD_TRANSACTION_REQUEST: "thirdPayment/LOAD_TRANSACTION_REQUEST",
    LOAD_TRANSACTION_SUCCESS: "thirdPayment/LOAD_TRANSACTION_SUCCESS",
    LOAD_TRANSACTION_FAILURE: "thirdPayment/LOAD_TRANSACTION_FAILURE",
    CLEAR_DATA: "thirdPayment/CLEAR_DATA",
    CLEAR_SUMMARY: "thirdPayment/CLEAR_SUMMARY",
    SET_TYPEH: "thirdPayment/SET_TYPEH",
    SET_TYPESU: "thirdPayment/SET_TYPESU",
    DOWNLOAD_TRANSACTION_LINE_REQUEST: "thirdPayment/DOWNLOAD_TRANSACTION_LINE_REQUEST",
    DOWNLOAD_TRANSACTION_LINE_FAILURE: "thirdPayment/DOWNLOAD_TRANSACTION_LINE_FAILURE",
    DOWNLOAD_TRANSACTION_LINE_SUCCESS: "thirdPayment/DOWNLOAD_TRANSACTION_LINE_SUCCESS",
    DOWNLOAD_LIST_REQUEST: "thirdPayment/DOWNLOAD_LIST_REQUEST",
    DOWNLOAD_LIST_FAILURE: "thirdPayment/DOWNLOAD_LIST_FAILURE",
    DOWNLOAD_LIST_SUCCESS: "thirdPayment/DOWNLOAD_LIST_SUCCESS",

    DOWNLOAD_TRANSACTION_FILE_REQUEST: "thirdPayment/DOWNLOAD_TRANSACTION_FILE_REQUEST",
    DOWNLOAD_TRANSACTION_FILE_FAILURE: "thirdPayment/DOWNLOAD_TRANSACTION_FILE_FAILURE",
    DOWNLOAD_TRANSACTION_FILE_SUCCESS: "thirdPayment/DOWNLOAD_TRANSACTION_FILE_SUCCESS",
    DOWNLOAD_CONFIRMATION_REQUEST: "thirdPayment/DOWNLOAD_CONFIRMATION_REQUEST",
    DOWNLOAD_CONFIRMATION_FAILURE: "thirdPayment/DOWNLOAD_CONFIRMATION_FAILURE",
    DOWNLOAD_CONFIRMATION_SUCCESS: "thirdPayment/DOWNLOAD_CONFIRMATION_SUCCESS",
    SET_PAGE_TO_GO_BACK: "thirdPayment/SET_PAGE_TO_GO_BACK",
    SET_REMEMBER_FORM_DATA: "thirdPayment/SET_REMEMBER_FORM_DATA",
    SET_NOT_REMEMBER_FORM_DATA: "thirdPayment/SET_NOT_REMEMBER_FORM_DATA",
    LOAD_FILTERS_BACK: "thirdPayment/LOAD_FILTERS_BACK",
};

export const INITIAL_STATE = {
    fetching: false,
    transactionLinesStatus: [],
    dateFrom: null,
    dateTo: null,
    amountFrom: null,
    typeSelect: true,
    amountTo: null,
    status: "",
    idTransaction: "",
    fetchingDownload: false,
    summary: {
        typePayment: "",
        account: "",
        description: "",
        currency: 0,
        amount: null,
        file: null,
        dateProcess: null,
        signatures: null,
        pendingDispatch: null,
        idTransactionStatus: "",
        modificationDateTime: null,
        userEditorFullName: "",
        creatorFullName: "",
        creationDate: null,
    },
    isFirstFetching: true,
    pageToGoBack: null,
    rememberFormData: false,
    control: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        filters = {},
        otp,
        summary,
        signatures,
        pendingDispatch,
        idTransactionStatus,
        modificationDateTime,
        userEditorFullName,
        creatorFullName,
        creationDate,
        transactionLinesStatus,
        idTransaction,
        transaction,
        file,
        typePayment,
        dateProcess,
        reference,
        debitAccount,
        cuit,
        totalAmount,
        latestTotalPages,
        latestPageNumber,
        transactionOrder,
        pageToGoBack,
        filtersBack,
        typeSelect,
        amount,
        control,
    } = action;
    switch (action.type) {
        case types.CREATE_THIRDPAYMENT_REQUEST:
            return {
                ...state,
                fetching: true,
                summary,
            };
        case types.CLEAR_DATA:
            return {
                ...state,
                ...INITIAL_STATE,
                summary: {},
            };
        case types.CLEAR_SUMMARY:
            return {
                ...state,
                summary: INITIAL_STATE.summary,
            };
        case types.SET_TYPEH:
            return {
                ...state,
                typeSelect: false,
                transactionLinesStatus: [],
            };
        case types.SET_TYPESU:
            return {
                ...state,
                typeSelect: true,
                transactionLinesStatus: [],
            };
        case types.CREATE_THIRDPAYMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: {
                    ...state.summary,
                    signatures,
                    pendingDispatch,
                    idTransactionStatus,
                    modificationDateTime,
                    userEditorFullName,
                    creatorFullName,
                    creationDate,
                },
                control,
            };

        case types.CREATE_THIRDPAYMENT_FAILURE:
            return {
                ...state,
                fetching: false,
                summary,
            };
        case types.SUMMARY_THIRDPAYMENT_REQUEST:
            return {
                ...state,
                fetching: true,
                summary,
                otp,
            };

        case types.SUMMARY_THIRDPAYMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: { ...state.summary },
                control,
            };
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                ...filters,
                fetching: true,
                isFirstFetching: filters.isFirstFetching,
            };
        case types.LOAD_LIST_FAILURE:
            return {
                fetching: false,
                isFirstFetching: false,
            };
        case types.LOAD_LIST_SUCCESS:
            return {
                ...state,
                typeSelect,
                transactionLinesStatus,
                latestTotalPages,
                latestPageNumber,
                fetching: false,
                isFirstFetching: false,
            };

        case types.LOAD_MORE_LIST_REQUEST:
            return {
                ...state,
                ...filters,
                fetching: true,
            };
        case types.LOAD_MORE_LIST_FAILURE:
            return {
                fetching: false,
            };
        case types.LOAD_MORE_LIST_SUCCESS:
            return {
                ...state,
                transactionLinesStatus: state.transactionLinesStatus.concat(transactionLinesStatus),
                latestTotalPages,
                latestPageNumber,
                fetching: false,
            };

        case types.LOAD_THIRDPAYMENT:
            return {
                ...state,
                summary,
            };
        case types.LOAD_THIRDPAYMENT_REQUEST:
            return {
                ...state,
                fetching: true,
                transaction,
            };
        case types.LOAD_THIRDPAYMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: { ...state.summary },
            };
        case types.LOAD_TRANSACTION_REQUEST:
            return {
                ...state,
                idTransaction,
                fetching: true,
            };
        case types.LOAD_TRANSACTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                transaction: {
                    file,
                    typePayment,
                    dateProcess,
                    reference,
                    debitAccount,
                    cuit,
                    idTransactionStatus,
                    totalAmount,
                    transactionOrder,
                    amount,
                },
            };
        case types.LOAD_FILTERS_BACK:
            return {
                filtersBack,
            };
        case types.LOAD_TRANSACTION_FAILURE:
            return {
                ...state,
                fetching: true,
            };
        case types.DOWNLOAD_TRANSACTION_LINE_REQUEST:
            return {
                ...state,
            };
        case types.DOWNLOAD_TRANSACTION_LINE_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_TRANSACTION_LINE_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_TRANSACTION_FILE_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_TRANSACTION_FILE_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_TRANSACTION_FILE_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_LIST_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LIST_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_LIST_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_CONFIRMATION_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_CONFIRMATION_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_CONFIRMATION_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SET_PAGE_TO_GO_BACK:
            return {
                ...state,
                pageToGoBack,
            };
        case types.SET_REMEMBER_FORM_DATA:
            return {
                ...state,
                rememberFormData: true,
            };
        case types.SET_NOT_REMEMBER_FORM_DATA:
            return {
                ...state,
                rememberFormData: false,
            };
        case globalTypes.RESET_SELECTED_ELEMENTS:
            return { ...INITIAL_STATE, pageToGoBack: state.pageToGoBack };
        case globalTypes.CLEAN_UP:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export const actions = {
    validateThirdPay: (summary, otp) => ({
        type: types.CREATE_THIRDPAYMENT_REQUEST,
        summary,
        otp,
    }),
    clearData: () => ({
        type: types.CLEAR_DATA,
    }),
    clearSummary: () => ({
        type: types.CLEAR_SUMMARY,
    }),
    setTypeH: () => ({
        type: types.SET_TYPEH,
    }),
    setTypeSU: () => ({
        type: types.SET_TYPESU,
    }),
    summaryThirdPay: (summary, totalAmount, control) => ({
        type: types.SUMMARY_THIRDPAYMENT_REQUEST,
        summary,
        totalAmount,
        control,
    }),
    loadListThirdRequest: (filters) => ({
        type: types.LOAD_LIST_REQUEST,
        filters,
    }),
    loadMoreListThirdRequest: (filters) => ({
        type: types.LOAD_MORE_LIST_REQUEST,
        filters,
    }),
    loadConfirmThirdPayment: (summary) => ({
        type: types.LOAD_THIRDPAYMENT,
        summary,
    }),
    getTransaction: (idTransaction, getFileContest) => ({
        type: types.LOAD_TRANSACTION_REQUEST,
        idTransaction,
        getFileContest,
    }),
    downloadTransactionLines: (format, transactionLines) => ({
        type: types.DOWNLOAD_TRANSACTION_LINE_REQUEST,
        format,
        transactionLines,
    }),
    downloadListThirdPayment: (format, transactionLinesStatus, filters) => ({
        type: types.DOWNLOAD_LIST_REQUEST,
        format,
        transactionLinesStatus,
        filters,
    }),
    downloadFileTransaction: (
        format,
        transactionLines,
        typePayment,
        fileNam,
        id,
        reference,
        dateProcess,
        transactionOrder,
        currency,
        idTransactionStatus,
        accountName,
        transactionStatusName,
    ) => ({
        type: types.DOWNLOAD_TRANSACTION_FILE_REQUEST,
        format,
        transactionLines,
        typePayment,
        fileNam,
        id,
        reference,
        dateProcess,
        transactionOrder,
        currency,
        idTransactionStatus,
        accountName,
        transactionStatusName,
        isDetailDownload: true,
    }),
    downloadFileTransactionItem: (
        format,
        typePayment,
        fileNam,
        id,
        reference,
        dateProcess,
        transactionOrder,
        currency,
        idTransactionStatus,
        accountName,
        transactionStatusName,
    ) => ({
        type: types.DOWNLOAD_TRANSACTION_FILE_REQUEST,
        format,
        transactionLines: null,
        typePayment,
        fileNam,
        id,
        reference,
        dateProcess,
        transactionOrder,
        currency,
        idTransactionStatus,
        accountName,
        transactionStatusName,
        isDetailDownload: false,
    }),
    downloadConfirmation: (summary) => ({
        type: types.DOWNLOAD_CONFIRMATION_REQUEST,
        summary,
    }),
    setPageToGoBack: (pageToGoBack) => ({
        type: types.SET_PAGE_TO_GO_BACK,
        pageToGoBack,
    }),
    setFiltersBack: (filtersBack) => ({
        type: types.LOAD_FILTERS_BACK,
        filtersBack,
    }),
    setRememberFormData: () => ({
        type: types.SET_REMEMBER_FORM_DATA,
    }),
    setNotRememberFormData: () => ({
        type: types.SET_NOT_REMEMBER_FORM_DATA,
    }),
};

export const selectors = {
    getFetching: ({ thirdPayment }) => thirdPayment.fetching,
    getTypePayment: ({ thirdPayment }) => thirdPayment.summary.typePayment,
    getCurrency: ({ thirdPayment }) => thirdPayment.summary.currency,
    getAmount: ({ thirdPayment }) => thirdPayment.summary.amount,
    getAccount: ({ thirdPayment }) => thirdPayment.summary.account,
    getDateProcess: ({ thirdPayment }) => thirdPayment.summary.dateProcess,
    getReferente: ({ thirdPayment }) => thirdPayment.summary.description,
    getTransaction: ({ thirdPayment }) => thirdPayment.transaction,
    getTransactionLinesStatus: ({ thirdPayment }) => thirdPayment.transactionLinesStatus,
    getFile: ({ thirdPayment }) => thirdPayment.summary.file,
    getSummary: ({ thirdPayment }) => thirdPayment.summary,
    getTypeSelect: ({ thirdPayment }) => thirdPayment.typeSelect,
    getFetchingDownload: ({ thirdPayment }) => thirdPayment.fetchingDownload,
    getLatestPageNumber: ({ thirdPayment }) => thirdPayment.latestPageNumber,
    getLatestTotalPages: ({ thirdPayment }) => thirdPayment.latestTotalPages,
    isFirstFetching: ({ thirdPayment }) => thirdPayment.isFirstFetching,
    getPageToGoBack: ({ thirdPayment }) => thirdPayment.pageToGoBack,
    getFiltersBack: ({ thirdPayment }) => thirdPayment.filtersBack,
    getRememberFormData: ({ thirdPayment }) => thirdPayment.rememberFormData,
    getControl: ({ thirdPayment }) => thirdPayment.control,
};
