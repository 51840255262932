import { Box, Drawer, Text, TemplateItem, useMediaQuery, BottomSheet } from "@ui-kit/components/index.es";
import { arrayOf, bool, func, shape } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import { actions as templateActions } from "reducers/template";

function TemplateDrawer(props) {
    TemplateDrawer.propTypes = {
        isVisible: bool.isRequired,
        templates: arrayOf(shape({})).isRequired,
        setVisible: func.isRequired,
        dispatch: func.isRequired,
        onSelect: func.isRequired,
    };
    const { isVisible, templates, setVisible, onSelect } = props;
    const isMobile = useMediaQuery("min", "md");

    const handleTemplateDelete = (item) => {
        const { dispatch } = props;
        dispatch(templateActions.deleteTemplate(item));
    };

    const renderTemplateItems = () =>
        templates.map((template) => (
            <TemplateItem
                key={template.idTransactionTemplate}
                content={<Text variant="body2">{template.name}</Text>}
                onDelete={() => {
                    handleTemplateDelete(template);
                }}
                onClick={() => {
                    onSelect(template.transactionData);
                }}
            />
        ));

    return (
        <Box flex>
            {isMobile ? (
                <BottomSheet
                    title={i18n.get("transfers.templates.mb")}
                    iconTitle="PageCheck"
                    onClose={() => setVisible(false)}
                    isVisible={isVisible}>
                    {renderTemplateItems()}
                </BottomSheet>
            ) : (
                <Drawer
                    controlElementId="open-menu"
                    keepFixedOnWeb={false}
                    iconTitle="NewspaperAlt"
                    mainContentId="drawer-left-main"
                    onClose={() => setVisible(false)}
                    placement="right"
                    isVisible={isVisible}
                    title={i18n.get("transfers.templates")}
                    searcher>
                    {renderTemplateItems()}
                </Drawer>
            )}
        </Box>
    );
}

export default connect()(TemplateDrawer);
