/* eslint-disable import/no-unresolved */
import React, { Component, Fragment } from "react";
import { shape, func, string, number, arrayOf, bool } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as formActions } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";

import ContextMenu from "pages/_components/ContextMenu";
import IncomingDebinTransactionData from "pages/debinPayments/_components/IncomingDebinTransactionData";
import PageLoading from "pages/_components/PageLoading";
import TransactionTicket from "pages/_components/TransactionTicket";
import IncomingDebinSummaryData from "pages/debinPayments/_components/IncomingDebinSummaryData";
import Notification from "pages/_components/Notification";

import * as i18n from "util/i18n";
import * as accountUtils from "util/accounts";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

class IncomingDebinTransaction extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
        accounts: shape({}),
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }),
        debinDetail: shape({}).isRequired,
        fetching: bool,
        fetchingAccounts: bool,
        potentialSignaturesData: arrayOf(
            shape({ typesOfSignatures: arrayOf({ signature: string, selected: bool }), liberator: string }),
        ).isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        pageToGoBack: string.isRequired,
    };

    static defaultProps = {
        accounts: null,
        loggedUser: null,
        fetching: false,
        fetchingAccounts: false,
    };

    state = {
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { summary, dispatch, loggedUser } = this.props;

        if (!summary) {
            dispatch(push(`/pendingTransaction/list`));
        } else {
            const { idDebin } = summary;

            dispatch(debinActions.acceptIncomingRequestPre(idDebin));

            dispatch(accountsActions.listAccounts(loggedUser.userId));
            dispatch(transactionsActions.getPotentialSignaturesData(summary.idTransaction));
        }
    }

    signTransaction = () => {
        const {
            dispatch,
            summary,
            accounts,
            debinDetail: { buyerDetail },
        } = this.props;
        let accountName = null;

        if (accounts.length > 0) {
            const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
            accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
        }
        const signTransactionData = (
            <IncomingDebinSummaryData {...this.props} FORM_ID="payments.debin" accountName={accountName} signature />
        );
        dispatch(push("/transactions/sign"));

        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity: summary.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "payments.debin.table.menu.accept",
                pendingDispatch: summary?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: summary.idActivity,
                idTransaction: summary.idTransaction,
                idForm: "",
                ticketData: summary,
            }),
        );

        dispatch(debinActions.loadConfirmIncomingDebin({ ...summary, action: "sign" }));
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { summary, debinDetail, accounts } = this.props;
        const {
            sellerDetail,
            amount: { currency, quantity },
            buyerDetail,
            idVoucher,
            concept,
            expirationDate,
            description,
        } = debinDetail;
        let accountName = null;

        if (accounts.length > 0) {
            const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
            accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
        }

        const ticketData = {
            ticketName: i18n.get("payments.debin.confirm.summary"),
            ticketBody: [
                getGeneralTicketItem("negotiate.transaction.date.label", summary?.submitDateTime, "datefulltime"),
                getGeneralTicketItem(
                    "pay.paymentAFIP.idTransaction",
                    summary?.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem("payments.debin.confirm.table.header.debitAccount", accountName, "string"),
                getGeneralTicketItem("payments.debin.confirm.table.header.id", summary?.idDebin, "string"),
                getGeneralTicketItem(
                    `payments.debin.confirm.table.header.type${sellerDetail.cbu?.startsWith("000") ? "cvu" : "cbu"}`,
                    sellerDetail.cbu,
                    "string",
                ),
                getGeneralTicketItem(
                    "payments.debin.confirm.table.header.creditAccount",
                    accountUtils.getAccountNumber(sellerDetail.cbu),
                    "string",
                ),
                getGeneralTicketItem(
                    "payments.debin.detail.table.header.documentType",
                    sellerDetail.documentType.concat(" ", sellerDetail.documentNumber),
                    "string",
                ),
                getGeneralTicketItem("payments.debin.detail.table.header.sender", sellerDetail.titular, "string"),
                getGeneralTicketItem(`payments.debin.confirm.table.header.${sellerDetail.bank  && sellerDetail.bank!== "000" ? "bank" : "entity"}`, sellerDetail.bank && sellerDetail.bank!== "000" ? sellerDetail.bank : "-", "string"),
                getGeneralTicketItem(
                    "payments.debin.detail.table.header.reference",
                    debinDetail.refence || "",
                    "string",
                ),
                getGeneralTicketItem(
                    "payments.debin.detail.table.header.amount",
                    quantity,
                    currency === "032" ? "amountARS" : "amountUSD",
                ),
                getGeneralTicketItem("payments.debin.detail.table.header.voucher", idVoucher, "string"),
                getGeneralTicketItem(
                    "payments.debin.detail.table.header.concept",
                    concept ? `${concept} - ${i18n.get(`transfers.concept.${concept}`)}` : "",
                    "string",
                ),
                getGeneralTicketItem("payments.debin.table.header.expiration", expirationDate, "datetime"),
                getGeneralTicketItem("payments.debin.detail.table.header.description", description, "string"),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", marginTop: "2px" }}>{i18n.get("payments.debin.confirm.summary")}</h1>
        </div>
    );

    buttonsDetail = () => {
        const { dispatch, summary } = this.props;

        if (summary?.idTransactionStatus !== "PENDING") {
            return [
                {
                    label: "global.goToHome",
                    bsStyle: "primary",
                    onClick: () => dispatch(push("/desktop")),
                },
            ];
        }
        return [
            {
                label: "global.goToHome",
                bsStyle: "outline",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    rightContentTransactionScheme = (isFinished, isPending) => {
        const { isDesktop, summary } = this.props;

        if (isDesktop || isFinished || !isPending) {
            return <></>;
        }

        return (
            <>
                {summary?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    renderContent = () => {
        const { summary, debinDetail, accounts, isDesktop, fetching } = this.props;
        const { showDrawerMobile, fetchingDownload } = this.state;
        const { buyerDetail } = debinDetail;
        let accountName = null;

        if (accounts.length > 0) {
            const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
            accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
        }

        const isFinished = summary.idTransactionStatus === "FINISHED";
        const isPending = summary.idTransactionStatus === "PENDING";

        return (
            accounts &&
            debinDetail &&
            summary && (
                <TransactionTicket
                    notificationScope="forms"
                    handleClick={this.exportPdf}
                    downloadImageWhite={!isDesktop}
                    handleClickMessage="global.download"
                    headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.centerContentMobile}
                    uniqueDownload={isFinished}
                    rightContent={() => this.rightContentTransactionScheme(isFinished, isPending)}
                    hasInlineButtons
                    hideMobileMenu={!isDesktop}
                    summary={summary}
                    fetching={fetching}
                    buttonsDetail={this.buttonsDetail()}
                    isDesktop={isDesktop}
                    summaryTitle="payments.debin.confirm.summary"
                    showDrawerMobile={showDrawerMobile}
                    onSignTicket={this.signTransaction}
                    ignoreHomeButton
                    closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                    isFetchingExport={fetchingDownload}>
                    <IncomingDebinTransactionData {...this.props} accountName={accountName} />
                </TransactionTicket>
            )
        );
    };

    render() {
        const { fetching, debinDetail, fetchingAccounts, accounts } = this.props;

        return (
            <Fragment>
                <PageLoading loading={fetching || fetchingAccounts}>
                    <Notification scopeToShow="debinTransaction" />

                    {!fetching && debinDetail && accounts && this.renderContent()}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: debinSelectors.getSummary(state),
    debinDetail: debinSelectors.getDebinDetail(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    fetching: debinSelectors.getFetching(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
    pageToGoBack: debinSelectors.getPageToGoBack(state),
});

export default connect(mapStateToProps)(IncomingDebinTransaction);
