import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import I18n from 'pages/_components/I18n';
import FormattedAmount from 'pages/_components/FormattedAmount';
import Pagination from 'pages/_components/pagination/Pagination';
import MovementDetail from './MovementDetail';
import ButtonDrawer from 'pages/_components/drawer/ButtonDrawer';
import MovementsEmptyState from './MovementsEmptyState';

import {selectors as accountsSelectors } from "reducers/accounts";

import { productTypes, maskedAccountNumber } from "util/accounts";
import * as i18nUtils from "util/i18n";
import * as numUtils from "util/number";

function PendingsMovementsGrid({
    isDesktop,
    selectedAccount, 
    pendingMovements,
    pendingMovementsPageNumber,
    pendingMovementsTotalPages,
    getStrDate,
    getMovementsNextPage
}) {
    const { currency } = selectedAccount;
    return (
        <div>
            {pendingMovements.length > 0 ? (
                <>
                    {isDesktop && (
                        <Row className="account-table-wrapper account-table-header account-movements-desktop">
                            <Col sm={12} md={2} lg={2} className="align-self-center text-align-left">
                                <I18n id="accounts.pendingMovements.table.header.entranceDate" />
                            </Col>
                            <Col sm={12} md={2} lg={2} className="align-self-center text-align-center">
                                <I18n id="accounts.pendingMovements.table.header.imputationDate" />
                            </Col>
                            <Col sm={12} md={2} lg={2} className="align-self-center text-align-center">
                                <I18n id="accounts.pendingMovements.table.header.voucher" />
                            </Col>

                            <Col sm={12} md={2} lg={3} className="align-self-center text-align-center">
                                <I18n id="accounts.pendingMovements.table.header.detail" />
                            </Col>

                            <Col sm={12} md={1} lg={3} className="align-self-center text-align-right">
                                <I18n id="accounts.pendingMovements.table.header.amount" />
                            </Col>
                        </Row>
                    )}
                    <div>
                        {
                            pendingMovements.map((movement) => {
                                const { fechaIngreso, fechaImputacion, numeroComprobante, descripcion, importe, tipoMovimiento } = movement;
                                if (isDesktop) {
                                    return (
                                        <Row className="account-table-wrapper">
                                            <Col sm={12} md={2} lg={2} className="align-self-center text-align-left">
                                                <div className="data-text">{getStrDate(fechaIngreso)}</div>
                                            </Col>
                                            <Col sm={12} md={2} lg={2} className="align-self-center text-align-center">
                                                <div className="data-text">{getStrDate(fechaImputacion)}</div>
                                            </Col>
                                            <Col sm={12} md={2} lg={2} className="align-self-center text-align-center">
                                                <div className="data-text">{numeroComprobante}</div>
                                            </Col>
                                            <Col sm={12} md={2} lg={3} className="align-self-center text-align-left">
                                                <div className="data-text">{descripcion}</div>
                                            </Col>
                                            <Col sm={12} md={1} lg={3} className="align-self-center text-align-right">
                                                <FormattedAmount
                                                    currency={currency}
                                                    className="data-amount"
                                                    quantity={numUtils.parseDoubleWithSymbol(
                                                        importe,
                                                        tipoMovimiento ? "+" : "-" /* signoImporte */,
                                                    )}
                                                    medium
                                                    notBold
                                                />
                                            </Col>
                                        </Row>
                                    );
                                }
                                return (
                                    <ButtonDrawer
                                        width="100%"
                                        backButton
                                        headerContent={
                                            <div>
                                                <h1 className="w-100 m-0 px-2">{i18nUtils.get("accounts.movements.row.detail")}</h1>
                                                <p>
                                                    {`${i18nUtils
                                                        .get(`accounts.productType.${productTypes[selectedAccount.productType]}`)
                                                        .toUpperCase()}  Nº ${maskedAccountNumber(selectedAccount.number)}`}
                                                </p>
                                            </div>
                                        }
                                        content={
                                            <MovementDetail
                                                arrayInfo={[
                                                    {
                                                        label: "accounts.pendingMovements.table.header.entranceDate",
                                                        data: <div className="data-text">{getStrDate(fechaIngreso)}</div>,
                                                    },
                                                    {
                                                        label: "accounts.pendingMovements.table.header.imputationDate",
                                                        data: <div className="data-text">{getStrDate(fechaImputacion)}</div>,
                                                    },
                                                    {
                                                        label: "accounts.pendingMovements.table.header.voucher",
                                                        data: <div className="data-text">{numeroComprobante}</div>,
                                                    },
                                                    {
                                                        label: "accounts.pendingMovements.table.header.detail",
                                                        data: <div className="data-text">{descripcion}</div>,
                                                    },
                                                    {
                                                        label: "accounts.pendingMovements.table.header.amount",
                                                        data: (
                                                            <FormattedAmount
                                                                currency={currency}
                                                                className="data-amount"
                                                                quantity={numUtils.parseDoubleWithSymbol(importe, "+")}
                                                                small
                                                                notBold
                                                            />
                                                        ),
                                                    },
                                                ]}
                                            />
                                        }
                                        buttonElement={
                                            <Row
                                                key="buttonElement"
                                                className="account-table-wrapper font-size-13-px"
                                                style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                                                <Col sm={6} className="col col-6 text-align-left">
                                                    <div className="data-text">{getStrDate(fechaIngreso)}</div>
                                                    <div className="data-text">{descripcion}</div>
                                                </Col>
                                                <Col sm={6} className="col col-6 text-align-center ">
                                                    <Row style={{ justifyContent: "flex-end", marginRight: 0 }}>
                                                        <Col className="col col-6 text-align-right" sm={2}>
                                                            <I18n
                                                                id="accounts.pendingMovements.table.header.imputationDate.mobile"
                                                                componentProps={{ className: "data-field to-uppercase" }}
                                                            />
                                                        </Col>
                                                        <Col className="col col-6 text-align-left" sm={3}>
                                                            <div className="data-text">{getStrDate(fechaImputacion)}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ justifyContent: "flex-end", marginRight: 0 }}>
                                                        <Col className="col col-6 text-align-center" sm={6}>
                                                            <FormattedAmount
                                                                currency={currency}
                                                                className="data-amount"
                                                                quantity={importe}
                                                                small
                                                                notBold
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        }
                                    />
                                );
                            })
                    }
                    </div>
                    <div className="d-flex justify-content-end my-3 mr-3">
                        <Pagination
                            totalPages={pendingMovementsTotalPages}
                            pageNumber={pendingMovementsPageNumber}
                            action={getMovementsNextPage}
                        />
                    </div>
                </>
            ) : (
                <MovementsEmptyState />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    pendingMovements: accountsSelectors.getPendingMovements(state),
    pendingMovementsPageNumber: accountsSelectors.getPendingMovementsPageNumber(state),
    lpendingMovementsTotalPages: accountsSelectors.getPendingMovementsTotalPages(state),
});

export default compose(connect(mapStateToProps))(PendingsMovementsGrid)
