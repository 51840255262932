import { makeActionCreator } from "util/redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const types = {
    TOKEN_CREATE_PREVIEW_REQUEST: "token/ TOKEN_CREATE_PREVIEW",
    TOKEN_CREATE_PREVIEW_FAILED: "token/ TOKEN_CREATE_PREVIEW_FAILED",
    TOKEN_CREATE_PREVIEW_SUCCESS: "token/ TOKEN_CREATE_PREVIEW_SUCCESS",

    TOKEN_CREATE_FAILED: "token/TOKEN_CREATE_FAILED",
    TOKEN_CREATE_REQUEST: "token/TOKEN_CREATE_REQUEST",
    TOKEN_CREATE_SUCCESS: "token/TOKEN_CREATE_SUCCESS",

    CHECK_USER_ACTIVE_TOKEN: "token/CHECK_USER_ACTIVE_TOKEN",
    CHECK_USER_ACTIVE_TOKEN_SUCCESS: "token/CHECK_USER_ACTIVE_TOKEN_SUCCESS",
    CHECK_USER_ACTIVE_TOKEN_FAILED: "token/CHECK_USER_ACTIVE_TOKEN_FAILED",

    TOKEN_ACTIVATION_PRE_REQUEST: "token/TOKEN_ACTIVATION_PRE_REQUEST",
    TOKEN_ACTIVATION_PRE_SUCCESS: "token/TOKEN_ACTIVATION_PRE_SUCCESS",
    TOKEN_ACTIVATION_PRE_FAILED: "token/TOKEN_ACTIVATION_PRE_FAILED",

    TOKEN_ACTIVATION_PREVIEW_REQUEST: "token/TOKEN_ACTIVATION_PREVIEW_REQUEST",
    TOKEN_ACTIVATION_PREVIEW_SUCCESS: "token/TOKEN_ACTIVATION_PREVIEW_SUCCESS",
    TOKEN_ACTIVATION_PREVIEW_FAILED: "token/TOKEN_ACTIVATION_PREVIEW_FAILED",

    TOKEN_ACTIVATION_REQUEST: "token/TOKEN_ACTIVATION_REQUEST",
    TOKEN_ACTIVATION_SUCCESS: "token/TOKEN_ACTIVATION_SUCCESS",
    TOKEN_ACTIVATION_FAILED: "token/TOKEN_ACTIVATION_FAILED",

    TOKEN_CHANGE_PASSWORD_REQUEST: "token/TOKEN_CHANGE_PASSWORD_REQUEST",
    TOKEN_CHANGE_PASSWORD_SUCCESS: "token/TOKEN_CHANGE_PASSWORD_SUCCESS",
    TOKEN_CHANGE_PASSWORD_FAILURE: "token/TOKEN_CHANGE_PASSWORD_FAILURE",

    TOKEN_RECOVER_PASSWORD_REQUEST: "token/TOKEN_RECOVER_PASSWORD_REQUEST",
    TOKEN_RECOVER_PASSWORD_FAILURRE: "token/TOKEN_RECOVER_PASSWORD_FAILURE",
    TOKEN_RECOVER_PASSWORD_SUCCESS: "token/TOKEN_RECOVER_PASSWORD_SUCCESS",

    TOKEN_PASSWORD_VALIDATION_REQUEST: "token/TOKEN_PASSWORD_VALIDATION_REQUEST",
    TOKEN_PASSWORD_VALIDATION_FAILED: "token/TOKEN_PASSWORD_VALIDATION_FAILED",
    TOKEN_PASSWORD_VALIDATION_SUCCESS: "token/TOKEN_PASSWORD_VALIDATION_SUCCESS",

    EXCHANGE_TOKEN_REFRESH_REQUEST: "token/EXCHANGE_TOKEN_REFRESH_REQUEST",
    EXCHANGE_TOKEN_REFRESH_SUCCESS: "token/EXCHANGE_TOKEN_REFRESH_SUCCESS",
    EXCHANGE_TOKEN_REFRESH_CHECK: "token/EXCHANGE_TOKEN_REFRESH_CHECK",

    TOKEN_PASSWORD_GENERATION_REQUEST: "token/TOKEN_PASSWORD_GENERATION_REQUEST",
    TOKEN_PASSWORD_GENERATION_FAILED: "token/TOKEN_PASSWORD_GENERATION_FAILED",
    TOKEN_PASSWORD_GENERATION_SUCCESS: "token/TOKEN_PASSWORD_GENERATION_SUCCESS",

    TOKEN_STATUS_PASSWORD_REQUEST: "token/TOKEN_STATUS_PASSWORD_REQUEST",
    TOKEN_STATUS_PASSWORD_FAILED: "token/TOKEN_STATUS_PASSWORD_FAILED",
    TOKEN_STATUS_PASSWORD_SUCCESS: "token/TOKEN_STATUS_PASSWORD_SUCCESS",

    TOKEN_PASSWORD_DISABLED_REQUEST: "token/TOKEN_PASSWORD_DISABLED_REQUEST",
    TOKEN_OTHER_DEVICES_NEW_TOKEN: "token/TOKEN_OTHER_DEVICES_NEW_TOKEN",

    TOKEN_SYNC_NEW_DEVICE_PREVIEW_REQUEST: "token/TOKEN_SYNC_NEW_DEVICE_PREVIEW_REQUEST",
    TOKEN_SYNC_NEW_DEVICE_PREVIEW_FAILED: "token/TOKEN_SYNC_NEW_DEVICE_PREVIEW_FAILED",
    TOKEN_SYNC_NEW_DEVICE_PREVIEW_SUCCESS: "token/TOKEN_SYNC_NEW_DEVICE_PREVIEW_SUCCESS",

    TOKEN_SYNC_NEW_DEVICE_REQUEST: "token/TOKEN_SYNC_NEW_DEVICE_REQUEST",
    TOKEN_SYNC_NEW_DEVICE_FAILED: "token/TOKEN_SYNC_NEW_DEVICE_FAILED",
    TOKEN_SYNC_NEW_DEVICE_SUCCESS: "token/TOKEN_SYNC_NEW_DEVICE_SUCCESS",

    TOKEN_SEND_NEW_CODE_REQUEST: "token/TOKEN_SEND_NEW_CODE_REQUEST",
    TOKEN_SEND_NEW_CODE_FAILED: "token/TOKEN_SEND_NEW_CODE_FAILED",
    TOKEN_SEND_NEW_CODE_SUCCCES: "token/TOKEN_SEND_NEW_CODE_SUCCESS",

    TOKEN_QUERY_CREDENTIAL: "token/TOKEN_QUERY_CREDENTIAL",
    TOKEN_GENERATED_VALUE: "token/TOKEN_GENERATED_VALUE",
    TOKEN_GENERATED_VALUE_NUMBER: "token/TOKEN_GENERATED_VALUE_NUMBER",
    SELECT_CHANNEL: "token/SELECT_CHANNEL",

    TOKEN_RECOVER_ANONYMOUS_REQUEST: "token/TOKEN_RECOVER_ANONYMOUS_REQUEST",
    TOKEN_RECOVER_ANONYMOUS_FAILED: "token/TOKEN_RECOVER_ANONYMOUS_FAILED",
    TOKEN_RECOVER_ANONYMOUS_SUCCESS: "token/TOKEN_RECOVER_ANONYMOUS_SUCCESS",

    SET_FIRST_NAME: "token/SET_FIRST_NAME",

    TOKEN_STATUS_PASSWORD_REVOKE: "token/TOKEN_STATUS_PASSWORD_REVOKE",
    TOKEN_STATUS_PASSWORD_REVOKED_SUCCESS: "token/TOKEN_STATUS_PASSWORD_REVOKED_SUCCESS",
};

export const INITIAL_STATE = {
    isQuery: false,
    isUsed: false,
    fetching: false,
    fetchingToken: false,
    exchangeToken: undefined,
    showNotificationIn: 0,
    email: "",
    numberPhone: "",
    tokenNumber: "",
    configSelectChannel: {},
    documentType: "",
    document: "",
    password: "",
    pin: "",
    userFirstName: "",
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    const { data, exchangeToken, showNotificationIn, userIdToken, statusToken, webTokenStatus, userFirstName } = action;

    switch (action.type) {
        case types.TOKEN_CREATE_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_CREATE_PREVIEW_FAILED:
            return {
                ...INITIAL_STATE,
                fetching: false,
            };
        case types.TOKEN_CREATE_PREVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                isUsed: data.isUsed,
                isQuery: data.isQuery,
            };

        case types.TOKEN_CREATE_REQUEST:
            return {
                ...state,
                fetchingToken: true,
            };
        case types.TOKEN_CREATE_FAILED:
            return {
                ...INITIAL_STATE,
                fetchingToken: false,
            };
        case types.TOKEN_CREATE_SUCCESS:
            return {
                ...state,
                fetchingToken: false,
            };
        case types.TOKEN_ACTIVATION_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_ACTIVATION_PRE_SUCCESS:
            return {
                ...INITIAL_STATE,
                fetching: false,
                email: data.email,
                numberPhone: data.numberPhone,
                isQuery: data.isQuery,
            };
        case types.TOKEN_ACTIVATION_PRE_FAILED:
            return {
                ...state,
                fetching: false,
                email: data.email,
                numberPhone: data.numberPhone,
                isQuery: data.isQuery,
            };
        case types.TOKEN_ACTIVATION_PREVIEW_REQUEST:
            return {
                ...state,
                fetchingToken: true,
            };
        case types.TOKEN_ACTIVATION_PREVIEW_FAILED:
            return {
                ...state,
                fetchingToken: false,
            };
        case types.TOKEN_ACTIVATION_PREVIEW_SUCCESS:
            return {
                ...state,
                fetchingToken: false,
            };
        case types.TOKEN_ACTIVATION_REQUEST:
            return {
                ...state,
                fetchingToken: true,
            };
        case types.TOKEN_ACTIVATION_FAILED:
            return {
                ...INITIAL_STATE,
                userFirstName: state.userFirstName,
                fetchingToken: false,
            };
        case types.TOKEN_ACTIVATION_SUCCESS:
            return {
                ...state,
                fetchingToken: false,
            };
        case types.TOKEN_PASSWORD_VALIDATION_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_PASSWORD_VALIDATION_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case types.TOKEN_PASSWORD_VALIDATION_SUCCESS:
            return {
                ...state,
                exchangeToken,
                showNotificationIn,
                fetching: false,
            };
        case types.EXCHANGE_TOKEN_REFRESH_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.EXCHANGE_TOKEN_REFRESH_SUCCESS:
            return {
                ...state,
                exchangeToken,
                showNotificationIn,
                fetching: false,
            };
        case types.TOKEN_PASSWORD_GENERATION_REQUEST:
            return {
                ...state,
                fetchingToken: true,
            };
        case types.TOKEN_PASSWORD_GENERATION_FAILED:
            return {
                ...state,
                fetchingToken: false,
            };
        case types.TOKEN_PASSWORD_GENERATION_SUCCESS:
            return {
                ...state,
                tokenNumber: data._tokenNumber,
                fetchingToken: false,
            };
        case types.TOKEN_STATUS_PASSWORD_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_STATUS_PASSWORD_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case types.TOKEN_STATUS_PASSWORD_SUCCESS:
            return {
                ...state,
                tokenStatus: statusToken,
                userIdToken,
                fetching: false,
            };
        case types.TOKEN_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.TOKEN_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.TOKEN_RECOVER_PASSWORD_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_RECOVER_PASSWORD_FAILURRE:
            return {
                ...state,
                fetching: false,
            };
        case types.TOKEN_RECOVER_PASSWORD_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.TOKEN_SYNC_NEW_DEVICE_PREVIEW_REQUEST:
            return {
                ...state,
                fetchingToken: true,
            };
        case types.TOKEN_SYNC_NEW_DEVICE_PREVIEW_FAILED:
            return {
                ...INITIAL_STATE,
                userFirstName: state.userFirstName,
                fetchingToken: false,
            };
        case types.TOKEN_SYNC_NEW_DEVICE_PREVIEW_SUCCESS:
            return {
                ...state,
                fetchingToken: false,
            };
        case types.TOKEN_SYNC_NEW_DEVICE_REQUEST:
            return {
                ...state,
                fetchingToken: true,
            };
        case types.TOKEN_SYNC_NEW_DEVICE_FAILED:
            return {
                ...INITIAL_STATE,
                userFirstName: state.userFirstName,
                fetchingToken: false,
            };
        case types.TOKEN_SYNC_NEW_DEVICE_SUCCESS:
            return {
                ...state,
                fetchingToken: false,
            };
        case types.TOKEN_SEND_NEW_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_SEND_NEW_CODE_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case types.TOKEN_SEND_NEW_CODE_SUCCCES:
            return {
                ...state,
                fetching: false,
            };
        case types.SELECT_CHANNEL:
            return {
                ...state,
                configSelectChannel: { ...data },
            };
        case types.TOKEN_RECOVER_ANONYMOUS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TOKEN_RECOVER_ANONYMOUS_SUCCESS:
            return {
                ...state,
                fetching: false,
                userFirstName: data._userFirstName,
                userFullName: data._userFullName,
                maskedEmail: data._userMaskedEmail,
                maskedPhone: data._userMaskedPhoneNumber,
            };
        case types.TOKEN_RECOVER_ANONYMOUS_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case types.CHECK_USER_ACTIVE_TOKEN:
            return {
                ...state,
                fetching: true,
            };
        case types.CHECK_USER_ACTIVE_TOKEN_SUCCESS:
            return {
                ...state,
                fetching: false,
                webTokenStatus,
            };
        case types.CHECK_USER_ACTIVE_TOKEN_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_FIRST_NAME:
            return {
                ...state,
                userFirstName,
            };
        case types.TOKEN_STATUS_PASSWORD_REVOKED_SUCCESS:
            return {
                ...state,
                tokenStatus: statusToken,
                userIdToken,
                fetching: false,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        storage,
        key: "token",
        whitelist: ["userIdToken", "tokenStatus", "userFirstName"],
    },
    reducer,
);

export const actions = {
    tokenQueryCredential: makeActionCreator(types.TOKEN_QUERY_CREDENTIAL, "idForms", "userId", "form", "field"),
    tokenGeneratedValue: makeActionCreator(types.TOKEN_GENERATED_VALUE, "userId", "form", "field"),
    tokenGeneratedValueNumber: makeActionCreator(types.TOKEN_GENERATED_VALUE_NUMBER, "userId", "setState"),

    tokenCreateRequest: makeActionCreator(types.TOKEN_CREATE_REQUEST, "sendChannel"),
    tokenCreateFailed: makeActionCreator(types.TOKEN_CREATE_FAILED),
    tokenCreateSuccess: makeActionCreator(types.TOKEN_CREATE_SUCCESS, "data"),

    checkUserActiveToken: makeActionCreator(types.CHECK_USER_ACTIVE_TOKEN),

    tokenActivationPreRequest: makeActionCreator(types.TOKEN_ACTIVATION_PRE_REQUEST, "params"),
    tokenActivationPreFailed: makeActionCreator(types.TOKEN_ACTIVATION_PRE_FAILED),
    tokenActivationPreSuccess: makeActionCreator(types.TOKEN_ACTIVATION_PRE_SUCCESS, "data"),

    tokenActivationPreviewRequest: makeActionCreator(types.TOKEN_ACTIVATION_PREVIEW_REQUEST, "codeInvite"),
    tokenActivationPreviewFailed: makeActionCreator(types.TOKEN_ACTIVATION_PREVIEW_FAILED),
    tokenActivationPreviewSuccess: makeActionCreator(types.TOKEN_ACTIVATION_PREVIEW_SUCCESS, "data"),

    tokenActivationRequest: makeActionCreator(
        types.TOKEN_ACTIVATION_REQUEST,
        "password",
        "passwordConfirmation",
        "formikBag",
    ),
    tokenActivationFailed: makeActionCreator(types.TOKEN_ACTIVATION_FAILED),
    tokenActivationSuccess: makeActionCreator(types.TOKEN_ACTIVATION_SUCCESS, "data"),

    tokenPasswordValidationRequest: makeActionCreator(
        types.TOKEN_PASSWORD_VALIDATION_REQUEST,
        "password",
        "userId",
        "formikBag",
    ),
    tokenPasswordValidationFailed: makeActionCreator(types.TOKEN_PASSWORD_VALIDATION_FAILED),

    refreshTokenRequest: makeActionCreator(
        types.EXCHANGE_TOKEN_REFRESH_REQUEST,
        "userId",
        "exchangeToken",
    ),
    exchangeTokenCheck: makeActionCreator(
        types.EXCHANGE_TOKEN_REFRESH_CHECK,
        "userId",
        "exchangeToken",
    ),

    tokenPasswordGenerationRequest: makeActionCreator(types.TOKEN_PASSWORD_GENERATION_REQUEST, "exchangeToken"),
    tokenPasswordGenerationFailed: makeActionCreator(types.TOKEN_PASSWORD_GENERATION_FAILED),
    tokenPasswordGenerationSuccess: makeActionCreator(types.TOKEN_PASSWORD_GENERATION_SUCCESS, "data"),

    tokenStatusPasswordRequest: makeActionCreator(types.TOKEN_STATUS_PASSWORD_REQUEST, "userId"),
    tokenStatusPasswordFailed: makeActionCreator(types.TOKEN_STATUS_PASSWORD_FAILED),
    tokenStatusPasswordSuccess: makeActionCreator(types.TOKEN_STATUS_PASSWORD_SUCCESS, "statusToken", "userIdToken"),
    tokenStatusPasswordRevoke: makeActionCreator(types.TOKEN_STATUS_PASSWORD_REVOKE),
    tokenStatusPasswordRevokedSuccess: makeActionCreator(types.TOKEN_STATUS_PASSWORD_REVOKED_SUCCESS, "statusToken", "userIdToken"),

    changePassword: makeActionCreator(
        types.TOKEN_CHANGE_PASSWORD_REQUEST,
        "password",
        "newPassword",
        "newPasswordConfirmation",
        "formikBag",
    ),
    changePasswordFailure: makeActionCreator(types.TOKEN_CHANGE_PASSWORD_FAILURE),
    changePasswordSuccess: makeActionCreator(types.TOKEN_CHANGE_PASSWORD_SUCCESS, "data"),

    recoverTokenPassword: makeActionCreator(
        types.TOKEN_RECOVER_PASSWORD_REQUEST,
        "username",
        "documentType",
        "document",
        "password",
        "sendChannel",
        "formikBag",
    ),
    recoverTokenPasswordFailure: makeActionCreator(types.TOKEN_RECOVER_PASSWORD_FAILURRE),
    recoverTokenPasswordSuccess: makeActionCreator(types.TOKEN_RECOVER_PASSWORD_SUCCESS, "data"),

    tokenPasswordDisabled: makeActionCreator(types.TOKEN_PASSWORD_DISABLED_REQUEST, "password", "formikBag"),

    tokenOtherDevicesNewTokenRequest: makeActionCreator(types.TOKEN_OTHER_DEVICES_NEW_TOKEN),

    tokenSyncNewDevicePreviewRequest: makeActionCreator(
        types.TOKEN_SYNC_NEW_DEVICE_PREVIEW_REQUEST,
        "codeRandom",
        "formikBag",
    ),
    tokenSyncNewDevicePreviewFailed: makeActionCreator(types.TOKEN_SYNC_NEW_DEVICE_PREVIEW_FAILED),
    tokenSyncNewDevicePreviewSuccess: makeActionCreator(types.TOKEN_SYNC_NEW_DEVICE_PREVIEW_SUCCESS, "data"),

    tokenSyncNewDeviceRequest: makeActionCreator(types.TOKEN_SYNC_NEW_DEVICE_REQUEST, "password", "formikBag"),
    tokenSyncNewDeviceFailed: makeActionCreator(types.TOKEN_SYNC_NEW_DEVICE_FAILED),
    tokenSyncNewDeviceSuccess: makeActionCreator(types.TOKEN_SYNC_NEW_DEVICE_SUCCESS, "data"),

    tokenActivationSendNewCodeRequest: makeActionCreator(types.TOKEN_SEND_NEW_CODE_REQUEST),
    tokenActivationSendNewCodeFailed: makeActionCreator(types.TOKEN_SEND_NEW_CODE_FAILED),
    tokenActivationSendNewCodeSuccess: makeActionCreator(types.TOKEN_SEND_NEW_CODE_SUCCCES),
    configureSelectChannel: makeActionCreator(types.SELECT_CHANNEL, "data"),

    tokenRecoverAnonymousRequest: makeActionCreator(
        types.TOKEN_RECOVER_ANONYMOUS_REQUEST,
        "values",
        "formikBag",
        "config",
    ),
    tokenRecoverAnonymousSuccess: makeActionCreator(types.TOKEN_RECOVER_ANONYMOUS_SUCCESS, "data"),
    tokenRecoverAnonymousFailed: makeActionCreator(types.TOKEN_RECOVER_ANONYMOUS_FAILED),
    setFirstName: makeActionCreator(types.SET_FIRST_NAME, "userFirstName"),
};

export const selectors = {
    getFetching: ({ token }) => token.fetching,
    getFetchingToken: ({ token }) => token.fetchingToken,
    getEmail: ({ token }) => token.email,
    getExchangeToken: ({ token }) => token.exchangeToken,
    getShowNotificationIn: ({ token }) => token.showNotificationIn,
    getNumberPhone: ({ token }) => token.numberPhone,
    getTokenNumber: ({ token }) => token.tokenNumber,
    getUserIdToken: ({ token }) => token.userIdToken,
    getUserFristName: ({ token }) => token.userFirstName,
    getStatusTokenPass: ({ token }) => token.tokenStatus,
    getSelectChannelConfig: ({ token }) => token.configSelectChannel,
    getTokenAnonymousData: ({ token }) => ({
        userFirstName: token.userFirstName,
        userFullName: token.userFullName,
        maskedPhone: token.maskedPhone,
        maskedEmail: token.maskedEmail,
    }),
    // token.tokenStatus esta guardado en la memoria del dispositivo y solo precisa para mobile
    getIsTokenActiveInThisDevice: ({ token }) => token.tokenStatus === "active" && token.userIdToken !== "",
    getIsTokenBlocked: ({ token }) => token.webTokenStatus === "blocked",
    getUserTokenStatus: ({ token }) => token.webTokenStatus,
    userHasActiveToken: ({ token }) => token.webTokenStatus === "active",
};
