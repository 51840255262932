import { Box, Drawer, Text, useMediaQuery, BottomSheet, Form, Separator } from "@ui-kit/components/index.es";
import { bool, func, number, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { selectors as frequentDestinationSelectors } from "reducers/frequentDestination";
import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import Yup from "yup";
import Notification from "pages/_components/Notification";
import isTokenActive from "util/token";

function AgendaDrawer(props) {
    AgendaDrawer.propTypes = {
        dispatch: func.isRequired,
        frequentDestinationLoading: bool.isRequired,
        idEnvironment: number.isRequired,
        agendaDrawerValues: shape({
            cbuOrAliasAgenda: string,
            emailAgenda: string,
            referenceAgenda: string,
            tokenAgenda: string,
        }).isRequired,
        agendaDrawerVisibleRed: bool.isRequired,
        tokenNumber: string,
    };

    AgendaDrawer.defaultProps = {
        tokenNumber: null,
    };

    const { frequentDestinationLoading, dispatch, agendaDrawerValues, agendaDrawerVisibleRed } = props;
    const isMobile = useMediaQuery("min", "md");
    const isTokenMobileActive = isTokenActive();

    useEffect(() => {
        if (agendaDrawerVisibleRed && isTokenMobileActive)
            dispatch(tokenActions.tokenGeneratedValueNumber());
    }, [agendaDrawerVisibleRed, isTokenMobileActive, dispatch]);
    

    const handleModalChange = (e) => {
        const modalAgendaValuesToSet = { ...agendaDrawerValues, [e.target.id]: e.target.value };
        dispatch(transferActions.setAgendaDrawerValues(modalAgendaValuesToSet));
    };

    const handleOnSubmit = () => {
        const { idEnvironment, tokenNumber } = props;
        const requestValues = {
            cbu: agendaDrawerValues?.cbuOrAliasAgenda,
            recipientEmail: agendaDrawerValues?.emailAgenda,
            name: agendaDrawerValues?.referenceAgenda,
            otp: isTokenMobileActive ? tokenNumber : agendaDrawerValues?.tokenAgenda,
            scopeToShow: "form",
            idEnvironment,
        };

        dispatch(transferActions.frequentDestinationCreateRequest(requestValues));
    };

    return (
        <Box flex>
            {isMobile ? (
                <BottomSheet
                    iconTitle="UserAdd"
                    iconColor="primary-pressed-color"
                    title={i18n.get("transfers.controlAgenda.title")}
                    className="px-4"
                    onClose={() => {
                        dispatch(transferActions.setAgendaDrawerVisible(false));
                    }}
                    isVisible={agendaDrawerVisibleRed}>
                    <Box flex mt={12} mb={24}>
                        <Text variant="body2">{configUtils.get("frontend.ControlAgenda.warningMessage")}</Text>
                    </Box>
                    <Form
                        className="pb-5"
                        disabledSubmitByDirty
                        hiddeForm={false}
                        rowSpacingForm={32}
                        buttonSubmit={{
                            colProps: {
                                size: {
                                    lg: 12,
                                    xs: 12,
                                },
                            },
                            text: i18n.get(`global.confirm`),
                            variant: "solid",
                            icon: "ArrowRight",
                            iconVariant: "outline",
                            isInactive: frequentDestinationLoading,
                            loading: frequentDestinationLoading,
                        }}
                        initialValues={agendaDrawerValues}
                        inputsList={[
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "accountSearcher",
                                id: "cbuOrAliasAgenda",
                                onChange: (e) => {
                                    handleModalChange(e);
                                },
                                value: agendaDrawerValues?.cbuOrAliasAgenda,
                                disabled: true,
                            },
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "textField",
                                id: "referenceAgenda",
                                label: i18n.get(`transfers.referenceAgenda`),
                                maxLength: 50,
                                required: true,
                                onChange: (e) => handleModalChange(e),
                                value: agendaDrawerValues?.referenceAgenda,
                            },
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "textField",
                                id: "emailAgenda",
                                label: i18n.get(`transfers.emailAgenda`),
                                maxLength: 50,
                                onChange: (e) => handleModalChange(e),
                                value: agendaDrawerValues?.emailAgenda,
                            },
                            {
                                colProps: {
                                    size: {
                                        xs: 12,
                                        md: 12,
                                        lg: 12,
                                    },
                                },
                                id: "separator",
                                customElement: () => (
                                    <Box flex w="100%" mt={12}>
                                        <Separator color="neutral-light" type="horizontal" />
                                    </Box>
                                ),
                            },
                            {
                                colProps: {
                                    size: {
                                        xs: 12,
                                        md: 12,
                                        lg: 12,
                                    },
                                },
                                id: "tokenText",
                                customElement: () => (
                                    <Box flex my={8}>
                                        <Text variant="body2">
                                            { i18n.get( !isTokenMobileActive ? "transfers.frequentDestinationModal.token.text" : "confirmation.withoutotp.text")}
                                        </Text>
                                    </Box>
                                ),
                            },
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "tokenPassField",
                                id: "tokenAgenda",
                                isHidden: isTokenMobileActive,
                                label: "TOKEN",
                                placeholder: i18n.get(`confirmation.otp.placeholder`),
                                onChange: (e) => handleModalChange(e),
                                value: agendaDrawerValues?.tokenAgenda,
                            },
                        ]}
                        onSubmit={() => {
                            handleOnSubmit();
                        }}
                        validationSchema={Yup.object().shape({
                            emailAgenda: Yup.string().email(i18n.get(`transfers.field.error.invalid`)),
                            referenceAgenda: Yup.string()
                                .required(i18n.get(`transfers.field.error.required`))
                                .max(
                                    configUtils.getInteger("frequentDestination.reference.maxLength", 100),
                                    i18n.get(`frequentDestination.reference.length.error`),
                                ),
                            tokenAgenda: isTokenMobileActive ? Yup.string() : Yup.string().required(i18n.get(`transfers.field.error.required`)),
                        })}
                    />
                </BottomSheet>
            ) : (
                <Drawer
                    controlElementId="open-menu"
                    keepFixedOnWeb={false}
                    iconTitle="UserAdd"
                    iconColor="primary-pressed-color"
                    mainContentId="drawer-left-main"
                    onClose={() => {
                        dispatch(transferActions.setAgendaDrawerVisible(false));
                    }}
                    placement="right"
                    isVisible={agendaDrawerVisibleRed}
                    w={400}
                    title={i18n.get("transfers.controlAgenda.title")}>
                    <Notification notificationClassname="snackbar__agenda" scopeToShow="frequentDestinationDrawer" />
                    <Box flex align="baseline" mb={24}>
                        <Text variant="body2">{configUtils.get("frontend.ControlAgenda.warningMessage")}</Text>
                    </Box>
                    <Form
                        disabledSubmitByDirty={false}
                        hiddeForm={false}
                        rowSpacingForm={32}
                        buttonSubmit={{
                            colProps: {
                                size: {
                                    lg: 12,
                                    xs: 12,
                                },
                            },
                            text: i18n.get(`global.confirm`),
                            variant: "solid",
                            icon: "ArrowRight",
                            iconVariant: "outline",
                            isInactive: frequentDestinationLoading,
                            loading: frequentDestinationLoading,
                        }}
                        initialValues={agendaDrawerValues}
                        inputsList={[
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "accountSearcher",
                                id: "cbuOrAliasAgenda",
                                onChange: (e) => {
                                    handleModalChange(e);
                                },
                                value: agendaDrawerValues?.cbuOrAliasAgenda,
                                disabled: true,
                            },
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "textField",
                                id: "referenceAgenda",
                                label: i18n.get(`transfers.referenceAgenda`),
                                maxLength: 50,
                                required: true,
                                onChange: (e) => handleModalChange(e),
                                value: agendaDrawerValues?.referenceAgenda,
                            },
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "textField",
                                id: "emailAgenda",
                                label: i18n.get(`transfers.emailAgenda`),
                                maxLength: 50,
                                onChange: (e) => handleModalChange(e),
                                value: agendaDrawerValues?.emailAgenda,
                            },
                            {
                                colProps: {
                                    size: {
                                        xs: 12,
                                        md: 12,
                                        lg: 12,
                                    },
                                },
                                id: "separator",
                                customElement: () => (
                                    <Box flex w="100%" mt={12}>
                                        <Separator color="neutral-light" type="horizontal" />
                                    </Box>
                                ),
                            },
                            {
                                colProps: {
                                    size: {
                                        xs: 12,
                                        md: 12,
                                        lg: 12,
                                    },
                                },
                                id: "tokenText",
                                customElement: () => (
                                    <Box flex align="baseline">
                                        <Text variant="body2">
                                            {i18n.get("transfers.frequentDestinationModal.token.text")}
                                        </Text>
                                    </Box>
                                ),
                            },
                            {
                                colProps: {
                                    size: {
                                        lg: 12,
                                        md: 12,
                                        xs: 12,
                                    },
                                },
                                component: "tokenPassField",
                                id: "tokenAgenda",
                                label: "TOKEN",
                                placeholder: i18n.get(`confirmation.otp.placeholder`),
                                onChange: (e) => handleModalChange(e),
                                value: agendaDrawerValues?.tokenAgenda,
                            },
                        ]}
                        onSubmit={() => {
                            handleOnSubmit();
                        }}
                        validationSchema={Yup.object().shape({
                            emailAgenda: Yup.string().email(i18n.get(`transfers.field.error.invalid`)),
                            referenceAgenda: Yup.string()
                                .required(i18n.get(`transfers.field.error.required`))
                                .max(
                                    configUtils.getInteger("frequentDestination.reference.maxLength", 100),
                                    i18n.get("frequentDestination.reference.length.error"),
                                ),
                            tokenAgenda: Yup.string().required(i18n.get("transfers.field.error.required")),
                        })}
                    />
                </Drawer>
            )}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
    agendaDrawerValues: transferSelectors.getAgendaDrawerValues(state),
    agendaDrawerVisibleRed: transferSelectors.getAgendaDrawerVisibleRed(state),
    frequentDestinationLoading: transferSelectors.getFrequentDestinationLoading(state),
    tokenNumber: tokenSelectors.getTokenNumber(state),
});
export default connect(mapStateToProps)(AgendaDrawer);
