import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Container from "pages/_components/Container";
import Dropdown from "pages/_components/Dropdown";
import Credential from "pages/_components/fields/credentials/Credential";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import Header from "pages/_components/header/Header";
import Heading from "pages/_components/Heading";
import Hint from "pages/_components/hints/Hint";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import DebinTermsAndConditionsContent from "pages/charges/_components/DebinTermsAndConditionsContent";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Drawer from "rc-drawer";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as debinActions, selectors as debinSelectors } from "reducers/debin";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as accountUtils from "util/accounts";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import isTokenActive from "util/token";
import Yup from "yup";

const FORM_ID = "debin.addAccount.summary";
class AcceptDebinConfirm extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        values: shape({}).isRequired,
        debinDetail: shape({}).isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        fetchingDownload: bool.isRequired,
        accounts: shape({}),
        fetchingAccounts: bool.isRequired,
        // accountName: string.isRequired,
        isSubmitting: bool.isRequired,
        control: bool.isRequired,
    };

    static defaultProps = {
        loggedUser: null,
        accounts: null,
    };

    state = {
        showDrawer: false,
    };

    componentDidMount() {
        const { dispatch, loggedUser, accounts, debinDetail } = this.props;

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }

        if (!debinDetail) {
            dispatch(goBack());
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleDownload = () => {
        const { dispatch } = this.props;
        dispatch(debinActions.putNotification({ format: "pdf", i18nTerms: "debin.termsAndConditions.content" }));
    };

    handleClickDownload = async () => {
        const { dispatch } = this.props;
        dispatch(debinActions.putNotification({ format: "pdf", i18nTerms: "debin.termsAndConditions.content" }));
    };

    getTitle = () => {
        const { isDesktop, control } = this.props;

        if (isDesktop) {
            return (
                <>
                    <div className="view-title data-wrapper-flex">
                        <h1 className="my-0 mr-3">{i18nUtils.get("payments.debin.confirm.header.title")}</h1>
                        {control && (
                            <div className="ms-5">
                                <Hint
                                    defaultValue={configUtils.get("frontend.mobileSignatureControl.text", "")}
                                    classNameGroup="d-flex align-items-center w-100 mb-2"
                                    classNameHintP="m-0 ms-2"
                                    alwaysShowMessage
                                />
                            </div>
                        )}
                    </div>
                    <p className="my-1">{i18nUtils.get("payments.debin.confirm.header.subtitle")}</p>
                </>
            );
        }

        return (
            <div className="title-account-header-multiline-background-blue">
                <h1>{i18nUtils.get("payments.debin.confirm.header.title")}</h1>
            </div>
        );
    };

    render() {
        const {
            isDesktop,
            values,
            fetchingAccounts,
            accounts,
            debinDetail,
            isSubmitting,
            fetchingDownload,
        } = this.props;
        const { showDrawer } = this.state;

        if (!debinDetail) {
            return <></>;
        }

        const continueButton = (
            <Col className="col col-12 col-md-10 col-lg-6 col-xl-4">
                <Button
                    disabled={!values.acceptTerms}
                    type="submit"
                    bsStyle="primary"
                    label="global.confirm"
                    image="images/arrowRight.svg"
                    imageStyle="mr-2"
                    loading={isSubmitting}
                    className="text-capitalize"
                />
            </Col>
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center info-token">
                        <I18n id="transfer.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        const {
            buyerDetail,
            concept,
            description,
            expirationDate,
            id,
            sellerDetail,
            amount: { currency, quantity },
            amount,
            reference,
        } = debinDetail;

        const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
        const arrayAccountData = loggedUserAccount && loggedUserAccount.extraInfoMasked.split("|");
        const containerClassName = `flex-grow align-items-center container-white my-2 py-3 px-md-3 ${
            !isDesktop ? "mb-0" : ""
        }`;

        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        onClose={isDesktop && this.handleClose}
                        handleClick={this.handleClickDownload}
                        handleClickMessage="global.download"
                        centerElement={!isDesktop && this.getTitle}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        emptyRightOption
                    />
                    {isDesktop && (
                        <Head
                            centerElement={this.getTitle}
                            centerContentClassName="d-flex flex-column align-items-start"
                        />
                    )}
                </div>
                <PageLoading loading={fetchingAccounts}>
                    <MainContainer>
                        <Form>
                            {!isDesktop && (
                                <div className="admin-content-center d-flex justify-content-center">
                                    <I18n
                                        id="payments.debin.confirm.header.subtitle"
                                        component="h2"
                                        componentProps={{ className: "py-3 px-4 f-size-35" }}
                                    />
                                </div>
                            )}
                            <Container className={containerClassName}>
                                <Col className="col-12 col-md-9 col-lg-3">
                                    <Heading.DataGroup
                                        label="payments.debin.confirm.table.header.debitAccount"
                                        containerClassName={`transfer-data transfer-debin mb-lg-0 data-wrapper${
                                            !isDesktop ? "-flex space-between mb-0" : ""
                                        }`}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                        data={
                                            <>
                                                {arrayAccountData && arrayAccountData.length > 0 ? (
                                                    <p className={`data-desc my-0 ${!isDesktop ? "text-right" : ""}`}>
                                                        {`${arrayAccountData[1]} ${arrayAccountData[0]}
                                                        ${i18nUtils.get(`currency.label.${arrayAccountData[2]}`)}`}
                                                    </p>
                                                ) : (
                                                    "-"
                                                )}
                                                {!isDesktop &&
                                                    (loggedUserAccount ? (
                                                        <div className="d-flex">
                                                            <span className="mr-2">
                                                                {i18nUtils.get("accounts.availableBalance")}
                                                            </span>
                                                            <FormattedAmount
                                                                currency={loggedUserAccount.currency}
                                                                quantity={loggedUserAccount.balance}
                                                                className="my-0"
                                                                fontClassName="data-desc"
                                                                cleanClassName
                                                            />
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    ))}
                                            </>
                                        }
                                    />
                                    {isDesktop && (
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin my-lg-0 ${
                                                isDesktop ? "data-wrapper" : "data-wrapper-flex space-between"
                                            }`}
                                            data={
                                                loggedUserAccount ? (
                                                    <div className="d-flex">
                                                        <span className="mr-2">
                                                            {i18nUtils.get("accounts.availableBalance")}
                                                        </span>
                                                        <FormattedAmount
                                                            currency={loggedUserAccount.currency}
                                                            quantity={loggedUserAccount.balance}
                                                        />
                                                    </div>
                                                ) : (
                                                    "-"
                                                )
                                            }
                                        />
                                    )}
                                </Col>
                                <Col className="col-12 col-md-9 col-lg-3">
                                    <Heading.DataGroup
                                        containerClassName={`transfer-data transfer-debin mb-lg-0 data-wrapper${
                                            !isDesktop ? "-flex space-between mb-0" : ""
                                        }`}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                        label="payments.debin.detail.table.header.concept"
                                        data={concept ? i18nUtils.get(`transfers.concept.${concept}`) : "-"}
                                    />
                                </Col>
                                <Col className="col-12 col-md-9 col-lg-3">
                                    <Heading.DataGroup
                                        containerClassName={`transfer-data transfer-debin mb-lg-0 data-wrapper${
                                            !isDesktop ? "-flex space-between mb-0" : ""
                                        }`}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                        label="payments.debin.detail.table.header.description"
                                        data={description || "-"}
                                    />
                                </Col>
                                <Col className="col-12 col-md-9 col-lg-3">
                                    <Heading.DataGroup
                                        containerClassName={`transfer-data transfer-debin mb-lg-0 data-wrapper${
                                            !isDesktop ? "-flex space-between mb-0" : ""
                                        }`}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                        label="payments.debin.detail.table.header.expirationDate"
                                        data={
                                            expirationDate ? (
                                                <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                            ) : (
                                                "-"
                                            )
                                        }
                                    />
                                </Col>
                            </Container>
                            <Container className={containerClassName}>
                                <Col className="col col-12 px-0" md={12} xl={12} lg={12} sm={12}>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin data-wrapper${
                                                !isDesktop ? "-flex space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.confirm.table.header.id"
                                            data={id}
                                        />
                                    </Col>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin data-wrapper${
                                                !isDesktop ? "-flex space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.detail.table.header.sender"
                                            data={sellerDetail.titular || <span> - </span>}
                                        />
                                    </Col>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin data-wrapper${
                                                !isDesktop ? "-flex space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.confirm.table.header.documentType"
                                            data={`${sellerDetail.documentType} ${sellerDetail.documentNumber}`}
                                        />
                                    </Col>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin data-wrapper${
                                                !isDesktop ? "-flex space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.detail.table.header.amount"
                                            data={
                                                amount ? (
                                                    <FormattedAmount
                                                        currency={currency}
                                                        quantity={quantity}
                                                        className="my-0"
                                                        fontClassName="data-desc"
                                                        cleanClassName
                                                    />
                                                ) : (
                                                    "-"
                                                )
                                            }
                                        />
                                    </Col>
                                </Col>
                                <Col className="col col-12 px-0" md={12} xl={12} lg={12} sm={12}>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin mb-0 data-wrapper${
                                                !isDesktop ? "-flex space-between" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.confirm.table.header.creditAccount"
                                            data={accountUtils.getAccountNumber(sellerDetail.cbu)}
                                        />
                                    </Col>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin mb-0 data-wrapper${
                                                !isDesktop ? "-flex space-between" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label={`payments.debin.confirm.table.header.type${
                                                sellerDetail.cbu?.startsWith("000") ? "cvu" : "cbu"
                                            }`}
                                            data={sellerDetail.cbu}
                                        />
                                    </Col>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin mb-0 data-wrapper${
                                                !isDesktop ? "-flex space-between" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.confirm.table.header.bank"
                                            data={sellerDetail.bank ? sellerDetail.bank : "-"}
                                        />
                                    </Col>
                                    <Col className="col col-12 col-md-3 pr-lg-0">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data transfer-debin mb-0 data-wrapper${
                                                !isDesktop ? "-flex space-between " : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.detail.table.header.reference"
                                            data={reference || "-"}
                                        />
                                    </Col>
                                </Col>
                            </Container>
                            <Container
                                className="flex-grow align-items-center container-white
                                mt-2 pt-3 mb-0 pb-0"
                                gridClassName="form-content">
                                <Col className="col col-12 col-md-10 col-lg-6 col-xl-4">{credentials}</Col>
                            </Container>
                            <Container
                                className={`flex-grow align-items-center mt-0 container-white ${
                                    !isDesktop ? "mb-0" : ""
                                }`}
                                gridClassName="form-content">
                                <Col className="col col-12 col-md-10 col-lg-6 col-xl-4 d-flex">
                                    <Field
                                        idForm={FORM_ID}
                                        name="acceptTerms"
                                        component={Checkbox}
                                        className="mb-1"
                                        linkLabel="global.termAndConditions"
                                        onClickLabel={() => this.setState((prev) => ({ showDrawer: !prev.showDrawer }))}
                                        linkColor="primary"
                                    />
                                    <Drawer
                                        width={isDesktop ? "600px" : "auto"}
                                        wrapperClassName=""
                                        open={showDrawer}
                                        handler={false}
                                        placement="right"
                                        level={null}
                                        onClose={() => this.setState((prev) => ({ showDrawer: !prev.showDrawer }))}>
                                        {showDrawer && (
                                            <div className={classNames("drawer-content-inner")}>
                                                {isDesktop && (
                                                    <Header
                                                        additionalClassName={classNames({
                                                            "background-white": !isDesktop,
                                                        })}>
                                                        <div className="navbar-header navbar-header-fixed p-0">
                                                            <div className="view-title" />
                                                            <div className="drawer-toolbar-item drawer-toolbar-item-right align-items-start">
                                                                {isDesktop && (
                                                                    <Dropdown
                                                                        image="images/download_bold.svg"
                                                                        imageStyle="mr-2"
                                                                        buttonClass="min-width-14"
                                                                        label="global.download"
                                                                        bsStyle="outline"
                                                                        fetching={fetchingDownload}
                                                                        pullDown>
                                                                        <Button
                                                                            onClick={this.handleDownload}
                                                                            label="accounts.pdfFile"
                                                                            className="dropdown__item-btn"
                                                                            bsStyle="link"
                                                                        />
                                                                    </Dropdown>
                                                                )}
                                                                <Button
                                                                    bsStyle="link"
                                                                    image="images/cross.svg"
                                                                    className="toolbar-btn view-close"
                                                                    onClick={() =>
                                                                        this.setState((prev) => ({
                                                                            showDrawer: !prev.showDrawer,
                                                                        }))
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Header>
                                                )}
                                                {!isDesktop && (
                                                    <Header
                                                        additionalClassName={classNames({
                                                            "background-white": !isDesktop,
                                                        })}>
                                                        <div className="navbar-header navbar-header-fixed p-0">
                                                            <div className="view-title" />
                                                            <div className="drawer-toolbar-item drawer-toolbar-item-right align-items-start">
                                                                <div className="d-flex">
                                                                    <Button
                                                                        bsStyle="link"
                                                                        image="images/download_bold.svg"
                                                                        disabled={fetchingDownload}
                                                                        onClick={() => this.handleDownload()}
                                                                    />
                                                                    <Button
                                                                        className="view-close"
                                                                        bsStyle="link"
                                                                        image="images/cross-mobile-blue.svg"
                                                                        onClick={this.handleClose}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Header>
                                                )}

                                                <div className={classNames({ "h-100": isDesktop })}>
                                                    <MainContainer viewContentClassName={!isDesktop ? "pt-0" : ""}>
                                                        <div>
                                                            <DebinTermsAndConditionsContent
                                                                isDesktop={isDesktop}
                                                                closeDrawer={() =>
                                                                    this.setState((prev) => ({
                                                                        showDrawer: !prev.showDrawer,
                                                                    }))
                                                                }
                                                            />
                                                        </div>
                                                    </MainContainer>
                                                </div>
                                            </div>
                                        )}
                                    </Drawer>
                                </Col>
                            </Container>
                            <Container
                                className="flex-grow align-items-center container-white pb-2"
                                gridClassName="form-content">
                                {continueButton}
                            </Container>
                        </Form>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    debinDetail: debinSelectors.getDebinDetail(state),
    accountName: debinSelectors.getAccountName(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    fetchingDownload: debinSelectors.getFetchingDownload(state),
    control: debinSelectors.getControl(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
                acceptTerms: Yup.bool()
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty"))
                    .oneOf([true], i18nUtils.get("enrollment.index.acceptTerms.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, debinDetail, accounts, loggedUser } = formikBag.props;

            const {
                id: idDebin,
                amount: { currency, quantity: amount },
                buyerDetail,
                sellerDetail,
                idVoucher,
            } = debinDetail;

            const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);

            const isOwn =
                buyerDetail?.documentNumber === sellerDetail?.documentNumber &&
                buyerDetail?.documentType === sellerDetail?.documentType;

            dispatch(
                debinActions.acceptIncomingRequestSend(
                    currency,
                    amount,
                    idDebin,
                    otp,
                    loggedUserAccount,
                    loggedUser.userId,
                    formikBag,
                    sellerDetail,
                    idVoucher,
                    isOwn,
                ),
            );
        },
    }),
)(AcceptDebinConfirm);
