import { getLimitDate } from "util/date";
import { globalTypes } from "reducers/types/global";
import * as configUtils from "util/config";

export const types = {
    LOAD_MULTIPLE_TRANSFERS_LIST_REQUEST: "multiple-transfers/LOAD_MULTIPLE_TRANSFERS_LIST_REQUEST",
    LOAD_MULTIPLE_TRANSFERS_LIST_SUCCESS: "multiple-transfers/LOAD_MULTIPLE_TRANSFERS_LIST_SUCCESS",
    LOAD_MULTIPLE_TRANSFERS_LIST_FAILURE: "multiple-transfers/LOAD_MULTIPLE_TRANSFERS_LIST_FAILURE",
    CREATE_MULTIPLE_TRANSFERS_PRE_REQUEST: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_PRE_REQUEST",
    CREATE_MULTIPLE_TRANSFERS_PRE_SUCCESS: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_PRE_SUCCESS",
    CREATE_MULTIPLE_TRANSFERS_PRE_FAILURE: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_PRE_FAILURE",
    CREATE_MULTIPLE_TRANSFERS_PREVIEW_REQUEST: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_PREVIEW_REQUEST",
    CREATE_MULTIPLE_TRANSFERS_PREVIEW_SUCCESS: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_PREVIEW_SUCCESS",
    CREATE_MULTIPLE_TRANSFERS_PREVIEW_FAILURE: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_PREVIEW_FAILURE",
    GET_TRANSFER_MULTIPLE_BY_ID_REQUEST: "multiple-transfers/GET_TRANSFER_MULTIPLE_BY_ID_REQUEST",
    GET_TRANSFER_MULTIPLE_BY_ID_SUCCESS: "multiple-transfers/GET_TRANSFER_MULTIPLE_BY_ID_SUCCESS",
    GET_TRANSFER_MULTIPLE_BY_ID_FAILURE: "multiple-transfers/GET_TRANSFER_MULTIPLE_BY_ID_FAILURE",
    CLEAN_MASSIVE_DATA: "multiple-transfers/CLEAN_MASSIVE_DATA",
    SET_REMEMBER_FORM_DATA: "multiple-transfers/SET_REMEMBER_FORM_DATA",
    GET_FILE_CONTENTS_REQUEST: "multiple-transfers/GET_FILE_CONTENTS_REQUEST",
    GET_FILE_CONTENTS_SUCCESS: "multiple-transfers/GET_FILE_CONTENTS_SUCCESS",
    CREATE_MULTIPLE_TRANSFERS_SEND_REQUEST: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_SEND_REQUEST",
    CREATE_MULTIPLE_TRANSFERS_SEND_SUCCESS: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_SEND_SUCCESS",
    CREATE_MULTIPLE_TRANSFERS_SEND_FAILURE: "multiple-transfers/CREATE_MULTIPLE_TRANSFERS_SEND_FAILURE",
    LOAD_MULTIPLE_TRANSFER: "multiple-transfers/LOAD_MULTIPLE_TRANSFER",
    SET_SHOW_DETAIL_MODAL: "multiple-transfers/SET_SHOW_DETAIL_MODAL",
    SET_FETCHING_STATUS: "multiple-transfers/SET_FETCHING_STATUS",
    DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_REQUEST: "multiple-transfers/DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_REQUEST",
    DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_SUCCESS: "multiple-transfers/DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_SUCCESS",
    DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_FAILURE: "multiple-transfers/DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    accounts: [],
    filters: {
        status: "TODOS",
        batchId: "",
        dateFrom: getLimitDate(0).toDate(),
        dateTo: "",
        amountFrom: "",
        amountTo: "",
        reference: "",
    },
    values: {
        account: "",
        executionDate: getLimitDate(0),
        file: null,
        description: "",
    },
    multipleTransfersList: [],
    activeTransferMultiple: null,
    summary: {},
    rememberMassiveData: false,
    snackbarMessage: "",
    showDetail: false,
    statusList: [],
    totalPages: 0,
    pageNumber: 0,
    batchDetail: [],
    totalPagesDetail: 0,
    pageNumberDetail: 0,
    bankConcepts: [],
    batchErrors: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        list,
        filters,
        accounts,
        summary,
        rememberMassiveData,
        showDetail,
        snackbarMessage,
        totalPages,
        pageNumber,
        transfer,
        isDesktop,
        statusList,
        batchDetail,
        totalPagesDetail,
        pageNumberDetail,
        bankConcepts,
        batchErrors,
        fetching,
    } = action;

    switch (action.type) {
        case types.LOAD_MULTIPLE_TRANSFERS_LIST_REQUEST:
            return {
                ...state,
                filters,
                fetching: true,
            };
        case types.LOAD_MULTIPLE_TRANSFERS_LIST_SUCCESS: {
            const batchList = pageNumber <= 1 || isDesktop ? list : [...state.multipleTransfersList, ...list];
            return {
                ...state,
                multipleTransfersList: batchList,
                pageNumber,
                totalPages,
                statusList,
                fetching: false,
            };
        }
        case types.LOAD_MULTIPLE_TRANSFERS_LIST_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case types.CREATE_MULTIPLE_TRANSFERS_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.CREATE_MULTIPLE_TRANSFERS_PRE_SUCCESS:
            return {
                ...state,
                accounts,
                fetching: false,
            };
        case types.CREATE_MULTIPLE_TRANSFERS_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_SUCCESS:
            return {
                ...state,
                summary,
                fetching: false,
            };
        case types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_FAILURE:
            return {
                ...state,
                summary,
                fetching: false,
            };
        case types.GET_TRANSFER_MULTIPLE_BY_ID_REQUEST:
            return {
                ...state,
                activeTransferMultiple:
                    !pageNumberDetail || pageNumberDetail <= 1 ? null : state?.activeTransferMultiple,
                fetching: isDesktop ? true : pageNumberDetail <= 1,
            };
        case types.GET_TRANSFER_MULTIPLE_BY_ID_SUCCESS: {
            const detailList =
                pageNumberDetail <= 1 || isDesktop ? batchDetail : [...state.batchDetail, ...batchDetail];
            return {
                ...state,
                activeTransferMultiple: transfer || state?.activeTransferMultiple,
                batchDetail: detailList,
                pageNumberDetail,
                totalPagesDetail,
                bankConcepts: bankConcepts || state?.bankConcepts,
                batchErrors: batchErrors || state?.batchErrors,
                fetching: false,
            };
        }
        case types.GET_TRANSFER_MULTIPLE_BY_ID_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.CLEAN_MASSIVE_DATA:
            return {
                ...state,
                summary: INITIAL_STATE.summary,
            };
        case types.SET_REMEMBER_FORM_DATA:
            return {
                ...state,
                rememberMassiveData,
            };
        case types.GET_FILE_CONTENTS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_FILE_CONTENTS_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.CREATE_MULTIPLE_TRANSFERS_SEND_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_MULTIPLE_TRANSFER:
            return {
                ...state,
                summary,
            };
        case types.SET_SHOW_DETAIL_MODAL:
            return {
                ...state,
                showDetail,
            };
        case globalTypes.SET_VOUCHER_SNACKBAR:
            return {
                ...state,
                snackbarMessage,
            };
        case types.SET_FETCHING_STATUS:
            return {
                ...state,
                fetching,
            };
        case types.DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_SUCCESS:
        case types.DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case globalTypes.RESET_SELECTED_ELEMENTS:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const actions = {
    getMultipleTransfersList: (filters, isDesktop) => ({
        type: types.LOAD_MULTIPLE_TRANSFERS_LIST_REQUEST,
        filters,
        isDesktop,
    }),
    multipleTransferPre: () => ({
        type: types.CREATE_MULTIPLE_TRANSFERS_PRE_REQUEST,
    }),
    cleanMassiveSummary: () => ({
        type: types.CLEAN_MASSIVE_DATA,
    }),
    createMultipleTransferPreview: (summary, formikBag) => ({
        type: types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_REQUEST,
        summary,
        formikBag,
    }),
    getFileContentsRequest: (idFile, deleteFile, idUserCreator) => ({
        type: types.GET_FILE_CONTENTS_REQUEST,
        idFile,
        deleteFile,
        idUserCreator,
    }),
    setRememberFormData: (rememberMassiveData) => ({
        type: types.SET_REMEMBER_FORM_DATA,
        rememberMassiveData,
    }),
    createMultipleTransfer: (otp, summary, formikBag) => ({
        type: types.CREATE_MULTIPLE_TRANSFERS_SEND_REQUEST,
        otp,
        summary,
        formikBag,
    }),
    loadMultipleTransfer: (summary) => ({
        type: types.LOAD_MULTIPLE_TRANSFER,
        summary,
    }),
    getMultipleTransferById: (id, pageNumberDetail, status, isDesktop) => ({
        type: types.GET_TRANSFER_MULTIPLE_BY_ID_REQUEST,
        id,
        pageNumberDetail,
        status,
        isDesktop,
    }),
    setShowDetail: (showDetail) => ({
        type: types.SET_SHOW_DETAIL_MODAL,
        showDetail,
    }),
    setFetching: (fetching) => ({
        type: types.SET_FETCHING_STATUS,
        fetching,
    }),
    downloadBatchDetail: (batchId, statusFilter, fileName) => ({
        type: types.DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_REQUEST,
        batchId,
        statusFilter,
        fileName,
    }),
};

export const selectors = {
    getFetching: ({ multipleTransfers }) => multipleTransfers.fetching,
    getFetchingDownload: ({ multipleTransfers }) => multipleTransfers.fetchingDownload,
    getFilters: ({ multipleTransfers }) => ({
        ...multipleTransfers.filters,
        dateTo: multipleTransfers.filters.dateTo || getLimitDate(configUtils.getInteger("frontend.MultipleTransfer.filter.max.dateTo", 30)).toDate(),
    }),
    getAccounts: ({ multipleTransfers }) => multipleTransfers.accounts,
    getMultipleTransfersList: ({ multipleTransfers }) => multipleTransfers.multipleTransfersList,
    getMultipleTransfer: ({ multipleTransfers }) => multipleTransfers.summary,
    getActiveMultipleTransfer: ({ multipleTransfers }) => multipleTransfers.activeTransferMultiple,
    getRememberMassiveData: ({ multipleTransfers }) => multipleTransfers.rememberMassiveData,
    getSummary: ({ multipleTransfers }) => multipleTransfers.summary,
    getValues: ({ multipleTransfers }) => multipleTransfers.values,
    showDetailModal: ({ multipleTransfers }) => multipleTransfers.showDetail,
    getSnackbarMessage: ({ multipleTransfers }) => multipleTransfers.snackbarMessage,
    getPageNumber: ({ multipleTransfers }) => multipleTransfers.pageNumber,
    getTotalPages: ({ multipleTransfers }) => multipleTransfers.totalPages,
    getStatusList: ({ multipleTransfers }) => multipleTransfers.statusList,
    getPageNumberDetail: ({ multipleTransfers }) => multipleTransfers.pageNumberDetail,
    getTotalPagesDetail: ({ multipleTransfers }) => multipleTransfers.totalPagesDetail,
    getBatchDetail: ({ multipleTransfers }) => multipleTransfers.batchDetail,
    getBankConcepts: ({ multipleTransfers }) => multipleTransfers.bankConcepts,
    getTransferBatchErrors: ({ multipleTransfers }) => multipleTransfers.batchErrors,
};
