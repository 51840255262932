/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { func, shape, bool, arrayOf, string } from "prop-types";
import { push } from "react-router-redux/actions";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import * as i18n from "util/i18n";
import Date from "pages/_components/fields/DateField";
import PageLoading from "pages/_components/PageLoading";
import IncomingDebinTable from "pages/debinPayments/_components/IncomingDebinTable";
import { allStatus } from "util/debin";
import ContextMenu from "pages/_components/ContextMenu";
import Yup from "yup";
import classNames from "classnames";
import * as configUtils from "util/config";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { saveLocation } from "util/transaction";
import moment from "moment";
import I18n from "pages/_components/I18n";

const FORM_ID = "debinPayments.incomingDebin.list";

class IncomingDebinList extends Component {
    state = {
        selectedDateFrom: null,
        selectedDateTo: null,
        showForm: false,
        hasChanged: false,
    };

    static propTypes = {
        accounts: arrayOf(shape({})).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                debinRecurrency: bool,
            }),
        }).isRequired,
        dateFrom: shape({}).isRequired,
        dateTo: shape({}).isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        filtersIncomingDebins: shape({
            dateFrom: shape({}).isRequired,
            dateTo: shape({}).isRequired,
            status: string.isRequired,
            recipient: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        loggedUser: shape({}).isRequired,
        setFieldValue: func.isRequired,
        values: shape({}).isRequired,
    };

    componentDidMount() {
        const { dispatch, setFieldValue, filtersIncomingDebins, accounts, loggedUser } = this.props;
        const { status, dateFrom, dateTo, seller } = filtersIncomingDebins;
        saveLocation(this.props);
        this.setState({ selectedDateFrom: dateFrom });
        this.setState({ selectedDateTo: dateTo });
        setFieldValue("dateFrom", dateFrom);
        setFieldValue("dateTo", dateTo);
        if (accounts.length === 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
        dispatch(debinActions.loadIncomingRequests({ status, dateFrom, dateTo, seller, pageNumber: 1 }));
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    rightContent = () => {
        const { dispatch, isDesktop, activeEnvironment } = this.props;
        if (!activeEnvironment.permissions.debinRecurrency) {
            return null;
        }
        const useApiV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);
        if (isDesktop) {
            return (
                <>
                    <Button
                        className="btn-outline"
                        block
                        onClick={() => dispatch(push("/payments/recurrent"))}
                        label={`debinPayments.incomingDebin.buyerRecurrences.button${useApiV3 ? "V3" : ""}`}
                    />
                </>
            );
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName={`align-self-center account-dropdown-header-font ${
                    !isDesktop ? "font-black-alpha" : ""
                }`}
                items={[
                    {
                        label: `debinPayments.incomingDebin.buyerRecurrences.button${useApiV3 ? "V3" : ""}`,
                        onClick: () => dispatch(push("/payments/recurrent")),
                    },
                ]}
            />
        );
    };

    renderEmptyState = () => {};

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    getMinDateFrom = () => {
        const daysBefore = configUtils.getInteger("frontend.debin.requests.min.dateFrom", 180);
        return moment().add(-daysBefore, "days");
    };

    handleChangeDateTo = (selectedDate) => {
        this.setState({ selectedDateTo: selectedDate });
    };

    getPreviousDate = (days) => {
        const currentDate = new Date();
        const myPastDate = new Date(currentDate);
        myPastDate.setDate(myPastDate.getDate() - days);
        return myPastDate;
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("debinPayments.incomingDebin.list.title")}</h1>
        </div>
    );

    clearFilters = () => {
        const { setFieldValue, dispatch } = this.props;

        const dateFrom = moment().add(-1, "weeks").toDate();
        const dateTo = moment().toDate();

        setFieldValue("recipient", "");
        setFieldValue("status", "INICIADO");
        setFieldValue("dateFrom", dateFrom);
        setFieldValue("dateTo", dateTo);
        
        this.setState({...this.state, hasChanged: false})

        dispatch(debinActions.loadIncomingRequests({ 
            status:"INICIADO", dateFrom, dateTo, seller:"", pageNumber: 1 }));
    };

    handleFilterButton = () => {
        const { values } = this.props;
        const {
            recipient,
            status,
            dateFrom,
            dateTo
        } = values;
        
        const usingDefaultValues = 
            (moment().add(-1, "weeks").toDate().toDateString() === dateFrom.toDateString()) &&
            (moment().toDate().toDateString() === dateTo.toDateString()) && status === "INICIADO" && !recipient;

        this.setState({...this.state, hasChanged: !usingDefaultValues})
    }

    render() {
        const { fetching, isDesktop, isSmallDesktop, values } = this.props;
        const { dateFrom, dateTo } = values;
        const { selectedDateFrom, selectedDateTo, showForm, hasChanged } = this.state;
        const defaultOptionStatus = { value: "Todos", label: "TODOS" };

        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={classNames({ "blue-main-header-mobile": !isDesktop })}
                        centerElement={isDesktop ? null : this.centerContentMobile}
                        centerContentClassName={classNames({ "mx-6 align-center": !isDesktop })}
                        rightContent={!isDesktop && this.rightContent}
                    />
                    {isDesktop && (
                        <>
                            <Head
                                title="debinPayments.incomingDebin.list.title"
                                rightContent={this.rightContent}
                                centerContentClassName="p-0"
                                nodeMessage={
                                    <I18n
                                        id=""
                                        defaultValue={configUtils.get("DEBIN.requestreceived.text")}
                                        component="p"
                                        componentProps={{ className: "hint-text hint-text-header" }}
                                    />
                                }
                                alwaysShowMessage
                            />
                        </>
                    )}
                </div>
                <PageLoading loading={fetching}>
                    <Container>
                        <div className="above-the-fold">
                            <Form autocomplete="off" className="above-the-fold">
                                {isDesktop && (
                                    <Container>
                                        <Col className="hide-filter__btn-content mt-5">
                                            <Button
                                                block
                                                bsStyle="outline"
                                                className="hide-filter__btn m-0"
                                                label={
                                                    showForm
                                                        ? "global.hide.filter"
                                                        : `${hasChanged ? "global.filter.active" : "global.see.filter"}`
                                                }
                                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                                onClick={() =>
                                                    this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))
                                                }
                                            />
                                            {this.state.hasChanged && (
                                                <Button
                                                    label="echeq.clean.filters"
                                                    className="hide-filter__clean-btn"
                                                    onClick={() => this.clearFilters()}
                                                    image="images/cross.svg"
                                                    imageStyle="circle-cross"
                                                />
                                            )}
                                        </Col>
                                    </Container>
                                )}
                                {!isDesktop && (
                                    <Col xs={12} className="d-flex justify-content-end pr-0 py-3">
                                        <div className="hide-filter__btn-content">
                                            <Button
                                                block
                                                bsStyle="outline"
                                                className="hide-filter__btn m-0"
                                                label={
                                                    showForm
                                                        ? "global.hide.filter"
                                                        : `${hasChanged ? "global.filter.active" : "global.see.filter"}`
                                                }
                                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                                onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                                            />
                                        </div>
                                        {this.state.hasChanged && (
                                            <Button
                                                label="global.clean.filters"
                                                className={"clean-filters-label font-light p-absolute top-25 mt-2 pt-1 min-height-auto mr-4 "}
                                                onClick={() => this.clearFilters()}
                                                image="images/cross.svg"
                                                imageStyle="circle-cross"
                                            />
                                        )}
                                    </Col>
                                )}
                                {showForm && (
                                    <Container
                                    className={`flex-grow mt-4 ${isDesktop ? "container-white pt-3 mb-0 pb-0 px-3" : "px-2"}`}
                                    gridClassName="form-content">
                                        <Col sm={12} md={4} lg={3}>
                                            <Field
                                                component={TextField}
                                                idForm={FORM_ID}
                                                name="recipient"
                                                type="text"
                                                pattern="[0-9]*"
                                                maxLength={configUtils.get("cuit.validation.character.amount")}
                                                minLength={0}
                                            />
                                        </Col>
                                        <Col sm={2} md={4} lg={isSmallDesktop ? 3 : 2}>
                                            <Field
                                                component={Selector}
                                                options={[defaultOptionStatus, ...allStatus]}
                                                idForm={FORM_ID}
                                                name="status"
                                                isRequired
                                                className="debin-select"
                                                searchable={isDesktop}
                                            />
                                        </Col>
                                        <Col sm={2} md={4} lg={2}>
                                            <Field
                                                idField="dateFrom"
                                                component={Date}
                                                endDate={dateTo}
                                                idForm={FORM_ID}
                                                name="dateFrom"
                                                hidePlaceholder
                                                selectsStart
                                                startDate={dateFrom}
                                                handleChange={this.handleChangeDateFrom}
                                                maxDate={selectedDateTo}
                                                minDate={this.getMinDateFrom()}
                                            />
                                        </Col>
                                        <Col sm={2} md={4} lg={2}>
                                            <Field
                                                idField="dateTo"
                                                component={Date}
                                                endDate={dateTo}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="dateTo"
                                                selectsEnd
                                                startDate={dateFrom}
                                                minDate={selectedDateFrom}
                                                isClearable={false}
                                                handleChange={this.handleChangeDateTo}
                                                maxDate={new Date()}
                                            />
                                        </Col>
                                        <Col sm={2} md={4} lg={2}>
                                            <div className="form-group mt-4">
                                                <Button
                                                    label="debinPayments.incomingDebin.list.button.search"
                                                    bsStyle="primary"
                                                    type="submit"
                                                    className="mt-25 py-25"
                                                    onClick={()=>this.handleFilterButton()}
                                                />
                                            </div>
                                        </Col>
                                    </Container>
                                )}
                                <PageLoading className="line-loader" loading={fetching}>
                                    <IncomingDebinTable isDesktop={isDesktop} />
                                </PageLoading>
                            </Form>
                        </div>
                    </Container>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    sentRequests: debinSelectors.getSentRequests(state),
    fetching: debinSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filtersIncomingDebins: debinSelectors.getFiltersIncomingDebins(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: ({ filtersIncomingDebins }) => ({
            ...filtersIncomingDebins,
            recipient: filtersIncomingDebins.seller,
        }),
        validationSchema: () =>
            Yup.object().shape({
                recipient: Yup.string()
                    .nullable()
                    .min(
                        configUtils.get("cuit.validation.character.amount"),
                        i18n.get(`charges.debin.sentRequests.recipient.invalid`),
                    )
                    .max(
                        configUtils.get("cuit.validation.character.amount"),
                        i18n.get(`charges.debin.sentRequests.recipient.invalid`),
                    ),
                dateTo: Yup.string()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.field.error.required`)),
                dateFrom: Yup.string()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.field.error.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;
            const { status, dateFrom, dateTo, recipient } = values;

            dispatch(
                debinActions.loadIncomingRequests({
                    status,
                    dateFrom,
                    dateTo,
                    seller: recipient,
                    pageNumber: 1,
                }),
            );
        },
    }),
)(IncomingDebinList);
