/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18nUtils from "util/i18n";
import { shape, bool } from "prop-types";
import classNames from "classnames";

class CreateCredinRecipientSummary extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        recipient: shape({}).isRequired,
    };

    render() {
        const { isDesktop, recipient } = this.props;
        const {
            recipientName,
            recipientDocumentType,
            recipientDocumentNumber,
            amount: { currency, quantity },
            accountNumber,
            cbu,
            localBank,
            reference,
            id,
        } = recipient;
        return (
            <>
                 <Col className={isDesktop && "pl-0 pt-2"} lg={3} xl={3} md={6} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${
                            isDesktop ? "" : "space-between debin-detail"
                        }`}
                        label="requestCredin.summary.idCredin.label"
                        data={id}
                    />
                </Col>

                <Col lg={3} xl={3} md={6} sm={12} className="pt-2">
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between debin-detail": !isDesktop,
                        })}
                        label="requestCredin.summary.owner.label"
                        data={recipientName}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={6} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between debin-detail": !isDesktop,
                        })}
                        label="requestCredin.summary.documentType.label"
                        data={`${i18nUtils.get(
                            `documentType.label.${recipientDocumentType.toUpperCase()}`,
                        )} ${recipientDocumentNumber}`}
                    />
                </Col>

                <Col lg={3} xl={3} md={6} sm={12} className="pt-2">
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between debin-detail": !isDesktop,
                        })}
                        label="requestCredin.summary.amount.label"
                        data={<FormattedAmount quantity={quantity} currency={currency} notBold />}
                    />
                </Col>
                <Col lg={3} xl={3} md={6} sm={12} className="pt-2">
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between debin-detail": !isDesktop,
                        })}
                        label="requestCredin.summary.creditAccount.label"
                        data={accountNumber}
                    />
                </Col>

                <Col lg={3} xl={3} md={6} sm={12} className="pt-2">
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between debin-detail": !isDesktop,
                        })}
                        label="requestCredin.summary.cbu.label"
                        data={cbu}
                    />
                </Col>

                <Col lg={3} xl={3} md={6} sm={12} className="pt-2">
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between debin-detail": !isDesktop,
                        })}
                        label="requestCredin.summary.bankName.label"
                        data={localBank}
                    />
                </Col>

                <Col lg={3} xl={3} md={6} sm={12} className="pt-2">
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between debin-detail": !isDesktop,
                        })}
                        label="requestCredin.summary.reference.label"
                        data={reference}
                    />
                </Col>
            </>
        );
    }
}

export default CreateCredinRecipientSummary;
