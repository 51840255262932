import React from "react";
import { arrayOf, bool, element, func, string, shape } from "prop-types";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";

export default function ContextMenu({
    isDesktop,
    children,
    items,
    styleContext,
    contributorDropdown,
    imageDropdown,
    dropBtnClass,
    listClassName,
    dropClass,
    additionalClass,
}) {
    ContextMenu.propTypes = {
        isDesktop: bool,
        children: arrayOf(element),
        items: arrayOf(shape({ label: string.isRequired, onClick: func, disabled: bool })),
        styleContext: shape({}),
        contributorDropdown: bool,
        imageDropdown: string,
        dropBtnClass: string,
        listClassName: string,
        dropClass: string,
        additionalClass: string,
    };

    ContextMenu.defaultProps = {
        isDesktop: true,
        children: null,
        items: null,
        styleContext: {},
        contributorDropdown: false,
        imageDropdown: null,
        dropBtnClass: "",
        listClassName: null,
        dropClass: "",
        additionalClass: "",
    };

    return (
        ((items && items.length > 0) || children) ? (
            <div
                className={`${isDesktop ? "account-list-div" : "context-mobile"}  ${additionalClass || ""}`}
                onClick={(e) => e.preventDefault()}
                style={styleContext}
                onKeyDown={(e) => e.preventDefault()}
                role="button"
                tabIndex={0}>
                <Dropdown
                    image={imageDropdown || "context-menu.svg"}
                    bsStyle="link"
                    buttonClass={`context-menu-button context-menu-dropdown view-back ${
                        isDesktop ? "account-list-div" : "ml-0"
                    } ${contributorDropdown ? "contributor-dropdown" : ""} ${dropBtnClass}`}
                    listClassName={listClassName}
                    imageStyle="context-menu-svg-img"
                    dropClass={dropClass}
                    fitWidth>
                    {items.map((item) => (
                        <Button
                            label={item.label}
                            onClick={item.onClick}
                            disabled={item.disabled}
                            className="dropdown__item-btn btn-b-none dropdown__item-btn-custom justify-content-start my-0 ml-0 w-100"
                        />
                    ))}
                    {children}
                </Dropdown>
            </div>
        ) : null
    );
}
