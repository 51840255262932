import React, { Component } from "react";
import { shape, string, arrayOf } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Heading from "pages/_components/Heading";
import { Col } from "react-bootstrap";
import FormattedDate from "pages/_components/FormattedDate";
import { isFinalState } from "util/transaction";

class CredinTransactionData extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        recipient: arrayOf(shape({})).isRequired,
        accountName: string.isRequired,
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const { summary, recipient, accountName } = this.props;
        const { concept, description, submitDateTime, idTransactionStatus } = summary;
        const {
            recipientName,
            recipientDocumentType,
            recipientDocumentNumber,
            amount: { currency, quantity },
            accountNumber,
            cbu,
            localBank,
            reference,
            comprobante,
        } = recipient;
        return (
            summary && (
                <>
                    {isFinalState(idTransactionStatus) && (
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="requestCredin.confirm.table.header.date"
                                data={
                                    <p className="data-desc m-0">
                                        <FormattedDate date={submitDateTime} dateFormat="dd/MM/yyyy" showTime />
                                    </p>
                                }
                            />
                        </Col>
                    )}
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.ticket"
                            data={
                                <p className="data-desc m-0">{summary?.idTransaction.substring(0, 8).toUpperCase()}</p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.debitAccount"
                            data={<p className="data-desc m-0">{accountName}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0  mb-3">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="credin.transaction.id.credin"
                            data={<p className="data-desc m-0">{recipient.id || ""}</p>}
                        />
                    </Col>

                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`requestCredin.confirm.table.header.type${cbu?.startsWith("000") ? "cvu" : "cbu"}`}
                            data={<p className="data-desc m-0">{cbu}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.creditAccount.label"
                            data={<p className="data-desc m-0">{accountNumber}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.creditAccount.cuit"
                            data={
                                <div>
                                    <span className="mt-2">
                                        {recipientDocumentType.concat(" ", recipientDocumentNumber)}
                                    </span>
                                </div>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.creditAccount.owner"
                            data={<p className="data-desc m-0">{recipientName}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`requestCredin.confirm.table.header.creditAccount.${localBank && localBank!== "000" ? "bank": "entity"}`}
                            data={<p className="data-desc m-0">{localBank && localBank!== "000" ? localBank : "-"}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0 mb-3">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.creditAccount.reference"
                            data={<p className="data-desc m-0">{reference}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.amount"
                            data={
                                <p className="data-desc m-0">
                                    <FormattedAmount quantity={quantity} currency={currency} notBold />
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.ticket"
                            data={<p className="data-desc m-0">{comprobante}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.concept"
                            data={<p className="data-desc m-0">{i18nUtils.get(`transfers.concept.${concept}`)}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="requestCredin.confirm.table.header.description"
                            data={<p className="data-desc m-0">{description}</p>}
                        />
                    </Col>
                </>
            )
        );
    }
}

export default connect(null)(CredinTransactionData);
