import { makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "frequentDestination/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "frequentDestination/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "frequentDestination/LOAD_LIST_SUCCESS",
    FREQUENT_DESTINATION_CREATE_PREVIEW: "frequentDestination/FREQUENT_DESTINATION_CREATE_PREVIEW",
    FREQUENT_DESTINATION_CREATE_PREVIEW_FAILED: "frequentDestination/FREQUENT_DESTINATION_CREATE_PREVIEW_FAILED",
    FREQUENT_DESTINATION_CREATE_PREVIEW_SUCCESS: "frequentDestination/FREQUENT_DESTINATION_CREATE_PREVIEW_SUCCESS",
    FREQUENT_DESTINATION_CREATE_FAILURE: `frequentDestination/FREQUENT_DESTINATION_CREATE_FAILURE`,
    FREQUENT_DESTINATION_CREATE_REQUEST: `frequentDestination/FREQUENT_DESTINATION_CREATE_REQUEST`,
    FREQUENT_DESTINATION_CREATE_SUCCESS: `frequentDestination/FREQUENT_DESTINATION_CREATE_SUCCESS`,
    FREQUENT_DESTINATION_RESET: "frequentDestination/FREQUENT_DESTINATION_RESET",
    DELETE_FREQUENT_DESTINATION_PREVIEW_REQUEST: "frequentDestination/DELETE_FREQUENT_DESTINATION_PREVIEW_REQUEST",
    DELETE_FREQUENT_DESTINATION_PREVIEW_FAILURE: "frequentDestination/DELETE_FREQUENT_DESTINATION_PREVIEW_FAILURE",
    DELETE_FREQUENT_DESTINATION_PREVIEW_SUCCESS: "frequentDestination/DELETE_FREQUENT_DESTINATION_PREVIEW_SUCCESS",
    DELETE_FREQUENT_DESTINATION_REQUEST: "frequentDestination/DELETE_FREQUENT_DESTINATION_REQUEST",
    DELETE_FREQUENT_DESTINATION_FAILURE: "frequentDestination/DELETE_FREQUENT_DESTINATION_FAILURE",
    DELETE_FREQUENT_DESTINATION_SUCCESS: "frequentDestination/DELETE_FREQUENT_DESTINATION_SUCCESS",
    MODIFY_FREQUENT_DESTINATION_PRE_REQUEST: "frequentDestination/MODIFY_FREQUENT_DESTINATION_PRE_REQUEST",
    MODIFY_FREQUENT_DESTINATION_PRE_FAILURE: "frequentDestination/MODIFY_FREQUENT_DESTINATION_PRE_FAILURE",
    MODIFY_FREQUENT_DESTINATION_PRE_SUCCESS: "frequentDestination/MODIFY_FREQUENT_DESTINATION_PRE_SUCCESS",
    MODIFY_FREQUENT_DESTINATION_PREVIEW_REQUEST: "frequentDestination/MODIFY_FREQUENT_DESTINATION_PREVIEW_REQUEST",
    MODIFY_FREQUENT_DESTINATION_PREVIEW_FAILURE: "frequentDestination/MODIFY_FREQUENT_DESTINATION_PREVIEW_FAILURE",
    MODIFY_FREQUENT_DESTINATION_PREVIEW_SUCCESS: "frequentDestination/MODIFY_FREQUENT_DESTINATION_PREVIEW_SUCCESS",
    MODIFY_FREQUENT_DESTINATION_REQUEST: "frequentDestination/MODIFY_FREQUENT_DESTINATION_REQUEST",
    MODIFY_FREQUENT_DESTINATION_FAILURE: "frequentDestination/MODIFY_FREQUENT_DESTINATION_FAILURE",
    MODIFY_FREQUENT_DESTINATION_SUCCESS: "frequentDestination/MODIFY_FREQUENT_DESTINATION_SUCCESS",
    FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_REQUEST:
        "frequentDestination/FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_REQUEST",
    FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_FAILURE:
        "frequentDestination/FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_FAILURE",
    FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_SUCCESS:
        "frequentDestination/FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_SUCCESS",
    FREQUENT_DESTINATION_REQUEST: "frequentDestination/FREQUENT_DESTINATION_REQUEST",
    FREQUENT_DESTINATION_FAILURE: "frequentDestination/FREQUENT_DESTINATION_FAILURE",
    FREQUENT_DESTINATION_SUCCESS: "frequentDestination/FREQUENT_DESTINATION_SUCCESS",
    GET_CBU_ACCOUNT_INFORMATION: "frequentDestination/GET_CBU_ACCOUNT_INFORMATION",
    FREQUENT_DESTINATIONS_DOWLOAD: "frequentDestination/FREQUENT_DESTINATIONS_DOWLOAD",
    FREQUENT_DESTINATIONS_DOWLOAD_SUCCESS: "frequentDestination/FREQUENT_DESTINATIONS_DOWLOAD_SUCCESS",
    FREQUENT_DESTINATIONS_DOWLOAD_FAILURE: "frequentDestination/FREQUENT_DESTINATIONS_DOWLOAD_FAILURE",
    FREQUENT_DESTINATIONS_SET_FETCHING: "frequentDestination/FREQUENT_DESTINATION_SET_FETCHING",
};

export const INITIAL_STATE = {
    listFrequentDestinations: [],
    moreDestinations: false,
    fetching: false,
    fetchingExport: false,
    frequentDestination: {},
    credentialGroups: [],
    isSubmitting: false,
    frequentDestinationDelete: {},
    frequentDestinationModify: {},
    reference: "",
    email: "",
    idDestinationModify: 0,
    alias: "",
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        frequentDestinations,
        moreStatements,
        data,
        credentialGroupsData,
        destinationDelete,
        destinationModify,
        nameModify,
        emailModify,
        idFrequentDestination,
    } = action;

    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LOAD_LIST_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_LIST_SUCCESS:
            return {
                ...state,
                listFrequentDestinations: frequentDestinations,
                moreDestinations: moreStatements,
                frequentDestinationModify: {},
                frequentDestinationDelete: {},
                fetching: false,
            };
        case types.FREQUENT_DESTINATION_CREATE_PREVIEW:
            return {
                ...state,
                fetching: true,
            };
        case types.FREQUENT_DESTINATION_CREATE_PREVIEW_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case types.FREQUENT_DESTINATION_CREATE_PREVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                frequentDestination: data.frequentDestination,
                alias: data.alias,
                credentialGroups: credentialGroupsData,
            };
        case types.FREQUENT_DESTINATION_CREATE_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.FREQUENT_DESTINATION_CREATE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.FREQUENT_DESTINATION_RESET:
            return {
                ...state,
                frequentDestination: INITIAL_STATE.frequentDestination,
                alias: INITIAL_STATE.alias,
            };
        case types.DELETE_FREQUENT_DESTINATION_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DELETE_FREQUENT_DESTINATION_PREVIEW_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.DELETE_FREQUENT_DESTINATION_PREVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                frequentDestinationDelete: destinationDelete,
                credentialGroups: credentialGroupsData,
            };
        case types.DELETE_FREQUENT_DESTINATION_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DELETE_FREQUENT_DESTINATION_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.DELETE_FREQUENT_DESTINATION_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.MODIFY_FREQUENT_DESTINATION_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.MODIFY_FREQUENT_DESTINATION_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.MODIFY_FREQUENT_DESTINATION_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                frequentDestinationModify: destinationModify,
            };
        case types.MODIFY_FREQUENT_DESTINATION_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.MODIFY_FREQUENT_DESTINATION_PREVIEW_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.MODIFY_FREQUENT_DESTINATION_PREVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                credentialGroups: credentialGroupsData,
                reference: nameModify,
                email: emailModify,
                idDestinationModify: idFrequentDestination,
            };
        case types.MODIFY_FREQUENT_DESTINATION_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.MODIFY_FREQUENT_DESTINATION_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.MODIFY_FREQUENT_DESTINATION_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                frequentDestination: data.frequentDestination,
                alias: data.alias,
            };
        case types.FREQUENT_DESTINATION_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.FREQUENT_DESTINATION_SUCCESS:
            return {
                ...state,
                fetching: false,
                frequentDestination: data.frequentDestinations[0],
            };
        case types.FREQUENT_DESTINATIONS_DOWLOAD:
            return {
                ...state,
                fetchingExport: true,
            };
        case types.FREQUENT_DESTINATIONS_DOWLOAD_SUCCESS:
            return {
                ...state,
                fetchingExport: false,
            };
        case types.FREQUENT_DESTINATIONS_DOWLOAD_FAILURE:
            return {
                ...state,
                fetchingExport: false,
            };
        case types.FREQUENT_DESTINATIONS_SET_FETCHING:
            return {
                ...state,
                fetching: true,
            };
        default:
            return state;
    }
};

export const actions = {
    loadListRequest: (idEnvironment, filters) => ({
        type: types.LOAD_LIST_REQUEST,
        idEnvironment,
        filters,
    }),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),
    resetListFrequentDestinations: () => ({
        type: types.FREQUENT_DESTINATION_RESET,
    }),
    downloadListFrequentDestinations: () => ({
        type: types.FREQUENT_DESTINATIONS_DOWLOAD,
    }),
    downloadListFrequentDestinationsSuccess: () => ({
        type: types.FREQUENT_DESTINATIONS_DOWLOAD_SUCCESS,
    }),
    downloadListFrequentDestinationsFailure: () => ({
        type: types.FREQUENT_DESTINATIONS_DOWLOAD_FAILURE,
    }),
    frequentDestinationCreatePreview: makeActionCreator(
        types.FREQUENT_DESTINATION_CREATE_PREVIEW,
        "params",
        "formikBag",
    ),
    frequentDestinationCreatePreviewFailed: makeActionCreator(types.FREQUENT_DESTINATION_CREATE_PREVIEW_FAILED),
    frequentDestinationCreatePreviewSuccess: makeActionCreator(
        types.FREQUENT_DESTINATION_CREATE_PREVIEW_SUCCESS,
        "data",
        "credentialGroupsData",
    ),
    frequentDestinationCreateRequest: makeActionCreator(
        types.FREQUENT_DESTINATION_CREATE_REQUEST,
        "frequentDestination",
        "otp",
        "formikBag",
    ),
    frequentDestinationCreateSuccess: makeActionCreator(types.FREQUENT_DESTINATION_CREATE_SUCCESS, "data"),
    frequentDestinationCreateFailure: makeActionCreator(types.FREQUENT_DESTINATION_CREATE_FAILURE),
    deleteFrequentDestinationPreviewRequest: (idFrequentDestination) => ({
        type: types.DELETE_FREQUENT_DESTINATION_PREVIEW_REQUEST,
        idFrequentDestination,
    }),
    deleteFrequentDestinationPreviewFailure: makeActionCreator(types.DELETE_FREQUENT_DESTINATION_PREVIEW_FAILURE),
    deleteFrequentDestinationPreviewSuccess: makeActionCreator(
        types.DELETE_FREQUENT_DESTINATION_PREVIEW_SUCCESS,
        "data",
        "credentialGroupsData",
    ),
    deleteFrequentDestinationRequest: makeActionCreator(
        types.DELETE_FREQUENT_DESTINATION_REQUEST,
        "idFrequentDestination",
        "otp",
        "formikBag",
    ),
    deleteFrequentDestinationFailure: makeActionCreator(types.DELETE_FREQUENT_DESTINATION_FAILURE),
    deleteFrequentDestinationSuccess: makeActionCreator(types.DELETE_FREQUENT_DESTINATION_SUCCESS, "data"),

    modifyFrequentDestinationPreRequest: makeActionCreator(
        types.MODIFY_FREQUENT_DESTINATION_PRE_REQUEST,
        "idFrequentDestination",
        "fromTransfers",
    ),
    modifyFrequentDestinationPreFailure: makeActionCreator(types.MODIFY_FREQUENT_DESTINATION_PRE_FAILURE),
    modifyFrequentDestinationPreSuccess: makeActionCreator(types.MODIFY_FREQUENT_DESTINATION_PRE_SUCCESS, "data"),
    modifyFrequentDestinationPreviewRequest: makeActionCreator(
        types.MODIFY_FREQUENT_DESTINATION_PREVIEW_REQUEST,
        "params",
        "formikBag",
        "credentialGroups",
    ),
    modifyFrequentDestinationPreviewFailure: makeActionCreator(types.MODIFY_FREQUENT_DESTINATION_PREVIEW_FAILURE),
    modifyFrequentDestinationPreviewSuccess: makeActionCreator(
        types.MODIFY_FREQUENT_DESTINATION_PREVIEW_SUCCESS,
        "data",
    ),
    modifyFrequentDestinationRequest: makeActionCreator(
        types.MODIFY_FREQUENT_DESTINATION_REQUEST,
        "idFrequentDestination",
        "name",
        "email",
        "otp",
        "formikBag",
    ),
    modifyFrequentDestinationFailure: makeActionCreator(types.MODIFY_FREQUENT_DESTINATION_FAILURE),
    modifyFrequentDestinationSuccess: makeActionCreator(types.MODIFY_FREQUENT_DESTINATION_SUCCESS, "data"),
    createFrequentDestinationTransferPreRequest: makeActionCreator(
        types.FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_REQUEST,
        "cbuAlias",
    ),
    createFrequentDestinationTransferPreFailure: makeActionCreator(
        types.FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_FAILURE,
    ),
    createFrequentDestinationTransferPreSuccess: makeActionCreator(
        types.FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_SUCCESS,
        "data",
    ),
    getFrequentDestinationRequest: makeActionCreator(types.FREQUENT_DESTINATION_REQUEST, "idEnvironment", "cbu"),
    getFrequentDestinationFailure: makeActionCreator(types.FREQUENT_DESTINATION_FAILURE),
    getFrequentDestinationSuccess: makeActionCreator(types.FREQUENT_DESTINATION_SUCCESS, "data"),
    getCbuAccountInformation: makeActionCreator(types.GET_CBU_ACCOUNT_INFORMATION, "params", "formikBag"),
    setFetching: makeActionCreator(types.FREQUENT_DESTINATIONS_SET_FETCHING),
};

export const selectors = {
    getListFrequentDestinations: ({ frequentDestination }) => frequentDestination.listFrequentDestinations,
    getMoreDestinations: ({ frequentDestination }) => frequentDestination.moreDestinations,
    getEnviromentId: ({ session }) => session.activeEnvironment.id,
    getCredentialGroups: ({ frequentDestination }) => frequentDestination.credentialGroups,
    getFrequentDestination: ({ frequentDestination }) => frequentDestination.frequentDestination,
    getIsSubmitting: ({ frequentDestination }) => frequentDestination.isSubmitting,
    getFrequentDestinationDelete: ({ frequentDestination }) => frequentDestination.frequentDestinationDelete,
    getFetching: ({ frequentDestination }) => frequentDestination.fetching,
    getFetchingExport: ({ frequentDestination }) => frequentDestination.fetchingExport,
    getFrequentDestinationModify: ({ frequentDestination }) => frequentDestination.frequentDestinationModify,
    getReference: ({ frequentDestination }) => frequentDestination.reference,
    getEmail: ({ frequentDestination }) => frequentDestination.email,
    getIdDestinationModify: ({ frequentDestination }) => frequentDestination.idDestinationModify,
    getAlias: ({ frequentDestination }) => frequentDestination.alias,
};
