import React, { Component } from "react";
import { number, string } from "prop-types";

import FormattedAmount from "pages/_components/FormattedAmount";

import * as configUtils from "util/config";
import * as i18n from "util/i18n";

class Limits extends Component {
    static propTypes = {
        currency: number.isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
        customLimitLabel: string,
    };

    static defaultProps = {
        customLimitLabel: null,
    };

    getTruncNumber = (value) => {
        const numberText = value?.toString();
        const index = numberText?.indexOf(".");

        if (!numberText || index === -1) {
            return value;
        }
        const end = numberText.length >= index + 3 ? index + 3 : index + 2;
        return parseFloat(numberText.slice(0, end));
    };

    render() {
        const { limit, limitUsed, currency, customLimitLabel } = this.props;

        let limitVar = limit;
        let limitUsedVar = limitUsed;

        if (currency === 2) {
            const dolarQuot = parseFloat(configUtils.get("CMF_Dolar_Valor", "98.90"));
            limitVar = this.getTruncNumber(limitVar / dolarQuot);
            limitUsedVar = this.getTruncNumber(limitUsedVar / dolarQuot);
        }

        return (
            <div className="p-relative">
                <div className="transfer-limits">
                    <div className="item-limit">
                        <span className="transfer-limit-label">
                            {customLimitLabel || i18n.get("transfers.limit.label")}
                        </span>
                        <FormattedAmount
                            className="transfer-limit-amount"
                            currency={currency?.toString()}
                            quantity={limitVar}
                            notBold
                        />
                    </div>
                    <div className="item-limit">
                        <span className="transfer-limit-label">{i18n.get("transfers.available.label")}</span>
                        <FormattedAmount
                            className="transfer-limit-amount"
                            currency={currency?.toString()}
                            quantity={limitUsedVar}
                            notBold
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Limits;
