import React from "react";
import { getAccountDescription } from "util/accounts";

const TransactionFrom = {
    "debin.incoming.accept.send": ({ sellerDetail }) => sellerDetail?.titular,
    "debinCVU.incoming.accept.send": ({ sellerDetail }) => sellerDetail?.titular,
    "load.debin.recurrency": ({ sellerName }) => sellerName,
    "change.status.debin.recurrency": ({ sellerName }) => sellerName,
    "accept.return.echeqs": ({ solicitantName }) => solicitantName,
    "cesion.echeq.activity": ({ echeqList }) =>
        echeqList?.map((echeq) => <div key={echeq.cheque_numero}> {echeq.from?.emisorRazonSocial} </div>),
    "endorse.echeq.activity": ({ echeqList }) =>
        echeqList?.map((echeq) => <div key={echeq.cheque_numero}> {echeq.from?.emisorRazonSocial} </div>),
    "negotiate.echeq.activity": ({ echeqList }) =>
        echeqList?.map((echeq) => <div key={echeq.cheque_numero}> {echeq.from?.emisorRazonSocial} </div>),
    "transfers.internal.send": ({ formikBag }) => {
        if (formikBag?.props?.summary?.debitAccount?.number) {
            return getAccountDescription(formikBag?.props?.summary?.debitAccount);
        }
        return "";
    },
};

export default TransactionFrom;
