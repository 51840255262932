/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { Col } from "react-bootstrap";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import Date from "pages/_components/fields/DateField";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";
import Button from "pages/_components/Button";
import AlfaNumericStgField from "pages/_components/fields/AlfaNumericStgField";

import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { getLimitDate } from "util/date";
import classNames from "classnames";
import AmountField from "pages/_components/fields/formik/AmountField";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { actions as multipleTransferActions } from "reducers/multiple-transfers";

import moment from "moment";

const FORM_ID = "multiple.transfers";

function MultipleTransferFilters({
    dispatch,
    isSmallDesktop,
    isDesktop,
    values,
    setValues,
    filters,
    statusList,
    fetching,
}) {
    const [show, setShow] = useState(false);
    const [statusBatch, setStatusBatch] = useState([]);
    const defaultStatusOption = { value: "TODOS", label: "TODOS" };
    const [hasChanged, setHasChanged] = useState(false);

    const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

    useEffect(() => {
        setValues({ 
            ...filters, 
            ...(enableRangeDatefield &&{
                dates: [filters.dateFrom, filters.dateTo] 
            })});
    }, []);

    useEffect(() => {
        setStatusBatch(
            statusList?.map((state) => ({
                value: state,
                label: i18n.get(`multiple.transfers.status.${state.toLowerCase()}`),
            })),
        );
    }, [statusList]);

    const isDateChanged = (date1, date2) => {
        const dateOne = date1 && date1 instanceof moment ? date1.toDate() : date1;
        const dateTwo = date2 && date2 instanceof moment ? date2.toDate() : date2;
        return dateOne?.toDateString() !== dateTwo?.toDateString()
    }

    const handleFilterButton = (values) => {
        const { status, batchId, amountFrom, amountTo, reference, dateFrom, dateTo, dates } = values;
        const dateFromChanged = isDateChanged(enableRangeDatefield ? dates[0] : dateFrom, getLimitDate(0).toDate());
        const dateToChanged = isDateChanged(enableRangeDatefield ? dates[1] : dateTo, getLimitDate(
            configUtils.getInteger("frontend.MultipleTransfer.filter.max.dateTo", 30)
        ).toDate());

        if (
            status !== "TODOS" || !(
            !batchId ||
            !amountFrom ||
            !amountTo ||
            !reference) ||
            dateFromChanged ||
            dateToChanged
        ) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    };

    const cleanFilters = () => {
        const defaultFilters = {
            status: "TODOS",
            batchId: "",
            dateFrom: getLimitDate(0).toDate(),
            dateTo: getLimitDate(
                configUtils.getInteger("frontend.MultipleTransfer.filter.max.dateTo", 30),
            ).toDate(),
            amountFrom: "",
            amountTo: "",
            reference: "",
            pageNumber: 1,
        };
        setValues({
            ...defaultFilters,
            ...(enableRangeDatefield ? {
                dates: [
                    getLimitDate(0).toDate(),
                    getLimitDate(
                        configUtils.getInteger("frontend.MultipleTransfer.filter.max.dateTo", 30),
                    ).toDate(),
                ]
            } : {}),
        });
        dispatch(multipleTransferActions.getMultipleTransfersList(defaultFilters, isDesktop));
        setHasChanged(false);
    };

    return (
        <>
            {!isDesktop ? (
                <div className="hide-filter__btn-content">
                    <Button
                        block
                        bsStyle="outline"
                        className="hide-filter__btn m-0"
                        label={
                            show ? "global.hide.filter" : `${hasChanged ? "global.filter.active" : "global.see.filter"}`
                        }
                        image={show ? "images/eye-off.svg" : "images/eye.svg"}
                        onClick={() => setShow(!show)}
                    />
                    {hasChanged && (
                        <Button
                            label="global.clean.filters"
                            className="hide-filter__clean-btn"
                            onClick={()=>cleanFilters()}
                            image="images/cross.svg"
                            imageStyle="circle-cross"
                        />
                    )}
                </div>
            ) : (
                undefined
            )}
            <Form className="transfer-multiple__filters">
                {isDesktop && (
                    <Container>
                        <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className={classNames(
                                "ml-auto my-0 pt-0",
                                { "hide-filter__btn-content": isDesktop },
                                { "pb-3": isDesktop && show },
                            )}>
                            <div className="d-flex flex-column align-items-center">
                                <Button
                                    block
                                    bsStyle="outline"
                                    className="hide-filter__btn m-0"
                                    label={
                                        show
                                            ? "global.hide.filter"
                                            : `${hasChanged ? "global.filter.active" : "global.see.filter"}`
                                    }
                                    image={show ? "images/eye-off.svg" : "images/eye.svg"}
                                    onClick={() => setShow(!show)}
                                />
                                {hasChanged && (
                                    <Button
                                        label="global.clean.filters"
                                        className="hide-filter__clean-btn"
                                        onClick={()=>cleanFilters()}
                                        image="images/cross.svg"
                                        imageStyle="circle-cross"
                                    />
                                )}
                            </div>
                        </Col>
                    </Container>
                )}
                {show && (
                    <>
                        <Container gridClassName={isDesktop && "background-white pt-4"}>
                            <Col xs={12} md={6} lg={enableRangeDatefield ? 4 : 3} className={!isDesktop ? "mb-3" : ""}>
                                <Field
                                    component={Selector}
                                    hidePlaceholder
                                    options={[defaultStatusOption, ...statusBatch]}
                                    idForm={FORM_ID}
                                    name="status"
                                    isRequired
                                    value={values?.status}
                                    formGroupClassName="my-0"
                                />
                            </Col>
                            <Col xs={12} md={6} lg={enableRangeDatefield ? 4 : 3} className={!isDesktop ? "mb-3" : ""}> 
                                <Field
                                    component={AlfaNumericStgField}
                                    hidePlaceholder
                                    isNumeric
                                    idForm={FORM_ID}
                                    name="batchId"
                                    isRequired
                                    value={values?.batchId}
                                    formGroupClassName="my-0"
                                    labelText={`${FORM_ID}.idBatch.label`}
                                />
                            </Col>
                            {
                                enableRangeDatefield ?
                                    <Col xs={12} lg={4} className={!isDesktop ? "align-items-end d-flex mb-3" : "align-items-end d-flex"}>
                                        <Field
                                            component={RangeDatePicker}
                                            idForm={FORM_ID}
                                            name="dates"
                                            required
                                            maxDate={getLimitDate(
                                                configUtils.getInteger("frontend.MultipleTransfer.filter.max.dateTo", 30),
                                            )}
                                            value={values.dates}
                                        />
                                    </Col>
                                :
                                    <>
                                        <Col xs={6} lg={3} className={!isDesktop ? "mb-3" : ""}>
                                            <Field
                                                component={Date}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="dateFrom"
                                                isRequired
                                                maxDate={values?.dateTo}
                                                formikValueFirst
                                                formGroupClassName="my-0"
                                                onKeyDown={(e) => e.preventDefault()}
                                                customKey={!isDesktop ? `${FORM_ID}.dates.label` : undefined}
                                                errorClassName="p-absolute"
                                            />
                                        </Col>
                                        <Col xs={6} lg={3} className={!isDesktop ? "align-items-end mb-3" : "align-items-end m-0"}>
                                            <Field
                                                component={Date}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="dateTo"
                                                isRequired
                                                minDate={values?.dateFrom}
                                                maxDate={getLimitDate(
                                                    configUtils.getInteger("frontend.MultipleTransfer.filter.max.dateTo", 30),
                                                )}
                                                formikValueFirst
                                                formGroupClassName="my-0 w-100"
                                                hideLabel={!isDesktop}
                                                onKeyDown={(e) => e.preventDefault()}
                                                errorClassName="p-absolute"
                                            />
                                        </Col>
                                    </>
                            }
                        </Container>
                        <Container className={isDesktop && "py-4 background-white"} rowClassName="space-between d-flex">
                            <Col xs={12} md={10} lg={!enableRangeDatefield ? (!isSmallDesktop ? 7 : 9) : 10} className="px-0">
                                <Col xs={12} md={4} lg={4} className={!isDesktop ? "mb-3" : ""}>
                                    <Field
                                        component={AmountField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="amountFrom"
                                        isRequired
                                        plainValue
                                        hideCurrency
                                        fixedDecimalScale
                                        value={values?.amountFrom}
                                        formGroupClassName="my-0"
                                        errorClassName="p-absolute"
                                        maxLength={20}
                                    />
                                </Col>
                                <Col xs={12} md={4} lg={4} className={!isDesktop ? "mb-3" : ""}>
                                    <Field
                                        component={AmountField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="amountTo"
                                        isRequired
                                        plainValue
                                        hideCurrency
                                        fixedDecimalScale
                                        value={values?.amountTo}
                                        formGroupClassName="my-0"
                                        errorClassName="p-absolute"
                                        maxLength={20}
                                    />
                                </Col>
                                <Col xs={12} md={4} lg={4} className={!isDesktop ? "mb-3" : ""}>
                                    <Field
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="reference"
                                        isRequired
                                        value={values?.reference}
                                        formGroupClassName="my-0"
                                    />
                                </Col>
                            </Col>
                            <Col
                                xs={12}
                                md={2}
                                lg={2}
                                className={classNames("ml-auto my-0 py-0", { "align-items-end pl-2": isDesktop })}>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="transactions.list.filters.button.filter"
                                    loading={fetching}
                                    className={classNames("w-100 mr-md-0", {
                                        "mb-0": isSmallDesktop,
                                        "my-0 filter-button": isDesktop,
                                        "my-4": !isDesktop,
                                    })}
                                    onClick={()=>handleFilterButton(values)}
                                />
                            </Col>
                        </Container>
                    </>
                )}
            </Form>
        </>
    );
}

MultipleTransferFilters.propTypes = {
    isDesktop: bool.isRequired,
    isSmallDesktop: bool.isRequired,
    values: shape({}).isRequired,
    setValues: func.isRequired,
    filters: shape({}).isRequired,
    statusList: arrayOf(string).isRequired,
    fetching: bool.isRequired,
    dispatch: func.isRequired,
};

export default compose(
    withFormik({
        mapPropsToValues: ({ filters }) => filters,
        validationSchema: () => {
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
            return (
                Yup.lazy((values) =>
                    Yup.object().shape({
                        status: Yup.string().required(i18n.get(`${FORM_ID}.filter.error.required`)),
                        batchId: Yup.string(),
                        dateFrom: Yup.date()
                            .nullable()
                            .max(Yup.ref("dateTo"), i18n.get("accounts.movements.filters.from.error"))
                            .required(i18n.get(`${FORM_ID}.field.error.required`)),
                        dateTo: Yup.date()
                            .nullable()
                            .required(i18n.get(`${FORM_ID}.field.error.required`)),
                        amountFrom: values.amountTo
                            ? Yup.number().max(values.amountTo, i18n.get(`${FORM_ID}.filter.amountFrom.error`))
                            : Yup.number().nullable(),
                        amountTo: values.amountFrom
                            ? Yup.number().min(values.amountFrom, i18n.get(`${FORM_ID}.filter.amountTo.error`))
                            : Yup.number().nullable(),
                        reference: Yup.string(),
                        ...(enableRangeDatefield && { 
                            dates: Yup.mixed().test(
                                "dates",
                                i18n.get(`${FORM_ID}.validation.range.date`),
                                ([from, to]) => from && to
                            )
                        })
                    }),
                )
            )
        },
        handleSubmit: (filters, formikBag) => {
            const { dispatch, isDesktop } = formikBag.props;
            const { dates, ...filtersRest } = filters
            dispatch(multipleTransferActions.getMultipleTransfersList({ ...filtersRest, ...(dates && {dateFrom: dates[0], dateTo: dates[1]}), pageNumber: 1 }, isDesktop));
        },
    }),
)(MultipleTransferFilters);
