/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { goBack, replace } from "react-router-redux";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";

import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import * as config from "util/config";

import {
    Box,
    Button,
    Col,
    Container,
    Grid,
    Tab,
    Tabs,
    Text,
    ThemeProvider,
} from "@ui-kit/components/index.es";
import TransferInternalForm from "./TransferInternalForm";
import TransferThirdPartiesForm from "./TransferThirdPartiesForm";
import TransferToContactForm from "./TransferToContactForm";

const MAX_REQUIRED = "22";
const TRANSFER_GROUPS = {
    Internal: "internal",
    ThirdParties: "thirdParties",
    ToContact: "toContact",
};

function V2Transfer(props) {
    V2Transfer.propTypes = {
        fetching: bool.isRequired,
        dispatch: func.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        transferGroup: string.isRequired,
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        templates: {},
        idTransaction: string.isRequired,
        concepts: arrayOf(shape({})).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                transferInternal: bool,
                transferThirdParties: bool,
            }),
        }).isRequired,
        isRetailEnvironment: bool.isRequired,
        isCVU: bool.isRequired,
        summary: shape({
            cbu: string.isRequired,
            email: string.isRequired,
            transferKind: string.isRequired,
        }).isRequired,
    };

    V2Transfer.defaultProps = {
        templates: null,
    };

    const {
        dispatch,
        match,
        transferGroup,
        isDesktop,
        location,
        activeEnvironment,
    } = props;

    const mvp5 = config.getBoolean("frontend.showMVP5.transfers.functionalities");

    const [agendaVisible, setAgendaVisible] = useState(true);
    const [isTemplatesVisible, setIsTemplatesVisible] = useState(false);

    useEffect(() => {
      return () => {
        dispatch(templateActions.closeCreateModal())
      }
    }, [])


    const handleBack = () => dispatch(goBack());

    const handleCreateTemplateClick = (values) => {
        dispatch(templateActions.createTemplate(values));
    };

    const changeTransferGroup = (newTransferGroup) => {
        dispatch(transferActions.setTransferGroup(newTransferGroup));
        dispatch(transferActions.clearTransferData());
        setAgendaVisible(true);
    };

    const isValidCbu = (value) => {
        const reg = /^\d+$/;
        return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
    };

    if (location?.pathname.startsWith("/v2/transfer/toContact") && transferGroup !== TRANSFER_GROUPS.ToContact) {
        dispatch(transferActions.setTransferGroup(TRANSFER_GROUPS.ToContact));
    }
    if (location?.pathname.startsWith("/v2/transfer/internal") && transferGroup !== TRANSFER_GROUPS.Internal) {
        dispatch(transferActions.setTransferGroup(TRANSFER_GROUPS.Internal));
    }
    if (location?.pathname.startsWith("/v2/transfer/thirdParties") && transferGroup !== TRANSFER_GROUPS.ThirdParties) {
        dispatch(transferActions.setTransferGroup(TRANSFER_GROUPS.ThirdParties));
    }

    return (
        <ThemeProvider>
            <Notification scopeToShow="form" />
            {!isDesktop && (
                <Box className="admin-detail-head z-index-1 px-0">
                    <Head
                        onBack={handleBack}
                        headerClassName="blue-main-header-mobile"
                        centerElement={() =>
                            <Text as="subtitle" className="m-0 no-wrap text-white font-medium">
                                {i18n.get(
                                    location?.pathname === "/v2/transfer/internal" ? 
                                        "transfers.form.internal.title.mb"
                                    : 
                                    location?.pathname === "/v2/transfer/toContact" ? 
                                        "transfers.form.toContact.title.mb"
                                        : "transfers.form.thirdParties.title.mb")}
                            </Text>
                        }
                        rightContent={() => (
                            <Button
                                variant="solid"
                                icon="NewspaperAlt"
                                iconVariant="outline"
                                className="p-0"
                                size="large"
                                onClick={() => {
                                    setIsTemplatesVisible(true);
                                }}
                            />
                        )}
                        accessibilityTextId="transfers.make.transfer.title"
                    />
                </Box>
            )}
            <Container fixed className="h-100">
                {isDesktop && (
                    <Box className="admin-detail-head px-0">
                        <Head onBack={handleBack} accessibilityTextId="transfers.make.transfer.title" />
                        <Box flex align="center" justify="between" mb={24}>
                            <Text variant="h2">{i18n.get("transfers.make.transfer.title")}</Text>
                            <Button
                                justify="right"
                                text={i18n.get("transfers.templates")}
                                variant="outline"
                                icon="NewspaperAlt"
                                iconVariant="outline"
                                onClick={() => {
                                    setIsTemplatesVisible(true);
                                }}
                            />
                        </Box>
                    </Box>
                )}
                <Grid>
                    <Col size={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 2, lg: 3 }}>
                        {isDesktop ? (
                            <Tabs defaultActiveKey={match.params.transferType || "internal"}>
                                {activeEnvironment.permissions.transferInternal && (
                                    <Tab
                                        eventKey="internal"
                                        label={i18n.get("transfers.form.internal.title")}
                                        onClick={() => {
                                            changeTransferGroup(TRANSFER_GROUPS.Internal);
                                            dispatch(replace("/v2/transfer/internal"));
                                        }}>
                                        {transferGroup === TRANSFER_GROUPS.Internal && (
                                            <TransferInternalForm
                                                isDesktop={isDesktop}
                                                transferGroup={transferGroup}
                                                onCreateTemplate={handleCreateTemplateClick}
                                                agendaVisible={agendaVisible}
                                                setAgendaVisible={setAgendaVisible}
                                                isTemplatesVisible={isTemplatesVisible}
                                                setIsTemplatesVisible={setIsTemplatesVisible}
                                                location={location}
                                            />
                                        )}
                                    </Tab>
                                )}
                                {activeEnvironment.permissions.transferThirdParties && (
                                    <Tab
                                        eventKey="thirdParties"
                                        label={i18n.get("transfers.form.thirdParties.title")}
                                        onClick={() => {
                                            changeTransferGroup(TRANSFER_GROUPS.ThirdParties);
                                            dispatch(replace("/v2/transfer/thirdParties"));
                                        }}>
                                        {transferGroup === TRANSFER_GROUPS.ThirdParties && (
                                            <TransferThirdPartiesForm
                                                isDesktop={isDesktop}
                                                transferGroup={transferGroup}
                                                onCreateTemplate={handleCreateTemplateClick}
                                                isValidCbu={isValidCbu}
                                                agendaVisible={agendaVisible}
                                                setAgendaVisible={setAgendaVisible}
                                                isTemplatesVisible={isTemplatesVisible}
                                                setIsTemplatesVisible={setIsTemplatesVisible}
                                                location={location}
                                        />)}
                                    </Tab>
                                )}
                                {mvp5 &&
                                    activeEnvironment.permissions.transferToContact &&
                                    activeEnvironment.permissions.frequentDestination && (
                                        <Tab
                                            eventKey="toContact"
                                            label={i18n.get("transfers.form.contact.title")}
                                            onClick={() => {
                                                changeTransferGroup(TRANSFER_GROUPS.ToContact);
                                                dispatch(replace("/v2/transfer/toContact"));
                                            }}>
                                            {transferGroup === TRANSFER_GROUPS.ToContact && (
                                                <TransferToContactForm
                                                    isDesktop={isDesktop}
                                                    transferGroup={transferGroup}
                                                    onCreateTemplate={handleCreateTemplateClick}
                                                    isValidCbu={isValidCbu}
                                                    schedulerButtonVisible={agendaVisible}
                                                    setSchedulerButtonVisible={setAgendaVisible}
                                                    isTemplatesVisible={isTemplatesVisible}
                                                    setIsTemplatesVisible={setIsTemplatesVisible}
                                                    location={location}
                                            />)}
                                        </Tab>
                                )}
                            </Tabs>
                        ) : (
                            <>
                                {activeEnvironment.permissions.transferInternal &&
                                    match.params.transferType === "internal" && (
                                        <TransferInternalForm
                                            isDesktop={isDesktop}
                                            transferGroup={transferGroup}
                                            onCreateTemplate={handleCreateTemplateClick}
                                            agendaVisible={agendaVisible}
                                            setAgendaVisible={setAgendaVisible}
                                            isTemplatesVisible={isTemplatesVisible}
                                            setIsTemplatesVisible={setIsTemplatesVisible}
                                            location={location}
                                        />
                                    )}
                                {activeEnvironment.permissions.transferThirdParties &&
                                    match.params.transferType === "thirdParties" && (
                                        <TransferThirdPartiesForm
                                            isDesktop={isDesktop}
                                            transferGroup={transferGroup}
                                            onCreateTemplate={handleCreateTemplateClick}
                                            isValidCbu={isValidCbu}
                                            agendaVisible={agendaVisible}
                                            setAgendaVisible={setAgendaVisible}
                                            isTemplatesVisible={isTemplatesVisible}
                                            setIsTemplatesVisible={setIsTemplatesVisible}
                                            location={location}
                                        />
                                    )}
                                {mvp5 &&
                                    activeEnvironment.permissions.transferToContact &&
                                    activeEnvironment.permissions.frequentDestination &&
                                    match.params.transferType === "toContact" && (
                                        <TransferToContactForm
                                            isDesktop={isDesktop}
                                            transferGroup={transferGroup}
                                            onCreateTemplate={handleCreateTemplateClick}
                                            isValidCbu={isValidCbu}
                                            schedulerButtonVisible={agendaVisible}
                                            setSchedulerButtonVisible={setAgendaVisible}
                                            isTemplatesVisible={isTemplatesVisible}
                                            setIsTemplatesVisible={setIsTemplatesVisible}
                                            location={location}
                                        />
                                    )}
                            </>
                        )}
                    </Col>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    fetching: transferSelectors.getFetching(state),
    accounts: transferSelectors.getAccounts(state),
    concepts: transferSelectors.getConcepts(state),
    transferGroup: transferSelectors.getTransferGroup(state),
    summary: transferSelectors.getSummary(state),
    templates: templateSelectors.getTemplateList(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    sendEmail: transferSelectors.getSendEmail(state),
    selectedFromAgenda: transferSelectors.getSelectedFromAgenda(state),
});

export default compose(connect(mapStateToProps))(V2Transfer);
