import * as API from "middleware/api";
import * as restAPI from "middleware/apiRESTful";
import { format } from "date-fns";
import * as configUtils from "util/config";

export const openingAccount = (openingAccountData, otp, creationDate, userId) => {
    const { accountType, currency, message, idTransaction } = openingAccountData;
    return API.executeWithAccessToken("/accounts.opening", {
        accountType,
        currency,
        message,
        creationDate,
        userId,
        idTransaction,
        _otp: otp,
    });
};

export const getAccountInfo = (account, isTransfer = false) =>
    API.executeWithAccessToken("/accounts.info", { account, isTransfer });

export const downloadStatement = (idAccount, idStatementLine) =>
    API.executeWithAccessToken("/accounts.downloadStatementLine", { idAccount, idStatementLine });

export const accountOpeningConfirmationPdf = (
    currency,
    accountType,
    message,
    accountNumber,
    cbu,
    accountStatus,
    idComprobante,
    creationDate,
) =>
    API.executeWithAccessToken("/accounts.opening.pdf", {
        currency,
        accountType,
        message,
        accountNumber,
        cbu,
        accountStatus,
        idComprobante,
        creationDate,
    });

export const editMovementNote = (idProduct, idStatement, note) =>
    API.executeWithAccessToken("/notes.editStatementNote", { idProduct, idStatement, note });

export const setFavoriteStatus = (idProduct, userId, favorite) =>
    API.executeWithAccessToken("/accounts.set.favorite", { idProduct, userId, favorite });

export const listAccounts = (userId) => API.executeWithAccessToken("/accounts.list", { userId });

export const historicBalance = (account, dateFrom, dateTo, numberPage) =>
    API.executeWithAccessToken("/accounts.historicBalance", {
        accountId: account.idProduct,
        dateFrom,
        dateTo,
        pageNumber: numberPage,
    });
export const listProyectedBalances = (accountId) =>
    API.executeWithAccessToken("/list.proyected.balance", { accountId });

export const listDiferedMovements = (accountId, pageNumber) =>
    API.executeWithAccessToken("/list.difered.movements", { accountId, pageNumber });

export const listMovements = (idAccount, userId, filters) => {
    let { dateFrom, dateTo } = filters;
    const { channels, check, minAmount, maxAmount, pageNumber, reference } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/accounts.listStatements", {
        idAccount,
        userId,
        channels,
        check,
        dateFrom,
        dateTo,
        minAmount,
        maxAmount,
        pageNumber,
        reference,
    });
};

export const listStatements = (idAccount) =>
    API.executeWithAccessToken("/accounts.listStatementLines", {
        idAccount,
    });

export const movementDetails = (idAccount, idStatement) =>
    API.executeWithAccessToken("/accounts.listStatementDetails", {
        idAccount,
        idStatement,
    });

export const readAccount = (idAccount) => {
    if (configUtils.getBoolean("ms.account.enabled", false)) {
        return restAPI.getWithAccessToken(`/api/accounts/v1/accounts/${idAccount}`);
    }
    return API.executeWithAccessToken("/accounts.read", { idAccount });
};

export const downloadMovements = (idAccount, { dateFrom = null, dateTo = null, ...filters }, fileFormat) =>
    API.executeWithAccessToken("/accounts.downloadMovements", {
        idAccount,
        format: fileFormat,
        dateFrom: dateFrom && format(dateFrom, "YYYY-MM-DD"),
        dateTo: dateTo && format(dateTo, "YYYY-MM-DD"),
        ...filters,
    });

export const downloadAccountsList = (fileFormat) =>
    API.executeWithAccessToken("/accounts.exportList", {
        format: fileFormat,
    });

export const downloadHistoricBalance = (accountId, fileFormat, dateFrom, dateTo) =>
    API.executeWithAccessToken("/accounts.exportHistoricBalance", {
        accountId,
        format: fileFormat,
        dateFrom,
        dateTo,
    });

export const downloadInformation = (
    idAccount,
    { accountLabel, accountNumber, cbu, cbuAlias, ownerName, cuit, format: fileFormat },
) =>
    API.executeWithAccessToken("/accounts.downloadInformation", {
        idAccount,
        productType: accountLabel,
        accountNumber,
        cbu,
        cbuAlias,
        ownerName,
        documentNumber: cuit,
        fileFormat,
    });

export const modifyCbuAlias = (idAccount, data) =>
    API.executeWithAccessToken("/accounts.cbuAlias.modify", {
        idAccount,
        ...data,
    });

export const latestMovements = (
    account,
    latestMovementsPageNumber,
    pendingMovementsPageNumber,
    findBy,
    dateFrom,
    dateTo,
    amountFrom,
    amountTo,
    voucher,
    detail,
    movementType,
    isLatestMovementsSelected,
    longDetail,
) =>
    API.executeWithAccessToken("/list.latest.movements", {
        accountId: account.idProduct,
        latestMovementsPageNumber,
        pendingMovementsPageNumber,
        findBy,
        dateFrom,
        dateTo,
        amountFrom: amountFrom?.amount,
        amountTo: amountTo?.amount,
        voucher,
        detail,
        movementType,
        isLatestMovementsSelected,
        longDetail,
    });

export const downloadLatestMovements = (
    fileFormat,
    isLatestMovementsSelected,
    latestMovementsPageNumber,
    pendingMovementsPageNumber,
    findBy,
    dateFrom,
    dateTo,
    account,
    pageNumber,
    amountF,
    amountT,
    voucher,
    detail,
    movementType,
) =>
    API.executeWithAccessToken("/accounts.exportLatestMovements", {
        format: fileFormat,
        accountId: account.idProduct,
        isLatestMovementsSelected,
        latestMovementsPageNumber,
        pendingMovementsPageNumber,
        findBy,
        dateFrom,
        dateTo,
        number: pageNumber,
        amountFrom: amountF?.amount,
        amountTo: amountT?.amount,
        voucher,
        detail,
        movementType,
    });

export const summariesQuery = (accountId, year, month) =>
    API.executeWithAccessToken("/accounts.summaries.query", {
        accountId,
        year,
        month,
    });

export const summariesExtract = (extractId, productType) =>
    API.executeWithAccessToken("/accounts.summaries.extract", {
        extractId,
        productType,
    });
